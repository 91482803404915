import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { baseUrl } from '../queryMutations/mutationFunctions'

// Define the request parameters type
interface ValidateGoogleTokenParams {
  accessToken: string | null
  refreshToken: string | null
  userId: string | null
}

// Define the expected response type
interface ValidateGoogleTokenResponse {
  isValid: boolean
  accessToken: string | null
  refreshed: boolean
  error?: string
}

// Function to validate and refresh Google token
const validateGoogleToken = async (params: ValidateGoogleTokenParams): Promise<ValidateGoogleTokenResponse> => {
  // console.log('PARAMS', params)
  try {
    const response = await axios.post<ValidateGoogleTokenResponse>(`${baseUrl}/aws/googleapi/validate-token`, params)

    if (response.data.refreshed) {
      console.log('✅ Access token refreshed!')
      localStorage.setItem('google_access_token', response.data.accessToken || '') // ✅ Update stored token
    }

    return {
      isValid: true,
      accessToken: response.data.accessToken,
      refreshed: response.data.refreshed,
    }
  } catch (error) {
    return {
      isValid: false,
      accessToken: null,
      refreshed: false,
      error: error.response?.data || 'Invalid token',
    }
  }
}

// Custom hook for validating Google token
export const useValidateGoogleToken = () => {
  const mutation = useMutation<ValidateGoogleTokenResponse, Error, ValidateGoogleTokenParams>({
    mutationFn: validateGoogleToken, // ✅ Correctly pass the function here
  })

  return {
    validateToken: mutation.mutate, // ✅ Call this function directly
    isPending: mutation.isPending, // ✅ Proper loading state
    isValid: mutation.data?.isValid ?? false, // ✅ Token validity
    accessToken: mutation.data?.accessToken ?? null, // ✅ New access token
    refreshed: mutation.data?.refreshed ?? false, // ✅ If refreshed
    error: mutation.error, // ✅ Error state
  }
}

// import { useQuery } from '@tanstack/react-query'
// import axios from 'axios'
// import { baseUrl } from '../queryMutations/mutationFunctions'

// const validateGoogleToken = async (accessToken: string) => {
//   try {
//     const response = await axios.post(`${baseUrl}/aws/googleapi/validate-token`, { accessToken })
//     return { isValid: true, data: response.data, error: null }
//   } catch (error) {
//     return { isValid: false, data: null, error: error.response?.data || 'Invalid token' }
//   }
// }

// export const useValidateGoogleToken = (accessToken: string | null) => {
//   const { data, isLoading, isError, error } = useQuery({
//     queryKey: ['validateGoogleToken', accessToken],
//     queryFn: () => validateGoogleToken(accessToken!),
//     enabled: !!accessToken, // Only run query if accessToken exists
//     retry: false, // Don't retry failed requests
//   })

//   return {
//     isValid: data?.isValid ?? false, // Default to false if data is undefined
//     error: isError ? error : null,
//     isLoading,
//   }
// }
