import React from 'react'
import MetaLightSvg from '../../images/icons/meta (1).svg'
import GoogleDisplaySvg from '../../images/icons/Google Display.png'
import GoogleSearchSvg from '../../images/icons/Google Ads.png'
import LinkedInSvg from '../../images/icons/linkedin.svg'
import MicrosoftSvg from '../../images/icons/bing.png'
import SimplifiSvg from '../../images/icons/simplifi_logo.svg'
import SnapchatSvg from '../../images/icons/snapchat.svg'
import SpotifySvg from '../../images/icons/spotify.svg'
import TikTokSvg from '../../images/icons/tiktok.svg'
import YoutubeSvg from '../../images/icons/youtube.svg'
import ChannelIcon from '../Icons/ChannelIcon'
const DisplayChannels = ({ deal }) => {
  return (
    <>
      {/* {deal?.isMetaAdvertising && <ChannelIcon title={'Meta Advertising'} src={MetaLightSvg} />}
      {deal?.isGoogleDisplay && <ChannelIcon title={'Google Display'} src={GoogleDisplaySvg} />}
      {deal?.isGoogleSearch && <ChannelIcon title={'Google Search'} src={GoogleSearchSvg} />}
      {deal?.isLinkedIn && <ChannelIcon title={'LinkedIn'} src={LinkedInSvg} />}
      {deal?.isMicrosoftAdvertising && <ChannelIcon title={'Microsoft'} src={MicrosoftSvg} />}
      {deal?.isSimplifi && <ChannelIcon title={'Simplifi'} src={SimplifiSvg} />}
      {deal?.isSnapchat && <ChannelIcon title={'Snapchat'} src={SnapchatSvg} />}
      {deal?.isSpotify && <ChannelIcon title={'Spotify'} src={SpotifySvg} />}
      {deal?.isTikTok && <ChannelIcon title={'TikTok'} src={TikTokSvg} />}
      {deal?.isYoutube && <ChannelIcon title={'Youtube'} src={YoutubeSvg} />} */}
      {<ChannelIcon isContracted={deal?.isMetaAdvertising} title={'Meta Advertising'} src={MetaLightSvg} />}
      {<ChannelIcon isContracted={deal?.isGoogleDisplay} title={'Google Display'} src={GoogleDisplaySvg} />}
      {<ChannelIcon isContracted={deal?.isGoogleSearch} title={'Google Search'} src={GoogleSearchSvg} />}
      {<ChannelIcon isContracted={deal?.isLinkedIn} title={'LinkedIn'} src={LinkedInSvg} />}
      {<ChannelIcon isContracted={deal?.isMicrosoftAdvertising} title={'Microsoft'} src={MicrosoftSvg} />}
      {<ChannelIcon isContracted={deal?.isSimplifi} title={'Simplifi'} src={SimplifiSvg} />}
      {<ChannelIcon isContracted={deal?.isSnapchat} title={'Snapchat'} src={SnapchatSvg} />}
      {<ChannelIcon isContracted={deal?.isSpotify} title={'Spotify'} src={SpotifySvg} />}
      {<ChannelIcon isContracted={deal?.isTikTok} title={'TikTok'} src={TikTokSvg} />}
      {<ChannelIcon isContracted={deal?.isYoutube} title={'Youtube'} src={YoutubeSvg} />}
    </>
  )
}

export default DisplayChannels
