import React, { useEffect, useState, useRef } from 'react'
import {
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarColumnsButton,
  GridFilterOperator,
  GridToolbarQuickFilter,
  useGridApiRef,
  getGridStringOperators,
  getGridNumericOperators,
  getGridBooleanOperators,
  getGridDateOperators,
  getGridSingleSelectOperators,
  gridFocusStateSelector,
  useGridApiContext,
} from '@mui/x-data-grid-pro'
import { Badge, Box, Button, IconButton, Tooltip, Typography, useTheme } from '@mui/material'
import { gridToolbarContainerStyles, StyledGridToolbarQuickFilter } from './style'
import CustomStatusFilter from '../DataGrid/createColumns/CustomStatusFilter'
import { useActiveUser } from '../../api/slack/useActiveUser'
import { useNavigate } from 'react-router-dom'
import ResetFiltersButton from '../ui/reset-filters-button'
import ResetSortingButton from '../ui/reset-sorting-button'
import ChangeViewSelect from '../ui/change-view-select'
import OpenColumnsMenu from '../ui/open-columns-menu'
import OpenFiltersMenu from '../ui/open-filters-menu'
import ExportCsvButton from '../ui/export-csv-button'

export const initialSortModel = [
  { field: 'lastModified', sort: 'desc' },
  { field: 'name', sort: 'asc' },
  { field: 'organizations', sort: 'asc' },
  { field: 'status', sort: 'asc' },
]

const slotProps = theme => ({
  button: {
    sx: {
      borderRadius: 2,
      maxHeight: '100%',
      mt: 2,
      mb: 2,
      '&:hover': { backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.main : 'rgba(0,0,0,0.1)' },
      color: theme.palette.text.primary,
      '& .MuiBadge-badge': {
        backgroundColor: 'rgb(251, 193, 30)', // Your custom background color
        color: theme.palette.mode === 'dark' ? 'rgba(0,0,0,1)' : 'rgba(0,0,0,1)', // Change the text color if needed
        fontSize: '14px', // Optional: adjust the font size
        width: '20px', // Adjust the width/height to control the size of the badge
        height: '20px',
      },
    },
  },
})
const isSortModelEqual = (sortModel1, sortModel2) => {
  if (sortModel1.length !== sortModel2.length) return false
  return sortModel1.every((sortItem, index) => {
    const initialSortItem = sortModel2[index]
    return sortItem.field === initialSortItem.field && sortItem.sort === initialSortItem.sort
  })
}

const CustomAccountingGridToolbar = ({
  densityModel,
  filterModel,
  visibilityModel,
  sortModel,
  setFilterModel,
  onClearSorting,
  onClearVisibility,
  onChangeView,
  context,
}) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const [invoicedActive, setInvoicedActive] = useState(false)
  const [notInvoicedActive, setNotInvoicedActive] = useState(false)
  const textColor = theme.palette.mode === 'dark' ? 'rgba(255,255,255,1)' : 'rgba(0,0,0,1)'
  const gridFilterBgColor = theme.palette.mode === 'dark' ? theme.palette.primary.main : 'rgba(0,0,0,0.1)'
  const slotPropsColor = theme.palette.text.primary
  const quickFilterRef = useRef(null) // Create a ref for the quick filter
  const [filterStatus, setFilterStatus] = useState('')
  const [filterStage, setFilterStage] = useState('')
  const [hoverOpen, setHoverOpen] = useState(false) // Manage hover open state
  const [selectedView, setSelectedView] = useState(() => {
    const savedView = localStorage.getItem('accountingSelectedView')
    return savedView ? JSON.parse(savedView) : 'invoice'
  })
  useEffect(() => {
    if (selectedView) {
      console.log('selectedView', selectedView)
    }
  }, [selectedView])
  const { activeUser, isActiveUserLoading, isActiveUserError } = useActiveUser()
  const activeUserName =
    activeUser && activeUser.user && activeUser.user.profile ? activeUser.user.profile.real_name : null
  /////////////////////// KanBan navigation ///////////////////////////////
  const isSortModelChanged = !isSortModelEqual(sortModel, initialSortModel)
  const apiRef = useGridApiContext() // Get DataGrid API

  // 🔹 Toggle Columns Visibility Menu
  const handleOpenColumnsMenu = event => {
    apiRef.current.showPreferences('columns')
  }

  // 🔹 Toggle Filter Panel
  const handleOpenFilterPanel = () => {
    apiRef.current.showFilterPanel()
  }

  // 🔹 Export Data
  const handleExport = () => {
    apiRef.current.exportDataAsCsv()
  }
  ///////////////////////////////////////////////////////////////////////////\
  // Utility function to check if two sort models are deeply equal

  // Handle keydown event to focus the quick filter on Ctrl+Shift+F or Cmd+Shift+F
  useEffect(() => {
    const handleKeyDown = event => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'f') {
        event.preventDefault()
        if (quickFilterRef.current) {
          quickFilterRef.current.focus() // Focus the quick filter input
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])
  const handleSetFilter = (fieldName, newValue) => {
    const updatedFilters = filterModel.items.filter(item => item.field !== fieldName)

    updatedFilters.push({
      id: `${fieldName}-${Date.now()}`, // Add a unique ID
      field: fieldName,
      operator: 'contains',
      value: newValue,
    })

    setFilterModel({
      ...filterModel,
      items: updatedFilters,
    })
  }

  const handleSetCustomFilters = (fieldName, operator, newValue) => {
    const updatedFilters = filterModel.items.filter(item => item.field !== fieldName)

    updatedFilters.push({
      id: `${fieldName}-${Date.now()}`, // Add a unique ID
      field: fieldName,
      operator: operator,
      value: newValue,
    })

    setFilterModel({
      ...filterModel,
      items: updatedFilters,
    })
  }
  const handleFilterInvoiced = () => {
    setInvoicedActive(true)
    setNotInvoicedActive(false)
    handleSetCustomFilters('invoiceStatus', 'equals', 'Invoiced')
  }
  const handleFilterNotInvoiced = () => {
    setNotInvoicedActive(true)
    handleSetCustomFilters('invoiceStatus', 'equals', 'Not Invoiced')
    setInvoicedActive(false)
  }

  const handleClearFilters = e => {
    e.preventDefault()
    setFilterModel({ items: [], quickFilterValues: [] })
    setFilterStatus('')
    setFilterStage('')
    setInvoicedActive(false)
    setNotInvoicedActive(false)
  }

  return (
    <GridToolbarContainer sx={gridToolbarContainerStyles}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignContent: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2, ml: 2 }}>
          <ChangeViewSelect
            onChangeView={newView => onChangeView(newView)}
            selectedView={selectedView}
            setSelectedView={setSelectedView}
          />
          <GridToolbarDensitySelector slotProps={slotProps(theme)} />
          <OpenColumnsMenu onOpenColumns={handleOpenColumnsMenu} />
          <OpenFiltersMenu
            onOpenFilters={handleOpenFilterPanel}
            filterModel={filterModel}
            badgeColor={theme.palette.icon.accounting}
          />
          <ExportCsvButton onExport={handleExport} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <>
            {selectedView === 'billing' ? (
              <CustomStatusFilter
                context={'billingStatus'}
                options={[
                  { label: 'Current (<60)', color: theme.palette.text.success },
                  { label: 'Past Due (60+)', color: theme.palette.text.warning },
                  { label: 'Past Due (90+)', color: theme.palette.text.error },
                  { label: 'Potential Bad Debt', color: theme.palette.text.error },
                ]}
                setterFn={setFilterStatus}
                value={filterStatus}
                onChange={handleSetFilter}
              />
            ) : null}

            <Button
              sx={{
                height: 40,
                color: theme.palette.text.success,
              }}
              onClick={handleFilterInvoiced}
            >
              <Typography sx={{ fontWeight: invoicedActive ? 'bold' : '', fontStyle: invoicedActive ? 'oblique' : '' }}>
                Invoiced
              </Typography>
            </Button>
            <Button
              sx={{
                height: 40,
                color: theme.palette.text.warning,
              }}
              onClick={handleFilterNotInvoiced}
            >
              <Typography
                sx={{ fontWeight: notInvoicedActive ? 'bold' : '', fontStyle: notInvoicedActive ? 'oblique' : '' }}
              >
                Not Invoiced
              </Typography>
            </Button>
            {/* <ResetColumnsButton
              visibilityModel={visibilityModel}
              onClearVisibility={onClearVisibility}
              initialVisibilityState={initialVisibilityState}
            /> */}
            <ResetSortingButton isSortModelChanged={isSortModelChanged} onClearSorting={onClearSorting} />
            <ResetFiltersButton filterModel={filterModel} handleClearFilters={handleClearFilters} />
            <Badge
              badgeContent={
                <>
                  {filterModel &&
                  filterModel.quickFilterValues &&
                  Array.isArray(filterModel.quickFilterValues) &&
                  filterModel.quickFilterValues.length > 0 ? (
                    <Typography
                      sx={{
                        fontSize: 12,
                        bgcolor: 'rgb(251, 193, 30)',
                        p: 0.5,
                        height: '20px',
                        width: '20px',
                        borderRadius: '50%',
                        display: 'flex', // Flexbox centering
                        alignItems: 'center', // Vertically center
                        justifyContent: 'center', // Horizontally center
                        color: theme.palette.mode === 'dark' ? 'rgba(0,0,0,1)' : 'rgba(0,0,0,1)',
                        fontWeight: 'bold',
                      }}
                    >
                      {filterModel && filterModel.quickFilterValues && Array.isArray(filterModel.quickFilterValues)
                        ? filterModel.quickFilterValues.length
                        : null}
                    </Typography>
                  ) : null}
                </>
              }
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <StyledGridToolbarQuickFilter placeholder={'ctrl+f or cmd+f to search'} inputRef={quickFilterRef} />
            </Badge>
          </>
        </Box>
      </Box>
    </GridToolbarContainer>
  )
}

export default CustomAccountingGridToolbar
