import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import { baseUrl } from '../queryMutations/queryMutations'

interface DriveFile {
  id?: string
  name?: string
  mimeType?: string
  webViewLink?: string
  parents?: string[]
  createdTime?: string
  modifiedTime?: string
}
interface Drive {
  id: string
  kind: string
  name: string
}
interface GroboticDriveResponse {
  drive: Drive[]
  folders: DriveFile[]
}
const getGroboticDrive = async (): Promise<GroboticDriveResponse> => {
  const apiUrl = `${baseUrl}/aws/googleapi/grobotic-drive`

  const googleToken = localStorage.getItem('google_access_token')
  if (!googleToken) {
    console.error('No authentication token available')
    return { drive: [], folders: [] } // ✅ Return correctly typed response
  }

  try {
    const response = await axios.get<GroboticDriveResponse>(apiUrl, {
      headers: { Authorization: `Bearer ${googleToken}` },
    })

    // console.log(`📂 Grobotic Drive:`, response.data)
    return response.data
  } catch (error) {
    console.error('❌ Error fetching folders:', error)
    return { drive: [], folders: [] } // ✅ Ensure return type always matches
  }
}
export const useGroboticDrive = (enabled: boolean) => {
  const { data: groboticDrive, isLoading: isGroboticDriveLoading, isError: isGroboticDriveError } = useQuery({
    queryKey: ['groboticDrive'], // ✅ Dynamically updates when  changes
    queryFn: () => getGroboticDrive(),
    enabled: enabled ?? false, // ✅ Prevents fetching if no folder is selected
    staleTime: 60000, // Cache data for 1 minute
    refetchOnWindowFocus: false, // Avoid unnecessary re-fetching
  })
  return { groboticDrive, isGroboticDriveLoading, isGroboticDriveError }
}
