import React, { createContext, useState, useContext } from 'react'

const AuthContext = createContext()

export const useAuth = () => useContext(AuthContext)

export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    isAuthenticated: false,
    user: null,
  })

  const redirectToSSOAuth = () => {
    console.log('redirectToSSOAuth Triggered')
    const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID
    const redirectUri = process.env.REACT_APP_REDIRECT_URI
    const scope = [
      'openid',
      'https://www.googleapis.com/auth/userinfo.email',
      'https://www.googleapis.com/auth/userinfo.profile',
      'https://www.googleapis.com/auth/drive',
      'https://www.googleapis.com/auth/drive.install',
      'https://www.googleapis.com/auth/drive.meet.readonly',
      'https://www.googleapis.com/auth/drive.photos.readonly',
      'https://www.googleapis.com/auth/drive.readonly',
      // 'https://www.googleapis.com/auth/gmail.readonly',
      // 'https://www.googleapis.com/auth/gmail.compose',
      // 'https://www.googleapis.com/auth/gmail.send',
      // 'https://www.googleapis.com/auth/gmail.modify',
      'https://www.googleapis.com/auth/documents.readonly',
      'https://www.googleapis.com/auth/documents',
      'https://www.googleapis.com/auth/docs',
      'https://www.googleapis.com/auth/spreadsheets',
      'https://www.googleapis.com/auth/spreadsheets.readonly',
      'https://www.googleapis.com/auth/activity',
      'https://www.googleapis.com/auth/drive.apps',
      // 'https://www.googleapis.com/auth/script.external_request',
      // 'https://www.googleapis.com/auth/script.scriptapp',
      // 'https://www.googleapis.com/auth/script.projects',
      // 'https://www.googleapis.com/auth/adwords',
    ].join(' ')
    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?scope=${encodeURIComponent(
      scope
    )}&response_type=code&client_id=${clientId}&redirect_uri=${encodeURIComponent(
      redirectUri
    )}&access_type=offline&prompt=consent&state=google`
    window.location.href = authUrl
  }

  const redirectToSlackAuth = () => {
    console.log('redirectToSlackAuth Triggered')
    const slackClientId = process.env.REACT_APP_SLACK_CLIENT_ID
    const scope = 'users:read,chat:write'
    const userScope = 'openid+profile+email'
    const slackRedirectUrl = process.env.REACT_APP_SLACK_REDIRECT_URI
    const slackAuthUrl = `https://slack.com/oauth/v2/authorize?client_id=${slackClientId}&scope=${scope}&user_scope=${userScope}&redirect_uri=${slackRedirectUrl}&state=slack`
    window.location.href = slackAuthUrl
  }

  const redirectToPandaAuth = () => {
    console.log('redirectToPandaAuth Triggered')
    const pandaClientId = process.env.REACT_APP_PANDA_CLIENT_ID
    const scope = 'read+write'
    const pandaRedirectUri = process.env.REACT_APP_PANDA_REDIRECT_URI
    const pandaAuthUrl = `https://app.pandadoc.com/oauth2/authorize?client_id=${pandaClientId}&redirect_uri=${pandaRedirectUri}&scope=${scope}&response_type=code&state=panda`
    window.location.href = pandaAuthUrl
  }

  return (
    <AuthContext.Provider
      value={{
        authState,
        setAuthState,
        redirectToSSOAuth,
        redirectToSlackAuth,
        redirectToPandaAuth,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
