export const handleCopy = (content, setIsCopied) => {
  if (typeof content === 'string') {
    navigator.clipboard
      .write([
        new ClipboardItem({
          'text/html': new Blob([content], { type: 'text/html' }),
          'text/plain': new Blob([content.replace(/<[^>]*>/g, '')], { type: 'text/plain' }), // Fallback to plain text
        }),
      ])
      .then(() => {
        setIsCopied(true)
        setTimeout(() => setIsCopied(false), 2000) // Reset copied state after 2 seconds
      })
      .catch(err => {
        console.error('Failed to copy:', err)
      })
  } else {
    console.error('Invalid content: Expected a string for the tooltip content.')
  }
}
