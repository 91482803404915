import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import duration from 'dayjs/plugin/duration'
import minMax from 'dayjs/plugin/minMax'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(duration)
dayjs.extend(minMax)

export const calculatePaymentSchedule2025 = deal => {
  if (!deal || !deal.products) return []

  const validProducts = deal.products.filter(product => product.startDate && product.endDate)
  if (validProducts.length === 0) return []

  const start = dayjs.min(validProducts.map(p => dayjs(p.startDate)))
  const end = dayjs.max(validProducts.map(p => dayjs(p.endDate)))

  const paymentSchedule = []
  let currentMonth = start.startOf('month')

  while (currentMonth.isSameOrBefore(end)) {
    const month = currentMonth.format('MM')
    const year = currentMonth.format('YYYY')

    let paymentAmount = 0
    let activeDays = 0
    let productsAssociated = []

    validProducts.forEach(product => {
      const productStart = dayjs(product.startDate)
      const productEnd = dayjs(product.endDate)

      if (
        productStart.isSameOrBefore(currentMonth.endOf('month')) &&
        productEnd.isSameOrAfter(currentMonth.startOf('month'))
      ) {
        productsAssociated.push(product.productName)

        // Get overlap days for the product in the current month
        const overlapStart = productStart.isBefore(currentMonth.startOf('month'))
          ? currentMonth.startOf('month')
          : productStart
        const overlapEnd = productEnd.isAfter(currentMonth.endOf('month')) ? currentMonth.endOf('month') : productEnd
        const daysInMonth = currentMonth.daysInMonth()
        const daysActive = overlapEnd.diff(overlapStart, 'days') + 1

        activeDays += daysActive

        // Determine if the product should count as a full month
        let monthFactor = 1 // Default to full month
        if (daysActive < 2) monthFactor = 0
        // Ignore if only active for 1 day
        else if (daysActive < 15) monthFactor = daysActive / daysInMonth // Partial month weighting

        const totalMonths = Math.max(1, productEnd.diff(productStart, 'month', true))
        const productPrice = parseFloat(product.price)

        paymentAmount += productPrice * monthFactor
      }
    })

    paymentSchedule.push({
      month: currentMonth.format('MMMM'),
      year: currentMonth.year(),
      paymentAmount: parseFloat(paymentAmount.toFixed(2)),
      activeDays,
      productsAssociated: [...new Set(productsAssociated)].join(', '),
    })

    currentMonth = currentMonth.add(1, 'month')
  }

  return paymentSchedule
}
