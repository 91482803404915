import React, { useRef, useState } from 'react'
import { Autocomplete, TextField, Chip, Typography, Box, useTheme } from '@mui/material'
import { contactChipStyle } from '../ContractDialog/style'
import { useContacts } from '../../api/aws/useContacts'
import CheckIcon from '@mui/icons-material/Check'
import { renderUserOption } from '../Users/renderOptions'

const ContactRecipientAutocomplete = ({ recipients, value, label, onChange, error, isDraft, isCurrentRecipient }) => {
  const theme = useTheme()
  const inputRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [selectedUsers, setSelectedUsers] = useState(value || [])
  const handleSelectUser = (event, newValue) => {
    setSelectedUsers(newValue)
    onChange(newValue)
  }

  const handleRemoveUser = contactId => {
    const newSelectedContacts = selectedUsers.filter(
      contact => contact.id !== contactId && contact.contacts_uuid !== contactId
    )
    setSelectedUsers(newSelectedContacts)
    onChange(newSelectedContacts)
    if (inputRef.current) inputRef.current.focus()
    setIsOpen(true)
  }
  return (
    <Autocomplete
      multiple
      open={isOpen}
      id='contact-recipient-autocomplete'
      disabled={!isDraft ?? false}
      options={recipients || []}
      getOptionLabel={option => {
        const firstName = option.first_name || option.firstName || ''
        const lastName = option.last_name || option.lastName || ''
        return `${firstName} ${lastName} (${option.email})`
      }}
      value={selectedUsers}
      onFocus={() => setIsOpen(true)}
      onBlur={() => setIsOpen(false)}
      onChange={handleSelectUser}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
      filterOptions={(options, { inputValue }) => {
        return options.filter(option => {
          const firstName = option?.first_name || option?.firstName || ''
          const lastName = option?.last_name || option?.lastName || ''
          const fullName = `${firstName} ${lastName}`.toLowerCase() || `${option?.name}`.toLowerCase()
          return (
            fullName.includes(inputValue?.toLowerCase()) ||
            option?.email?.toLowerCase().includes(inputValue?.toLowerCase())
          )
        })
      }}
      // isOptionEqualToValue={(option, value) => {
      //   console.log('option', option?.id, 'value', value?.id)
      //   return (option.id || '') === (value?.contacts_uuid || value?.contact_id || value.id || '')
      // }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      // renderOption={(props, option) => {
      //   const firstName = option.first_name || option.firstName || ''
      //   const lastName = option.last_name || option.lastName || ''
      //   return (
      //     <Box {...props} key={option.id || option.contacts_uuid} sx={{ display: 'flex', flexDirection: 'column' }}>
      //       <Typography flex={1} sx={{ textAlign: 'left', width: '100%' }}>{`${firstName} ${lastName}`}</Typography>
      //       {option?.email && (
      //         <Typography
      //           sx={{
      //             color: theme.palette.text.secondary,
      //             fontSize: '11px',
      //             fontStyle: 'italic',
      //             textAlign: 'left',
      //             width: '100%',
      //           }}
      //         >
      //           {option.email}
      //         </Typography>
      //       )}
      //     </Box>
      //   )
      // }}
      renderOption={(props, option, state) => renderUserOption(props, option, state, theme)}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          const { key, onDelete, ...rest } = getTagProps({ index })
          const firstName = option.first_name || option.firstName || ''
          const lastName = option.last_name || option.lastName || ''
          return (
            <Chip
              key={key}
              label={`${firstName} ${lastName}`}
              {...rest}
              onDelete={() => handleRemoveUser(option.id || option.contacts_uuid)}
              sx={contactChipStyle(theme)}
              icon={isCurrentRecipient ? <CheckIcon /> : null}
            />
          )
        })
      }
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          error={error}
          placeholder={selectedUsers?.length > 0 ? 'Addtl Recipients' : 'Select Contact Recipient'}
          inputRef={inputRef}
          InputLabelProps={{ shrink: true }}
          sx={{
            '& .MuiOutlinedInput-notchedOutline': { border: 'none !important' },
            '& .MuiInputBase-input::placeholder': { color: theme.palette.text.secondary, fontStyle: 'italic' },
          }}
        />
      )}
      fullWidth
    />
  )
}
export default ContactRecipientAutocomplete
