import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { baseUrl } from '../queryMutations/queryMutations'

const getOrganizationSubfolders = async (orgFolderId: string, filter?: string) => {
  if (!orgFolderId) return []

  const response = await axios.get(`${baseUrl}/aws/googleapi/drive/subfolders`, {
    params: { orgFolderId, filter },
    headers: { Authorization: `Bearer ${localStorage.getItem('google_access_token')}` },
  })

  return response.data.subfolders
}

export const useOrganizationSubfolders = (orgFolderId: string | null, filter?: string) => {
  return useQuery({
    queryKey: ['organizationSubfolders', orgFolderId, filter],
    queryFn: () => getOrganizationSubfolders(orgFolderId!, filter),
    enabled: !!orgFolderId, // Only fetch if orgFolderId exists
    staleTime: 60000, // Cache for 1 min
  })
}
