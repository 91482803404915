import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { baseUrl } from '../queryMutations/mutationFunctions'

const downloadGoogleFile = async (fileId: string) => {
  const apiUrl = `${baseUrl}/aws/googleapi/download`
  const googleAccessToken = localStorage.getItem('google_access_token')

  if (!googleAccessToken) {
    throw new Error('No authentication token available')
  }

  try {
    const response = await axios.post(
      apiUrl,
      { fileId, googleAccessToken }, // ✅ Send fileId in request body
      {
        headers: { Authorization: `Bearer ${googleAccessToken}` },
        responseType: 'blob', // ✅ Treat response as a binary file
        transformResponse: response => response, // 🔥 Prevent Axios from modifying response headers
      }
    )

    console.log('RESPONSE HEADERS: ', response.headers) // 🔍 Debugging step

    // ✅ Extract filename from Content-Disposition header
    let fileName = 'downloaded-file.pdf' // Default fallback
    const contentDisposition = response.headers['content-disposition']
    console.log('contentDisposition', contentDisposition)

    if (contentDisposition) {
      fileName = contentDisposition
    }

    console.log(`Downloading file as: ${fileName}`)

    // ✅ Create a URL for the downloaded file
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', fileName) // ✅ Uses backend-provided filename
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)

    console.log('Successfully downloaded file:', response)
    return response.data
  } catch (error) {
    console.error('[useGoogleDownloadFile] Error downloading Google File:', error)
    throw error
  }
}

// ✅ Use in React Query
export const useGoogleDownloadFile = () => {
  return useMutation({
    mutationFn: (fileId: string) => downloadGoogleFile(fileId),
    onSuccess: () => console.log('Successfully downloaded Google File'),
    onError: error => console.error('[useGoogleDownloadFile] Error:', error),
  })
}
