import React, { useEffect } from 'react'
import { Box, Typography, Grid, useTheme, CircularProgress, Button } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useOrganizationById } from '../../api/aws/useOrganization'
import OrganizationInfoCard from './OrganizationInfoCard'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import { useUpdateItems } from '../../api/aws/useUpdateItems'
import TeamMembers from '../TeamMembers/TeamMembers'
import { useUpdateWebsite } from '../../api/aws/useUpdateWebsite'
import { useQueryClient } from '@tanstack/react-query'
import { useValidationContext } from '../../context/ValidationProvider/ValidationProvider'
import DetailsLoading from '../Loading/DetailsLoading'
import { useContact } from '../../api/aws/useContact'
import { useUsers } from '../../api/aws/useUsers'
import { useNewContactsById } from '../../api/customHooks/useNewContactsById'
import { useCreateGoogleFolder } from '../../api/google/useCreateGoogleFolder.ts'
import { useCreateGoogleFolders } from '../../api/google/useCreateGoogleFolders.ts'
import { useGoogleFilesByFolderId } from '../../api/google/useGoogleFilesByFolderId.ts'
import GoogleDriveSection from '../../api/google/GoogleDriveSection.tsx'
import DriveFoldersById from '../Google/DriveFoldersById.tsx'

const OrganizationDetails = () => {
  const { orgId } = useParams()
  const queryClient = useQueryClient()
  const { organization, isOrganizationLoading, isOrganizationError } = useOrganizationById(orgId)
  const { isValidWebsite } = useValidationContext()
  const { showSnackbar } = useSnackbarContext()
  const theme = useTheme()
  const { users, isUsersLoading, isUsersError } = useUsers(true, ['orgDetails', 'users'])
  const {
    data: primaryContact,
    isLoading: isPrimaryContactLoading,
    isError: isPrimaryContactError,
  } = useContact(organization?.primaryContact?.[0], ['primaryContact', organization?.primaryContact?.[0]])
  const {
    data: billingContact,
    isLoading: isBillingContactLoading,
    isError: isBillingContactError,
  } = useContact(organization?.billingContact?.[0], ['billingContact', organization?.billingContact?.[0]])
  const { newContacts: additionalContacts, isContactsByIdLoading: isAddtlContactsLoading } = useNewContactsById(
    organization?.contacts,
    'additionalContacts'
  )
  const { data: orgFolders, isLoading: isOrgFoldersLoading } = useGoogleFilesByFolderId(
    organization?.googleDriveFolderId
  )

  const { mutate: updateItems } = useUpdateItems()
  const { mutate: updateWebsite } = useUpdateWebsite()
  const { mutate: createGoogleFolder } = useCreateGoogleFolder()
  const { mutate: createGoogleFolders } = useCreateGoogleFolders()

  const dealIds = organization?.dealIds || []
  const gridBgColor = theme.palette.mode === 'dark' ? '#1e2735' : 'rgba(255,255,255)'

  const handleChange = async (fieldName, newValue) => {
    // Update local state immediately
    // setOrganization(prev => ({ ...prev, [fieldName]: newValue }))
    await queryClient.setQueryData(['organization', orgId], old => ({
      ...old,
      [fieldName]: newValue,
    }))
  }
  useEffect(() => {
    if (orgFolders && !isOrgFoldersLoading) {
      console.log('orgFolders', orgFolders)
    }
  }, [orgFolders])
  const handleUpdate = (fieldName, newValue) => {
    // Trigger API update only on blur
    if (organization[fieldName] !== newValue) {
      const updates = [
        {
          primaryKey: 'org_uuid',
          tableName: 'organizations',
          itemId: orgId,
          fieldName,
          newValue,
        },
      ]

      updateItems(
        { updates },
        {
          onSuccess: () => {
            showSnackbar(`Update Successful for ${fieldName}`)
            queryClient.invalidateQueries(['organization', orgId])
          },
          onError: err => {
            console.error('Update failed', err)
          },
        }
      )
    }
  }
  const handleUpdateWebsite = (fieldName, newValue) => {
    console.log('newValue: ', newValue)
    if (organization[fieldName] !== newValue && isValidWebsite(newValue)) {
      const updates = [
        {
          primaryKey: 'org_uuid',
          tableName: 'organizations',
          itemId: orgId,
          fieldName,
          newValue,
          dealIds: dealIds, // Include dealIds if needed
        },
      ]

      updateWebsite(
        { updates: updates },
        {
          onSuccess: () => {
            console.log('Update successful')
            showSnackbar(`Update Successful for ${fieldName}`)
            queryClient.invalidateQueries(['organization', orgId]) // Invalidate and refetch the organization query
          },
          onError: err => {
            console.error('Update failed', err)
          },
        }
      )
    }
  }
  useEffect(() => {
    if (organization) {
      console.log('organization', organization)
    }
  }, [organization])

  const handleCreateGoogleFolder = () => {
    const groboticDriveClientFolderId = '11kc72jDSrY5SBUF9shoPGgsCFwA9quwi'
    createGoogleFolder({ folderName: 'Test Folder', parentFolderId: organization?.googleDriveFolderId })
  }
  const handleCreateGoogleFolders = () => {
    const groboticDriveClientFolderId = '11kc72jDSrY5SBUF9shoPGgsCFwA9quwi'
    createGoogleFolders({ folderName: organization?.name, parentFolderId: groboticDriveClientFolderId, orgId: orgId })
  }

  const isLoading =
    isOrganizationLoading ||
    isPrimaryContactLoading ||
    isBillingContactLoading ||
    isUsersLoading ||
    isAddtlContactsLoading
  const isError = isOrganizationError || isPrimaryContactError || isBillingContactError
  if (isLoading) {
    return <DetailsLoading />
  }

  if (isError) {
    return <Typography>Error loading component....</Typography>
  }

  if (!organization) {
    return <Typography>No organization data available.</Typography>
  }
  return (
    <Grid
      container
      spacing={2}
      justifyContent='center'
      sx={{
        padding: 5,
        flexGrow: 1,
        minHeight: '100vh',
        boxSizing: 'border-box',
      }}
    >
      <Grid item xs={12} sm={12} md={9} lg={9}>
        {isLoading && <CircularProgress />}
        <OrganizationInfoCard
          users={users}
          organization={organization}
          primaryContact={primaryContact}
          billingContact={billingContact}
          addtlContacts={additionalContacts}
          orgId={orgId}
          onChange={handleChange}
          onSave={handleUpdate}
          onUpdateWebsite={handleUpdateWebsite}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                padding: 2,
                backgroundColor: gridBgColor,
                borderRadius: '25px',
                flexGrow: 1, // Instead of maxHeight: 200
                overflowY: 'auto',
              }}
            >
              <TeamMembers users={users} organization={organization} onChange={handleChange} onBlur={handleUpdate} />
            </Box>
          </Grid>
          <Grid item xs={12}>
            {/* <GoogleDriveSection
              organization={organization}
              parentFolderId={organization?.googleDriveFolderId}
              orgFolders={orgFolders ?? []}
              handleCreateGoogleFolder={handleCreateGoogleFolder}
              handleCreateGoogleFolders={handleCreateGoogleFolders}
            /> */}

            <Box
              sx={{
                padding: 2,
                backgroundColor: gridBgColor,
                borderRadius: '25px',
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Typography fontSize={24}>Google Drive</Typography>
              </Box>
              <Button onClick={handleCreateGoogleFolder}>Create Folder</Button>
              <Button onClick={handleCreateGoogleFolders}>Create Folders for {organization?.name}</Button>
              {/* {organization?.googleDriveFolderId && (
                <DriveFoldersById folderId={organization?.googleDriveFolderId} name='Grobotic' />
              )} */}
              {/* {orgFolders && orgFolders?.length > 0 && (
                <Box>
                  {orgFolders?.map(sf => (
                    <DriveSubfoldersById folderId={sf?.id} name={sf?.name} />
                  ))}
                </Box>
              )} */}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                padding: 2,
                backgroundColor: gridBgColor,
                height: 130,
                borderRadius: '25px',
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={9} lg={9}>
        <Box
          sx={{
            width: '100%',
            backgroundColor: gridBgColor,
            padding: 4,
            borderRadius: '25px',
            mt: 2,
          }}
        >
          <Typography variant='h6' gutterBottom>
            Summary
          </Typography>
          <Typography>{organization && organization.summary ? organization.summary : 'No summary included'}</Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <Box
          sx={{
            padding: 2,
            backgroundColor: 'none',
            height: 190,
            borderRadius: '25px',
          }}
        />
      </Grid>
    </Grid>
  )
}

export default OrganizationDetails
