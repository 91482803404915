import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { baseUrl } from '../queryMutations/mutationFunctions'

const createDriveFolders = async (folderName: string, parentFolderId: string, orgId: string) => {
  const apiUrl = `${baseUrl}/aws/googleapi/create-folder-with-subfolders`
  const googleAccessToken = localStorage.getItem('google_access_token')

  if (!googleAccessToken) {
    throw new Error('No authentication token available')
  }

  try {
    const response = await axios.post(
      apiUrl,
      { folderName, parentFolderId, googleAccessToken, orgId }, // ✅ Send fileId in request body
      {
        headers: { Authorization: `Bearer ${googleAccessToken}` },
      }
    )
    return response.data
  } catch (error) {
    console.error('[useCreateGoogleFolder] Error downloading Google File:', error)
    throw error
  }
}

export const useCreateGoogleFolders = () => {
  return useMutation({
    mutationFn: ({
      folderName,
      parentFolderId,
      orgId,
    }: {
      folderName: string
      parentFolderId: string
      orgId: string
    }) => createDriveFolders(folderName, parentFolderId, orgId),
    onSuccess: () => console.log('Successfully created Google Folder'),
    onError: error => console.error('[useCreateGoogleFolder] Error:', error),
  })
}
