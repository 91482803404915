import React from 'react'
import { Box, Button, CircularProgress, Tooltip, Typography, useTheme } from '@mui/material'
import WarningIcon from '@mui/icons-material/Warning'
import SendIcon from '@mui/icons-material/Send'
import RequestButtonTooltip from './request-button-tooltip'

const ApprovalRequestButton = ({ newDeal, isRequested, isRequesting, onRequest, startIcon, errorIcon, isValid }) => {
  const theme = useTheme()
  return (
    <Button
      sx={{
        width: '100%',
        height: '75%',
        minHeight: 42,
        backgroundColor: 'rgb(5, 190, 113)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      // disabled={isRequesting || isRequested || !isValid}
      disabled={isRequesting || isRequested || !isValid}
      onClick={onRequest}
      name='isApproved'
      variant='contained'
      startIcon={
        errorIcon ? (
          <WarningIcon sx={{ color: theme.palette.text.error }} />
        ) : startIcon ? (
          <SendIcon sx={{ color: theme.palette.text.primary }} />
        ) : null
      }
    >
      {isRequesting ? (
        <Typography>
          Requesting...{' '}
          <CircularProgress
            size={15}
            sx={{
              color: theme.palette.text.secondary,
            }}
          />
        </Typography>
      ) : (
        <Typography
          sx={{
            textAlign: 'left', // Align text left or right as needed
            whiteSpace: 'nowrap', // Prevent wrapping
            overflow: 'hidden', // Add ellipsis for long links
            textOverflow: 'ellipsis',
          }}
        >
          {!isRequested ? 'Request Approval' : 'Pending Approval'}
        </Typography>
      )}
    </Button>
  )
}

export default ApprovalRequestButton
