import { Badge, Box, Tooltip, Typography, useTheme } from '@mui/material'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import React from 'react'
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner'

const TemplateLink = ({ displayName, googleDocLink }) => {
  const theme = useTheme()

  return (
    <Tooltip title={'Kick Off Call Doc'}>
      <Box
        onClick={() => window.open(googleDocLink, '_blank', 'noopener,noreferrer')}
        sx={{
          p: 0,
          m: 0,
          display: 'flex',
          alignItems: 'center',
          gap: 0.5,
          cursor: 'pointer',
          textDecoration: 'none',
          color: theme.palette.text.primary,
          ':hover': {
            color: theme.palette.text.secondary,
          },
        }}
      >
        <Badge
          badgeContent={<DocumentScannerIcon sx={{ fontSize: '18px', color: theme.palette.text.success }} />}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Typography>{displayName}</Typography>
        </Badge>
        {/* <OpenInNewIcon sx={{ fontSize: 26 }} /> */}
      </Box>
    </Tooltip>
  )
}

export default TemplateLink
{
  /* <Grid item xs={12} sm={12} md={12} lg={2} xl={2} sx={{ p: 1, pl: 1.5, pr: 1.5 }}>
<Box
  sx={{
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  }}
>
  <Typography variant='subtitle2' color='textSecondary' sx={{ textAlign: 'left' }}>
    NCO Email Template
  </Typography>
  <EmailTemplateLink displayName={displayName} googleDocLink={googleDocLink} />
</Box>
<Box
  sx={{
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  }}
>
  <Typography variant='subtitle2' color='textSecondary' sx={{ textAlign: 'left' }}>
    {organization?.kickoffCallDocId ? 'Kickoff Call Template' : 'Create Kickoff Call Doc'}
  </Typography>
  {organization?.kickoffCallDocId ? (
    <EmailTemplateLink displayName={'Kickoff Call'} googleDocLink={kickoffCallLink} />
  ) : (
    <CreateGoogleDocIcon onClick={handleCreateDocFromTemplate} />
  )}
</Box>
<Box
  sx={{
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  }}
>
  <Typography variant='subtitle2' color='textSecondary' sx={{ textAlign: 'left' }}>
    Creative Intake Form
  </Typography>

  <EmailTemplateLink displayName={'Creative Intake Form'} googleDocLink={creativeIntakeLink} />
</Box>
</Grid> */
}
