import React from 'react'
import {
  Box,
  Card,
  CardContent,
  Typography,
  Table,
  TableHead,
  TableCell,
  Stack,
  useTheme,
  TableRow,
} from '@mui/material'
import { formatDateString } from '../Tasks/taskUtil'
import ReactQuill from 'react-quill'

const TaskDetails = ({ task }) => {
  console.log('TASK IN TASK DETAILS: ', task)
  const theme = useTheme()
  const typographyColor = theme.palette.type === 'dark' ? 'white' : 'black'

  return (
    <Box sx={{ padding: '20px', width: '95%' }}>
      {task &&
        task.map((item, idx) => (
          <Box
            key={idx}
            sx={{
              margin: '10px',
              padding: '10px',
              boxShadow: '0 3px 5px rgba(0,0,0,0.2)',
            }}
          >
            {/* Adjusted Box for layout */}
            {/* <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start', // Ensure items are aligned at the start vertically
                mb: 2,
              }}
            > */}
            {/* Image Section */}
            {/* <Box>
              <ProjectDetailsAccountImage
                account={item && item.project ? item.project.selectedAccount : null}
                imageUrl={item.project.selectedAccount ? item.project.selectedAccount.imageUrl : null}
              />
            </Box> */}
            {/* Text Section with Labels */}
            <Card key={idx} sx={{ margin: '10px', boxShadow: '0 3px 5px rgba(0,0,0,0.2)' }}>
              <CardContent>
                <Typography variant='h6' component='div' gutterBottom>
                  Task Details
                </Typography>
                <Typography variant='body2' color='text.secondary' gutterBottom>
                  Task Name: {item.taskName}
                </Typography>
                <Typography variant='body2' color='text.secondary' gutterBottom>
                  Task ID: {item.gtasks_uuid}
                </Typography>
                <Typography variant='body2' color='text.secondary' gutterBottom>
                  Task Status: {item.masterStatus}
                </Typography>
                <Typography variant='body2' color='text.secondary' gutterBottom>
                  Project: {item?.project?.selectedAccount?.Account_Name || 'N/A'}
                </Typography>
                <Typography variant='body2' color='text.secondary' gutterBottom>
                  Start Date: {formatDateString(item?.startDate || null)}
                </Typography>
                <Typography variant='body2' color='text.secondary'>
                  Created Time: {formatDateString(item?.creation_timestamp || null)}
                </Typography>
                <Typography variant='body2' color='text.secondary'>
                  Description: {item.description}
                </Typography>
                <ReactQuill
                  theme='snow'
                  value={item.description}
                  // onChange={value =>
                  //   setNewTask(prev => ({ ...prev, description: value }))
                  // }
                />
                {/* Include additional details as needed */}
              </CardContent>
            </Card>
            {/* </Box> */}
          </Box>
        ))}
    </Box>
  )
}

export default TaskDetails
