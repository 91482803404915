import React, { useEffect } from 'react'
import { Tabs, Tab, Box, Typography, useTheme, Grid, useMediaQuery, Tooltip } from '@mui/material'
import { useParams, Link, Outlet, useLocation } from 'react-router-dom'
import { useDeal } from '../../api/aws/useDeal'
import DealsDetailsHeader from './DealsDetailsHeader'
import { DealDetailsProvider } from '../../context/DealsDetailsProvider/DealsDetailsProvider'
import DetailsLoading from '../Loading/DetailsLoading'
import { useTimeline } from '../../api/aws/useTimeline'
import { useStrategy } from '../../api/aws/useStrategy'
import { useValidationContext } from '../../context/ValidationProvider/ValidationProvider'
import RenderErrorTooltip from '../Strategy/RenderErrorTooltip'

const DealPage = () => {
  const { dealId } = useParams()
  const { deal, isLoading, isError } = useDeal(dealId, ['dealPage', dealId])
  const { strategy, isLoading: isStrategyLoading, isError: isStrategyError } = useStrategy(dealId)
  const { strategyErrors, errorKeys, handleValidateStrategy, isStrategyValid } = useValidationContext()
  const { timeline, isLoading: timelineLoading, isError: timelineError } = useTimeline(dealId)
  const theme = useTheme()
  const location = useLocation()
  const [tabValue, setTabValue] = React.useState(0)
  const isMacbookPro = useMediaQuery(
    '(min-width: 1512px) and (max-width: 1512px) and (min-height: 857px) and (max-height: 857px)'
  )
  useEffect(() => {
    if (deal && strategy && !isStrategyLoading) {
      handleValidateStrategy(strategy, deal)
    }
  }, [deal, strategy])

  useEffect(() => {
    // Determine the tab value based on the current route
    if (location.pathname.includes('/agreement')) {
      setTabValue(1)
    } else if (location.pathname.includes('/tasks')) {
      setTabValue(2)
    } else if (location.pathname.includes('/services')) {
      setTabValue(3)
    } else if (location.pathname.includes('/strategy')) {
      setTabValue(4)
    } else if (location.pathname.includes('/paymentschedule')) {
      setTabValue(5)
    } else if (location.pathname.includes('/reviewdeal')) {
      setTabValue(6)
    } else if (location.pathname.includes('/invoicing')) {
      setTabValue(7)
    } else if (location.pathname.includes('/timeline')) {
      setTabValue(8)
    } else {
      setTabValue(0)
    }
  }, [location])

  if (isLoading || isStrategyLoading) {
    return <DetailsLoading />
  }

  if (isError) {
    return <Typography>Error loading deal details</Typography>
  }

  return (
    <>
      {/* <Box sx={{ position: 'sticky', top: 0, zIndex: 1100 }}> */}
      <DealDetailsProvider dealData={deal}>
        <Box sx={{ display: 'flex', maxWidth: '100vw' }}>
          <DealsDetailsHeader deal={deal} />
        </Box>

        <Grid container>
          <Box
            sx={{
              width: '100%',
              maxWidth: '100vw', // Prevent overflow beyond viewport
              overflowX: 'auto', // Enable horizontal scrolling
            }}
          >
            <Grid item xs={10.5} sm={11} md={12} lg={12} xl={12}>
              <Tabs
                value={tabValue}
                variant='scrollable'
                scrollButtons={'auto'}
                allowScrollButtonsMobile
                sx={{
                  borderBottom: 1,
                  borderColor: 'divider',
                  backgroundColor: theme.palette.mode === 'dark' ? 'rgb(30, 39, 53)' : 'rgba(255,255,255,1)',
                  width: '100%', // Ensure it stretches to the parent's width
                  '& .MuiTabs-scrollButtons': {
                    border: `1px solid ${theme.palette.text.primary}`,
                    '&.Mui-disabled': {
                      opacity: 0.3,
                    },
                  },
                }}
              >
                <Tab label={<Typography noWrap>Details</Typography>} component={Link} to={`/deals/${dealId}`} />
                <Tab
                  label={<Typography noWrap>Agreement</Typography>}
                  component={Link}
                  to={`/deals/${dealId}/agreement`}
                />
                <Tab
                  label={<Typography noWrap>Tasks</Typography>}
                  component={Link}
                  to={`/deals/${dealId}/tasks`}
                  disabled
                />
                <Tab
                  label={<Typography noWrap>Services</Typography>}
                  component={Link}
                  to={`/deals/${dealId}/services`}
                  disabled
                />
                <Tab
                  label={
                    errorKeys &&
                    errorKeys?.length > 0 &&
                    !deal?.isCompleted &&
                    deal?.status !== 'Completed Internally' ? (
                      <RenderErrorTooltip strategy={strategy} isStrategyValid={isStrategyValid} errorKeys={errorKeys} />
                    ) : (
                      <Typography noWrap>Strategy</Typography>
                    )
                  }
                  disabled={deal?.status === 'Completed Internally'}
                  component={Link}
                  to={`/deals/${dealId}/strategy`}
                />
                <Tab
                  label={<Typography>Payment Schedule</Typography>}
                  component={Link}
                  to={`/deals/${dealId}/paymentschedule`}
                />
                <Tab label={<Typography>Review Deal</Typography>} component={Link} to={`/deals/${dealId}/reviewdeal`} />
                <Tab label={<Typography>Invoicing</Typography>} component={Link} to={`/deals/${dealId}/invoicing`} />
                <Tab
                  label={<Typography sx={{ whiteSpace: 'nowrap' }}>Timeline</Typography>}
                  component={Link}
                  to={`/deals/${dealId}/timeline`}
                  disabled={!timeline || timeline?.length === 0}
                />
              </Tabs>
            </Grid>
          </Box>
        </Grid>

        <Grid
          container
          sx={{
            height: isMacbookPro ? 'calc(100vh - 115px)' : 'calc(100vh - 120px)', // Adjust this value based on header height
            overflowY: isMacbookPro ? 'hidden' : 'auto', // Allow scrolling if content overflows\
            overflowX: 'auto',
            p: 0,
          }}
        >
          <Grid item xs={12}>
            <Outlet />
          </Grid>
        </Grid>
      </DealDetailsProvider>
      {/* </Box> */}
    </>
  )
}

export default DealPage
