export const formatNumber = number => {
  return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(number)
}
export const formatCurrency = number => {
  const currencyNumnber = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(
    number
  )
  return `$${currencyNumnber}`
}
export const formatWholeNumber = number => {
  const currencyNumnber = new Intl.NumberFormat('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(
    number
  )
  return `$${currencyNumnber}`
}

export const formatCurrencyString = amount => {
  if (!amount) return '$0'

  // Convert string like "$1,262,121.00" into a number
  const numericAmount = parseFloat(amount.replace(/[$,]/g, ''))

  if (isNaN(numericAmount)) return '$0'

  return `$${Math.round(numericAmount).toLocaleString()}`
}

const formatTotals = totals => {
  const formattedStageTotals = {}

  for (const stage in totals) {
    if (totals.hasOwnProperty(stage)) {
      formattedStageTotals[stage] = {
        total: formatCurrency(totals[stage].total),
        grossProfit: formatCurrency(totals[stage].grossProfit),
      }
    }
  }

  return formattedStageTotals
}

export const aggregateStageTotals = stages => {
  const result = {}

  for (const stage in stages) {
    if (stages.hasOwnProperty(stage)) {
      const stageData = stages[stage]
      const totalSum = stageData.items.reduce((sum, item) => {
        return sum + (item.total || 0)
      }, 0)

      const grossProfitSum = stageData.items.reduce((sum, item) => {
        return sum + (item.grossProfit || 0)
      }, 0)

      result[stage] = { total: totalSum, grossProfit: grossProfitSum }
    }
  }

  const formattedTotals = formatTotals(result)
  return formattedTotals
}
