import React, { useState, useEffect } from 'react'
import { Drawer, Box, Typography, useTheme, useMediaQuery, Tabs, Tab, Grid } from '@mui/material'
import { useNavigate, Outlet, useLocation, useParams } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { useDeal } from '../../api/aws/useDeal'
import { useOrganizationById } from '../../api/aws/useOrganization'
import { useUpdateDeal } from '../../api/aws/useUpdateDeal'
import { useGoogleOrganizationFolder } from '../../api/google/useGoogleOrganizationFolder.ts'
import { useOrganizationSubfolders } from '../../api/google/useOrganizationSubfolders.ts'
import { useDealFolders } from '../../api/google/useDealFolders.ts'
import BoardsGridHeader from './BoardsGridHeader'
import DrawerTasksGrid from '../Creative/DrawerTasksGrid'
import ReviewDeal from '../DealApproval/ReviewDeal'
import Strategy from '../Strategy/Strategy'
import { ncoTeamTasks } from '../NCO/ncoTaskObjects.js'
import DriveFoldersTree from '../Google/DriveFoldersTree.tsx'
import { formatDayjsDateTime } from '../../utility/formatDayjsDateTime.js'
import GoogleDriveIcon from '../Icons/GoogleDriveIcon.js'
import RenderCampaign from '../StrategyCampaigns/RenderCampaign.js'
import HandoffCall from './components/HandoffCall.js'
import TemplateLink from '../ui/template-link.js'
import CreateGoogleDocIcon from '../ui/create-google-doc-icon.js'
import { useCreateGoogleDoc } from '../../api/google/useCreateGoogleDoc.ts'
import { useValidateGoogleToken } from '../../api/google/useValidateGoogleToken.ts'

// Once contract signed , in NCO, PMS AMS get info, they mark off the checklist thats required to push to other teams
const initializeTasks = (deal, tasks) => {
  if (!deal) return {}
  const updatedDeal = { ...deal }
  // Loop through roles and initialize task properties if missing
  Object.keys(tasks[0]).forEach(role => {
    const roleKey = `${role.toLowerCase()}Tasks`
    if (!updatedDeal[roleKey]) {
      const roleTasks = tasks[0][role].reduce((acc, taskGroup) => {
        const [taskName] = Object.keys(taskGroup)
        acc[taskName] = 'N/A' // Default status
        return acc
      }, {})
      updatedDeal[roleKey] = roleTasks
    }
  })
  return updatedDeal
}

const Notes = () => <Typography>Notes</Typography>

const BoardsDrawerComponent = ({ open, onClose, context, tasks }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { validateToken, isValid, accessToken, refreshed } = useValidateGoogleToken()

  const { dealId, campaignId } = useParams()
  const handleValidateToken = (token, userId) => {
    if (!token || !isValid) {
      console.log('🔄 Token missing or invalid, validating...')

      validateToken(
        { accessToken: token, refreshToken: '', userId },
        {
          onSuccess: data => {
            if (data.isValid) {
              localStorage.setItem('google_access_token', data.accessToken || '')
            } else {
              console.warn('❌ Token validation failed, please reauthenticate.')
            }
          },
        }
      )
    } else {
      console.log('✅ Using valid access token from cache.')
    }
  }
  const theme = useTheme()
  const queryClient = useQueryClient()
  const [updates, setUpdates] = useState({})
  const { mutate: updateDeal } = useUpdateDeal()
  // Fetch the deal & organization
  const { deal: newDeal, isDealLoading } = useDeal(dealId)
  const { organization: newOrganization, isOrganizationLoading } = useOrganizationById(newDeal?.organizations?.[0])
  // Fetch the Organization's Google Drive Folder ID
  const { data: organizationFolder, isLoading: isOrganizationFolderLoading } = useGoogleOrganizationFolder(
    newOrganization?.org_uuid
  )
  const [initialValues, setInitialValues] = useState(newDeal ?? null)
  const orgFolderId = organizationFolder?.organizationFolderId ?? null // ✅ Default to null
  // Fetch 'Deals' Folder inside the Organization
  const { data: subfolders, isLoading: isSubfoldersLoading } = useOrganizationSubfolders(orgFolderId || '')
  // Ensure subfolders is always an array
  const safeSubfolders = Array.isArray(subfolders) ? subfolders : []
  // Find the "Deals" folder dynamically
  const dealsFolder = safeSubfolders.find(folder => folder.name === 'Deals')
  const dealsFolderId = dealsFolder ? dealsFolder.id : null
  const creation_timestamp = formatDayjsDateTime(newDeal?.creation_timestamp)
  // Fetch Specific Deal Folder inside 'Deals' Folder
  // const { data: dealFolders, isLoading: isDealFoldersLoading } = useDealFolders(dealsFolderId || '', dealId || '')
  const { data: dealFolders, isLoading: isDealFoldersLoading } = useDealFolders(
    dealsFolderId || '',
    creation_timestamp || ''
  )
  // Ensure dealFolders is always an array
  const safeDealFolders = Array.isArray(dealFolders) ? dealFolders : []
  const dealFolderId = safeDealFolders.length > 0 ? safeDealFolders[0]?.id : null

  // Manage State for Organization & Deal
  const [organization, setOrganization] = useState(null)
  const [deal, setDeal] = useState(null)
  const [activeTab, setActiveTab] = useState(0)
  useEffect(() => {
    if (newDeal && newOrganization && !isDealLoading && !isOrganizationLoading) {
      setDeal(initializeTasks(newDeal, tasks ?? ncoTeamTasks))
      setOrganization(newOrganization)
    }
  }, [newDeal, newOrganization, isDealLoading, isOrganizationLoading])

  const handleTabChange = (event, newValue) => {
    const paths = ['', 'strategy', 'review', 'notes', 'handoffcall', '', '', '', '', '', '']
    if (newValue === 8) {
      event.preventDefault()
    } else if (newValue === 9) {
      event.preventDefault()
    } else {
      setActiveTab(newValue)
      navigate(`${dealId}/${paths[newValue]}`)
    }
  }
  const { mutate: createDoc } = useCreateGoogleDoc()
  const kickoffCallLink = newOrganization?.handoffDocId
    ? `https://docs.google.com/document/d/${newOrganization?.handoffDocId}/edit?tab=t.0`
    : ''
  const handoffTemplateId = '1EgooUWhtEQ2DTPuv1gpN4iGkw-nvSfTj67-mR5yupOI'
  console.log('newOrganization?.handoffDocId ', newOrganization?.handoffDocId)
  const handleCreateDocFromTemplate = formData => {
    const token = localStorage.getItem('google_access_token')
    const userId = localStorage.getItem('slack_user_id')

    const validateToken = handleValidateToken(token, userId)
    console.log('validateToken', validateToken)
    if (!organization?.googleDriveFolderId) return
    createDoc(
      {
        folderId: organization?.googleDriveFolderId,
        title: `Handoff Call - ${organization?.name}`,
        templateId: handoffTemplateId,
        content: {
          organizationId: organization?.org_uuid,
          organizationName: organization?.name,
          clientIntro: formData?.clientIntro ?? 'N/A',
          website: formData?.website ?? 'N/A',
          portal: formData?.portal ?? 'N/A',
          channels: formData?.channels ?? 'N/A',
          dependencies: formData?.dependencies ?? 'N/A',
          vertical: Array.isArray(formData?.vertical) ? formData.vertical.join(', ') : formData?.vertical ?? 'N/A', // ✅ Convert array to string
          location: formData?.location ?? 'N/A',
          socials: formData?.socialProfiles ?? 'N/A',
          launchDate: formData?.launchDate ?? 'N/A',
          propertyDetails: formData?.propertyDetails ?? 'N/A',
          billingDetails: formData?.billingDetails ?? 'N/A',
          creatives: formData?.creatives ?? 'N/A',
          extraNotes: formData?.extraNotes ?? 'N/A',
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['organization', organization?.org_uuid])
        },
      }
    )
  }
  const handleUpdate = () => {
    if (Object.keys(updates).length === 0) return

    const dealId = newDeal?.deal_uuid
    // const updateData = { ...initialValues, ...updates, queryKey: ['deal', dealId] }
    const updateData = { ...initialValues, ...updates, queryKey: ['deal', dealId] }
    console.log('updateData:', updateData)
    try {
      updateDeal(
        { dealId, dealData: updateData },
        {
          onSuccess: () => {
            setUpdates({})
          },
          onError: error => {
            console.error('Error updating deal:', error)
            setUpdates({})
          },
        }
      )
    } catch (e) {
      console.error('[BoardsDrawer] error updating deal', e)
    }
  }
  const handleClose = async () => {
    handleUpdate()
    onClose()
  }
  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { width: '85%', overflow: 'hidden', height: '100vh' } }}
    >
      {/* {isDealLoading || isOrganizationLoading ? (
        <DetailsLoading />
      ) : ( */}
      <Box>
        {/* Header */}
        <BoardsGridHeader newDeal={deal} organization={organization} orgFolders={safeDealFolders} />
        {/* Tabs */}
        <Tabs value={activeTab} onChange={handleTabChange} sx={{ borderBottom: 1, borderColor: 'divider', mb: 0 }}>
          <Tab label={<Typography>Main</Typography>} />
          <Tab label={<Typography>Strategy</Typography>} disabled={['CSA', 'RCSA'].includes(newDeal?.type)} />
          <Tab label={<Typography>Review</Typography>} />
          <Tab label={<Typography>Notes</Typography>} />
          {context === 'nco' && <Tab label={<Typography>Handoff Call</Typography>} />}
          <Tab label={<Typography>Tab 6</Typography>} />
          <Tab label={<Typography>Tab 7</Typography>} />
          <Tab label={<Typography>Tab 8</Typography>} />

          {context === 'nco' && newOrganization?.googleDriveFolderId && (
            <Tab
              label={
                newOrganization?.handoffDocId ? (
                  <TemplateLink displayName={'Handoff Call'} googleDocLink={kickoffCallLink} />
                ) : (
                  <CreateGoogleDocIcon displayName={'Create Handoff Doc'} onClick={handleCreateDocFromTemplate} />
                )
              }
            />
          )}
          <Tab
            // sx={{ borderLeft: `2px solid ${theme.palette.text.primary}` }}
            label={
              safeDealFolders?.length > 0 && (
                <Box sx={{ display: 'flex', height: '100%', alignItems: 'center', gap: 2 }}>
                  {safeDealFolders?.map(sf => {
                    return (
                      <Box key={sf.id}>
                        <DriveFoldersTree
                          key={sf.id}
                          creation_timestamp={creation_timestamp}
                          folderId={sf?.id}
                          folderName={sf.name}
                          level={0}
                        />
                      </Box>
                    )
                  })}
                </Box>
              )
            }
          />
        </Tabs>

        {/* Tab Content */}
        <Box sx={{ height: 'calc(100vh - 120px)' }}>
          {activeTab === 0 && (
            <DrawerTasksGrid deal={deal} updates={updates} setUpdates={setUpdates} initialValues={newDeal} />
          )}
          {activeTab === 1 && location.pathname.includes(`strategy/${campaignId}`) ? (
            <RenderCampaign />
          ) : activeTab === 1 && location.pathname.includes(`strategy`) ? (
            <Strategy />
          ) : null}
          {activeTab === 2 && <ReviewDeal />}
          {activeTab === 3 && <Notes />}
          {activeTab === 4 && context === 'nco' && (
            <HandoffCall organization={newOrganization} onCreateDoc={handleCreateDocFromTemplate} />
          )}
          {activeTab === 5 && context === 'nco' && (
            <HandoffCall organization={newOrganization} onCreateDoc={handleCreateDocFromTemplate} />
          )}
          {activeTab === 6 && <Typography>Tab 6</Typography>}
          {activeTab === 7 && <Typography>Tab 7</Typography>}
          {activeTab === 8 && <Typography>Tab 8</Typography>}
        </Box>
      </Box>
      {/* )} */}
    </Drawer>
  )
}

export default BoardsDrawerComponent

// import React, { useState, useEffect } from 'react'
// import { Drawer, Box, Typography, useTheme, useMediaQuery, Tabs, Tab, Grid } from '@mui/material'
// import NcoDrawerHeader from '../NCO/NcoDrawerHeader'
// import { useUpdateDeal } from '../../api/aws/useUpdateDeal'
// import { useQueryClient } from '@tanstack/react-query'
// import { useNavigate, Outlet, useLocation, useParams } from 'react-router-dom'
// import Strategy from '../Strategy/Strategy'
// import RenderCampaign from '../StrategyCampaigns/RenderCampaign'
// import { useDeal } from '../../api/aws/useDeal'
// import ReviewDeal from '../DealApproval/ReviewDeal'
// import DrawerTasksGrid from '../Creative/DrawerTasksGrid'
// import { ncoTeamTasks } from './taskTemplates'
// import BoardsGridHeader from './BoardsGridHeader'
// import { useOrganizationById } from '../../api/aws/useOrganization'
// import { useGoogleFilesByFolderId } from '../../api/google/useGoogleFilesByFolderId.ts'
// import { useCreateGoogleFolders } from '../../api/google/useCreateGoogleFolders.ts'
// import { useCreateGoogleFolder } from '../../api/google/useCreateGoogleFolder.ts'
// import DetailsLoading from '../Loading/DetailsLoading.js'
// import { useGoogleOrganizationFolder } from '../../api/google/useGoogleOrganizationFolder.ts'
// import { useOrganizationSubfolders } from '../../api/google/useOrganizationSubfolders.ts'
// import { useDealFolders } from '../../api/google/useDealFolders.ts'

// const initializeTasks = (deal, tasks) => {
//   if (!deal) return {}

//   const updatedDeal = { ...deal }

//   // Loop through roles and initialize task properties if missing
//   Object.keys(tasks[0]).forEach(role => {
//     const roleKey = `${role.toLowerCase()}Tasks`
//     if (!updatedDeal[roleKey]) {
//       const roleTasks = tasks[0][role].reduce((acc, taskGroup) => {
//         const [taskName] = Object.keys(taskGroup)
//         acc[taskName] = 'N/A' // Default status
//         return acc
//       }, {})
//       updatedDeal[roleKey] = roleTasks
//     }
//   })
//   return updatedDeal
// }

// const Notes = () => <Typography>Notes </Typography>

// const BoardsDrawerComponent = ({ open, onClose, route, tasks }) => {
//   const navigate = useNavigate()
//   const location = useLocation()
//   const { dealId, campaignId } = useParams()
//   // const { newDeal } = useDealDetailsContext()
//   const theme = useTheme()
//   const { mutate: updateDeal } = useUpdateDeal()
//   const [updates, setUpdates] = useState({})
//   const queryClient = useQueryClient()
//   const { deal: newDeal, isDealLoading, isDealError } = useDeal(dealId)
//   const { organization: newOrganization, isOrganizationLoading, isOrganizationError } = useOrganizationById(
//     newDeal?.organizations?.[0]
//   )
//   const { mutate: createGoogleFolders } = useCreateGoogleFolders()
//   const { mutate: createGoogleFolder } = useCreateGoogleFolder()
//   const [folderId, setFolderId] = useState(null)
//   const [organization, setOrganization] = useState(null)
//   const [deal, setDeal] = useState(null)
//   const [initialValues, setInitialValues] = useState(newDeal ?? ncoTeamTasks)
//   const [activeTab, setActiveTab] = useState(0)
//   // const { data: orgFolders, isLoading: isOrgFoldersLoading } = useGoogleFilesByFolderId(
//   //   organization?.googleDriveFolderId
//   // )
//   const { data: dealFolder, isLoading } = useGoogleFilesByFolderId(folderId)
//   const { data: organizationFolder, isLoading: isOrganizationFolderLoading } = useGoogleOrganizationFolder(orgId);
//   const { data: subfolders, isLoading: isSubfoldersLoading } = useOrganizationSubfolders(orgFolderId, 'Deals');
//   const { data: dealFolders, isLoading:isDealFoldersLoading } = useDealFolders(dealsFolderId, dealId);

//   const isMacbookPro = useMediaQuery(
//     '(min-width: 1512px) and (max-width: 1512px) and (min-height: 857px) and (max-height: 857px)'
//   )
// useEffect(() => {
//   if (newDeal && newOrganization && !isDealLoading && !isOrganizationLoading) {
//     setDeal(initializeTasks(newDeal, tasks ?? ncoTeamTasks))
//     setOrganization(newOrganization)
//   }
// }, [newDeal, newOrganization, isDealLoading, isOrganizationLoading])

//   useEffect(() => {
//     if (newOrganization?.googleDriveFolderId) {
//       console.log('newOrganization?.googleDriveFolderId', newOrganization?.googleDriveFolderId)
//       setFolderId(newOrganization?.googleDriveFolderId)
//     }
//   }, [newOrganization?.googleDriveFolderId])
// const handleTabChange = (event, newValue) => {
//   setActiveTab(newValue)

//   if (newValue === 0) {
//     navigate(`${dealId}`) // Navigate to the base path for Main
//   } else if (newValue === 1) {
//     navigate(`${dealId}/strategy`)
//   } else if (newValue === 2) {
//     navigate(`${dealId}/review`)
//   } else if (newValue === 3) {
//     navigate(`${dealId}/notes`)
//   }
// }

// // Determine active tab based on the URL
// useEffect(() => {
//   if (location.pathname.includes(`strategy/${campaignId}`)) {
//     setActiveTab(1)
//   } else if (location.pathname.includes(`strategy`)) {
//     setActiveTab(1)
//   } else if (location.pathname.includes(`review`)) {
//     setActiveTab(2)
//   } else if (location.pathname.includes(`notes`)) {
//     setActiveTab(3)
//   } else {
//     setActiveTab(0)
//   }
// }, [location, campaignId])
// const handleUpdate = () => {
//   if (Object.keys(updates).length === 0) return

//   const dealId = newDeal?.deal_uuid
//   // const updateData = { ...initialValues, ...updates, queryKey: ['deal', dealId] }
//   const updateData = { ...initialValues, ...updates, queryKey: ['deal', dealId] }
//   console.log('updateData:', updateData)
//   try {
//     updateDeal(
//       { dealId, dealData: updateData },
//       {
//         onSuccess: () => {
//           setUpdates({})
//         },
//         onError: error => {
//           console.error('Error updating deal:', error)
//           setUpdates({})
//         },
//       }
//     )
//   } catch (e) {
//     console.error('[BoardsDrawer] error updating deal', e)
//   }
// }
// const handleClose = async () => {
//   handleUpdate()
//   onClose()
// }
//   const handleCreateGoogleFolder = () => {
//     const groboticDriveClientFolderId = '11kc72jDSrY5SBUF9shoPGgsCFwA9quwi'
//     createGoogleFolder({ folderName: 'Test Folder', parentFolderId: organization?.googleDriveFolderId })
//   }
//   const handleCreateGoogleFolders = () => {
//     const groboticDriveClientFolderId = '11kc72jDSrY5SBUF9shoPGgsCFwA9quwi'
//     createGoogleFolders({
//       folderName: organization?.name,
//       parentFolderId: groboticDriveClientFolderId,
//       orgId: organization?.org_uuid,
//     })
//   }
//   // Call useMediaQuery only at the top level
//   const isExtraLargeScreen = useMediaQuery(theme.breakpoints.up('xl'))
//   const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'))
//   const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'))
//   const isSmallScreen = useMediaQuery(theme.breakpoints.up('sm'))

//   // Calculate drawerWidth based on screen size
//   let drawerWidth = '100%'
//   if (isExtraLargeScreen) {
//     // console.log('XL SCREEN')
//     drawerWidth = '85%'
//   } else if (isLargeScreen) {
//     // console.log('LG SCREEN')
//     drawerWidth = '65%'
//   } else if (isMediumScreen) {
//     // console.log('MD SCREEN')
//     drawerWidth = '75%'
//   } else if (isSmallScreen) {
//     // console.log('SM SCREEN')
//     drawerWidth = '85%'
//   }
//   if (isLoading) {
//     return <DetailsLoading />
//   }
//   return (
//     <Drawer
//       anchor='right'
//       open={open}
//       onClose={handleClose}
//       PaperProps={{
//         sx: {
//           width: drawerWidth,
//           overflow: 'hidden',
//           height: '100vh',
//           // p: 1,
//           // height: '93.2vh'
//         },
//       }}
//     >
//       <Box>
//         {/* Header */}

//         <BoardsGridHeader
//           newDeal={newDeal}
//           organization={organization}
//           orgFolders={dealFolder}
//           handleCreateGoogleFolder={handleCreateGoogleFolder}
//           handleCreateGoogleFolders={handleCreateGoogleFolders}
//         />
//         {/* Tabs */}
//         <Tabs value={activeTab} onChange={handleTabChange} sx={{ borderBottom: 1, borderColor: 'divider', mb: 0 }}>
//           <Tab label='Main' />
//           <Tab label='Strategy' disabled={['CSA', 'RCSA'].includes(newDeal?.type)} />
//           <Tab label='Review' />
//           <Tab label='Notes' />
//         </Tabs>

//         {/* Tab Content */}
//         <Box>
//           {activeTab === 0 && (
//             <DrawerTasksGrid deal={deal} updates={updates} setUpdates={setUpdates} initialValues={newDeal} />
//           )}
// {activeTab === 1 && location.pathname.includes(`strategy/${campaignId}`) ? (
//   <RenderCampaign />
// ) : activeTab === 1 && location.pathname.includes(`strategy`) ? (
//   <Strategy />
// ) : null}
//           {activeTab === 2 && <ReviewDeal />}
//           {activeTab === 3 && <Notes />}
//         </Box>
//         <Grid
//           container
//           sx={{
//             height: isMacbookPro ? 'calc(100vh - 115px)' : 'calc(100vh - 120px)', // Adjust this value based on header height
//             overflowY: isMacbookPro ? 'hidden' : 'auto', // Allow scrolling if content overflows\
//             overflowX: 'auto',
//             p: 0,
//           }}
//         >
//           <Grid item xs={12}>
//             <Outlet />
//           </Grid>
//         </Grid>
//       </Box>
//     </Drawer>
//   )
// }

// export default BoardsDrawerComponent
