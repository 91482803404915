import React, { useEffect, useState } from 'react'
import { Autocomplete, TextField, CircularProgress, Box, useTheme } from '@mui/material'
import { useUsers } from '../../api/aws/useUsers'
import { useUsersById } from '../../api/customHooks/useUsersById'
import { renderUserOption } from '../Users/renderOptions'

const OrgOwnerAutocomplete = ({ userId, label, onChange, context, onBlur, multiple = false }) => {
  const theme = useTheme()
  const [inputValue, setInputValue] = useState('')

  // Ensure userId is treated as an array
  const ownerIds = Array.isArray(userId) ? userId : userId ? [userId] : []

  // Fetch all users for dropdown options
  const { users: allUsers, isUsersLoading } = useUsers(true, ['allUsers'])

  // Fetch selected owner(s)
  const { users: fetchedUsers, isUserLoading } = useUsersById(ownerIds)

  // Maintain selected user state
  const [selectedUser, setSelectedUser] = useState(multiple ? [] : null)

  useEffect(() => {
    if (!isUserLoading && fetchedUsers.length > 0) {
      setSelectedUser(multiple ? fetchedUsers : fetchedUsers[0] || null)
    }
  }, [fetchedUsers, isUserLoading, multiple])

  // Handle user selection
  const handleSelectUser = (_, newValue) => {
    if (typeof onChange !== 'function') {
      console.error('❌ ERROR: onChange is not a function', onChange)
      return
    }

    if (multiple) {
      const newOwnerIds = newValue.map(user => user.id)
      setSelectedUser(newValue)
      onChange(context, newOwnerIds)
      if (onBlur) onBlur(context, newOwnerIds)
    } else {
      setSelectedUser(newValue)
      onChange(context, newValue ? newValue.id : null)
      if (onBlur) onBlur(context, newValue ? newValue.id : null)
    }
  }

  if (isUsersLoading || isUserLoading) {
    return (
      <Box>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Autocomplete
      multiple={multiple}
      id='assign-owner-autocomplete'
      options={allUsers || []} // 🔥 Use ALL users instead of only fetchedUsers
      getOptionLabel={option =>
        option.real_name ||
        `${option.firstName || option.first_name} ${option.lastName || option.last_name} (${option.email})`
      }
      value={selectedUser}
      onChange={handleSelectUser}
      inputValue={inputValue}
      onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      filterOptions={(options, { inputValue }) =>
        options.filter(option =>
          `${option.firstName || option.first_name} ${option.lastName || option.last_name}`
            .toLowerCase()
            .includes(inputValue.toLowerCase())
        )
      }
      renderOption={(props, option, state) => renderUserOption(props, option, state, theme)}
      renderInput={params => <TextField name='owner' {...params} label={label} />}
      fullWidth
    />
  )
}

export default OrgOwnerAutocomplete
