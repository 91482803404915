import { Badge, Box, Typography, Tooltip, useTheme } from '@mui/material'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import React from 'react'

const CreateGoogleDocIcon = ({ displayName, onClick }) => {
  const theme = useTheme()

  return (
    <Tooltip title={'Create Doc'}>
      <Box
        onClick={() => onClick()}
        sx={{
          p: 0,
          m: 0,
          display: 'flex',
          alignItems: 'center',
          gap: 0.5,
          cursor: 'pointer',
          textDecoration: 'none',
          color: theme.palette.text.primary,
          ':hover': {
            color: theme.palette.text.secondary,
          },
        }}
      >
        <Badge
          badgeContent={<UploadFileIcon sx={{ fontSize: '18px', color: theme.palette.text.warning }} />}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Typography>{displayName}</Typography>
        </Badge>
        {/* <OpenInNewIcon sx={{ fontSize: 26 }} /> */}
      </Box>
    </Tooltip>
  )
}

export default CreateGoogleDocIcon
