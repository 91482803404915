import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { baseUrl } from '../queryMutations/mutationFunctions'

const createGoogleDoc = async (
  folderId: string,
  title: string,
  templateId: string,
  content: Record<string, string> // Improved type from Object to Record<string, string>
) => {
  const apiUrl = `${baseUrl}/aws/googleapi/googledocs`
  const googleAccessToken = localStorage.getItem('google_access_token')

  if (!googleAccessToken) {
    console.error('No authentication token available')
    return
  }

  try {
    const response = await axios.post(
      apiUrl,
      {
        folderId,
        title,
        templateId,
        content,
        googleAccessToken,
      },
      {
        headers: { Authorization: `Bearer ${googleAccessToken}` },
      }
    )
    console.log('Response Create Google Doc: ', response)
    return response.data
  } catch (error) {
    console.error('Error creating Google Doc:', error)
    throw error // Re-throw the error for useMutation's onError to catch
  }
}

export const useCreateGoogleDoc = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({
      folderId,
      title,
      templateId,
      content,
    }: {
      folderId: string
      title: string
      templateId: string
      content: Record<string, string> // Updated to Record<string, string>
    }) => createGoogleDoc(folderId, title, templateId, content),

    onSuccess: () => {
      console.log('Successfully created Google Doc')

      // ✅ FIXED: Corrected invalidateQueries syntax
      queryClient.invalidateQueries({ queryKey: ['googleDocs'] })
    },

    onError: error => {
      console.error('[useCreateGoogleDoc] Error creating Google Doc:', error)
    },
  })
}
