import React, { useState } from 'react'
import { Modal, Box, TextField, Button, Select, MenuItem, Chip, Typography, Stack } from '@mui/material'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'

const DealStatusModal = ({ isOpen, onClose, onSave, tempDealStatus }) => {
  const { showSnackbar } = useSnackbarContext()
  const [tempLastDate, setTempLastDate] = useState(null) // Keep as Day.js object
  const [tempReasonChurn, setTempReasonChurn] = useState('')
  const [tempChurnNote, setTempChurnNote] = useState('')
  const [error, setError] = useState(false)
  const handleUpdateModal = () => {
    if (!tempLastDate || !tempReasonChurn || !tempChurnNote) {
      setError(true)
      showSnackbar('All fields are required.', 'typeWarning')
      return
    }
    setError(false)

    const formattedLastDate = tempLastDate.toISOString()

    onSave(tempChurnNote, formattedLastDate, tempReasonChurn, tempDealStatus) // Pass tempDealStatus
  }

  const handleChange = event => {
    const newValue = event.target.value
    setTempReasonChurn(newValue)
  }

  const handleDateChange = newValue => {
    setTempLastDate(newValue) // Keep as Day.js object
  }

  const options = [
    'GRO Performance',
    'Contract Ended',
    'Temporary Pause',
    'Payment Issues',
    'Property Sold',
    'Property Leased Up',
    'Other',
  ]

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Stack sx={{ gap: 2 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label='Last Date of Service'
              value={tempLastDate}
              onChange={handleDateChange}
              slotProps={{
                textField: {
                  fullWidth: true,
                  error: error && !tempLastDate,
                  helperText: error && !tempLastDate ? 'Please select a valid date' : '',
                },
              }}
            />
          </LocalizationProvider>

          <Select
            id='churn-reason-select'
            value={tempReasonChurn || ''}
            onChange={handleChange}
            displayEmpty
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <MenuItem value='' disabled>
              <Typography variant='subtitle1' color='text.secondary'>
                Select a reason
              </Typography>
            </MenuItem>
            {options.map(option => (
              <MenuItem key={option} value={option}>
                <Chip
                  size='small'
                  style={{
                    width: 14,
                    height: 14,
                    borderRadius: '50%',
                    marginRight: 8,
                  }}
                />
                <Typography variant='subtitle1'>{option}</Typography>
              </MenuItem>
            ))}
          </Select>

          <TextField
            label='Notes'
            type='text'
            fullWidth
            value={tempChurnNote}
            onChange={e => setTempChurnNote(e.target.value)}
            error={error && !tempChurnNote}
            helperText={error && !tempChurnNote ? 'Notes are required' : ''}
          />
        </Stack>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
          <Button onClick={onClose} variant='outlined'>
            Cancel
          </Button>
          <Button onClick={handleUpdateModal} variant='contained' color='primary'>
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default DealStatusModal
