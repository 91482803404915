import React from 'react'
import { FormControl, Select, MenuItem, FormHelperText, InputLabel } from '@mui/material'
const SelectAgreementOption = ({ agreementOption, onChange, validationErrors, label = true }) => {
  return (
    <FormControl fullWidth>
      {label ? (
        <InputLabel id='agreement-option-label' error={validationErrors.agreementOption}>
          Agreement Option
        </InputLabel>
      ) : null}

      <Select
        labelId='agreement-option-label'
        label={label ? 'Agreement Option' : ''}
        value={agreementOption}
        onChange={onChange}
        required
        error={validationErrors.agreementOption}
      >
        <MenuItem value='New/Add-on Business'>New/Add-on Business</MenuItem>
        <MenuItem value='Replace Existing Business'>Replace Existing Business</MenuItem>
      </Select>
      {/* <FormHelperText>{validationErrors.agreementOption && 'Agreement Option is required'}</FormHelperText> */}
    </FormControl>
  )
}

export default SelectAgreementOption
