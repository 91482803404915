import React, { useState, useContext, useEffect, useMemo } from 'react'
import { styled } from '@mui/material/styles'
import { Box, Chip, Grid, InputLabel, Stack, Typography, useTheme } from '@mui/material'
import { DragDropContext } from '@hello-pangea/dnd'
import KanbanColumn from './KanBanColumn'
import { aggregateStageTotals, formatCurrency } from './aggregateStageTotals'
import { useUpdateItem } from '../../api/aws/useUpdateItem'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import { getDeals, useDeals } from '../../api/aws/useDeals'
import DealsKanbanHeader from './DealsKanbanHeader'
import DetailsLoading from '../Loading/DetailsLoading'
import { usePaginateDeals } from '../../api/aws/usePaginateDeals'
import './styles.css'
import MetricHeader from './MetricHeader'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { usePipelineDeals } from '../../api/aws/usePipelineDeals'
import { useUsers } from '../../api/aws/useUsers'
import { useOrganizations } from '../../api/aws/useOrganizations'

const updateDealStages = deals => {
  return deals?.map(deal => {
    if (deal?.stage === 'Qualified Lead') {
      deal.stage = 'Proposal'
    } else if (deal?.stage === 'New Lead') {
      deal.stage = 'Lead'
    } else if (deal?.stage === 'Proposed - Cold') {
      deal.stage = 'Cold'
    } else if (deal?.stage === 'Agreement Sent') {
      deal.stage = 'Contract Sent'
    }
    return deal
  })
}
const calculateDaysSinceModified = lastModified => {
  if (!lastModified) return 'N/A' // If no lastModified date, return N/A
  const modifiedDate = new Date(lastModified)
  const today = new Date()
  const timeDiff = today.getTime() - modifiedDate.getTime()
  return Math.floor(timeDiff / (1000 * 60 * 60 * 24)) // Convert ms → days
}

const loadDealsIntoKanban = (deals, extraStages, users, organizations) => {
  const defaultStages = {
    Discovery: { items: [], label: 'Discovery' },
    'Pending Approval': { items: [], label: 'Pending Approval' },
    Proposal: { items: [], label: 'Proposal' },
    'Contract Created': { items: [], label: 'Contract Created' },
    'Contract Sent': { items: [], label: 'Contract Sent' },
    'Deal Won': { items: [], label: 'Deal Won' },
  }

  const additionalStages = {
    Cold: { items: [], label: 'Cold' },
    Purgatory: { items: [], label: 'Lost' },
  }

  const stages = extraStages ? { ...defaultStages, ...additionalStages } : defaultStages

  const sortedDeals = deals.sort((a, b) => {
    const dateA = new Date(a.lastModified || 0).getTime()
    const dateB = new Date(b.lastModified || 0).getTime()
    return dateB - dateA
  })

  sortedDeals.forEach(deal => {
    const owner = users.find(user => user.user_uuid === deal?.owner?.[0])
    const organization = organizations.find(org => org.org_uuid === deal?.organizations?.[0])

    if (stages[deal.stage]) {
      const daysSinceModified = calculateDaysSinceModified(deal.lastModified)
      // stages[deal.stage].items.push({
      //   id: deal.id || deal.deal_uuid,
      //   primary: deal.name || deal.Deal_Name || deal.organizations.name,
      //   secondary: `Stage: ${deal.stage} - Modified: ${daysSinceModified} days ago`,
      //   details: `Contact Email: ${deal.contact?.email || 'No contact email'}, Website: ${
      //     deal.contact?.websiteUrl || 'No website URL'
      //   }, Total Value: ${deal.total || 'N/A'}`,
      //   ownerName: owner?.name,
      //   lastModified: deal.lastModified,
      //   daysSinceModified, // ✅ Include for debugging or sorting
      //   ...deal,
      //   organization: {
      //     name: organization?.name,
      //     vertical: organization?.vertical,
      //   },
      //   staleDays: daysSinceModified,
      // })
      stages[deal.stage].items.push({
        id: deal.id || deal.deal_uuid,
        primary: deal.name || deal.Deal_Name || deal.organizations?.name,
        secondary: `Stage: ${deal.stage} - Modified: ${daysSinceModified} days ago`,
        details: `Contact Email: ${deal.contact?.email || 'No contact email'}, Website: ${
          deal.contact?.websiteUrl || 'No website URL'
        }, Total Value: ${deal.total ? formatCurrency(deal.total) : '$0'}`, // ✅ Ensure total is formatted correctly
        total: deal.total || 0, // ✅ Default to 0 if missing
        ownerName: owner?.name,
        lastModified: deal.lastModified,
        staleDays: daysSinceModified,
        ...deal,
        organization: {
          name: organization?.name,
          vertical: organization?.vertical,
        },
      })
    }
  })

  return stages
}

const Root = styled('div')({
  display: 'flex',
  flexWrap: 'nowrap',
  overflowX: 'auto',
  height: 'calc(100vh - 122px)', // Adjust based on your header and footer height
  width: 'calc(100vw - 60px)',
  padding: '16px',
  // overflowY: 'hidden', // Prevent scrolling of the entire page
})
const calculateTotalSum = data => {
  return Object.values(data).reduce((sum, item) => {
    const total = parseFloat(item.total.replace(/[$,]/g, ''))
    return sum + total
  }, 0)
}
const calculateTotalGrossProfit = data => {
  return Object.values(data).reduce((sum, item) => {
    const total = parseFloat(item.grossProfit.replace(/[$,]/g, ''))
    return sum + total
  }, 0)
}
const calculateTotalDeals = data => {
  if (!data) return 0 // Ensure data exists
  return Object.values(data).reduce((sum, stage) => sum + (stage.items?.length || 0), 0)
}

const calculatePipelineMetrics = kanbanData => {
  const targetStages = ['Discovery', 'Pending Approval', 'Proposal', 'Contract Created', 'Contract Sent']

  let openDealsCount = 0
  let activePipelineIncome = 0

  targetStages.forEach(stage => {
    if (kanbanData[stage]) {
      openDealsCount += kanbanData[stage].items.length
      activePipelineIncome += kanbanData[stage].items.reduce((sum, deal) => {
        const dealTotal = deal.total ? parseFloat(deal.total) : 0
        return sum + dealTotal
      }, 0)
    }
  })

  return { openDealsCount, activePipelineIncome }
}

const calculateDealWonRevenue = kanbanData => {
  const targetStages = ['Deal Won']

  let dealWonRevenue = 0

  targetStages.forEach(stage => {
    if (kanbanData[stage]) {
      dealWonRevenue += kanbanData[stage].items.reduce((sum, deal) => {
        const dealTotal = deal.total ? parseFloat(deal.total) : 0
        return sum + dealTotal
      }, 0)
    }
  })

  return dealWonRevenue
}

const calculateWeightedPipeline = kanbanData => {
  const targetStages = ['Discovery', 'Pending Approval', 'Proposal', 'Contract Created', 'Contract Sent']

  let weightedPipelineTotal = 0

  targetStages.forEach(stage => {
    if (kanbanData[stage]) {
      weightedPipelineTotal += kanbanData[stage].items.reduce((sum, deal) => {
        // Ensure total is a valid number
        const dealTotal = deal.total ? parseFloat(deal.total.toString().replace(/[$,]/g, '')) : 0

        // Ensure probability is valid (default to 50% if empty)
        let probability = deal.probability ? parseFloat(deal.probability) : 50

        // Convert percentage to decimal (100% = 1, 50% = 0.5, etc.)
        const probabilityFactor = probability / 100

        return sum + dealTotal * probabilityFactor
      }, 0)
    }
  })

  return Math.round(weightedPipelineTotal) // Round to nearest whole number
}

const DealsKanban = () => {
  console.log('dealsKanbanRendered')
  const theme = useTheme()
  const queryClient = useQueryClient()
  // const { pipelineDeals, isPipelineLoading, isPipelineError } = usePipelineDeals(true, ['pipeline', 'deals'])
  const {
    data: organizations,
    isLoading: isOrganizationsLoading,
    isError: isOrganizationsError,
  } = useOrganizations(true, ['pipeline', 'organizations'])
  // const { deals: pipelineDeals, isDealsLoading: isPipelineLoading, isDealsError: isPipelineError } = useDeals(true, [
  //   'pipeline',
  //   'deals',
  // ])
  const { data: pipelineDeals, isLoading: isPipelineLoading, isError: isPipelineError } = useQuery({
    queryKey: ['pipelineDeals'],
    queryFn: () => getDeals('/aws/deals', 'deals'),
    staleTime: 5 * 60 * 1000, // ✅ Allows periodic updates
  })
  const { users, isUsersLoading, isUsersError } = useUsers(true, ['pipeline', 'users'])
  const [kanbanTotals, setKanbanTotals] = useState({})
  const [stageTotals, setStageTotals] = useState({})
  const [extraStages, setExtraStages] = useState(false)
  const [totalSum, setTotalSum] = useState(0)
  const [isDragging, setIsDragging] = useState(false)

  const [totalGrossProfit, setTotalGrossProfit] = useState(0)
  const [totalDeals, setTotalDeals] = useState(0)
  const [localStageState, setLocalStageState] = useState({})
  const [kanbanData, setKanbanData] = useState({})
  const refinedDeals = useMemo(() => updateDealStages(pipelineDeals), [pipelineDeals])
  useEffect(() => {
    if (kanbanData) {
      setKanbanTotals(prev => ({
        ...prev,
        openDeals: calculatePipelineMetrics(kanbanData).openDealsCount,
        activePipelineIncome: calculatePipelineMetrics(kanbanData).activePipelineIncome,
        weightedPipeline: calculateWeightedPipeline(kanbanData),
        dealWonRevenue: calculateDealWonRevenue(kanbanData),
      }))
    }
  }, [kanbanData])
  useEffect(() => {
    if (!isDragging && pipelineDeals && !isPipelineLoading && !isUsersLoading && !isOrganizationsLoading) {
      const stages = loadDealsIntoKanban(refinedDeals, extraStages, users, organizations)
      const totals = aggregateStageTotals(stages)
      setStageTotals(totals)
      setLocalStageState(totals)
      setKanbanData(stages) // ✅ Ensure this does not update while dragging
    }
  }, [pipelineDeals, extraStages, users, organizations, isDragging])

  const { mutate: updateItem } = useUpdateItem()
  const { showSnackbar } = useSnackbarContext()

  const handleDroppedUpdate = (fieldName, itemId, newValue) => {
    updateItem(
      {
        primaryKey: 'deal_uuid',
        tableName: 'deals',
        itemId,
        fieldName,
        newValue,
      },
      {
        onSuccess: () => {
          showSnackbar(`Deal ${fieldName} updated successfully`, 'success')
          queryClient.invalidateQueries(['deal', itemId])
          console.log('Update successful')
        },
        onError: err => {
          console.error('Update failed', err)
        },
      }
    )
  }

  const onDragEnd = result => {
    const { source, destination } = result
    if (!destination || (source.droppableId === destination.droppableId && source.index === destination.index)) return

    handleDroppedUpdate('stage', result.draggableId, destination.droppableId)

    setKanbanData(prevData => {
      const newData = { ...prevData }

      const sourceColumn = [...newData[source.droppableId].items]
      const destinationColumn = [...newData[destination.droppableId].items]

      const [movedItem] = sourceColumn.splice(source.index, 1)
      destinationColumn.splice(destination.index, 0, movedItem)

      return {
        ...newData,
        [source.droppableId]: { ...newData[source.droppableId], items: sourceColumn },
        [destination.droppableId]: { ...newData[destination.droppableId], items: destinationColumn },
      }
    })
  }

  const StickyHeader = styled(Box)(({ theme }) => ({
    position: 'sticky',
    top: 0,
    zIndex: 2,
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
    padding: '16px',
    borderBottom: `1px solid ${theme.palette.divider}`,
  }))
  const testLoading = true
  const isLoading = isPipelineLoading || isUsersLoading || isOrganizationsLoading
  const MemoizedKanbanColumn = React.memo(KanbanColumn)

  return (
    <>
      {isLoading ? (
        <Stack sx={{ height: '100vh' }}>
          <StickyHeader>
            <DealsKanbanHeader
              extraStages={extraStages}
              setExtraStages={setExtraStages}
              totalSum={totalSum}
              totalGrossProfit={totalGrossProfit}
              totalDeals={kanbanTotals?.openDeals ?? 0}
              activePipeline={kanbanTotals?.activePipelineIncome ?? 0}
              weightedPipeline={kanbanTotals?.weightedPipeline ?? 0}
              dealWonRevenue={kanbanTotals?.dealWonRevenue ?? 0}
              isLoading={isLoading}
            />
            <DetailsLoading />
          </StickyHeader>
        </Stack>
      ) : (
        <Stack sx={{ height: '100vh' }}>
          {/* Sticky Header */}
          <StickyHeader>
            <DealsKanbanHeader
              extraStages={extraStages}
              setExtraStages={setExtraStages}
              totalSum={totalSum}
              totalGrossProfit={totalGrossProfit}
              totalDeals={kanbanTotals?.openDeals ?? 0}
              activePipeline={kanbanTotals?.activePipelineIncome ?? 0}
              weightedPipeline={kanbanTotals?.weightedPipeline ?? 0}
              dealWonRevenue={kanbanTotals?.dealWonRevenue ?? 0}
              isLoading={isLoading}
            />
          </StickyHeader>
          {/* Scrollable Kanban Columns */}
          <DragDropContext onDragEnd={onDragEnd}>
            <Box
              sx={{
                flex: 1,
                // overflowX: 'auto', // Horizontal scrolling for columns
                // overflowY: 'hidden', // Prevent vertical scrolling here
                display: 'flex',
              }}
            >
              <Root>
                {Object.keys(kanbanData).map(key => {
                  return (
                    <MemoizedKanbanColumn
                      key={key}
                      columnId={key}
                      items={kanbanData[key].items}
                      label={kanbanData[key].label}
                      total={stageTotals[key]?.total}
                      grossProfit={stageTotals[key]?.grossProfit}
                    />
                  )
                })}
              </Root>
            </Box>
          </DragDropContext>
        </Stack>
      )}
    </>
  )
}

export default DealsKanban
