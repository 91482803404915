import React, { useState, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import GroboticDataGrid from '../DataGrid/GroboticDataGrid.js'
import { useProducts } from '../../api/aws/useProducts.js'
import { useFilteredDeals } from '../../api/aws/useFilteredDeals.js'
import { creativeTasks } from '../DataGrid/taskTemplates.js'
import { creativeStatusFilters } from '../DataGrid/filterArrays.js'

const initialSortModel = [
  { field: 'dealStatus', sort: 'desc' },
  { field: 'organizations', sort: 'asc' },
]

const initialVisibilityState = {
  implementationFee: false,
  total: false,
  status: false,
  id: false,
  strategy: false,
  vertical: false,
  stage: false,
  contractId: false,
  type: false,
  startDate: false,
  endDate: false,
  isGoogleDisplay: false,
  isGoogleSearch: false,
  isLinkedIn: false,
  isMetaAdvertising: false,
  isMicrosoftAdvertising: false,
  isSimplifi: false,
  isSnapchat: false,
  isSpotify: false,
  isTikTok: false,
  isYoutube: false,
}

const CreativePage = () => {
  const { dealId } = useParams()
  const filters = {
    status: 'Completed',
    stage: 'Deal Won',
    type: 'CSA',
    productCategories: ['Creative', 'Graphic Design'],
    excludedDealStatus: ['Expired', 'Expired (system)', 'Cancelled'],
  }
  const { filteredDeals, isFilteredDealsLoading, isFilteredDealsError } = useFilteredDeals(
    true,
    ['creative', 'deals'],
    filters
  )
  console.log('filteredDeals', filteredDeals)
  const { products, isProductsLoading, isProductsError, productsError } = useProducts()
  console.log('products', products)

  return (
    <GroboticDataGrid
      dealId={dealId}
      filteredDeals={filteredDeals}
      isFilteredDealsLoading={isFilteredDealsLoading}
      queryKey={['creative', 'deals']}
      route={'creative'}
      tasks={creativeTasks}
      context={'creative'}
      filters={filters}
      initialVisibilityState={initialVisibilityState}
      initialSortModel={initialSortModel}
      statusKey={'onboardingStatus'}
      statusLabel={'Onboarding Status'}
      filterOptions={creativeStatusFilters}
    />
  )
}
export default CreativePage
