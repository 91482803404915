import { styled, TextField } from '@mui/material'

export const dialogStyles = theme => ({
  width: '100%',
  maxWidth: '800px',
  // backgroundColor: theme.palette.mode === 'dark' ? '' : 'rgb(232, 246, 252)',
  color: '#fff',
  minHeight: '50vh',
  maxHeight: '95vh',
  padding: '30px',
  '& .MuiDialogContent-root': {
    width: '100%',
    // padding: '30px',
  },
  '& .MuiTextField-root': {
    margin: '10px 0',
  },
  '& .MuiInputBase-root': {
    backgroundColor: theme.palette.mode === 'dark' ? 'rgb(79, 84, 94)' : 'white',
    minWidth: '100%', // Ensure the input base width fits within the dialog
  },
  '& .MuiFormControl-root': {
    width: '100%', // Ensure the form control takes full width
  },
  '& .MuiDateRangePicker-root': {
    width: '100%', // Ensure the DateRangePicker takes full width
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(167,51,51,51)' : 'rgba(0,0,0,0.5)',
    borderRadius: '6px',
  },
})
export const dialogTitleStyles = theme => ({
  color: theme.palette.mode === 'dark' ? 'white' : 'black',
  fontWeight: 'bold',
  textAlign: 'center',
})
export const boxStyles = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}
export const DialogIcon = styled('img')({
  width: 50,
  height: 50,
  borderRadius: '50%',
})
export const StyledTaskTextField = styled(TextField)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? 'rgb(30 43 59)' : '#ffffff',
  backgroundColor: 'transparent',
  textAlign: 'left',
  borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0,0,0,0.12)', // Dim white borde
  borderWidth: '1px',
  borderStyle: 'solid',
  borderRadius: '4px',
  margin: 0,
  '&:hover': {
    borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.32)' : 'rgba(0,0,0,0.22)',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.12)', // Dim white border
    },
    '&:hover fieldset': {
      borderWidth: '0px !important',
    },
    '&.Mui-focused fieldset': {
      borderWidth: '1px !important',
      borderColor: theme.palette.mode === 'dark' ? 'white' : 'black',
      // backgroundColor: theme.palette.mode === 'dark' ? 'red' : '#f0f0f0', // Change background color when focused
      // borderColor: 'white', // Change border color when focused
    },
  },
  '& .MuiOutlinedInput-root.Mui-focused': {
    // backgroundColor: theme.palette.mode === 'dark' ? 'red' : '#f0f0f0', // Change background color when focused
  },
  '& .MuiOutlinedInput-input': {
    '&:focus': {
      backgroundColor: theme.palette.mode === 'dark' ? 'rgb(40 53 69)' : '#f0f0f0', // Ensure the input itself also changes background color
    },
  },
}))
