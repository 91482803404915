import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import Main from './Main'
import ThemeProviderWrapper from './themeProviders/ThemeProviderWrapper'
import { ThemeProviderContext } from './themeProviders/ThemeContext'
import { LicenseInfo } from '@mui/x-license'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

LicenseInfo.setLicenseKey(
  'c1b224b295a305fa98a7ddc8b19b8141Tz05MTg5NyxFPTE3NDkxMzgwOTAwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI='
)
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity, // Prevent all queries from becoming stale
      cacheTime: 1000 * 60 * 30, // Cache for 30 minutes test
      refetchOnWindowFocus: false, // Disable refetching on window focus
      refetchOnReconnect: false, // Disable refetching on reconnect
    },
  },
})
console.log('QueryClient initialized')
// await queryClient.prefetchQuery({
//   queryKey: ['deals'],
//   queryFn: () => getDeals('/aws/deals', 'deals'),
// })
// await queryClient.prefetchQuery({
//   queryKey: ['organizations'],
//   queryFn: () => getQuery('/aws/organizations', 'organizations'),
// })
// await queryClient.prefetchQuery({
//   queryKey: ['contacts'],
//   queryFn: () => getQuery('/aws/contacts', 'contacts'),
// })
// await queryClient.prefetchQuery({
//   queryKey: ['users'],
//   queryFn: () => getQuery('/aws/users', 'users'),
// })
const App = () => {
  return (
    <ThemeProviderWrapper>
      <QueryClientProvider client={queryClient}>
        {/* <QueryDataDebugger /> */}
        <Main />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ThemeProviderWrapper>
  )
}
const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <ThemeProviderContext>
    <App />
  </ThemeProviderContext>
)
