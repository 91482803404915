import { useState, useEffect } from 'react'
import { useGoogleFilesByFolderId } from './useGoogleFilesByFolderId.ts'
import { useValidateGoogleToken } from './useValidateGoogleToken.ts'
import { useAuth } from '../../authentication/AuthContext.js'
import axios from 'axios' // ✅ Use axios to fetch parent folder

const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY!
const DEVELOPER_KEY = process.env.REACT_APP_GOOGLE_DEVELOPER_KEY!
const APP_ID = process.env.REACT_APP_GOOGLE_APP_ID!
declare global {
  interface Window {
    google?: any
    gapi?: any
    googlePickerInstance?: any
  }
}
const useGooglePickerById = (refreshToken: string, userId: string) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [storedAccessToken, setStoredAccessToken] = useState<string | null>(null)
  const [selectedFolder, setSelectedFolder] = useState<string | null>(null)
  const [selectedFile, setSelectedFile] = useState<{
    id: string
    name: string
    mimeType: string
    url: string
    embedUrl: string
  } | null>(null)
  const [lastOpenedFolderId, setLastOpenedFolderId] = useState<string>('root')
  const { redirectToSSOAuth } = useAuth()

  // ✅ Fetch Google files when folder changes
  const { data: filesInFolder, isLoading: isFilesLoading } = useGoogleFilesByFolderId(selectedFolder)

  // ✅ Get access token from local storage
  useEffect(() => {
    const localStorageToken = localStorage.getItem('google_access_token')
    if (localStorageToken) {
      setStoredAccessToken(localStorageToken)
    }
  }, [])

  // ✅ Validate access token using `useValidateGoogleToken`
  const { validateToken, isValid, accessToken, refreshed } = useValidateGoogleToken()

  useEffect(() => {
    const checkPickerReady = setInterval(() => {
      if (window.gapi && window.google) {
        clearInterval(checkPickerReady)
        setIsLoaded(true)
      }
    }, 100)
    return () => clearInterval(checkPickerReady)
  }, [])

  // ✅ Show Google Picker
  // const showPicker = async (parentFolderId: string = 'root') => {
  //   if (!isLoaded) {
  //     console.error('Google Picker API is not fully loaded yet.')
  //     return
  //   }

  //   let token = localStorage.getItem('google_access_token')

  //   if (!token || !isValid) {
  //     console.log('🔄 Token missing or invalid, validating...')

  //     validateToken(
  //       { accessToken: storedAccessToken, refreshToken, userId },
  //       {
  //         onSuccess: data => {
  //           if (data.isValid) {
  //             localStorage.setItem('google_access_token', data.accessToken || '') // ✅ Cache token
  //             openPicker(data.accessToken!, parentFolderId) // ✅ Open picker with new token
  //           } else {
  //             console.warn('❌ Token validation failed, please reauthenticate.')
  //           }
  //         },
  //       }
  //     )
  //   } else {
  //     console.log('✅ Using valid access token from cache.')
  //     openPicker(token, parentFolderId) // ✅ Open picker immediately
  //   }
  // }
  const showPicker = async (parentFolderId: string = 'root') => {
    if (!isLoaded) {
      console.error('Google Picker API is not fully loaded yet.')
      return
    }

    const token = localStorage.getItem('google_access_token')

    if (!token || !isValid) {
      console.log('🔄 Token missing or invalid, validating...')

      validateToken(
        { accessToken: storedAccessToken, refreshToken, userId },
        {
          onSuccess: data => {
            if (data.isValid) {
              localStorage.setItem('google_access_token', data.accessToken || '')
              openPicker(data.accessToken!, parentFolderId)
            } else {
              console.warn('❌ Token validation failed, please reauthenticate.')
            }
          },
        }
      )
    } else {
      console.log('✅ Using valid access token from cache.')
      openPicker(token, parentFolderId)
    }
  }

  const fetchFolderDetails = async (folderId: string) => {
    try {
      const response = await axios.get(`https://www.googleapis.com/drive/v3/files/${folderId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('google_access_token')}` },
        params: { fields: 'id, name, mimeType, owners, createdTime, modifiedTime, webViewLink' },
      })

      console.log('📁 Folder Details:', response.data)
      return response.data
    } catch (error) {
      console.error('❌ Failed to fetch folder details:', error)
      return null
    }
  }
  // ✅ Open Picker with folder navigation and file handling
  const openPicker = (accessToken: string, parentFolderId: string) => {
    // ✅ Destroy previous picker if it exists
    if (window.googlePickerInstance) {
      window.googlePickerInstance.setVisible(false)
      window.googlePickerInstance = null // Reset the instance
    }

    const uploadView = new window.google.picker.DocsUploadView().setIncludeFolders(true).setParent(parentFolderId)

    const docsView = new window.google.picker.DocsView()
      .setEnableDrives(true)
      .setIncludeFolders(true)
      .setParent(parentFolderId)
      .setMode(window.google.picker.DocsViewMode.LIST)

    const picker = new window.google.picker.PickerBuilder()
      .addView(docsView)
      .addView(uploadView)
      .enableFeature(window.google.picker.Feature.MULTISELECT_ENABLED)
      .setOAuthToken(accessToken)
      .setDeveloperKey(DEVELOPER_KEY)
      .setCallback(async (data: any) => {
        console.log('Picker response:', data)

        if (data.action === window.google.picker.Action.PICKED) {
          const selectedItem = data.docs[0]
          console.log('selectedItem', selectedItem)

          if (selectedItem.mimeType === 'application/vnd.google-apps.folder') {
            setLastOpenedFolderId(parentFolderId)
            setSelectedFolder(selectedItem.id)
          } else if (Array.isArray(data.viewToken) && data.viewToken.includes('upload')) {
            console.log('📂 File uploaded. Navigating to last opened folder...')

            setLastOpenedFolderId(parentFolderId)
            setSelectedFolder(selectedItem.id)
            // ✅ Reopen the picker in the last opened folder
            showPicker(parentFolderId)
          } else {
            const fileUrl = `https://drive.google.com/file/d/${selectedItem.id}/view`
            setSelectedFile({
              id: selectedItem.id,
              name: selectedItem.name,
              mimeType: selectedItem.mimeType,
              url: selectedItem.url,
              embedUrl: selectedItem.embedUrl,
            })
            setLastOpenedFolderId(selectedItem.parentId)
          }
        } else if (data.action === window.google.picker.Action.CANCEL) {
          console.log('❌ Picker cancelled, destroying instance.')
          window.googlePickerInstance = null
        }
      })
      .setAppId(APP_ID)
      .build()

    window.googlePickerInstance = picker
    picker.setVisible(true)
  }

  // const openPicker = (accessToken: string, parentFolderId: string) => {
  //   const uploadView = new window.google.picker.DocsUploadView().setIncludeFolders(true).setParent(parentFolderId)
  //   const docsView = new window.google.picker.DocsView()
  //     .setEnableDrives(true)
  //     .setIncludeFolders(true)
  //     .setParent(parentFolderId)
  //     .setMode(window.google.picker.DocsViewMode.LIST)
  //   const picker = new window.google.picker.PickerBuilder()
  //     .addView(docsView)
  //     .addView(uploadView)
  //     .enableFeature(window.google.picker.Feature.MULTISELECT_ENABLED)
  //     .setOAuthToken(accessToken)
  //     .setDeveloperKey(DEVELOPER_KEY)
  //     .setCallback(async (data: any) => {
  //       console.log('Picker response:', data)

  //       if (data.action === window.google.picker.Action.PICKED) {
  //         const selectedItem = data.docs[0]
  //         console.log('selectedItem', selectedItem)

  //         if (selectedItem.mimeType === 'application/vnd.google-apps.folder') {
  //           setLastOpenedFolderId(parentFolderId)
  //           setSelectedFolder(selectedItem.id)
  //         } else if (Array.isArray(data.viewToken) && data.viewToken.includes('upload')) {
  // console.log('📂 File uploaded. Navigating to last opened folder...')

  // setLastOpenedFolderId(parentFolderId)
  // setSelectedFolder(selectedItem.id)

  // // ✅ Reopen the picker in the last opened folder
  // showPicker(parentFolderId)
  //         } else {
  //           // ✅ Set file info and update last opened folder
  //           const fileUrl = `https://drive.google.com/file/d/${selectedItem.id}/view`
  //           setSelectedFile({
  //             id: selectedItem.id,
  //             name: selectedItem.name,
  //             mimeType: selectedItem.mimeType,
  //             url: selectedItem.url,
  //             embedUrl: selectedItem.embedUrl,
  //           })

  //           setLastOpenedFolderId(selectedItem.parentId)
  //         }
  //       }
  //     })
  //     .setAppId(APP_ID)
  //     .build()
  //   // ✅ Force Google Picker to appear on top of everything
  //   setTimeout(() => {
  //     const pickerDialog = document.querySelector('.picker-dialog')
  //     if (pickerDialog) {
  //       pickerDialog.setAttribute('style', 'z-index: 1300 !important;')
  //     }
  //   }, 100)
  //   picker.setVisible(true)
  // }

  return { isLoaded, showPicker, selectedFolder, filesInFolder, isFilesLoading, selectedFile, lastOpenedFolderId }
}

export default useGooglePickerById
