import dayjs from 'dayjs'

export const formatDayjsDateTime = dateStr => {
  if (dateStr) {
    return dayjs(dateStr).format('MM/DD/YYYY HH:mm:ss') // e.g., 02/13/2025 15:45:30
  } else {
    return null
  }
}
export const formatDayjsDate = date => {
  return dayjs(date).format('YYYY-MM-DD') // Adjust the format as needed
}
export const formatDayJsTime = dateStr => {
  // console.log('[renderDealsColumns][formatDayjsDate] dateStr: ', dateStr)
  if (dateStr) {
    return dayjs(dateStr).format('hh:mm A')
  } else {
    return null
  }
}
export const formatDateString = date => {
  if (!date) return ''
  return dayjs.isDayjs(date) ? date.format('MM/DD/YYYY') : dayjs(date).format('MM/DD/YYYY')
}
export const formatDayjsModifiedTime = dateStr => {
  // console.log('[renderDealsColumns][formatDayjsModifiedTime] dateStr: ', dateStr)
  if (dateStr) {
    return dayjs(dateStr).format('MM/DD/YYYY hh:mm A')
  } else {
    return null
  }
}
