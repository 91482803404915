import React from 'react'
import { Box, InputLabel, Typography, Grid, useTheme } from '@mui/material'
import { formatCurrency } from './aggregateStageTotals'
import { styled } from '@mui/material/styles'

const MetricHeaderH3 = styled('h3')(({ label, theme }) => ({
  margin: 0,
  textAlign: 'center',
  color: 'black',
  padding: '8px 0',
  position: 'sticky',
  top: 0,
  zIndex: 1,
  // width: '100%',
  overflow: 'hidden',
  borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
}))

const MetricHeader = ({ totalSum, totalGrossProfit, totalDeals }) => {
  const theme = useTheme()
  return (
    <MetricHeaderH3>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'left',
          justifyContent: 'center',
        }}
      >
        <Grid item xl={3} sx={{ display: 'flex' }}>
          <InputLabel sx={{ fontWeight: 'bold', fontSize: 16, color: theme.palette.text.primary }}>
            {'Open Deals'}
          </InputLabel>
          <Typography sx={{ color: theme.palette.text.primary }}>{totalDeals}</Typography>
        </Grid>

        <Grid item xl={3} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <InputLabel sx={{ fontWeight: 'bold', fontSize: 16, color: theme.palette.text.primary }}>
            {'Active Pipeline'}
          </InputLabel>
        </Grid>
        <Grid item xl={3} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <InputLabel>Total Gross Profit</InputLabel>
          <Typography sx={{ color: 'rgb(22, 163, 74)' }}>{`${formatCurrency(totalSum) || '0.00'}`}</Typography>
        </Grid>
        <Grid item xl={3} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <InputLabel>Total Contract Amount</InputLabel>
          <Typography sx={{ color: 'rgb(22, 163, 74)' }}>{`${formatCurrency(totalGrossProfit) || '0.00'}`}</Typography>
        </Grid>
      </Box>
    </MetricHeaderH3>
  )
}

export default MetricHeader
