import React from 'react'
import { Box, Typography, useTheme } from '@mui/material'
import FolderIcon from '@mui/icons-material/Folder'
import GoogleDriveIcon from '../Icons/GoogleDriveIcon'

const ShowPickerButton = ({ folderName, folderId, showPicker, isLoaded, maxWidth = 275 }) => {
  const theme = useTheme()
  return (
    <Box
      onClick={() => showPicker(folderId)}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'left',
        textTransform: 'none',
        bgcolor: 'transparent',
        maxWidth: maxWidth ?? 275,
        width: '100%',
        textAlign: 'center',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        gap: 1,
      }}
    >
      {!isLoaded ? (
        <>
          {/* <FolderIcon sx={{ mr: 1, color: theme.palette.text.primary }} /> */}
          <GoogleDriveIcon />
          <Typography
            sx={{
              textAlign: 'center',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              color: theme.palette.text.secondary,
            }}
          >
            {folderName || 'No Folder'}
          </Typography>
        </>
      ) : (
        <>
          {/* <FolderIcon sx={{ mr: 1, color: theme.palette.text.primary }} /> */}
          <GoogleDriveIcon />
          <Typography
            sx={{
              fontWeight: 'bold',
              textAlign: 'center',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              color: folderName ? theme.palette.text.primary : theme.palette.text.secondary,
            }}
          >
            {folderName || 'No Folder'}
          </Typography>
        </>
      )}
    </Box>
  )
}

export default ShowPickerButton
