export const ncoTeamTasks = [
  {
    PM: [
      {
        checklistSent: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
      {
        creativeTasksSent: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
      {
        creativesReady: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
    ],
    MIA: [
      {
        verifyDependencies: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
      {
        createGTMGA4: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
      ///////
      {
        configureDomains: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
      {
        enableKeyEvent: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
      {
        enableGoogleSignals: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
      {
        keyCountingMethod: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
      //////////
      {
        createAccountShells: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
    ],
    SEO: [
      {
        createGSCTagForPM: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
      {
        onboardingChecklist: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
    ],
    AM: [
      {
        introEmail: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
      {
        tapclicksSetup: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
      {
        setReportingCallDate: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
    ],
    DMS: [
      {
        campaignCreation: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
      {
        launchCampaigns: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
    ],

    QA: [
      {
        campaignQuality: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
      {
        adApprovals: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
    ],
  },
]

export const taskNameMapping = {
  checklistSent: 'Checklist Sent',
  creativeTasksSent: 'Creative Tasks Sent',
  creativesReady: 'Creatives Ready',
  verifyDependencies: 'Verify Dependencies',
  createGTMGA4: 'Create GTM/GA4',
  //////
  configureDomains: 'Configure Domains',
  enableKeyEvent: 'Enable Key Event',
  enableGoogleSignals: 'Enable Google Signals',
  keyCountingMethod: 'Update Key Event Counting Method to "once per session"',
  //////
  createAccountShells: 'Create Account Shells',
  createGSCTagForPM: 'Create GSC Tag for PM',
  onboardingChecklist: 'Onboarding Checklist',
  introEmail: 'Intro Email',
  tapclicksSetup: 'Tapclicks Setup',
  setReportingCallDate: 'Set Reporting Call Date',
  campaignCreation: 'Campaign Creation',
  launchCampaigns: 'Launch Campaigns',
  campaignQuality: 'Campaign Quality',
  adApprovals: 'Ad Approvals',
}

export const ncoDependenciesList = [
  {
    Dependency: [
      {
        fbPage: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'Requested', color: 'rgb(253, 188, 100)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
      {
        creativeAssets: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'Requested', color: 'rgb(253, 188, 100)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
      {
        GA4: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'Requested', color: 'rgb(253, 188, 100)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
      {
        GTM: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'Requested', color: 'rgb(253, 188, 100)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
      {
        Integration: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'Requested', color: 'rgb(253, 188, 100)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
      // {
      //   Creatives: [
      //     { name: 'N/A', color: 'rgb(121, 126, 147)' },
      //     { name: 'Requested', color: 'rgb(253, 188, 100)' },
      //     { name: 'Done', color: 'rgb(51, 211, 145)' },
      //   ],
      // },
      {
        websiteAccess: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'Requested', color: 'rgb(253, 188, 100)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
    ],
  },
]
export const dependenciesNameMapping = {
  fbPage: 'FB Page',
  creativeAssets: 'Creative Assets',
  GA4: 'GA4',
  GTM: 'GTM',
  Integration: 'Integration',
  // Creatives: 'Creatives',
  websiteAccess: 'Website Access',
}

export const ncoChannelTasks = [
  {
    Channel: [
      {
        Search: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'Ready To Create', color: 'rgb(51, 158, 205)' },
          { name: 'Ready for QA', color: 'rgb(87, 155, 252)' },
          { name: 'Launched', color: 'rgb(51, 211, 145)' },
          { name: 'Awaiting Client', color: 'rgb(232, 105, 125)' },
        ],
      },
      {
        Social: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'Ready To Create', color: 'rgb(51, 158, 205)' },
          { name: 'Ready for QA', color: 'rgb(87, 155, 252)' },
          { name: 'Launched', color: 'rgb(51, 211, 145)' },
          { name: 'Awaiting Client', color: 'rgb(232, 105, 125)' },
        ],
      },
      {
        Display: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'Ready To Create', color: 'rgb(51, 158, 205)' },
          { name: 'Ready for QA', color: 'rgb(87, 155, 252)' },
          { name: 'Launched', color: 'rgb(51, 211, 145)' },
          { name: 'Awaiting Client', color: 'rgb(232, 105, 125)' },
        ],
      },
    ],
  },
]

export const ncoMasterStatus = [
  {
    status_name: 'Awaiting Dependencies',
    status_color_hexcode: 'rgb(87, 155, 252)',
  },
  {
    status_name: 'Dependencies Received',
    status_color_hexcode: 'rgb(255, 131, 88)',
  },
  { status_name: 'Handoff Call', status_color_hexcode: 'rgb(120, 75, 209)' },
  { status_name: 'Ready To Create', status_color_hexcode: 'rgb(250, 200, 50)' },
  { status_name: 'Launched', status_color_hexcode: 'rgb(3, 127, 76)' },
  // {
  //   status_name: 'Dependencies Verified',
  //   status_color_hexcode: 'rgb(226, 68, 92)',
  // },
  // { status_name: 'Agreement Signed', status_color_hexcode: 'rgb(0, 200, 117)' },
]
export const creativeTasks = [
  {
    PM: [
      {
        pmCreativeTask1: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
    ],
    MIA: [
      {
        miaCreativeTask1: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
      {
        createGTMGA4: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
    ],
    SEO: [
      {
        seoCreativeTask1: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
    ],
    AM: [
      {
        amCreativeTask1: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
    ],
    DMS: [
      {
        dmsCreativeTask1: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
    ],

    QA: [
      {
        qaCreativeTask1: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
    ],
  },
]
export const websiteTasks = [
  {
    PM: [
      {
        pmWebsiteTask1: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
    ],
    MIA: [
      {
        miaWebsiteTask1: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
      {
        createGTMGA4: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
    ],
    SEO: [
      {
        seoWebsiteTask1: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
    ],
    AM: [
      {
        amWebsiteTask1: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
    ],
    DMS: [
      {
        dmsWebsiteTask1: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
    ],

    QA: [
      {
        qaWebsiteTask1: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
    ],
  },
]
export const seoTasks = [
  {
    PM: [
      {
        pmSEOTask1: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
    ],
    MIA: [
      {
        miaSEOTask1: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
      {
        createGTMGA4: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
    ],
    SEO: [
      {
        seoSEOTask1: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
    ],
    AM: [
      {
        amSEOTask1: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
    ],
    DMS: [
      {
        dmsSEOTask1: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
    ],

    QA: [
      {
        qaSEOTask1: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
    ],
  },
]
