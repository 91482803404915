import { useState, useEffect } from 'react'

const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY!
const DEVELOPER_KEY = process.env.REACT_APP_GOOGLE_DEVELOPER_KEY!
const APP_ID = process.env.REACT_APP_GOOGLE_APP_ID!

declare global {
  interface Window {
    google?: any
    gapi?: any
    googlePickerInstance?: any
  }
}

const useGooglePicker = () => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [accessToken, setAccessToken] = useState<string | null>(null)
  const [selectedFile, setSelectedFile] = useState<{ id: string; name: string } | null>(null)
  const [lastOpenedFolderId, setLastOpenedFolderId] = useState<string>('root') // ✅ Store last folder

  useEffect(() => {
    // Ensure the Google API is loaded
    const checkPickerReady = setInterval(() => {
      if (window.gapi && window.google) {
        clearInterval(checkPickerReady)
        setIsLoaded(true)
      }
    }, 100)

    return () => clearInterval(checkPickerReady)
  }, [])

  useEffect(() => {
    // Retrieve the existing access token from localStorage or your auth provider
    const storedAccessToken = localStorage.getItem('google_access_token')
    if (storedAccessToken) {
      setAccessToken(storedAccessToken)
    }
  }, [])

  const showPicker = (parentFolderId: string = 'root') => {
    if (!isLoaded || !accessToken) {
      console.error('Google Picker API is not fully loaded yet.')
      return
    }

    // ✅ Use the last opened folder as the parent when reopening
    const folderPicker = new window.google.picker.DocsView(window.google.picker.ViewId.FOLDERS)
      .setSelectFolderEnabled(true)
      .setIncludeFolders(true)
      .setOwnedByMe(true)

    const docsView = new window.google.picker.DocsView()
      .setIncludeFolders(true)
      .setOwnedByMe(true)
      .setSelectFolderEnabled(true)
      .setMimeTypes(
        'application/vnd.google-apps.folder,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      )

    const driveView = new window.google.picker.DocsView(window.google.picker.ViewId.DOCS)
      .setEnableDrives(true)
      .setIncludeFolders(true)
      .setOwnedByMe(false)

    const uploadView = new window.google.picker.DocsUploadView().setIncludeFolders(true).setParent(parentFolderId) // ✅ Keep last folder open

    const picker = new window.google.picker.PickerBuilder()
      .addView(docsView)
      .addView(driveView)
      .addView(uploadView)
      .enableFeature(window.google.picker.Feature.MULTISELECT_ENABLED)
      .addView(folderPicker)
      .setOAuthToken(accessToken)
      .setDeveloperKey(DEVELOPER_KEY)
      .setCallback((data: any) => {
        console.log('Picker response:', data)

        if (data.action === window.google.picker.Action.PICKED) {
          const selectedItem = data.docs[0]

          if (selectedItem.mimeType === 'application/vnd.google-apps.folder') {
            // ✅ Store last folder ID
            setLastOpenedFolderId(selectedItem.id)
          } else {
            // ✅ Store the selected file
            setSelectedFile({ id: selectedItem.id, name: selectedItem.name })
          }
        }
      })
      .setAppId(APP_ID)
      .build()

    picker.setVisible(true)
  }

  return { isLoaded, showPicker, selectedFile, lastOpenedFolderId }
}

export default useGooglePicker
