import React, { useState, useMemo, useEffect } from 'react'
import { Box, Button, Typography, IconButton, useTheme } from '@mui/material'
import { DataGridPro, GridFooterContainer, GridFooter } from '@mui/x-data-grid-pro'
import CustomNcoDrawerToolbar from '../NCO/CustomNcoDrawerToolbar'
import CustomNcoGridFooter from '../NCO/CustomNcoDrawerFooter'
import UserImageComponent from '../Users/UserImageComponent'
import { taskNameMapping, ncoDependenciesList } from '../NCO/ncoTaskObjects'
import RoleCard from '../NCO/RoleCard'
import { renderColorByString } from '../DataGrid/renderColorByString'
import { useParams } from 'react-router-dom'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import Channels from '../NCO/Channels'
import { useDeal } from '../../api/aws/useDeal'

const DrawerTasksGrid = ({ deal, updates, setUpdates, initialValues }) => {
  // const { newDeal: deal, setNewDeal: setDeal } = useDealDetailsContext()
  const queryClient = useQueryClient()
  const { dealId } = useParams()
  const roles = ['PM', 'DMS', 'MIA', 'AM', 'QA', 'SEO']
  // const { deal, isDealLoading, isDealError } = useDeal(dealId)
  const [selectedRole, setSelectedRole] = useState('All')
  const [open, setOpen] = useState(false)
  const theme = useTheme()
  const statusOptions = useMemo(() => {
    const allStatuses = ncoDependenciesList[0]?.Dependency?.[0]?.fbPage || []
    return allStatuses.map(option => option.name)
  }, [])
  const tasks = useMemo(() => {
    return roles.flatMap(role => {
      const roleTasks = deal?.[`${role.toLowerCase()}Tasks`] || {}
      return Object.entries(roleTasks).map(([taskGroupName, status]) => ({
        id: `${role}-${taskGroupName}`, // Ensure id matches the format
        role,
        taskGroupName,
        status,
      }))
    })
  }, [deal, roles])

  const handleStatusChange = async (id, newValue) => {
    console.log('[handleStatusChange]')
    const [role, taskGroupName] = id.split('-')
    const roleTasksPropertyName = `${role.toLowerCase()}Tasks`

    // Ensure the role's tasks are updated
    const updatedRoleTasks = {
      ...deal[roleTasksPropertyName], // Spread existing tasks for the role
      [taskGroupName]: newValue, // Update the specific task group
    }

    // Update the task status within the correct role's tasks
    setUpdates(prev => ({
      ...prev,
      [roleTasksPropertyName]: updatedRoleTasks, // Scope to the roleTasks property
    }))

    // Update the query client to reflect the changes in the cache
    await queryClient.setQueryData(['deal', dealId], old => ({
      ...old,
      [roleTasksPropertyName]: updatedRoleTasks, // Update the roleTasks property
    }))
  }

  const handleFieldChange = async (fieldName, newValue) => {
    if (deal[fieldName] !== newValue) {
      setUpdates(prev => ({ ...prev, [fieldName]: newValue }))
      await queryClient.setQueryData(['deal', dealId], old => ({
        ...old,
        [fieldName]: newValue,
      }))
    }
  }

  const filteredTasks = useMemo(() => {
    if (selectedRole === 'All') return tasks
    return tasks.filter(task => task.role === selectedRole)
  }, [tasks, selectedRole])
  const columns = [
    {
      field: 'id',
      headerName: 'Role',
      flex: 0.2,
      valueGetter: params => {
        return params?.split('-')?.[0]
      },
      renderCell: params => {
        return <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>{params?.value}</Box>
      },
    },
    {
      field: 'role',
      headerName: 'Team Member(s)',
      flex: 1,
      editable: true,
      renderCell: params => {
        const role = params?.value
        const selectedUsers = deal?.[role]
        return (
          <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
            {selectedUsers?.map(user => (
              <UserImageComponent imageUrl={user.image_512} key={user.user_uuid} />
            ))}
          </Box>
        )
      },
      renderEditCell: params => {
        const role = params?.value
        return (
          <Box
            onClick={() => setOpen(true)}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%', // Full height of the cell
              width: '100%', // Full width of the cell
              boxSizing: 'border-box', // Include padding in the element's total width/height
              '& .MuiAutocomplete-root': {
                height: '100%', // Full height
                width: '100%', // Full width
              },
            }}
          >
            <RoleCard
              open={open || false}
              initialRoleUsers={deal[role]}
              handleChange={handleFieldChange}
              roleName={role}
            />
          </Box>
        )
      },
    },
    {
      field: 'taskGroupName',
      headerName: 'Task Group',
      flex: 1,
      valueGetter: params => taskNameMapping[params] || params,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 200,
      renderCell: params => {
        const currentStatusIndex = statusOptions.findIndex(option => option === params.value)
        const nextStatusIndex = (currentStatusIndex + 1) % statusOptions.length

        const handleToggle = () => {
          const newValue = statusOptions[nextStatusIndex]
          params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue })
          handleStatusChange(params.id, newValue)
        }

        return (
          <Button
            onClick={handleToggle}
            sx={{
              backgroundColor: renderColorByString(params?.value),
              color: '#fff',
              '&:hover': {
                backgroundColor: renderColorByString(params?.value, true),
              },
              textTransform: 'capitalize',
              width: '100%',
              padding: '8px 16px',
            }}
          >
            {params.value}
          </Button>
        )
      },
    },
  ]

  return (
    <>
      <Box
        sx={{
          height: 'calc(100vh - 310px)',
          pb: 0, // No padding to ensure it aligns with the cell
          pt: 0,
          pr: 1,
          pl: 1,
        }}
      >
        <Channels deal={deal} handleChange={handleFieldChange} />

        <DataGridPro
          rows={filteredTasks}
          columns={columns}
          editMode='cell'
          onRowEditStart={(event, params) => console.log('params row editStart', event, params)}
          onCellDoubleClick={params => {
            if (params?.field === 'role') {
              setOpen(true)
            }
          }}
          onCellEditCommit={params => handleStatusChange(params.id, params.value)}
          disableSelectionOnClick
          disableRowSelectionOnClick
          slots={{
            toolbar: CustomNcoDrawerToolbar,
            footer: CustomNcoGridFooter,
          }}
          slotProps={{
            toolbar: {
              roles,
              selectedRole,
              setSelectedRole,
            },
          }}
          sx={{
            '& .MuiDataGrid-cell': {
              transition: 'none',
              userSelect: 'none',
              '&:hover': {
                transform: 'none',
              },
            },
            '& .MuiDataGrid-main': {
              border: `1px dotted rgb(0, 0, 0)`,
            },
            '& .MuiDataGrid-container': {
              backgroundColor: theme.palette.background.main, // Darker background for column headers
            },
            '& .MuiDataGrid-topContainer': {
              backgroundColor: theme.palette.background.main, // Darker background for column headers
            },
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: theme.palette.background.main, // Darker background for column headers
            },
            '& .MuiDataGrid-columnHeader': {
              backgroundColor: theme.palette.background.main, // Darker background for column headers
            },
            '& .MuiDataGrid-columnHeaderDraggableContainer': {
              backgroundColor: theme.palette.background.main, // Darker background for column headers
            },
            '& .MuiDataGrid-columnSeparator': {
              bgcolor: `1px dotted rgb(0, 0, 0)`,
            },
          }}
        />
      </Box>
    </>
  )
}

export default DrawerTasksGrid
