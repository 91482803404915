const gridItemSize = {
  productName: {
    xs: 6,
    sm: 6,
    md: 6,
    lg: 11,
    xl: 3,
  },
  description: {
    xs: 1,
    sm: 1,
    md: 1,
    lg: 1,
    xl: 0.4,
  },
  numbers: {
    xs: 2,
    sm: 2,
    md: 2,
    lg: 3,
    xl: 0.8,
  },
  dateRange: {
    xs: 12,
    sm: 12,
    md: 12,
    lg: 6,
    xl: 2.4,
  },
  delete: {
    xs: 1,
    sm: 1,
    md: 1,
    lg: 3,
    xl: 0.2,
  },
}
const ioGridItemSize = {
  productName: {
    xs: 6,
    sm: 6,
    md: 6,
    lg: 6,
    xl: 3,
  },
  description: {
    xs: 1,
    sm: 1,
    md: 1,
    lg: 1,
    xl: 0.4,
  },
  qty: {
    xs: 1,
    sm: 1,
    md: 1,
    lg: 1,
    xl: 0.6,
  },
  numbers: {
    xs: 2,
    sm: 2,
    md: 2,
    lg: 2,
    xl: 0.7,
  },
  dateRange: {
    xs: 8,
    sm: 8,
    md: 8,
    lg: 8,
    xl: 2.4,
  },
  delete: {
    xs: 1,
    sm: 1,
    md: 1,
    lg: 1,
    xl: 0.2,
  },
}
const csaGridItemSize = {
  productName: {
    xs: 6,
    sm: 6,
    md: 6,
    lg: 11,
    xl: 4,
  },
  description: {
    xs: 1,
    sm: 1,
    md: 1,
    lg: 1,
    xl: 0.4,
  },
  numbers: {
    xs: 2,
    sm: 2,
    md: 2,
    lg: 3,
    xl: 1.4,
  },
  grossProfit: {
    xs: 10,
    sm: 10,
    md: 10,
    lg: 10,
    xl: 1.4,
  },
  dateRange: {
    xs: 6,
    sm: 6,
    md: 6,
    lg: 6,
    xl: 2.8,
  },
  delete: {
    xs: 1,
    sm: 1,
    md: 1,
    lg: 2,
    xl: 0.2,
  },
}

export const columns = [
  { id: 'rowId', label: 'Row Id', type: 'text', flex: '0 0 50px', style: { background: 'none', border: 'none' } },
  {
    id: 'productName',
    label: 'Product Name',
    type: 'autocomplete',
    flex: '1 1 250px',
    style: { background: 'none', border: 'none' },
    ...gridItemSize.productName,
  },
  {
    id: 'description',
    label: '',
    type: 'popover',
    flex: '0 0 200px',
    style: { background: 'none', border: 'none' },
    ...gridItemSize.description,
  },
  {
    id: 'price',
    label: 'Price',
    type: 'number',
    flex: '0 0 90px',
    style: { background: 'none', border: 'none' },
    ...gridItemSize.numbers,
  },
  {
    id: 'billedPrice',
    label: 'Billed Price',
    type: 'number',
    flex: '0 0 90px',
    style: { background: 'none', border: 'none' },
    ...gridItemSize.numbers,
  },
  {
    id: 'margin',
    label: 'Margin',
    type: 'number',
    flex: '0 0 80px',
    style: { background: 'none', border: 'none' },
    ...gridItemSize.numbers,
  },
  {
    id: 'mediaSpend',
    label: 'Media Spend',
    type: 'number',
    flex: '0 0 80px',
    style: { background: 'none', border: 'none' },
    ...gridItemSize.numbers,
  },
  {
    id: 'dateRange',
    label: 'Date Range',
    type: 'dateRange',
    flex: '1 1 250px',
    ...gridItemSize.dateRange,
  },

  //   {
  //     id: 'status',
  //     label: 'Status',
  //     type: 'select',
  //     flex: '0 0 150px',
  //     style: { background: 'none', border: 'none' },
  //   },
  {
    id: 'payer',
    label: 'Payer',
    type: 'paidSelect',
    flex: '0 0 75px',
    style: { background: 'none', border: 'none' },
    ...gridItemSize.numbers,
  },
  {
    id: 'accountUsed',
    label: 'Account Used',
    type: 'paidSelect',
    flex: '0 0 75px',
    style: { background: 'none', border: 'none' },
    ...gridItemSize.numbers,
  },
  {
    id: 'grossProfit',
    label: 'Gross Profit',
    type: 'number',
    flex: '0 0 80px',
    style: { background: 'none', border: 'none' },
    ...gridItemSize.numbers,
  },
  {
    id: 'deleteButton',
    label: 'Delete',
    type: 'component',
    flex: '0 0 50px',
    style: { background: 'none', border: 'none' },
    ...gridItemSize.delete,
  },
]

export const csaColumns = [
  { id: 'rowId', label: 'Row Id', type: 'text', flex: '0 0 50px', style: { background: 'none', border: 'none' } },
  {
    id: 'productName',
    label: 'Product Name',
    type: 'autocomplete',
    flex: '1 1 350px',
    style: { background: 'none', border: 'none' },
    ...csaGridItemSize.productName,
  },
  {
    id: 'description',
    label: '',
    type: 'popover',
    flex: '1 1 350px',
    style: { background: 'none', border: 'none' },
    ...csaGridItemSize.description,
  },
  {
    id: 'price',
    label: 'Price',
    type: 'number',
    flex: '0 0 90px',
    style: { background: 'none', border: 'none' },
    ...csaGridItemSize.numbers,
  },
  {
    id: 'margin',
    label: 'Margin',
    type: 'number',
    flex: '0 0 80px',
    style: { background: 'none', border: 'none' },
    ...csaGridItemSize.numbers,
  },
  {
    id: 'dateRange',
    label: 'Date Range',
    type: 'dateRange',
    flex: '1 1 250px',
    ...csaGridItemSize.dateRange,
  },

  {
    id: 'grossProfit',
    label: 'Gross Profit',
    type: 'number',
    flex: '0 0 80px',
    style: { background: 'none', border: 'none' },
    ...csaGridItemSize.grossProfit,
  },
  {
    id: 'deleteButton',
    label: 'Delete',
    type: 'component',
    flex: '0 0 50px',
    style: { background: 'none', border: 'none' },
    ...csaGridItemSize.delete,
  },
]
export const ioColumns = [
  { id: 'rowId', label: 'Row Id', type: 'text', flex: '0 0 50px', style: { background: 'none', border: 'none' } },
  {
    id: 'productName',
    label: 'Product Name',
    type: 'autocomplete',
    flex: '1 1 350px',
    style: { background: 'none', border: 'none' },
    ...ioGridItemSize.productName,
  },
  {
    id: 'description',
    label: '',
    type: 'popover',
    flex: '0 0 150px',
    style: { background: 'none', border: 'none' },
    ...ioGridItemSize.description,
  },
  {
    id: 'qty',
    label: 'Qty',
    type: 'number',
    flex: '0 0 90px',
    style: { background: 'none', border: 'none' },
    ...ioGridItemSize.qty,
  },
  {
    id: 'price',
    label: 'Price',
    type: 'number',
    flex: '0 0 90px',
    style: { background: 'none', border: 'none' },
    ...ioGridItemSize.numbers,
  },
  {
    id: 'billedPrice',
    label: 'Billed Price',
    type: 'number',
    flex: '0 0 90px',
    style: { background: 'none', border: 'none' },
    ...ioGridItemSize.numbers,
  },
  {
    id: 'margin',
    label: 'Margin',
    type: 'number',
    flex: '0 0 80px',
    style: { background: 'none', border: 'none' },
    ...ioGridItemSize.numbers,
  },
  {
    id: 'mediaSpend',
    label: 'Media Spend',
    type: 'number',
    flex: '0 0 80px',
    style: { background: 'none', border: 'none' },
    ...ioGridItemSize.numbers,
  },
  {
    id: 'dateRange',
    label: 'Date Range',
    type: 'dateRange',
    flex: '1 1 250px',
    ...ioGridItemSize.dateRange,
  },
  //   {
  //     id: 'status',
  //     label: 'Status',
  //     type: 'select',
  //     flex: '0 0 150px',
  //     style: { background: 'none', border: 'none' },
  //   },
  {
    id: 'payer',
    label: 'Payer',
    type: 'paidSelect',
    flex: '0 0 75px',
    style: { background: 'none', border: 'none' },
    ...ioGridItemSize.numbers,
  },
  {
    id: 'accountUsed',
    label: 'Account Used',
    type: 'paidSelect',
    flex: '0 0 75px',
    style: { background: 'none', border: 'none' },
    ...ioGridItemSize.numbers,
  },
  {
    id: 'grossProfit',
    label: 'Gross Profit',
    type: 'number',
    flex: '0 0 80px',
    style: { background: 'none', border: 'none' },
    ...ioGridItemSize.numbers,
  },
  {
    id: 'deleteButton',
    label: 'Delete',
    type: 'component',
    flex: '0 0 50px',
    style: { background: 'none', border: 'none' },
    ...ioGridItemSize.delete,
  },
]
export const smallScreenFieldTypes = ['popover', 'component', 'dateRange', 'number']
