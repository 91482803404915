// import React, { useState } from 'react'
// import { Box, TextField, IconButton, Button, Chip, Stack } from '@mui/material'
// import InsertEmoticonOutlinedIcon from '@mui/icons-material/InsertEmoticonOutlined'
// import AttachFileIcon from '@mui/icons-material/AttachFile'
// import CloseIcon from '@mui/icons-material/Close'
// import { useSlackReply } from '../../../api/slack/useSlackReply'
// import { useFileUpload } from '../../../api/slack/useFileUpload'

// const TaskReplyInput = ({ task }) => {
//   const [reply, setReply] = useState('')
//   const [files, setFiles] = useState([])
//   const { mutate: sendReply } = useSlackReply()
//   const { mutate: fileUploadMutation } = useFileUpload()

//   const handleFileChange = event => {
//     setFiles([...files, ...Array.from(event.target.files)])
//   }

//   const handleSubmit = () => {
//     if (reply.trim()) {
//       const payload = {
//         channel: task?.project?.slackChannel?.id,
//         text: reply,
//         thread_ts: task?.taskThread?.ts,
//       }
//       sendReply(payload)
//       setReply('')
//     }

//     if (files.length > 0) {
//       files.forEach(file => {
//         const formData = new FormData()
//         formData.append('file', file)
//         formData.append('channel', task?.project?.slackChannel?.id)
//         formData.append('thread_ts', task?.taskThread?.ts)
//         fileUploadMutation(formData) // Assuming your mutation can handle FormData
//       })
//       setFiles([])
//     }
//   }

//   const removeFile = index => {
//     setFiles(files.filter((_, i) => i !== index))
//   }

//   return (
//     <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
//       <TextField
//         fullWidth
//         multiline
//         variant='outlined'
//         placeholder='Type your reply here...'
//         value={reply}
//         onChange={e => setReply(e.target.value)}
//         minRows={3}
//         maxRows={6}
//       />
//       <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
//         <IconButton color='primary' component='label'>
//           <AttachFileIcon />
//           <input type='file' hidden onChange={handleFileChange} multiple />
//         </IconButton>
//         <IconButton color='primary'>
//           <InsertEmoticonOutlinedIcon />
//         </IconButton>
//         <Button variant='contained' color='primary' onClick={handleSubmit}>
//           Send Reply / Upload
//         </Button>
//       </Box>
//       <Stack direction='row' gap={1} flexWrap='wrap'>
//         {files.map((file, index) => (
//           <Chip
//             key={index}
//             label={file.name}
//             onDelete={() => removeFile(index)}
//             variant='outlined'
//           />
//         ))}
//       </Stack>
//     </Box>
//   )
// }

// export default TaskReplyInput

import React, { useState, useCallback } from 'react'
import {
  Box,
  IconButton,
  TextField,
  Button,
  Chip,
  Stack,
  useTheme,
} from '@mui/material'
import InsertEmoticonOutlinedIcon from '@mui/icons-material/InsertEmoticonOutlined'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import CloseIcon from '@mui/icons-material/Close'
import { useSlackReply } from '../../../api/slack/useSlackReply' // Adjust import paths as necessary
import { useFileUpload } from '../../../api/slack/useFileUpload'

const TaskReplyInput = ({ task }) => {
  const [reply, setReply] = useState('')
  const [files, setFiles] = useState([])
  const [isFocused, setIsFocused] = useState(false)
  const theme = useTheme()
  const { mutate: sendReply } = useSlackReply()
  const { mutate: fileUploadMutation } = useFileUpload()

  const handleFileChange = event => {
    setFiles([...files, ...Array.from(event.target.files)])
  }

  const handleReplyChange = event => {
    setReply(event.target.value)
  }
  const handleFocus = useCallback(() => {
    setIsFocused(true)
  }, [])
  const removeFile = index => {
    setFiles(files.filter((_, i) => i !== index))
  }
  const handleBlur = useCallback(event => {
    // Check if the related target is part of the interactive elements
    const relatedTarget = event.relatedTarget
    const withinInteractiveElements =
      relatedTarget &&
      (relatedTarget.closest('.attachment-button') ||
        relatedTarget.closest('.emoji-button') ||
        relatedTarget.closest('.submit-comment-button') ||
        relatedTarget.closest('.selected-file-attachment'))

    if (!withinInteractiveElements) {
      setIsFocused(false)
    }
  }, [])

  const handleSubmit = () => {
    if (reply.trim()) {
      const payload = {
        channel: task?.project?.slackChannel?.id,
        text: reply,
        thread_ts: task?.taskThread?.ts,
      }
      sendReply(payload)
      setReply('')
    }

    if (files.length > 0) {
      files.forEach(file => {
        const formData = new FormData()
        formData.append('file', file)
        formData.append('channel', task?.project?.slackChannel?.id)
        formData.append('thread_ts', task?.taskThread?.ts)
        fileUploadMutation(formData) // Adjust to how your mutation accepts FormData
      })
      setFiles([])
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center', // Ensure this aligns children to the center
        gap: '10px',
        mt: 2,
        width: '100%', // Ensure this fills the parent container
      }}
    >
      <TextField
        multiline
        fullWidth
        variant='outlined'
        placeholder='Type your reply here...'
        value={reply}
        onChange={handleReplyChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        sx={{
          '& .MuiOutlinedInput-root': {
            overflow: 'hidden',
            height: isFocused ? '150px' : '45px',
            borderColor: isFocused
              ? theme.palette.type === 'dark'
                ? 'white'
                : 'black'
              : theme.palette.type === 'dark'
              ? '#0060b9'
              : 'black',
            borderWidth: '1px',
            borderStyle: 'solid',
            margin: '0px',
            '& textarea': {
              height: '100% !important',
            },
          },
          '& .MuiInputBase-root': {
            padding: isFocused ? '18px 14px' : '10px 14px',
          },
        }}
        minRows={3}
        maxRows={6}
      />
      {isFocused && (
        <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
          <IconButton
            color='primary'
            component='label'
            className='attachment-button'
          >
            <AttachFileIcon />
            <input
              name='file'
              type='file'
              hidden
              onChange={handleFileChange}
              multiple
            />
          </IconButton>
          <IconButton color='primary' className='emoji-button'>
            <InsertEmoticonOutlinedIcon />
          </IconButton>
          <Button
            variant='contained'
            color='primary'
            className='submit-comment-button'
            onClick={handleSubmit}
          >
            Reply / Upload
          </Button>
        </Box>
      )}
      {/* <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <IconButton color='primary' component='label'>
          <AttachFileIcon />
          <input type='file' hidden onChange={handleFileChange} multiple />
        </IconButton>
        <IconButton color='primary'>
          <InsertEmoticonOutlinedIcon />
        </IconButton>
        <Button variant='contained' color='primary' onClick={handleSubmit}>
          Send Reply / Upload
        </Button>
      </Box> */}
      <Stack direction='row' gap={1} flexWrap='wrap' justifyContent='center'>
        {files.map((file, index) => (
          <Chip
            key={index}
            label={file.name}
            onDelete={() => removeFile(index)}
            variant='outlined'
            color='primary'
          />
        ))}
      </Stack>
    </Box>
  )
}

export default TaskReplyInput
