import { useQuery } from '@tanstack/react-query'
import { baseUrl } from '../queryMutations/mutationFunctions'
import axios from 'axios'

export const getContact = async (endpoint, tableName, id) => {
  try {
    const url = `${baseUrl}/aws/contacts/${id}`
    const response = await axios.get(url, {
      params: id ? {} : { table: tableName }, // Params are only needed for fetching all items
    })
    if (response.status === 204) {
      return { data: id ? {} : [], status: 204 }
    } else {
      return response.data || []
    }
  } catch (error) {
    console.error('Error fetching data:', error)
    return [] // Return an empty array in case of an error
  }
}

export const useContact = (contactId, queryKey = ['contact', contactId]) => {
  const { data, isLoading, isError } = useQuery({
    queryKey: queryKey, // Use consistent query key
    queryFn: () => getContact('/aws/contacts', 'contacts', contactId),
    staleTime: Infinity, // Optional: adjust based on your caching needs
    cacheTime: 1000 * 60 * 10, // Cache data for 10 minutes
    enabled: !!contactId, // Only fetch if contactId is provided
  })

  // Return in the same format as the original hook
  return {
    data: data, // Map data to `organization`
    isLoading,
    isError,
  }
}
