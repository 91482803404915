import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { baseUrl } from '../queryMutations/mutationFunctions'

// const fileUpload = async (file, additionalData) => {
//   const apiUrl = `${baseUrl}/slack/file-upload`
//   const slackToken = localStorage.getItem('slack_access_token')
//   if (!slackToken) {
//     throw new Error('No authentication token available')
//   }

//   const formData = new FormData()
//   formData.append('file', file) // Append file to form data
//   // Append additional data if necessary
//   Object.keys(additionalData).forEach(key => {
//     formData.append(key, additionalData[key])
//   })

//   const response = await axios.post(apiUrl, formData, {
//     headers: {
//       Authorization: slackToken,
//       'Content-Type': 'multipart/form-data', // This is set automatically by FormData
//     },
//   })

//   return response.data
// }

// export const useFileUpload = () => {
//   return useMutation({
//     mutationFn: data => fileUpload(data.file, data.additionalData),
//   })
// }
const fileUpload = async formData => {
  console.log('[fileUpload] formData: ', formData)
  const apiUrl = `${baseUrl}/slack/file-upload`
  const slackToken = localStorage.getItem('slack_access_token')
  if (!slackToken) {
    throw new Error('No authentication token available')
  }

  // Remove formData.getHeaders() from the headers object
  const response = await axios.post(apiUrl, formData, {
    headers: {
      Authorization: slackToken, // Ensure this is correctly prefixed with "Bearer " if needed
      // Do not set 'Content-Type': 'multipart/form-data' manually
    },
  })
  console.log('[fileUpload] response: ', response)
  return response.data
}

export const useFileUpload = () => {
  const mutation = useMutation({
    mutationFn: fileUpload,
    onSuccess: data => console.log('Upload success:', data),
    onError: error => console.log('Upload error:', error),
  })
  return mutation
}
