import React from 'react'
import { Button, Typography, useTheme } from '@mui/material'
const ResetFiltersButton = ({ filterModel, handleClearFilters, label }) => {
  const theme = useTheme()
  return (
    <Button
      sx={{
        height: 40,
        color: theme.palette.text.primary, // Active filters color
        fontWeight:
          filterModel &&
          ((filterModel.items && filterModel.items.length > 0) ||
            (filterModel.quickFilterValues && filterModel.quickFilterValues.length > 0))
            ? 'bold'
            : '',
      }}
      disabled={
        filterModel &&
        ((filterModel.items && filterModel.items.length > 0) ||
          (filterModel.quickFilterValues && filterModel.quickFilterValues.length > 0))
          ? false
          : true
      }
      onClick={handleClearFilters}
    >
      <Typography sx={{ fontSize: '0.875rem' }}> {label ? label : 'Reset Filters'}</Typography>
    </Button>
  )
}

export default ResetFiltersButton
