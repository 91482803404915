import React from 'react'
import { Draggable } from '@hello-pangea/dnd'
import { styled } from '@mui/material/styles'
import ListItem from '@mui/material/ListItem'
import DealItemDetails from './DealItemDetails'

const StyledListItem = styled(ListItem)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgb(26, 22, 22)' : 'rgb(255,255,255)',
  color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,1)' : 'black',
  margin: '5px',
  width: 'calc(100% - 10px)',
  borderRadius: '5px',
  transition: 'background-color 0.2s ease',
}))

const DraggableListItem = React.memo(
  ({ item, index }) => {
    return (
      <Draggable key={item.id} draggableId={item.id} index={index}>
        {provided => (
          <StyledListItem ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            <DealItemDetails deal={item} />
          </StyledListItem>
        )}
      </Draggable>
    )
  },
  (prevProps, nextProps) => {
    return prevProps.item.id === nextProps.item.id && prevProps.index === nextProps.index
  }
)

export default DraggableListItem

// import React from 'react'
// import { Draggable } from '@hello-pangea/dnd'

// import { styled } from '@mui/material/styles'
// import ListItem from '@mui/material/ListItem'
// import DealItemDetails from './DealItemDetails'

// const StyledListItem = styled(ListItem)(({ theme, $isDragging }) => ({
//   backgroundColor:
//     $isDragging && theme.palette.mode === 'dark'
//       ? 'rgba(0,0,0,0.4)'
//       : $isDragging && theme.palette.mode === 'light'
//       ? 'rgba(255,255,255,0.4)'
//       : theme.palette.mode === 'dark'
//       ? 'rgb(26, 22, 22)'
//       : 'rgb(255,255,255)',
//   color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,1)' : 'black',
//   margin: '5px',
//   width: 'calc(100% - 10px)',
//   borderRadius: '5px',
//   transition: 'background-color 0.2s ease',
// }))

// const DraggableListItem = ({ item, index }) => {
//   return (
//     <Draggable key={item.id} draggableId={item.id} index={index}>
//       {(provided, snapshot) => (
//         <StyledListItem
//           ref={provided.innerRef}
//           {...provided.draggableProps}
//           {...provided.dragHandleProps}
//           // $isDragging={snapshot.isDragging} // Use $ prefix to avoid passing it to the DOM
//         >
//           <DealItemDetails deal={item} />
//         </StyledListItem>
//       )}
//     </Draggable>
//   )
// }

// export default DraggableListItem
