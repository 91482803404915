import React, { useRef, useEffect, useState } from 'react'
import { Box, Grid, Button, useTheme, useMediaQuery } from '@mui/material'
import { useDealDetailsContext } from '../../context/DealsDetailsProvider/DealsDetailsProvider'
import useSaveDeal from '../../api/customHooks/useSaveDeal'
import { calculateProgress } from '../Approvals/calculateProgress'
import { useNotify } from '../../context/NotificationProvider/NotificationProvider'
import { useCreateTimeline } from '../../api/aws/useCreateTimeline'
import { updateContractTimelineData, updateDealTimelineData } from '../Timeline/createTimelineData'
import { useActiveUser } from '../../api/slack/useActiveUser'
import { useNavigate, useParams } from 'react-router-dom'
import { useUpdateDeal } from '../../api/aws/useUpdateDeal'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import { useCreateDocument } from '../../api/PandaDoc/useCreateDocument'
import { useOrganizationById } from '../../api/aws/useOrganization'
import useTotals from '../../api/customHooks/useTotals'
import DetailsLoading from '../Loading/DetailsLoading'
import ProductRowsTable from '../ProductRows/ProductRowsTable'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { useApproversById } from '../../api/customHooks/useApproversById'
import { determineApproverIds } from '../../context/DealsDetailsProvider/determineApprovers'
import { useUser } from '../../api/aws/useUser'
import ContractInfoTotals from '../ContractDialog/ContractInfoTotals'
import { useSaveAndUpdateDocument } from './utils/useSaveAndUpdateDocument'
import { useDeal } from '../../api/aws/useDeal'
import { useQueryClient } from '@tanstack/react-query'
import { useGetDocument } from '../../api/PandaDoc/useGetDocument'
import { useContact } from '../../api/aws/useContact'

const NewContract = () => {
  const theme = useTheme()
  const scrollRef = useRef(null)
  const { activeUser } = useActiveUser()
  const {
    newDeal,
    setNewDeal,
    productRows,
    handleChange,
    handleProductChange,
    isDealStatusChange,
    setIsDealStatusChange,
    handleRequestApproval,
    validateFields,
    isValid,
    setIsValid,
    addRow,
    removeRow,
    validationErrors,
    onDragEnd,
    resetState,
  } = useDealDetailsContext()
  // const { organization, isOrganizationLoading, isOrganizationError } = useOrganizationById(
  //   newDeal && Array.isArray(newDeal.organizations) ? newDeal.organizations[0] : null
  // )
  const { dealId } = useParams()
  const queryClient = useQueryClient()
  const { deal, isDealLoading, isDealError } = useDeal(dealId, ['dealContract', dealId])
  const { document, isDocumentLoading, isDocumentError } = useGetDocument(newDeal?.contractId, [
    'document',
    newDeal?.contractId,
  ])
  const { organization, isOrganizationLoading, isOrganizationError } = useOrganizationById(
    newDeal?.organizations?.[0],
    ['deal', 'organization', newDeal?.organizations?.[0]]
  )
  const {
    organization: contractedBy,
    isOrganizationLoading: isContractedByLoading,
    isOrganizationError: isContractedByError,
  } = useOrganizationById(organization?.contractedBy?.[0], ['deal', 'contractedBy', organization?.contractedBy?.[0]])
  const { user: currAuthRecipient, isUserLoading, isUserError } = useUser(
    newDeal?.recipients?.authorizedRecipient?.[0]?.id,
    'authorizedRecipient'
  )
  // const { data: currContactRecipient, isLoading: isContactLoading, isError: isContactError } = usePandaContact(
  //   newDeal?.recipients?.contactRecipient?.[0]?.contact_id
  // )
  const { data: currContactRecipient, isLoading: isContactLoading, isError: isContactError } = useContact(
    newDeal?.recipients?.contactRecipient?.[0]?.id
  )
  // const [totals, setTotals] = useState(useTotals(productRows, newDeal?.implementationFee || 0, newDeal.type))
  const totals = useTotals(productRows, newDeal?.implementationFee || 0, newDeal?.type)
  const { mutate: saveDeal } = useSaveDeal()
  const { mutate: createTimelineEntry } = useCreateTimeline()
  const { mutate: updateDeal } = useUpdateDeal()
  const { mutate: createDocument } = useCreateDocument()
  const { saveAndUpdateDocument } = useSaveAndUpdateDocument()
  const { showSnackbar } = useSnackbarContext()
  const { createMessagePayload, addNotification } = useNotify()
  const [isOpen, setIsOpen] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const navigate = useNavigate()
  const [timelineData, setTimelineData] = useState(null)
  const [isError, setIsError] = useState(false)
  const [isCreating, setIsCreating] = useState(false)
  const [isRequesting, setIsRequesting] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const [tempDealStatus, setTempDealStatus] = useState('')
  const [isDraft, setIsDraft] = useState(false)
  const approverIds = newDeal ? determineApproverIds(newDeal) : []
  const { user: requestor, isLoading: isRequestorLoading, isError: isRequestorError } = useUser(
    activeUser?.user?.id || activeUser?.id,
    'requestor'
  )
  const { approversById, isApproversByIdLoading, isApproversByIdError } = useApproversById(
    approverIds ? approverIds : []
  )
  const [isCurrentContactRecipient, setIsCurrentContactRecipient] = useState(false)
  const [isCurrentAuthorizedRecipient, setIsCurrentAuthorizedRecipient] = useState(false)
  // const isMacbookPro = useMediaQuery(
  //   '(min-width: 1512px) and (max-width: 1512px) and (min-height: 857px) and (max-height: 857px)'
  // )
  const isMacbookPro = useMediaQuery(
    '(min-width: 1500px) and (max-width: 1750px) and (min-height: 850px) and (max-height: 960px)'
  )

  useEffect(() => {
    if (newDeal) {
      const isValid = validateFields(newDeal)
      setIsValid(isValid)
      setTimelineData(updateDealTimelineData(newDeal?.deal_uuid, activeUser, 'Save', 'Save', 'Deal Saved'))
    }
    console.log('NEW DEAL NEWCONTRACT: ', newDeal)
  }, [newDeal])
  useEffect(() => {
    if (
      document &&
      currAuthRecipient &&
      currContactRecipient &&
      !isUserLoading &&
      !isContactLoading &&
      !isDocumentLoading
    ) {
      console.log('document', document)
      console.log('DOC RECIPIENTS: ', document?.recipients)
      console.log('DEAL AUTH RECIPIENT: ', newDeal?.recipients?.authorizedRecipient)
      console.log('DEAL CONTACT RECIPIENT: ', newDeal?.recipients?.contactRecipient)

      // Find the first matching authorized recipient
      const authRecipient = document?.recipients?.find(
        rec => rec?.role === 'Authorized Personnel' || rec?.roles?.includes('Authorized Personnel')
      )

      // Find the first matching client recipient
      const contactRecipient = document?.recipients?.find(
        rec => rec?.role === 'Client' || rec?.roles?.includes('Client')
      )

      console.log('FOUND AUTH RECIPIENT:', authRecipient)
      console.log('FOUND CONTACT RECIPIENT:', contactRecipient)

      setIsCurrentAuthorizedRecipient(authRecipient?.contact_id === currAuthRecipient?.panda_contact_id)
      setIsCurrentContactRecipient(contactRecipient?.contact_id === currContactRecipient?.panda_contact_id)
      const isDraft = document && document?.status === 'document.draft'
      setNewDeal(prev => ({ ...prev, isDraft: isDraft ? true : false }))
      setIsDraft(isDraft ? true : false)
      console.log('!isDraft', !isDraft)
    }
  }, [document, currAuthRecipient, currContactRecipient, isUserLoading, isContactLoading, isDocumentLoading])

  //////////////////////////////////////////////////////////

  ////////////////// Save Deal //////////////////////

  const handleSaveDeal = async (deal, strategyState, messagePayload) => {
    console.log('Product rows before saving:', productRows)
    console.log('Strategy state before saving:', strategyState)
    setIsSaving(true)
    const { progressPercentage, individualProgress } = calculateProgress(deal.approvers)
    console.log('has status changed????', isDealStatusChange)
    const dealData = {
      ...deal,
      status: !deal?.hasProducts ? 'Draft' : deal?.status,
      stage: !deal.hasProducts ? 'Discovery' : deal.hasProducts && deal.stage === 'Discovery' ? 'Proposal' : deal.stage,
      products: productRows, // Make sure this reflects the correct state
      // lastDateOfService: deal.lastDateOfService || lastDateOfService,
      // churnReason: deal.churnReason || churnReason,
      lastModified: new Date(),
      progress: individualProgress,
      progressPercentage,
      totals: { ...totals },
    }
    console.log('dealData', dealData)
    if (isDealStatusChange) {
      try {
        const payload = createMessagePayload(
          dealData?.owner,
          'status',
          'success',
          'Deal status changed',
          `${dealData.name} changed to ${dealData?.dealStatus}`,
          'deals',
          dealData?.name,
          dealData?.deal_uuid,
          dealData?.image_url,
          `https://grobotic.app/deals/${dealData?.deal_uuid}`
        )
        console.log('DEAL STATUS CHANGED: ', payload)
        addNotification(payload)
      } catch (error) {
        console.error('error creating notification for status', error)
      }
    }
    saveDeal(
      {
        dealId: deal.deal_uuid,
        dealData: dealData,
      },
      {
        onSuccess: () => {
          setIsEditMode(false)
          setIsOpen(false)
          if (timelineData) {
            createTimelineEntry(timelineData)
          }
          setNewDeal({ ...dealData })
          // queryClient.setQueryData(['deal', dealId], old => ({
          //   ...old,
          //   ...dealData,
          // }))
          queryClient.invalidateQueries(['deal', dealId])
          setIsDealStatusChange(false)
          setShowModal(false)
          setIsSaving(false)
          navigate(-1)
        },
        onError: () => {
          setIsDealStatusChange(false)
          setIsEditMode(false)
          setIsOpen(false)
          setShowModal(false)
          setIsSaving(false)
          setIsError(true)
        },
      }
    )
  }
  const handleSave = async e => {
    e.preventDefault()
    const hasProducts = newDeal && Array.isArray(newDeal.products) && newDeal.products.length > 0
    const updatedDeal = {
      ...newDeal,
      ...totals,
      totals: { ...totals },
      hasProducts: hasProducts,
      products: productRows,
      updatedProducts: productRows,
    }
    try {
      await handleSaveDeal(updatedDeal, organization, hasProducts)
    } catch (error) {
      console.error(error)
    }
  }

  ////////////////// Create Contract //////////////////////
  const createContract = (cleanedDeal, organization, activeUser) => {
    setIsCreating(true)
    try {
      const documentData = {
        deal: cleanedDeal,
        organization,
        activeUser,
      }
      const timelineData = updateContractTimelineData(
        cleanedDeal?.deal_uuid,
        activeUser,
        'Create',
        'deals',
        'Contract created'
      )
      console.log('documentData', documentData)
      createDocument(documentData, {
        onSuccess: data => {
          const hasProducts = data && Array.isArray(data.products) && data.products.length > 0
          const contractId = data.id
          const dealWithContractId = {
            ...cleanedDeal,
            contractId: contractId,
            status: cleanedDeal?.status === 'Completed Internally' ? cleanedDeal?.status : 'Created',
            stage: cleanedDeal?.status === 'Completed Internally' ? cleanedDeal?.stage : 'Contract Created',
            isDraft: true,
            isReverted: false,
            linksGenerated: false,
            lastModified: new Date(),
            ...totals,
            hasProducts: hasProducts,
            products: productRows,
            totals: { ...totals },
          }
          updateDeal(
            {
              dealId: dealWithContractId.deal_uuid,
              dealData: dealWithContractId,
            },
            {
              onSuccess: () => {
                setIsCreating(false)
                createTimelineEntry(timelineData)
                queryClient.invalidateQueries(['dealContract', newDeal?.deal_uuid])
              },
              onError: error => {
                console.error('Error in updateDeal:', error)
                setIsCreating(false)
              },
            }
          )
          showSnackbar('Document successfully created', 'success')
        },
        onError: error => {
          console.error('Error in createDocument:', error)
          setIsCreating(false)
          setIsError(true)
        },
      })
    } catch (error) {
      console.error('Error in createContract try-catch:', error)
      setIsCreating(false)
      setIsError(true)
    }
  }
  ////////////////// Submit Contract //////////////////////
  const handleSubmit = event => {
    event.preventDefault()
    console.log('documentData', newDeal)
    setIsCreating(true)
    try {
      createContract({ ...newDeal, creation_timestamp: new Date(), isCreated: true }, organization, activeUser)
    } catch (error) {
      console.error('Error in handleSubmit:', error)
      setIsCreating(false)
      setIsError(true)
    }
    setIsCreating(false)
  }
  const handleSaveAndUpdate = () => {
    // console.log('NEW DEAL BEFORE CLEAN: ', newDeal)
    // console.log('currAuthRecipient BEFORE CLEAN: ', currAuthRecipient)
    // console.log('currContactRecipient BEFORE CLEAN: ', currContactRecipient)
    const cleanedDeal = {
      ...newDeal,
      creation_timestamp: new Date(),
      recipients: {
        authorizedRecipient: newDeal?.recipients?.authorizedRecipient?.map(rec => {
          return { ...rec, contact_id: currAuthRecipient?.panda_contact_id || currAuthRecipient?.contact_id }
        }),
        contactRecipient: newDeal?.recipients?.contactRecipient?.map(rec => {
          return { ...rec, contact_id: currContactRecipient?.panda_contact_id || currContactRecipient?.contact_id }
        }),
      },
      isCreated: true,
    }
    // console.log('CLEANED DEAL: ', cleanedDeal)
    saveAndUpdateDocument(
      cleanedDeal,
      organization,
      activeUser,
      handleCancel,
      setIsUpdating,
      setIsSaving,
      totals,
      productRows,
      document
    )
  }

  ////////////////// Update Modal //////////////////////
  const handleUpdateModal = (churnNote, lastDate, reasonChurn) => {
    console.log('Last Date of Service:', lastDate)
    console.log('Churned Reason:', reasonChurn)
    console.log('Churned Notes:', churnNote)
    if (!reasonChurn || !lastDate || !churnNote) {
      showSnackbar('All fields are required.', 'error')
      return
    }
    // const formattedLastDate = lastDate.format('dddd, MMMM D, YYYY') // e.g., "Tuesday, December 31, 2024"
    // console.log('formattedLastDate of Service:', formattedLastDate)

    // Update deal data with formattedLastDate and newReason
    // setNewDeal(prev => ({
    //   ...prev,
    //   lastDateOfService: lastDate,
    //   reasonChurn: reasonChurn,
    //   churnNote: churnNote,
    //   dealStatus: tempDealStatus,
    // }))
    queryClient.setQueryData(['deal', dealId], old => ({
      ...old,
      lastDateOfService: lastDate,
      reasonChurn: reasonChurn,
      churnNote: churnNote,
      dealStatus: tempDealStatus,
    }))

    setShowModal(false)
  }
  const handleApprovalRequest = () => {
    console.log('Approval Requested!!!!!!')
    setIsRequesting(true)
    try {
      const dealData = {
        ...newDeal,
        isRequested: true,
        typeChange: false,
        requestedBy: activeUser && activeUser.user ? activeUser.user.id : activeUser.id,
        approvers: approversById,
        status: 'Approval Requested',
        requestedTime: new Date(),
        requestor: requestor
          ? {
              id: requestor.id,
              name: requestor.name,
              email: requestor.email,
              image: requestor.image_512,
              hasRequested: true,
            }
          : {},
      }
      const timelineData = updateDealTimelineData(
        newDeal?.deal_uuid,
        activeUser,
        'Request',
        'Approval',
        `Approval requested`
      )
      handleRequestApproval(requestor, activeUser, approversById)
      createTimelineEntry(timelineData)
      setIsOpen(false)
      setNewDeal({ ...dealData })
      queryClient.setQueryData(['deal', dealId], old => ({
        ...old,
        ...dealData,
      }))
    } catch (e) {
      setIsRequesting(false)
      console.error(e)
    }
    setIsRequesting(false)
  }
  ////////////////// Cancel Modal //////////////////////
  const handleCancel = () => {
    setShowModal(false)
    resetState()
    setTempDealStatus('')
    setNewDeal(prev => ({
      ...prev,
      dealStatus: prev.dealStatus, // Revert to old status
    }))
    // queryClient.setQueryData(['deal', dealId], old => ({
    //   ...old,
    //   dealStatus: old?.dealStatus,
    // }))
    navigate(-1)
  }

  const onChange = (fieldName, value) => {
    console.log('onChangeTriggered')
    handleChange(fieldName, value, organization)
  }

  const handleCloseModal = () => setShowModal(false)
  const loadingProps = { isRequesting, isSaving, isUpdating, isCreating, isUserLoading, isContactLoading }
  const handlers = {
    handleChange: onChange,
    handleUpdateModal,
    handleCancel,
    handleSave,
    handleSaveAndUpdate,
    handleSubmit,
    handleApprovalRequest,
  }
  const isLoading = isOrganizationLoading || isContractedByLoading || isDealLoading || isDocumentLoading
  if (isLoading) {
    return <DetailsLoading />
  }

  return (
    <Grid container spacing={1} p={2}>
      <Grid item xs={5.5} sm={4.5} md={4.5} lg={3.9} xl={2.9}>
        <Box
          sx={{
            height: isMacbookPro ? 'calc(100vh - 135px)' : 'calc(100vh - 160px)',
            maxHeight: isMacbookPro ? 'calc(100vh - 135px)' : 'calc(100vh - 160px)',
            overflowY: 'auto',
          }}
          bgcolor={theme.palette.background.section}
          borderRadius={'12px'}
        >
          <ContractInfoTotals
            activeUser={activeUser}
            totals={totals}
            validationErrors={validationErrors}
            organization={organization}
            isError={isError}
            document={document}
            isCurrentContactRecipient={isCurrentContactRecipient}
            isCurrentAuthorizedRecipient={isCurrentAuthorizedRecipient}
            {...handlers}
            {...loadingProps}
          />
        </Box>
      </Grid>
      {/* Products Table */}
      <Grid item xs={5.5} sm={6.5} md={7.2} lg={8.1} xl={9.1}>
        <Box
          sx={{
            height: 'calc(100vh - 210px)',
            maxHeight: 'calc(100vh - 210px)',
            overflowY: 'auto',
            overflowX: 'auto',
            border: '1px groove',
            borderColor: theme.palette.text.primary,
            backgroundImage: theme.palette.background.image,
            backgroundSize: '10px 10px', // Adjust size of the dots
          }}
          ref={scrollRef}
        >
          <ProductRowsTable
            newDeal={newDeal}
            productRows={productRows}
            onProductChange={handleProductChange}
            validationErrors={validationErrors}
            removeRow={removeRow}
            type={newDeal ? newDeal.type : ''}
            onDragEnd={onDragEnd}
          />
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Grid item xs={12} sm={6} md={12} lg={9.1} xl={9.1}>
            <Button
              sx={{
                '&:hover': { opacity: '80%' },
              }}
              disabled={!newDeal?.isDraft}
              startIcon={<AddCircleOutlineIcon />}
              onClick={() => addRow(scrollRef)}
            >
              Add Service
            </Button>
          </Grid>
        </Box>
      </Grid>
      {/* Products Table */}
    </Grid>
  )
}

export default NewContract
