import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import { baseUrl } from '../queryMutations/queryMutations'

const getFilesByFolderId = async (folderId: string | null, filters: string[] = []) => {
  if (!folderId) return [] // No folder selected, return empty array

  const apiUrl = `${baseUrl}/aws/googleapi/drive-files`

  const googleToken = localStorage.getItem('google_access_token')
  if (!googleToken) {
    console.error('No authentication token available')
    return []
  }

  try {
    const response = await axios.get(apiUrl, {
      headers: { Authorization: `Bearer ${googleToken}` },
      params: {
        folderId, // ✅ Required Parent Folder ID
        filters: filters.length > 0 ? filters.join(',') : undefined, // ✅ Pass filters if provided
      },
    })
    return response?.data || []
  } catch (error) {
    console.error('Error fetching files in folder:', error)
    return []
  }
}

export const useGoogleFilesByFolderId = (folderId: string | null, filters: string[] = []) => {
  return useQuery({
    queryKey: ['googleDriveFiles', folderId, filters], // ✅ Includes filters in query key
    queryFn: () => getFilesByFolderId(folderId, filters),
    enabled: !!folderId, // ✅ Prevents fetching if no folder is selected
    staleTime: 60000, // Cache data for 1 minute
    refetchOnWindowFocus: false, // Avoid unnecessary re-fetching
  })
}
