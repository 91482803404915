import React, { useState, useEffect, useContext } from 'react'
import { Button, Dialog, DialogTitle, DialogContent, IconButton, Box, Input, Typography, useTheme } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen'
import DownloadIcon from '@mui/icons-material/Download'
import CloseIcon from '@mui/icons-material/Close'
import useGooglePickerById from '../../api/google/useGooglePickerById.ts'
import { useGoogleDownloadFile } from '../../api/google/useGoogleDownloadFile.ts'
import { useUser } from '../../api/aws/useUser.js'
import { useGroboticDrive } from '../../api/google/useGroboticDrive.ts'
import FolderIcon from '@mui/icons-material/Folder'
import GoogleDriveIcon from '../Icons/GoogleDriveIcon.js'
import ShowPickerButton from '../ui/show-picker-button.js'

interface Folder {
  id: string
  name: string
}
const DriveFoldersById = ({ folderId }: { folderId: string }) => {
  const currentSlackUserId = localStorage.getItem('slack_user_id')
  const [folderName, setFolderName] = useState('')
  const { user: currentUser, isUserLoading, isUserError } = useUser(currentSlackUserId)
  const { isLoaded, showPicker, selectedFile, lastOpenedFolderId } = useGooglePickerById(
    currentUser?.googleRefreshToken,
    currentSlackUserId || ''
  )
  const { groboticDrive, isGroboticDriveLoading, isGroboticDriveError } = useGroboticDrive(true)
  const [isFileViewerOpen, setIsFileViewerOpen] = useState(false)
  const { mutate: downloadGoogleFile } = useGoogleDownloadFile()
  const theme = useTheme()

  useEffect(() => {
    if (selectedFile) {
      setIsFileViewerOpen(true)
    }
  }, [selectedFile])
  useEffect(() => {
    if (groboticDrive && !isGroboticDriveLoading) {
      // Fix type errors
      const newFolder = groboticDrive?.folders
      const currentFolder = newFolder?.find(f => f?.id === folderId)
      const newFolderName = currentFolder?.name || ''

      setFolderName(newFolderName || '')
    }
  }, [groboticDrive, folderId])
  // useEffect(() => {
  //   if (currentUser && !isUserLoading) {
  //     console.log('currentUser', currentUser)
  //   }
  // }, [currentUser])

  // ✅ Determine the correct URL for viewing
  const getViewerUrl = () => {
    if (!selectedFile) return ''
    if (selectedFile.mimeType.includes('application/vnd.google-apps')) {
      return `https://docs.google.com/document/d/${selectedFile.id}/preview` // ✅ Google Docs preview
    } else if (selectedFile.embedUrl) {
      return selectedFile.embedUrl // ✅ Use embed URL for images, PDFs
    } else {
      return selectedFile.url // ✅ Fallback to standard Google Drive URL
    }
  }

  // ✅ Determine the correct URL for editing
  const openInGoogleDocs = () => {
    if (!selectedFile) return
    if (selectedFile.mimeType.includes('application/vnd.google-apps')) {
      window.open(`https://docs.google.com/document/d/${selectedFile.id}/edit`, '_blank') // ✅ Open Google Docs editor
    } else {
      window.open(selectedFile.url, '_blank') // ✅ Open in Google Drive
    }
  }

  const handleCloseFilePreview = () => {
    setIsFileViewerOpen(false)
    // showPicker(lastOpenedFolderId)
    showPicker(folderId)
  }

  const handleDownloadGoogleFile = () => {
    if (selectedFile?.id) {
      downloadGoogleFile(selectedFile.id)
    }
  }

  return (
    <Box key={folderId}>
      {/* Opens the Google Picker */}
      {/* <Button
        onClick={() => showPicker(folderId)}
        disabled={!isLoaded}
        sx={{
          display: 'flex',
          justifyContent: 'left',
          textTransform: 'none',
          bgcolor: 'transparent',
          maxWidth: 345,
          width: '100%',
          textAlign: 'center',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        <FolderIcon sx={{ mr: 1, color: theme.palette.text.primary }} />
        <Typography sx={{ textAlign: 'center', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {folderName || 'Unnamed Folder'}
        </Typography>
      </Button> */}
      <ShowPickerButton folderName={folderName} folderId={folderId} showPicker={showPicker} isLoaded={isLoaded} />
      {/* File Viewer Modal */}
      {isFileViewerOpen && (
        <Dialog
          open={isFileViewerOpen}
          onClose={handleCloseFilePreview}
          maxWidth='xl'
          fullWidth
          PaperProps={{
            style: { height: '90vh', maxHeight: '90vh' },
          }}
        >
          <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography sx={{ color: 'rgb(92, 213, 251)', fontSize: 24 }}>{selectedFile?.name}</Typography>
            <Box>
              <IconButton onClick={openInGoogleDocs}>
                <EditIcon />
              </IconButton>
              <IconButton onClick={handleDownloadGoogleFile}>
                <DownloadIcon />
              </IconButton>
              <IconButton onClick={handleCloseFilePreview}>
                <CloseIcon
                  sx={{
                    ':hover': {
                      color: 'rgba(220, 20, 60, 0.8)',
                    },
                  }}
                />
              </IconButton>
            </Box>
          </DialogTitle>

          <DialogContent
            style={{
              padding: 0,
              height: 'calc(100vh - 120px)',
            }}
          >
            {selectedFile && (
              <iframe
                src={getViewerUrl()}
                width='100%'
                height='100%'
                style={{ border: 'none', minHeight: '80vh' }}
                title='Google Drive Viewer'
              />
            )}
          </DialogContent>
        </Dialog>
      )}
    </Box>
  )
}

export default DriveFoldersById
