import React from 'react'
import { Button, Typography, useTheme } from '@mui/material'

const ResetColumnsButton = ({ visibilityModel, onClearVisibility, initialVisibilityState }) => {
  const theme = useTheme()
  return (
    <Button
      sx={{
        height: 40,
        color: Object.keys(visibilityModel).some(column => visibilityModel[column] !== initialVisibilityState[column])
          ? theme.palette.text.primary // Active columns color
          : theme.palette.text.tertiary, // Default gray color when no changes
      }}
      disabled={
        Object.keys(visibilityModel).some(column => visibilityModel[column] !== initialVisibilityState[column])
          ? false
          : true
      }
      onClick={onClearVisibility}
    >
      <Typography sx={{ fontSize: '0.875rem' }}>Reset Columns</Typography>
    </Button>
  )
}

export default ResetColumnsButton
