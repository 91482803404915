import { IconButton, Badge, Drawer, Tooltip } from '@mui/material'
import GoogleDriveSvg from '../../images/googleDriveIcon.svg'

const ChannelIcon = ({ isContracted, title, src }) => {
  return (
    <Tooltip title={title ?? ''}>
      <img
        key={`channel-icon-key-${title ?? ''}`}
        src={src ?? GoogleDriveSvg}
        alt={'channel-icon-alt'}
        style={{ width: 26, height: 26, opacity: isContracted ? 1 : 0.1 }}
      />
    </Tooltip>
  )
}

export default ChannelIcon
