import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { baseUrl } from '../queryMutations/mutationFunctions'

const fetchConversationThread = async ({ queryKey }) => {
  const [, conversationId, thread_ts] = queryKey // Adjust to include `thread_ts`

  const slackAccessToken = localStorage.getItem('slack_access_token')
  if (!slackAccessToken) {
    console.error('Authentication token not available or expired')
    throw new Error('Authentication token is missing')
  }
  const response = await axios.get(
    `${baseUrl}/slack/replies/${conversationId}?ts=${thread_ts}`,
    {
      headers: { Authorization: `${slackAccessToken}` },
    }
  )
  console.log('[fetchConversationThread] response: ', response.data)
  return response.data.messages
}

export const useFetchConversationThread = (conversationId, thread_ts) => {
  // Ensure `thread_ts` is included here as well
  return useQuery({
    queryKey: ['threadMessages', conversationId, thread_ts], // Include `thread_ts` in the query key
    queryFn: fetchConversationThread,
    enabled: !!conversationId && !!thread_ts, // Only run the query if both IDs are available
    onError: error => {
      console.error('Error fetching conversation history', error)
    },
  })
}
