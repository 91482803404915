import React, { useEffect, useState, useRef } from 'react'
import {
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarColumnsButton,
  GridToolbarQuickFilter,
  useGridApiRef,
  getGridStringOperators,
  getGridNumericOperators,
  getGridBooleanOperators,
  getGridDateOperators,
  getGridSingleSelectOperators,
  gridFocusStateSelector,
  useGridApiContext,
} from '@mui/x-data-grid-pro'
import { Badge, Box, Button, Tooltip, Typography, useTheme } from '@mui/material'
import { gridToolbarContainerStyles, StyledGridToolbarQuickFilter } from './style'
import { useActiveUser } from '../../api/slack/useActiveUser'
import ResetSortingButton from '../ui/reset-sorting-button'
import ResetFiltersButton from '../ui/reset-filters-button'
import ResetColumnsButton from '../ui/reset-columns-button'
import OpenColumnsMenu from '../ui/open-columns-menu'
import OpenFiltersMenu from '../ui/open-filters-menu'
import ExportCsvButton from '../ui/export-csv-button'

const initialSortModel = [
  { field: 'isApproved', sort: 'asc' },
  { field: 'requestedTime', sort: 'desc' },
  { field: 'approvedTime', sort: 'desc' },
]
const initialVisibilityState = {}

export const organizationTypeOptions = [
  {
    label: 'Property Management Company',
    type: 'PMC',
    color: 'rgb(93, 79, 251)',
  },
  { label: 'Ownership Group', type: 'OG', color: 'rgb(92, 179, 253)' },
  { label: 'Housing Property', type: 'HP', color: 'rgb(152, 214, 129)' },
  { label: 'Parent Client', type: 'PC', color: 'rgb(93, 79, 251)' },
  { label: 'Agency Whitelabel', type: 'AW', color: 'rgb(92, 179, 253)' },
  {
    label: 'Organization',
    type: 'ORG',
    color: 'rgb(242, 140, 40)',
  },
]

const slotProps = theme => ({
  button: {
    sx: {
      borderRadius: 2,
      maxHeight: '100%',
      mt: 2,
      mb: 2,
      '&:hover': { backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.main : 'rgba(0,0,0,0.1)' },
      color: theme.palette.text.primary,
      '& .MuiBadge-badge': {
        backgroundColor: 'rgb(205, 254, 194)', // Your custom background color
        color: theme.palette.mode === 'dark' ? 'rgba(0,0,0,1)' : 'rgba(0,0,0,1)', // Change the text color if needed
        fontSize: '14px', // Optional: adjust the font size
        width: '20px', // Adjust the width/height to control the size of the badge
        height: '20px',
      },
    },
  },
})

const isSortModelEqual = (sortModel1, sortModel2) => {
  if (sortModel1.length !== sortModel2.length) return false
  return sortModel1.every((sortItem, index) => {
    const initialSortItem = sortModel2[index]
    return sortItem.field === initialSortItem.field && sortItem.sort === initialSortItem.sort
  })
}

const CustomApprovalsToolbar = ({
  filterModel,
  setFilterModel,
  onOpenApprovals,
  onClearSorting,
  onClearVisibility,
  visibilityModel,
  sortModel,
  context,
}) => {
  const theme = useTheme()
  const quickFilterRef = useRef(null) // Create a ref for the quick filter
  const [filterStatus, setFilterStatus] = useState('')
  const [filterStage, setFilterStage] = useState('')
  const [filterType, setFilterType] = useState('')
  const [orgStatus, setOrgStatus] = useState('')
  const { activeUser, isActiveUserLoading, isActiveUserError } = useActiveUser()
  const activeUserName =
    activeUser && activeUser.user && activeUser.user.profile ? activeUser.user.profile.real_name : null
  const activeUserId = activeUser && activeUser.user ? activeUser.user.id : ''
  const isSortModelChanged = !isSortModelEqual(sortModel, initialSortModel)
  const [densityAnchorEl, setDensityAnchorEl] = useState(null)
  const apiRef = useGridApiContext() // Get DataGrid API
  const handleOpenColumnsMenu = event => {
    apiRef.current.showPreferences('columns')
  }

  // 🔹 Toggle Filter Panel
  const handleOpenFilterPanel = () => {
    apiRef.current.showFilterPanel()
  }

  // 🔹 Export Data
  const handleExport = () => {
    apiRef.current.exportDataAsCsv()
  }

  // Handle keydown event to focus the quick filter on Ctrl+Shift+F or Cmd+Shift+F
  useEffect(() => {
    const handleKeyDown = event => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'f') {
        event.preventDefault()
        if (quickFilterRef.current) {
          quickFilterRef.current.focus() // Focus the quick filter input
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])
  const handleFilterMyApprovals = () => {
    setFilterModel({
      items: [
        {
          field: 'approvers', // Refers to the approver names
          operator: 'contains',
          value: activeUser.user.name, // Active user's name
        },
      ],
      quickFilterValues: [],
    })
  }

  const handleFilterMyData = () => {
    setFilterModel({
      ...filterModel,
      quickFilterValues: [activeUserName],
    })
  }

  const handleClearFilters = e => {
    e.preventDefault()
    setFilterModel({ items: [], quickFilterValues: [] })
    setFilterStatus('')
    setFilterStage('')
  }

  const handleSetAllApprovals = e => {
    e.preventDefault()
    setFilterModel({ items: [], quickFilterValues: [] })
  }

  return (
    <GridToolbarContainer sx={gridToolbarContainerStyles}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, ml: 3 }}>
          <GridToolbarDensitySelector slotProps={slotProps(theme)} />
          {/* <GridToolbarColumnsButton slotProps={slotProps(theme)} />
          <GridToolbarFilterButton slotProps={slotProps(theme)} />
          <GridToolbarExport slotProps={slotProps(theme)} /> */}
          <OpenColumnsMenu onOpenColumns={handleOpenColumnsMenu} styleProps={slotProps(theme)} />
          <OpenFiltersMenu onOpenFilters={handleOpenFilterPanel} styleProps={slotProps(theme)} />
          <ExportCsvButton onExport={handleExport} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <>
            {filterModel && Array.isArray(filterModel.quickFilterValues) && filterModel.quickFilterValues.length > 0 ? (
              <Button
                sx={{
                  height: 40,
                  color: theme.palette.mode === 'dark' ? 'lightblue' : 'lightblue',
                }}
                onClick={handleFilterMyApprovals}
              >
                My Approvals
              </Button>
            ) : (
              <Button
                sx={{
                  height: 40,
                  color: theme.palette.mode === 'dark' ? 'lightblue' : 'lightblue',
                }}
                onClick={handleFilterMyApprovals}
              >
                My Approvals
              </Button>
            )}

            <Button sx={{ height: 40, color: 'rgb(240,128,128)' }} onClick={handleSetAllApprovals}>
              All Approvals
            </Button>
            <Button sx={{ height: 40, color: 'rgb(251, 193, 30)' }} onClick={onOpenApprovals}>
              Open Approvals
            </Button>
            <ResetColumnsButton
              visibilityModel={visibilityModel}
              onClearVisibility={onClearVisibility}
              initialVisibilityState={initialVisibilityState}
            />
            <ResetSortingButton isSortModelChanged={isSortModelChanged} onClearSorting={onClearSorting} />
            <ResetFiltersButton
              filterModel={filterModel}
              handleClearFilters={handleClearFilters}
              label={'Clear Filters'}
            />
            <Badge
              badgeContent={
                <>
                  {filterModel &&
                  filterModel.quickFilterValues &&
                  Array.isArray(filterModel.quickFilterValues) &&
                  filterModel.quickFilterValues.length > 0 ? (
                    <Typography
                      sx={{
                        fontSize: 12,
                        bgcolor: 'rgb(205, 254, 194)',
                        p: 0.5,
                        height: '20px',
                        width: '20px',
                        borderRadius: '50%',
                        display: 'flex', // Flexbox centering
                        alignItems: 'center', // Vertically center
                        justifyContent: 'center', // Horizontally center
                        color: theme.palette.mode === 'dark' ? 'rgba(0,0,0,1)' : 'rgba(0,0,0,1)',
                        fontWeight: 'bold',
                      }}
                    >
                      {filterModel && filterModel.quickFilterValues && Array.isArray(filterModel.quickFilterValues)
                        ? filterModel.quickFilterValues.length
                        : null}
                    </Typography>
                  ) : null}
                </>
              }
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <StyledGridToolbarQuickFilter placeholder={'ctrl+f or cmd+f to search'} inputRef={quickFilterRef} />
            </Badge>
          </>
        </Box>
      </Box>
    </GridToolbarContainer>
  )
}

export default CustomApprovalsToolbar
