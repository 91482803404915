import React from 'react'
import { Box, IconButton, Tooltip, useMediaQuery, useTheme } from '@mui/material'
import ViewColumnIcon from '@mui/icons-material/ViewColumn' // Custom Columns Icon

const OpenColumnsMenu = ({ onOpenColumns }) => {
  const theme = useTheme()
  const isExtraLargeScreen = useMediaQuery(theme.breakpoints.up('xl'))
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'))
  const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'))
  const isSmallScreen = useMediaQuery(theme.breakpoints.up('sm'))

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 2,
      }}
    >
      <Tooltip title='Columns'>
        <IconButton
          sx={{
            height: 30,
            width: 30,
            '&:hover': {
              backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.main : 'rgba(0,0,0,0.1)',
            },
          }}
          onClick={onOpenColumns}
        >
          <ViewColumnIcon />
        </IconButton>
      </Tooltip>
    </Box>
  )
}

export default OpenColumnsMenu
