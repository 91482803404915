import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { baseUrl } from '../queryFunctions/queryFunctions'
import { pandaAccessToken } from './useGetDocuments'

const getPandaContacts = async () => {
  if (!pandaAccessToken) {
    throw new Error('Panda authentication token is missing or expired. Please log in again.')
  }
  try {
    const response = await axios.get(`${baseUrl}/aws/pandadoc/contacts`, {
      headers: { Authorization: `${pandaAccessToken}` },
    })
    return response.data
  } catch (error) {
    // Here you can handle specific errors and rethrow them
    throw new Error(error.response?.data?.message || error.message || 'An unexpected error occurred.')
  }
}

export const usePandaContacts = () => {
  return useQuery({
    queryKey: ['pandaContacts'],
    queryFn: getPandaContacts,

    onError: error => {
      // Handle error state here, useful for setting error messages in UI
      console.error('Error fetching panda contacts', error)
    },
  })
}
