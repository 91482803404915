// src/AppLayout.js
import React, { useState } from 'react'
import { Box, useTheme } from '@mui/material'
import { styled } from '@mui/material/styles'
import CreateOrganization from './components/CreateOrganization/CreateOrganization'
import CreateDeal from './components/CreateDeal/CreateDeal'
import CreateContact from './components/CreateContact/CreateContact'
import MiniDrawer from './components/AppSidebar/MiniDrawer'
import { useActiveUser } from './api/slack/useActiveUser'
import { DealDetailsProvider } from './context/DealsDetailsProvider/DealsDetailsProvider'
import { initialDealState } from './context/DealsDetailsProvider/initialDealState'
import SpeedDialComponent from './components/SpeedDial/SpeedDial'
import { NotificationProvider } from './context/NotificationProvider/NotificationProvider'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useUser } from './api/aws/useUser'
import CreateTask from './components/Tasks/CreateTask'

const MainContent = styled('main', {
  shouldForwardProp: prop => !['open'].includes(prop),
})(({ theme, open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: 0,
  ...(open && {
    marginLeft: 20,
    marginRight: 20,
  }),
}))

const AppLayout = ({ children }) => {
  const { activeUser, isActiveUserLoading } = useActiveUser()
  const { user: currentUser, isUserLoading: isCurrentUserLoading } = useUser(activeUser?.user?.id)
  const [isCreateOrganizationOpen, setIsCreateOrganizationOpen] = useState(false)
  const [isCreateDealOpen, setIsCreateDealOpen] = useState(false)
  const [isCreateContactOpen, setIsCreateContactOpen] = useState(false)
  const [isNotifyDrawerOpen, setIsNotifyDrawerOpen] = useState(false)
  const [isConnectedUsersOpen, setIsConnectedUsersOpen] = React.useState(false)
  const [isCreateTaskOpen, setIsCreateTaskOpen] = useState(false)

  const handleOpenCreateOrganization = () => setIsCreateOrganizationOpen(true)
  const handleCloseCreateOrganization = () => setIsCreateOrganizationOpen(false)

  const handleOpenCreateDeal = () => setIsCreateDealOpen(true)
  const handleCloseCreateDeal = () => setIsCreateDealOpen(false)

  const handleOpenCreateContact = () => setIsCreateContactOpen(true)
  const handleCloseCreateContact = () => setIsCreateContactOpen(false)

  const handleOpenNotifyDrawer = () => setIsNotifyDrawerOpen(true)
  const handleCloseNotifyDrawer = () => setIsNotifyDrawerOpen(false)

  const handleOpenConnectedUsers = () => setIsConnectedUsersOpen(true)
  const handleCloseConnectedUsers = () => setIsConnectedUsersOpen(false)

  const handleOpenCreateTask = () => setIsCreateTaskOpen(true)
  const handleCloseCreateTask = () => setIsCreateTaskOpen(false)

  const theme = useTheme()

  const isUserLoading = isActiveUserLoading || isCurrentUserLoading
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100vw',
        overflowX: 'hidden', // Only hides horizontal overflow; don't use 'hidden' for all directions
        overflowY: 'auto', // Allow vertical scrolling if needed
        backgroundColor: theme.palette.background.default,
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <NotificationProvider>
          <DealDetailsProvider dealData={initialDealState}>
            <MiniDrawer
              handleOpenCreateOrganization={handleOpenCreateOrganization}
              handleOpenCreateDeal={handleOpenCreateDeal}
              handleOpenCreateContact={handleOpenCreateContact}
              onOpenCreateTask={handleOpenCreateTask}
              onCloseCreateTask={handleCloseCreateTask}
              isNotifyDrawerOpen={isNotifyDrawerOpen}
              onOpenNotify={handleOpenNotifyDrawer}
              onCloseNotify={handleCloseNotifyDrawer}
              isConnectedUsersOpen={isConnectedUsersOpen}
              handleOpenConnectedUsers={handleOpenConnectedUsers}
              handleCloseConnectedUsers={handleCloseConnectedUsers}
            />
            <MainContent>
              {children}
              {isCreateOrganizationOpen && (
                <CreateOrganization open={isCreateOrganizationOpen} onClose={handleCloseCreateOrganization} />
              )}
              {isCreateDealOpen && <CreateDeal open={isCreateDealOpen} onClose={handleCloseCreateDeal} />}
              {isCreateContactOpen && <CreateContact open={isCreateContactOpen} onClose={handleCloseCreateContact} />}
              {isCreateTaskOpen && <CreateTask open={isCreateTaskOpen} onClose={handleCloseCreateTask} />}
              {currentUser && <SpeedDialComponent currentUser={currentUser} isLoading={isUserLoading} />}
            </MainContent>
          </DealDetailsProvider>
        </NotificationProvider>
      </LocalizationProvider>
    </Box>
  )
}

export default AppLayout
