import React, { useState, useEffect } from 'react'
import { Button, Dialog, DialogTitle, DialogContent, IconButton, Box, Input } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen'
import DownloadIcon from '@mui/icons-material/Download'
import useGooglePickerById from '../../api/google/useGooglePickerById.ts'
import { useGoogleDownloadFile } from '../../api/google/useGoogleDownloadFile.ts'

const GoogleViewerById = () => {
  const { isLoaded, showPicker, selectedFile, lastOpenedFolderId } = useGooglePickerById()
  const [isFileViewerOpen, setIsFileViewerOpen] = useState(false)
  const { mutate: downloadGoogleFile } = useGoogleDownloadFile()

  useEffect(() => {
    if (selectedFile) {
      setIsFileViewerOpen(true)
    }
  }, [selectedFile])

  // ✅ Determine the correct URL for viewing
  const getViewerUrl = () => {
    if (!selectedFile) return ''
    if (selectedFile.mimeType.includes('application/vnd.google-apps')) {
      return `https://docs.google.com/document/d/${selectedFile.id}/preview` // ✅ Google Docs preview
    } else if (selectedFile.embedUrl) {
      return selectedFile.embedUrl // ✅ Use embed URL for images, PDFs
    } else {
      return selectedFile.url // ✅ Fallback to standard Google Drive URL
    }
  }

  // ✅ Determine the correct URL for editing
  const openInGoogleDocs = () => {
    if (!selectedFile) return
    if (selectedFile.mimeType.includes('application/vnd.google-apps')) {
      window.open(`https://docs.google.com/document/d/${selectedFile.id}/edit`, '_blank') // ✅ Open Google Docs editor
    } else {
      window.open(selectedFile.url, '_blank') // ✅ Open in Google Drive
    }
  }

  const handleCloseFilePreview = () => {
    setIsFileViewerOpen(false)
    showPicker(lastOpenedFolderId)
  }

  const handleDownloadGoogleFile = () => {
    if (selectedFile?.id) {
      downloadGoogleFile(selectedFile.id)
    }
  }

  return (
    <Box>
      {/* Opens the Google Picker */}
      <Button onClick={() => showPicker()} disabled={!isLoaded} variant='contained' color='primary'>
        Open Google Picker By Id
      </Button>

      {/* File Viewer Modal */}
      {isFileViewerOpen && isLoaded && (
        <Dialog
          open={isFileViewerOpen}
          onClose={handleCloseFilePreview}
          maxWidth='xl'
          fullWidth
          PaperProps={{
            style: { height: '90vh', maxHeight: '90vh' },
          }}
        >
          <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {selectedFile?.name}
            <IconButton onClick={openInGoogleDocs}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={handleDownloadGoogleFile}>
              <DownloadIcon />
            </IconButton>
            <IconButton onClick={handleCloseFilePreview}>
              <CloseFullscreenIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent
            style={{
              padding: 0,
              height: 'calc(100vh - 120px)',
            }}
          >
            {selectedFile && (
              <iframe
                src={getViewerUrl()}
                width='100%'
                height='100%'
                style={{ border: 'none', minHeight: '80vh' }}
                title='Google Drive Viewer'
              />
            )}
          </DialogContent>
        </Dialog>
      )}
    </Box>
  )
}

export default GoogleViewerById

// import React, { useState, useEffect } from 'react'
// import { Button, Dialog, DialogTitle, DialogContent, IconButton, Box, Input } from '@mui/material'
// import EditIcon from '@mui/icons-material/Edit'
// import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen'
// import DownloadIcon from '@mui/icons-material/Download'
// import useGooglePickerById from '../../api/google/useGooglePickerById.ts'
// import { useGoogleDownloadFile } from '../../api/google/useGoogleDownloadFile.ts'

// const GoogleViewerById = () => {
//   const { isLoaded, showPicker, selectedFile, lastOpenedFolderId } = useGooglePickerById()
//   const [isFileViewerOpen, setIsFileViewerOpen] = useState(false)
//   const { mutate: downloadGoogleFile } = useGoogleDownloadFile()

//   useEffect(() => {
//     if (selectedFile) {
//       setIsFileViewerOpen(true)
//     }
//   }, [selectedFile])

//   const openInGoogleDocs = (fileId: string, mimeType: string) => {
//     let fileUrl = `https://drive.google.com/file/d/${fileId}/view` // Default Google Drive file URL

//     // 🔥 Handle Google Docs, Sheets, and Slides with the proper URL
//     if (mimeType.includes('document')) {
//       fileUrl = `https://docs.google.com/document/d/${fileId}/edit`
//     } else if (mimeType.includes('spreadsheet')) {
//       fileUrl = `https://docs.google.com/spreadsheets/d/${fileId}/edit`
//     } else if (mimeType.includes('presentation')) {
//       fileUrl = `https://docs.google.com/presentation/d/${fileId}/edit`
//     } else if (mimeType.includes('application')) {
//       fileUrl = `https://drive.google.com/document/d/${fileId}/edit`
//     } else if (mimeType.includes('image/png')) {
//       fileUrl = `https://drive.google.com/file/d/${fileId}/view`
//     }

//     window.open(fileUrl, '_blank')
//   }

//   const handleCloseFilePreview = () => {
//     setIsFileViewerOpen(false)
//     showPicker(lastOpenedFolderId)
//   }

//   const handleDownloadGoogleFile = () => {
//     if (selectedFile?.id) {
//       downloadGoogleFile(selectedFile.id)
//     }
//   }

//   return (
//     <Box>
//       {/* Opens the Google Picker */}
//       <Button
//         onClick={() => showPicker('1b2hLG-Peqan4kwSid6JtAlwWIsLFOhP7')}
//         disabled={!isLoaded}
//         variant='contained'
//         color='primary'
//       >
//         Open Google Picker By Id
//       </Button>

//       {/* File Viewer Modal */}
//       <Dialog
//         open={isFileViewerOpen}
//         onClose={handleCloseFilePreview}
//         maxWidth='xl'
//         fullWidth
//         PaperProps={{
//           style: { height: '90vh', maxHeight: '90vh' },
//         }}
//       >
//         <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
//           {selectedFile?.name}
//           <IconButton onClick={() => openInGoogleDocs(selectedFile?.id || '', selectedFile?.mimeType || '')}>
//             <EditIcon />
//           </IconButton>

//           <IconButton onClick={handleDownloadGoogleFile}>
//             <DownloadIcon />
//           </IconButton>
//           <IconButton onClick={handleCloseFilePreview}>
//             <CloseFullscreenIcon />
//           </IconButton>
//         </DialogTitle>

//         <DialogContent
//           style={{
//             padding: 0,
//             height: 'calc(100vh - 120px)',
//           }}
//         >
//           {selectedFile && (
//             <iframe
//               src={`https://docs.google.com/document/d/${selectedFile.id}/preview`}
//               width='100%'
//               height='100%'
//               style={{ border: 'none', minHeight: '80vh' }}
//               title='Google Drive Viewer'
//             />
//           )}
//         </DialogContent>
//       </Dialog>
//     </Box>
//   )
// }

// export default GoogleViewerById
