export const initialDealState = {
  name: '',
  originalName: '',
  dba: '',
  organizations: [],
  type: '',
  description: '',
  stage: 'Discovery',
  status: 'Draft',
  probability: '',
  agreementOptions: '',
  invoiceOptions: '',
  contacts: [],
  primaryContact: [],
  startDate: null,
  endDate: null,
  closeDate: null,
  products: [],
  recipients: {
    contactRecipient: [],
    authorizedRecipient: [],
  },
  targetLaunchDate: null,
  dependenciesDueDate: null,
  managementFee: '',
  grossProfit: '',
  setupFee: '',
  implementationFee: '',
  monthlyTotal: '',
  total: '',
  mediaSpend: '',
  contractId: '',
  createdBy: [],
  owner: [],
  noSignature: false,
  needsApproval: false,
  isRequested: false,
  isApproved: false,
  isInternal: false,
  isCompleted: false,
  isCreated: false,
  isDraft: true,
  dealStatus: 'In Progress',
  numberOfPayments: 2,
}
