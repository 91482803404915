import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { baseUrl } from '../queryMutations/queryMutations'

const getGoogleOrganizationFolder = async (orgId: string) => {
  if (!orgId) return null // Prevents execution

  const response = await axios.get(`${baseUrl}/aws/googleapi/drive/organization-folder`, {
    params: { orgId },
    headers: { Authorization: `Bearer ${localStorage.getItem('google_access_token')}` },
  })

  return response.data
}

export const useGoogleOrganizationFolder = (orgId: string) => {
  return useQuery({
    queryKey: ['organizationFolder', orgId],
    queryFn: () => getGoogleOrganizationFolder(orgId),
    enabled: !!orgId, // ✅ Will not trigger if orgId is missing
    staleTime: 60000, // Cache for 1 min
    retry: (failureCount, error) => {
      if (error.response?.data?.organizationFolderId === null) {
        return false // 🔹 Stop retrying if folder is confirmed as missing
      }
      return failureCount < 2 // Retry max 2 times
    },
  })
}
