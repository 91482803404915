import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const stackStyles = theme => ({
  height: 'calc(100vh - 135px)',
  overflowY: 'auto',
  flexGrow: 1,
  pl: 2,
  pr: 2,
  borderColor: theme.palette.text.secondary,
  // m: 2,
})
export const BoxRow = styled(Box)(({ theme, type }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: 4,
}))
export const typographyStyle = theme => ({
  flexGrow: 1,
  padding: '8px 16px',
  backgroundColor: theme.palette.background.input,
  borderRadius: '4px',
  fontSize: '0.875rem',
  color: theme.palette.mode === 'dark' ? 'rgb(181, 191, 205)' : 'rgb(97, 97, 97)',
  mb: 0.5,
})
export const StickyBox = styled(Box)(({ theme, type }) => ({
  position: 'sticky',
  top: 0,
  zIndex: 30,
  mb: 1,
  bgcolor: theme.palette.background.default,
  border: '1px solid',
}))
export const headerGridStyle = theme => ({
  border: '1px solid',
  // width: '75%',
  p: 1,
  borderColor: theme.palette.text.secondary,
  bgcolor: theme.palette.background.sectionContent,
})
export const iconButtonSyles = theme => ({
  gap: 2,
  borderRadius: 0,
  color: theme.palette.text.secondary,
  '&:hover': {
    color: theme.palette.text.primary,
  },
  '&:hover .MuiSvgIcon-root': {
    color: 'rgb(220, 20, 60)',
  },
})
export const cancelIconStyles = theme => ({
  height: 30,
  width: 30,
  color: theme.palette.text.secondary,
  '&:hover': {
    color: 'rgb(220, 20, 60)',
  },
})
