import React, { useEffect, useMemo, useState } from 'react'
import {
  Box,
  TextField,
  Grid,
  InputAdornment,
  useTheme,
  Typography,
  InputLabel,
  MenuItem,
  useMediaQuery,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import AuthorizedRecipientAutocomplete from '../PandaDoc/AuthorizedRecipientAutocomplete'
import ContactRecipientAutocomplete from '../PandaDoc/ContactRecipientAutocomplete'
import { useUsers } from '../../api/aws/useUsers'
import { useContactsById } from '../../api/customHooks/useContactsById'
import { fieldStyles, feeFieldStyles, totalsReadOnlyFieldStyles, dynamicGridSizes } from './style'
import ReviseDealType from '../DealTypeSelect/ReviseDealType'
import { useDealDetailsContext } from '../../context/DealsDetailsProvider/DealsDetailsProvider'
import ContractTotals from './ContractTotals'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { validDateValue, normalizeDate } from '../DateTime/dateUtils'
import { useValidationContext } from '../../context/ValidationProvider/ValidationProvider'
import RequestGenerateContract from '../Deals/RequestGenerateContract'
import CancelContractButton from '../ui/cancel-contract-button'
import SaveContractButton from '../ui/save-contract-button'
import SaveAndUpdateButton from '../ui/save-and-update-contract'
import StyledInputAdornment from '../ui/styled-input-adornment'
import PandaDocViewer from '../PaymentSchedule/PandDocViewer'
import RenderSaveButtons from './RenderSaveButtons'
import { usePandaContacts } from '../../api/PandaDoc/usePandaContacts'
import AssignRecipientsAutocomplete from './AssignRecipientsAutocomplete'
import { useContacts } from '../../api/aws/useContacts'

dayjs.extend(utc)
dayjs.extend(timezone)

const determineDraft = deal => {
  if (deal?.status !== 'Completed' && deal?.stage !== 'Deal Won') {
    return true
  } else {
    return false
  }
}

const ContractInfoTotals = ({
  activeUser,
  totals,
  handleChange,
  organization,
  handleUpdateModal,
  handleCancel,
  handleSave,
  handleSaveAndUpdate,
  handleSubmit,
  handleApprovalRequest,
  isError,
  document,
  isCurrentContactRecipient,
  isCurrentAuthorizedRecipient,
  isRequesting,
  isSaving,
  isUpdating,
  isCreating,
  isUserLoading,
  isContactLoading,
}) => {
  const { newDeal, setNewDeal, validationErrors, isValid } = useDealDetailsContext()
  const { errorKeys, isStrategyValid } = useValidationContext()
  const numberOfPaymentOptions = [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
    { label: '6', value: '6' },
  ]
  const contactUUIDs = newDeal && Array.isArray(newDeal.contacts) ? newDeal.contacts : []

  const { users, isUsersLoading, isUsersError } = useUsers(true, ['contractInfoHeader', 'users'])
  const { data: pandaContacts, isLoading: isPandaContactsLoading, isError: isPandaContactsError } = usePandaContacts()
  const { contacts, isContactsLoading, isContactsError } = useContacts(true, ['contacts', 'agreement'])
  const recipientList = useMemo(() => {
    if (users && pandaContacts && contacts && !isContactLoading && !isUsersLoading && !isPandaContactsLoading) {
      return [...users, ...contacts]
    }
  }, [users, pandaContacts])
  // Authorized Contacts (Matched with Users)
  const filteredAuthContacts = useMemo(() => {
    if (!pandaContacts || isPandaContactsLoading || isUsersLoading) return []

    return users.map(rec => {
      const matchedPandaContact = pandaContacts.find(
        contact => contact?.email?.toLowerCase() === rec?.email?.toLowerCase()
      )

      return {
        ...rec, // Keep recipient details from database
      }
    })
  }, [pandaContacts, users, isPandaContactsLoading])

  const mergedContactRecipients = useMemo(() => {
    if (!pandaContacts || !contacts || isPandaContactsLoading || isContactsLoading) return []

    return contacts.map(c => {
      const matchedPandaContact = pandaContacts.find(contact => contact?.contact_id === c?.panda_contact_id)

      return {
        ...c, // Keep recipient details from database
      }
    })
  }, [pandaContacts, contacts, isContactsLoading, isPandaContactsLoading])

  // Client Contacts (Exclude users)
  const filteredClientContacts = useMemo(() => {
    if (!pandaContacts || isPandaContactsLoading || isUsersLoading) return []

    return pandaContacts.filter(
      contact => !users.some(user => user?.email?.toLowerCase() === contact?.email?.toLowerCase())
    )
  }, [pandaContacts, users, isPandaContactsLoading])

  const theme = useTheme()
  const fieldBgColor = theme.palette.mode === 'dark' ? 'rgb(22, 28, 42, 0.7)' : 'rgba(255,255,255,1)'
  const userTimezone = dayjs.tz.guess() // Dynamically detect user's timezone
  const isShortScreen = useMediaQuery('(max-height: 800px)')
  const isTallScreen = useMediaQuery('(min-height: 1200px)')
  // const isDraft = useMemo(() => {
  //   const draftStatus = determineDraft(newDeal)
  //   return draftStatus
  // }, [newDeal])

  // useEffect(() => {
  //   if (isDraft) {
  //     console.log('isDraft?', isDraft)
  //     setNewDeal(prev => ({ ...prev, isDraft: isDraft }))
  //   }
  // }, [isDraft])
  // const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0
  // const isMacbookProDimensions = isMac && window.innerWidth === 1512 && window.innerHeight === 857
  const gridSizes = dynamicGridSizes(isTallScreen)
  const handleCancelContract = () => {
    handleCancel()
  }
  return (
    <Grid container borderRadius={'12px'} p={1.5}>
      {/***/}
      <Grid
        item
        xs={newDeal?.contractId ? 10 : 12}
        sm={newDeal?.contractId ? 10 : 12}
        md={newDeal?.contractId ? 10 : 12}
        lg={newDeal?.contractId ? 10 : 12}
        xl={newDeal?.contractId ? 10 : 12}
      >
        <Box width={'100%'} pb={2}>
          <InputLabel
            sx={{
              ml: 1.5,

              fontSize: '10.5px', // Customize font size
            }}
            error={validationErrors.startDate}
          >
            Legal Entity Name
          </InputLabel>
          <Typography sx={{ pl: 2, pb: 0.3 }}>{newDeal?.dba}</Typography>
        </Box>
      </Grid>
      {newDeal?.contractId && (
        <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
          <PandaDocViewer contractId={newDeal?.contractId} customFileName={`${newDeal?.name}`} height={32} width={32} />
        </Grid>
      )}
      {/***/}
      {/***/}
      <Grid item {...gridSizes.contactRecipient}>
        <Box width={'100%'}>
          <ContactRecipientAutocomplete
            recipients={contacts}
            document={document}
            value={newDeal && newDeal.recipients ? newDeal.recipients.contactRecipient : []}
            label={'Contact Recipient'}
            onChange={newValue => handleChange('contactRecipient', newValue)}
            error={validationErrors.contactRecipient}
            isDraft={newDeal?.isDraft}
            isCurrentRecipient={isCurrentContactRecipient}
            fullWidth
            sx={fieldStyles(fieldBgColor)}
          />
        </Box>
      </Grid>
      <Grid item {...gridSizes.authorizedRecipient}>
        <Box width={'100%'}>
          <AuthorizedRecipientAutocomplete
            recipients={filteredAuthContacts}
            value={newDeal && newDeal.recipients ? newDeal.recipients.authorizedRecipient : []}
            label={'GRO Authorized Recipient'}
            onChange={newValue => handleChange('authorizedRecipient', newValue)}
            error={validationErrors.authorizedRecipient}
            isDraft={newDeal?.isDraft}
            isCurrentRecipient={isCurrentAuthorizedRecipient}
            isUserLoading={isUserLoading}
            fullWidth
            sx={fieldStyles(fieldBgColor)}
          />
        </Box>
      </Grid>
      {/* <Grid item {...gridSizes.authorizedRecipient}>
        <Box width={'100%'}>
          <AssignRecipientsAutocomplete
            recipients={recipientList}
            value={
              newDeal &&
              newDeal.recipients &&
              newDeal?.recipients?.authorizedRecipient &&
              newDeal?.recipients?.contactRecipient
                ? [newDeal.recipients?.authorizedRecipient?.[0], newDeal.recipients?.contactRecipient?.[0]]
                : newDeal?.recipients
            }
            label={'Recipients'}
            onChange={newValue => handleChange('recipients', newValue)}
            error={validationErrors.recipients}
            isDraft={newDeal?.isDraft}
            isCurrentRecipient={isCurrentAuthorizedRecipient}
            isUserLoading={isUserLoading}
            fullWidth
            sx={fieldStyles(fieldBgColor)}
          />
        </Box>
      </Grid> */}
      {/***/}
      {/***/}
      <Grid
        item
        xs={['CSA', 'RCSA'].includes(newDeal.type) ? 6 : 12}
        sm={['CSA', 'RCSA'].includes(newDeal.type) ? 6 : 12}
        md={['CSA', 'RCSA'].includes(newDeal.type) ? 6 : 12}
        lg={['CSA', 'RCSA'].includes(newDeal.type) ? 6 : 12}
        xl={['CSA', 'RCSA'].includes(newDeal.type) ? 6 : 12}
      >
        <Box width={'100%'} pb={2}>
          <ReviseDealType
            error={validationErrors.type}
            helperText={validationErrors.type && 'Deal type is required'}
            onChange={newValue => handleChange('type', newValue)}
            isDraft={newDeal?.isDraft}
          />
        </Box>
      </Grid>
      {newDeal && newDeal.type && ['CSA', 'RCSA'].includes(newDeal.type) && (
        <Grid item {...gridSizes.numberOfPayments} xs={6} sm={6} md={6} lg={6} xl={6}>
          <Box width={'100%'} pb={2}>
            <TextField
              label='Payments'
              name='numberOfPayments'
              disabled={!newDeal?.isDraft}
              InputProps={{
                startAdornment: <StyledInputAdornment text={'#'} />,
                readOnly: totals && totals.total && totals.total < 2501,
                sx: { borderColor: 'red' },
              }}
              select
              value={newDeal.numberOfPayments}
              onChange={handleChange}
              sx={
                totals && totals.total && totals.total < 2501
                  ? {
                      ...totalsReadOnlyFieldStyles(fieldBgColor),
                      height: '65px',
                    }
                  : {
                      ...feeFieldStyles(fieldBgColor),
                      height: '65px',
                    }
              }
            >
              {numberOfPaymentOptions.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </Grid>
      )}
      {/***/}
      {/***/}
      <Grid item {...gridSizes.dates}>
        <Box width={'100%'}>
          <InputLabel
            sx={{
              ml: 1.5,
              fontSize: '10.5px', // Customize font size
            }}
            error={validationErrors.startDate}
          >
            Start Date
          </InputLabel>
          <DatePicker
            // label='End Date'
            disabled={!newDeal?.isDraft}
            value={validDateValue(newDeal ? newDeal?.startDate : null)}
            onChange={newValue => handleChange('startDate', normalizeDate(newValue, userTimezone))}
            slotProps={{
              textField: { InputProps: { readOnly: true }, fullWidth: true },
            }}
            sx={{ ...fieldStyles(fieldBgColor), bgcolor: 'transparent' }}
          />
        </Box>
      </Grid>
      <Grid item {...gridSizes.dates}>
        <Box width={'100%'}>
          <InputLabel
            sx={{
              ml: 1.5,
              fontSize: '10.5px', // Customize font size
            }}
            error={validationErrors.endDate}
          >
            End Date
          </InputLabel>
          <DatePicker
            // label='End Date'
            disabled={!newDeal?.isDraft}
            value={validDateValue(newDeal ? newDeal?.endDate : null)}
            onChange={newValue => handleChange('endDate', normalizeDate(newValue, userTimezone))}
            slotProps={{
              textField: { fullWidth: true, error: validationErrors.endDate },
            }}
            sx={{ ...fieldStyles(fieldBgColor), bgcolor: 'transparent' }}
          />
        </Box>
      </Grid>
      {/***/}
      {/***/}
      <Grid item {...gridSizes.dates}>
        <Box width={'100%'}>
          <InputLabel
            sx={{
              ml: 1.5,
              fontSize: '10.5px', // Customize font size
            }}
            error={validationErrors.dependenciesDueDate}
          >
            Dependencies Due Date
          </InputLabel>
          <DatePicker
            disabled={!newDeal?.isDraft}
            value={validDateValue(newDeal ? newDeal.dependenciesDueDate : null)}
            onChange={newValue => handleChange('dependenciesDueDate', normalizeDate(newValue, userTimezone))}
            slotProps={{
              textField: {
                fullWidth: true,
                error: validationErrors.targetLaunchDate, // Pass error to TextField
              },
            }}
            sx={{ ...fieldStyles(fieldBgColor), bgcolor: 'transparent' }}
          />
        </Box>
      </Grid>
      <Grid item {...gridSizes.dates}>
        <Box width={'100%'}>
          <InputLabel
            sx={{
              ml: 1.5,
              fontSize: '10.5px', // Customize font size
            }}
            error={validationErrors.targetLaunchDate}
          >
            Target Launch Date
          </InputLabel>
          <DatePicker
            disabled={!newDeal?.isDraft}
            value={validDateValue(newDeal ? newDeal.targetLaunchDate : null)}
            onChange={newValue => handleChange('targetLaunchDate', normalizeDate(newValue, userTimezone))}
            slotProps={{
              textField: {
                fullWidth: true,
                error: validationErrors.targetLaunchDate, // Pass error to TextField
              },
            }}
            sx={{ ...fieldStyles(fieldBgColor), bgcolor: 'transparent' }}
          />
        </Box>
      </Grid>
      {/***/}
      {/***/}
      <ContractTotals
        newDeal={newDeal}
        setNewDeal={setNewDeal}
        totals={totals}
        onChange={handleChange}
        gridSizes={gridSizes}
      />
      <Grid item {...gridSizes.cancelButton} px={1} py={1}>
        {isError && <Typography color='error'>Error creating deal</Typography>}
        <CancelContractButton onClick={handleCancelContract} color='primary' variant='outlined'>
          Cancel
        </CancelContractButton>
      </Grid>
      <Grid item {...gridSizes.saveButton} px={1} py={1}>
        <RenderSaveButtons
          contractId={newDeal?.contractId}
          onSave={handleSave}
          onSaveAndUpdate={handleSaveAndUpdate}
          isSaving={isSaving}
          isUpdating={isUpdating}
          isDraft={newDeal?.isDraft}
        />
      </Grid>
      <Grid item {...gridSizes.requestContainer} px={1} py={1}>
        <RequestGenerateContract
          activeUser={activeUser}
          newDeal={newDeal}
          isRequesting={isRequesting}
          onRequestApproval={handleApprovalRequest}
          onCreate={handleSubmit}
          isStrategyValid={isStrategyValid}
          validationErrors={validationErrors}
          isCreating={isCreating}
          isValid={isValid}
        />
      </Grid>

      {/***/}
    </Grid>
  )
}

export default ContractInfoTotals
