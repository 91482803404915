import React, { useState, useEffect } from 'react'
import useGooglePicker from '../../api/google/useGooglePicker.ts'
import { Button, Dialog, DialogTitle, DialogContent, IconButton, Box } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen'

const GoogleViewer = () => {
  const { isLoaded, showPicker, selectedFile, lastOpenedFolderId } = useGooglePicker()
  const [isFileViewerOpen, setIsFileViewerOpen] = useState(false) // ✅ Separate state for file preview

  useEffect(() => {
    if (selectedFile) {
      setIsFileViewerOpen(true) // ✅ Open viewer when a file is selected
    }
  }, [selectedFile])

  const openInGoogleDocs = (fileId: string) => {
    const editUrl = `https://docs.google.com/document/d/${fileId}/edit`
    window.open(editUrl, '_blank') // Opens in a new tab
  }

  const handleCloseFilePreview = () => {
    setIsFileViewerOpen(false)
    showPicker(lastOpenedFolderId) // ✅ Reopens picker in last folder
  }

  return (
    <Box>
      {/* Opens the Google Picker */}
      <Button onClick={() => showPicker()} disabled={!isLoaded} variant='contained' color='primary'>
        Open Google Picker
      </Button>

      {/* File Viewer Modal */}
      {isFileViewerOpen && (
        <Dialog
          open={isFileViewerOpen} // ✅ Uses separate state
          onClose={handleCloseFilePreview} // ✅ Reopens picker in last directory
          maxWidth='xl'
          fullWidth
          PaperProps={{
            style: { height: '90vh', maxHeight: '90vh' }, // Large modal size
          }}
        >
          <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {selectedFile?.name}
            <IconButton onClick={() => openInGoogleDocs(selectedFile?.id || '')}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={handleCloseFilePreview}>
              {' '}
              {/* ✅ Closes viewer & keeps picker open in the same folder */}
              <CloseFullscreenIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent
            style={{
              padding: 0,
              height: 'calc(100vh - 120px)', // Ensures full height
            }}
          >
            {selectedFile && (
              <iframe
                src={`https://docs.google.com/document/d/${selectedFile.id}/preview`}
                width='100%'
                height='100%'
                style={{ border: 'none', minHeight: '80vh' }}
                title='Google Drive Viewer'
              />
            )}
          </DialogContent>
        </Dialog>
      )}
    </Box>
  )
}

export default GoogleViewer
