import React, { useEffect, useState, useRef, useMemo } from 'react'
import {
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarColumnsButton,
  useGridApiContext,
} from '@mui/x-data-grid-pro'
import { Badge, Box, Button, IconButton, Tooltip, Typography, useTheme } from '@mui/material'
import { gridToolbarContainerStyles, StyledGridToolbarQuickFilter } from './style'
import CustomStatusFilter from '../DataGrid/createColumns/CustomStatusFilter'
import { useActiveUser } from '../../api/slack/useActiveUser'
import { ncoStatusFilters } from '../DataGrid/filterArrays'
import { useNavigate } from 'react-router-dom'
import ResetFiltersButton from '../ui/reset-filters-button'
import ResetSortingButton from '../ui/reset-sorting-button'
import ResetColumnsButton from '../ui/reset-columns-button'
import AddRoadIcon from '@mui/icons-material/AddRoad'
import OpenColumnsMenu from '../ui/open-columns-menu'
import OpenFiltersMenu from '../ui/open-filters-menu'
import ExportCsvButton from '../ui/export-csv-button'
import ChangeBoardView from './ChangeBoardView'

const slotProps = theme => ({
  button: {
    sx: {
      borderRadius: 2,
      maxHeight: '100%',
      mt: 2,
      mb: 2,
      '&:hover': { backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.main : 'rgba(0,0,0,0.1)' },
      color: theme.palette.text.primary,
      '& .MuiBadge-badge': {
        backgroundColor: 'rgb(251, 193, 30)', // Your custom background color
        color: theme.palette.mode === 'dark' ? 'rgba(0,0,0,1)' : 'rgba(0,0,0,1)', // Change the text color if needed
        fontSize: '14px', // Optional: adjust the font size
        width: '20px', // Adjust the width/height to control the size of the badge
        height: '20px',
      },
    },
  },
})
const isSortModelEqual = (sortModel1, sortModel2) => {
  if (sortModel1.length !== sortModel2.length) return false
  return sortModel1.every((sortItem, index) => {
    const initialSortItem = sortModel2[index]
    return sortItem.field === initialSortItem.field && sortItem.sort === initialSortItem.sort
  })
}
const BoardsGridToolbar = ({
  filterModel,
  selectionModel,
  // isTaskView,
  // onChangeBoardView,
  initialVisibilityState,
  visibilityModel,
  initialSortModel,
  sortModel,
  setFilterModel,
  onClearSorting,
  onClearVisibility,
  onChangeView,
  statusKey,
  statusLabel,
  filterOptions,
  context,
}) => {
  const theme = useTheme()
  const navigate = useNavigate()

  const textColor = theme.palette.mode === 'dark' ? 'rgba(255,255,255,1)' : 'rgba(0,0,0,1)'
  const gridFilterBgColor = theme.palette.mode === 'dark' ? theme.palette.primary.main : 'rgba(0,0,0,0.1)'
  const slotPropsColor = theme.palette.text.primary
  const quickFilterRef = useRef(null) // Create a ref for the quick filter
  const [ncoFilterStatus, setNcoFilterStatus] = useState('')
  const [statusFilter, setStatusFilter] = useState('')
  const [filterStage, setFilterStage] = useState('')
  const { activeUser, isActiveUserLoading, isActiveUserError } = useActiveUser()
  const activeUserName =
    activeUser && activeUser.user && activeUser.user.profile ? activeUser.user.profile.real_name : null
  const isSortModelChanged = useMemo(() => {
    return !isSortModelEqual(sortModel, initialSortModel)
  }, [sortModel, initialSortModel])
  const [selectedView, setSelectedView] = useState(() => {
    const savedView = localStorage.getItem(`${context}SelectedView`)
    return savedView ? JSON.parse(savedView) : `${context}`
  })
  const apiRef = useGridApiContext() // Get DataGrid API

  // 🔹 Toggle Columns Visibility Menu
  const handleOpenColumnsMenu = event => {
    apiRef.current.showPreferences('columns')
  }

  // 🔹 Toggle Filter Panel
  const handleOpenFilterPanel = () => {
    apiRef.current.showFilterPanel()
  }

  // 🔹 Export Data
  const handleExport = () => {
    apiRef.current.exportDataAsCsv()
  }
  ///////////////////////////////////////////////////////////////////////////\
  // Utility function to check if two sort models are deeply equal

  // Handle keydown event to focus the quick filter on Ctrl+Shift+F or Cmd+Shift+F
  useEffect(() => {
    const handleKeyDown = event => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'f') {
        event.preventDefault()
        if (quickFilterRef.current) {
          quickFilterRef.current.focus() // Focus the quick filter input
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])
  const handleFilterMyData = () => {
    setFilterModel({
      ...filterModel,
      quickFilterValues: [activeUserName],
    })
  }

  const handleSetStatusFilter = (fieldName, selectedValues) => {
    setFilterModel(prevModel => {
      // Remove existing filter for the same field
      let updatedFilters = prevModel.items.filter(item => item.field !== fieldName)

      // If selectedValues is empty, we remove the filter
      if (selectedValues && selectedValues.length > 0) {
        updatedFilters.push({
          id: `${fieldName}-${Date.now()}`, // Ensure unique ID
          field: fieldName,
          operator: 'isAnyOf', // 🔥 Multi-selection operator
          value: selectedValues,
        })
      }

      return {
        ...prevModel,
        items: updatedFilters,
      }
    })
  }
  const handleClearFilters = e => {
    e.preventDefault()
    setFilterModel({ items: [], quickFilterValues: [] })
    setNcoFilterStatus('')
    setFilterStage('')
  }

  return (
    <GridToolbarContainer sx={gridToolbarContainerStyles}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignContent: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',

            gap: 2,
            ml: 2,
          }}
        >
          <ChangeBoardView
            context={context}
            onChangeView={newView => onChangeView(newView)}
            selectedView={selectedView ?? `${context}SelectedView`}
            setSelectedView={setSelectedView}
          />
          <GridToolbarDensitySelector slotProps={slotProps(theme)} />
          <OpenColumnsMenu onOpenColumns={handleOpenColumnsMenu} />
          <OpenFiltersMenu
            onOpenFilters={handleOpenFilterPanel}
            filterModel={filterModel}
            badgeColor={theme.palette.icon.nco}
          />
          <ExportCsvButton onExport={handleExport} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <CustomStatusFilter
            options={filterOptions ?? ncoStatusFilters}
            setterFn={setStatusFilter ?? setNcoFilterStatus}
            filterModel={filterModel}
            value={statusFilter ?? ncoFilterStatus}
            onChange={handleSetStatusFilter}
            // context={statusKey}
            context={'onboardingStatus'}
            label={statusLabel ?? 'No Label'}
          />
          <Button
            sx={{
              height: 40,
              color: theme.palette.mode === 'dark' ? 'lightblue' : 'lightblue',
            }}
            onClick={handleFilterMyData}
          >
            My Deals
          </Button>
          <ResetColumnsButton
            visibilityModel={visibilityModel}
            onClearVisibility={onClearVisibility}
            initialVisibilityState={initialVisibilityState}
          />
          <ResetSortingButton isSortModelChanged={isSortModelChanged} onClearSorting={onClearSorting} />
          <ResetFiltersButton filterModel={filterModel} handleClearFilters={handleClearFilters} />
          <Badge
            badgeContent={
              <>
                {filterModel &&
                filterModel.quickFilterValues &&
                Array.isArray(filterModel.quickFilterValues) &&
                filterModel.quickFilterValues.length > 0 ? (
                  <Typography
                    sx={{
                      fontSize: 12,
                      bgcolor: 'rgb(251, 193, 30)',
                      p: 0.5,
                      height: '20px',
                      width: '20px',
                      borderRadius: '50%',
                      display: 'flex', // Flexbox centering
                      alignItems: 'center', // Vertically center
                      justifyContent: 'center', // Horizontally center
                      color: theme.palette.mode === 'dark' ? 'rgba(0,0,0,1)' : 'rgba(0,0,0,1)',
                      fontWeight: 'bold',
                    }}
                  >
                    {filterModel && filterModel.quickFilterValues && Array.isArray(filterModel.quickFilterValues)
                      ? filterModel.quickFilterValues.length
                      : null}
                  </Typography>
                ) : null}
              </>
            }
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <StyledGridToolbarQuickFilter placeholder={'ctrl+f or cmd+f to search'} inputRef={quickFilterRef} />
          </Badge>
        </Box>
      </Box>
    </GridToolbarContainer>
  )
}

export default BoardsGridToolbar
