import React from 'react'
import { Typography, Box, Chip, TextField, useTheme, Avatar, Stack } from '@mui/material'
import { setStageStyle } from './setStageStyle'
import { styled } from '@mui/material/styles'
import { Link } from 'react-router-dom'
import { renderColorByString } from '../DataGrid/renderColorByString'
import { useOrganizationById } from '../../api/aws/useOrganization'
import DetailsLoading from '../Loading/DetailsLoading'
import RenderVertical from '../Organizations/RenderVertical'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import PersonIcon from '@mui/icons-material/Person'
import WorkOutlineIcon from '@mui/icons-material/WorkOutline'
import RequestPageIcon from '@mui/icons-material/RequestPage'
import { formatWholeNumber } from './aggregateStageTotals'
import { formatDate } from '../../helperFunctions/formatDates'

const Div = styled('div')(({ theme }) => ({
  ...theme.typography.button,
  padding: theme.spacing(0),
}))

const formatTypeStyle = type => {
  switch (type) {
    case 'IO':
      return 'Insertion Order'
    case 'MSA':
      return 'Marketing Services Agreement'
    case 'RMSA':
      return 'Revise Marketing Services Agreement'
    case 'MSA - Housing':
      return 'Marketing Services Agreement - Housing'
    case 'RMSA - Housing':
      return 'Revised Marketing Services Agreement - Housing'
    case 'CSA':
      return 'Creative Services Agreement'
    case 'RCSA':
      return 'Revised Creative Services Agreement'
  }
}

const formatCurrency = amount => {
  const formattedAmount = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(amount)

  return formattedAmount
}

const DealItemDetails = ({ deal }) => {
  const theme = useTheme()

  return (
    <Box key={deal.id || deal.deal_uuid} width={'100%'}>
      <Box sx={{ display: 'flex', alignContent: 'center', gap: 1, py: 0.5 }}>
        <CorporateFareIcon sx={{ color: 'rgb(114, 229, 218)', height: 18, width: 18 }} />
        <Link
          key={deal.id || deal.deal_uuid}
          to={`/deals/${deal.deal_uuid}`}
          style={{
            textDecoration: 'none',
            color: 'inherit',
            backgroundColor: 'transparent',
          }}
        >
          <Typography
            sx={{
              color: setStageStyle(deal.stage),
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              fontWeight: 'bold',
              maxWidth: '200px', // ✅ Set a max width (adjust as needed)
              display: 'inline-block', // ✅ Ensure it respects the max width
              '&:hover': {
                opacity: '0.8',
              },
            }}
            variant='subtitle2'
          >
            {deal?.organization?.name}
          </Typography>
        </Link>
      </Box>
      <Box
        key={deal.id || deal.deal_uuid}
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {deal && deal.type && (
          <Box sx={{ display: 'flex', alignContent: 'center', gap: 1, py: 0.5 }}>
            <RequestPageIcon sx={{ height: 18, width: 18 }} />
            <Div variant='body2' sx={{ color: renderColorByString(deal.type) }}>
              {deal.type}
            </Div>
          </Box>
        )}
        {deal && deal?.owner?.[0] && deal?.ownerName ? (
          <Box key={deal?.owner?.[0]} sx={{ display: 'flex', alignContent: 'center', gap: 1, py: 0.5 }}>
            <PersonIcon sx={{ height: 18, width: 18 }} />
            <Typography variant='body2' color='textSecondary' sx={{ fontStyle: 'italic' }}>
              {deal?.ownerName}
            </Typography>
          </Box>
        ) : null}

        {deal?.organization && deal?.organization?.vertical && (
          <Box sx={{ display: 'flex', alignContent: 'center', gap: 1, py: 0.5 }}>
            <WorkOutlineIcon sx={{ height: 18, width: 18 }} />
            <Typography variant='body2' color={theme.palette.text.primary}>
              {`${
                Array.isArray(deal?.organization?.vertical && deal?.organization?.vertical?.length > 0)
                  ? deal?.organization?.vertical?.[0]
                  : deal?.organization?.vertical
              }`}
            </Typography>
          </Box>
        )}

        {deal && deal?.totals && deal?.totals?.total ? (
          <Stack>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                py: 0.5,
                pt: 2,
              }}
            >
              <Typography variant='body2' color={'rgb(22, 163, 74)'} sx={{ textAlign: 'left', fontWeight: 'bold' }}>
                {`${formatWholeNumber(deal?.totals?.total)}`}
              </Typography>
              {deal?.probability && (
                <Typography variant='body2' color={theme.palette.text.primary} sx={{ textAlign: 'right' }}>
                  {`${deal.probability} % confidence`}
                </Typography>
              )}
            </Box>
            {deal && deal?.stage !== 'Deal Won' && (
              <Typography sx={{ fontSize: 12, fontStyle: 'italic' }}>
                {deal?.staleDays && deal?.staleDays === 1
                  ? `${deal?.staleDays} day since modified`
                  : deal?.staleDays
                  ? `${deal?.staleDays} days since modified`
                  : 'Recently modified'}
              </Typography>
            )}
          </Stack>
        ) : (
          <Stack>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                py: 0.5,
                pt: 2,
              }}
            >
              <Typography variant='body2' color={'rgb(22, 163, 74)'} sx={{ textAlign: 'left', fontWeight: 'bold' }}>
                {`${formatWholeNumber(deal?.totals?.total)}`}
              </Typography>
            </Box>

            <Typography sx={{ fontSize: 12, fontStyle: 'italic' }}>
              {deal?.staleDays && deal?.staleDays === 1
                ? `${deal?.staleDays} day since modified`
                : deal?.staleDays
                ? `${deal?.staleDays} days since modified`
                : 'Recently modified'}
            </Typography>
          </Stack>
        )}
      </Box>
      {deal && deal?.timeCompleted && deal?.stage === 'Deal Won' && (
        <Typography sx={{ fontSize: 12, fontStyle: 'italic' }}>
          {`Date won: ${formatDate(deal?.timeCompleted)}`}
        </Typography>
      )}
    </Box>
  )
}

export default DealItemDetails
