import React, { useCallback } from 'react'
import {
  Box,
  Typography,
  Tooltip,
  IconButton,
  useTheme,
  ToggleButtonGroup,
  ToggleButton,
  Grid,
  InputLabel,
  CircularProgress,
} from '@mui/material'
import AppsIcon from '@mui/icons-material/Apps'
import { useNavigate } from 'react-router-dom'
import { headerStyle } from './style'
import { formatWholeNumber } from './aggregateStageTotals'

const DealsKanbanHeader = React.memo(
  ({ extraStages, setExtraStages, totalDeals, activePipeline, weightedPipeline, dealWonRevenue, isLoading }) => {
    const theme = useTheme()
    const navigate = useNavigate()

    // Memoize navigation function
    const handleNavigateToDeals = useCallback(() => {
      navigate('/deals')
    }, [navigate])

    // Memoize toggle function
    const handleToggleChange = useCallback(
      (event, value) => {
        if (value !== null) {
          setExtraStages(value === 'all')
        }
      },
      [setExtraStages]
    )

    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Tooltip title='View Grid'>
            <IconButton onClick={handleNavigateToDeals} sx={{ mr: 2 }}>
              <AppsIcon sx={{ height: 30, width: 30, color: 'rgb(251, 193, 30)' }} />
            </IconButton>
          </Tooltip>
          <Typography sx={{ ...headerStyle(theme), mr: 4, width: '100%' }}>{'Pipeline'}</Typography>
        </Box>

        <Grid container sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          {[
            { label: 'Open Deals', value: totalDeals },
            { label: 'Active Pipeline', value: activePipeline },
            { label: 'Weighted Pipeline', value: weightedPipeline },
            { label: 'Deal Won Revenue', value: dealWonRevenue },
          ].map(({ label, value }) => (
            <Grid
              key={label}
              item
              xs={3}
              sm={3}
              md={3}
              lg={3}
              xl={2}
              sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
            >
              <InputLabel sx={{ fontSize: 12, color: theme.palette.text.primary }}>{label}</InputLabel>
              <Typography
                sx={{
                  color: label !== 'Open Deals' ? 'rgb(22, 163, 74)' : theme.palette.text.primary,
                  fontSize: 24,
                  fontWeight: 'bold',
                }}
              >
                {isLoading ? (
                  <CircularProgress size={24} />
                ) : label !== 'Open Deals' ? (
                  formatWholeNumber(value) || '0.00'
                ) : (
                  value || 0
                )}
              </Typography>
            </Grid>
          ))}
        </Grid>

        <Box sx={{ ml: 4 }}>
          <ToggleButtonGroup value={extraStages ? 'all' : 'default'} exclusive onChange={handleToggleChange}>
            <ToggleButton value='default' sx={{ maxHeight: '60px', width: '75px' }}>
              Default
            </ToggleButton>
            <ToggleButton value='all' sx={{ maxHeight: '60px', width: '75px' }}>
              All
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Box>
    )
  },
  (prevProps, nextProps) =>
    prevProps.extraStages === nextProps.extraStages &&
    prevProps.totalDeals === nextProps.totalDeals &&
    prevProps.activePipeline === nextProps.activePipeline &&
    prevProps.weightedPipeline === nextProps.weightedPipeline &&
    prevProps.dealWonRevenue === nextProps.dealWonRevenue &&
    prevProps.isLoading === nextProps.isLoading
)

export default DealsKanbanHeader

// import React, { useEffect, useState } from 'react'
// import {
//   Box,
//   Typography,
//   Tooltip,
//   IconButton,
//   useTheme,
//   ToggleButtonGroup,
//   ToggleButton,
//   Grid,
//   InputLabel,
//   Stack,
//   CircularProgress,
// } from '@mui/material'
// import AppsIcon from '@mui/icons-material/Apps'
// import { useNavigate } from 'react-router-dom'
// import { headerStyle } from './style'
// import { formatCurrency, formatCurrencyString, formatWholeNumber } from './aggregateStageTotals'

// const DealsKanbanHeader = ({
//   extraStages,
//   setExtraStages,
//   totalSum,
//   totalGrossProfit,
//   totalDeals,
//   activePipeline,
//   weightedPipeline,
//   dealWonRevenue,
//   isLoading,
// }) => {
//   const theme = useTheme()
//   const navigate = useNavigate()

//   const handleToggleChange = (event, value) => {
//     if (value !== null) {
//       setExtraStages(value === 'all')
//     }
//   }

//   const handleNavigateToDeals = () => {
//     navigate('/deals')
//   }
//   return (
//     <>
//       <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
//         <Box sx={{ display: 'flex', alignItems: 'center' }}>
//           <Tooltip title='View Grid'>
//             <IconButton onClick={handleNavigateToDeals} sx={{ mr: 2 }}>
//               <AppsIcon sx={{ height: 30, width: 30, color: 'rgb(251, 193, 30)' }} />
//             </IconButton>
//           </Tooltip>
//           <Typography sx={{ ...headerStyle(theme), mr: 4, width: '100%' }}>{'Pipeline'}</Typography>
//         </Box>
//         <Grid
//           container
//           sx={{
//             display: 'flex',
//             flexDirection: 'row',
//             alignItems: 'left',
//             justifyContent: 'center',
//           }}
//         >
//           {/* <Grid
//             item
//             xs={3}
//             sm={3}
//             md={3}
//             lg={3}
//             xl={2}
//             sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
//           >
//             <InputLabel sx={{ fontSize: 12, color: theme.palette.text.primary }}>{'Open Deals'}</InputLabel>
//             <Box>
//               <Typography sx={{ fontWeight: 'bold', color: theme.palette.text.primary, fontSize: 24 }}>
//                 {isLoading || !totalDeals ? <CircularProgress /> : totalDeals}
//               </Typography>
//             </Box>
//           </Grid> */}
//           <Grid
//             item
//             xs={3}
//             sm={3}
//             md={3}
//             lg={3}
//             xl={2}
//             sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
//           >
//             <InputLabel sx={{ fontSize: 12, color: theme.palette.text.primary }}>{'Open Deals'}</InputLabel>
//             <Typography sx={{ fontWeight: 'bold', color: theme.palette.text.primary, fontSize: 24 }}>
//               {isLoading || !totalDeals ? <CircularProgress /> : totalDeals}
//             </Typography>
//           </Grid>

//           <Grid
//             item
//             xs={3}
//             sm={3}
//             md={3}
//             lg={3}
//             xl={2}
//             sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
//           >
//             <InputLabel sx={{ fontSize: 12, color: theme.palette.text.primary }}>{'Active Pipeline'}</InputLabel>
//             <Typography sx={{ color: 'rgb(22, 163, 74)', fontSize: 24, fontWeight: 'bold' }}>
//               {isLoading || !activePipeline ? <CircularProgress /> : formatWholeNumber(activePipeline)}
//             </Typography>
//           </Grid>
//           <Grid
//             item
//             xs={3}
//             sm={3}
//             md={3}
//             lg={3}
//             xl={2}
//             sx={{
//               display: 'flex',
//               flexDirection: 'column',
//               justifyContent: 'space-between',
//             }}
//           >
//             <InputLabel sx={{ fontSize: 12, color: theme.palette.text.primary }}>Weighted Pipeline</InputLabel>
//             <Typography sx={{ color: 'rgb(22, 163, 74)', fontSize: 24, fontWeight: 'bold' }}>
//               {isLoading || !weightedPipeline ? <CircularProgress /> : formatWholeNumber(weightedPipeline) || '0.00'}
//             </Typography>
//           </Grid>
//           <Grid
//             item
//             xs={3}
//             sm={3}
//             md={3}
//             lg={3}
//             xl={2}
//             sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
//           >
//             <InputLabel sx={{ fontSize: 12, color: theme.palette.text.primary }}>Deal Won Revenue</InputLabel>
//             <Typography sx={{ color: 'rgb(22, 163, 74)', fontSize: 24, fontWeight: 'bold' }}>
//               {isLoading || !dealWonRevenue ? <CircularProgress /> : `${formatWholeNumber(dealWonRevenue) || '0.00'}`}
//             </Typography>
//           </Grid>
//         </Grid>
//         <Box sx={{ ml: 4 }}>
//           <ToggleButtonGroup value={extraStages ? 'all' : 'default'} exclusive onChange={handleToggleChange}>
//             <ToggleButton value='default' sx={{ maxHeight: '60px', width: '75px' }}>
//               Default
//             </ToggleButton>
//             <ToggleButton value='all' sx={{ maxHeight: '60px', width: '75px' }}>
//               All
//             </ToggleButton>
//           </ToggleButtonGroup>
//         </Box>
//       </Box>
//     </>
//   )
// }

// export default DealsKanbanHeader
