import { Button, Grid, TextField, Typography, useTheme } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { fieldStyles } from '../style'
import { normalizeDate, validDateValue } from '../../DateTime/dateUtils'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import dayjs from 'dayjs'

dayjs.extend(utc)
dayjs.extend(timezone)

const HandoffCall = ({ organization, onCreateDoc }) => {
  // Initialize state for form inputs
  const [formData, setFormData] = useState({})
  const userTimezone = dayjs.tz.guess() // Dynamically detect user's timezone

  const theme = useTheme()
  useEffect(() => {
    if (organization) {
      console.log('organization', organization)
      setFormData(prev => ({
        ...prev,
        website: organization?.website,
        socialProfiles: organization?.socialProfiles,
        vertical: organization?.vertical?.[0],
      }))
    }
  }, [organization])
  useEffect(() => {
    if (formData) {
      console.log('formData', formData)
    }
  }, [formData])
  const handoffCallData = [
    { key: 'clientIntro', label: 'Introduce Client', type: 'text', multiline: false, height: 50 },
    { key: 'channels', label: 'Channels', type: 'text', multiline: false, height: 50 },
    { key: 'website', label: 'Website', type: 'text', multiline: false, height: 50 },
    { key: 'portal', label: 'Prospect Portal', type: 'text', multiline: false, height: 50 },
    { key: 'dependencies', label: 'Dependencies', type: 'text', multiline: false, height: 50 },
    { key: 'vertical', label: 'Vertical', type: 'text', multiline: false, height: 50 },
    { key: 'location', label: 'Location', type: 'text', multiline: false, height: 50 },
    { key: 'socialProfiles', label: 'Socials', type: 'text', multiline: false, height: 50 },
    { key: 'propertyDetails', label: 'Property Details', type: 'text', multiline: true, height: 50 },
    { key: 'billingDetails', label: 'Billing Details', type: 'text', multiline: true, height: 50 },
    { key: 'creatives', label: 'Creatives', type: 'text', multiline: false, height: 50 },
    { key: 'extraNotes', label: 'Things to note', type: 'text', multiline: true, height: 50 },
  ]

  // Handle input change
  const handleChange = (key, value) => {
    setFormData(prevState => ({
      ...prevState,
      [key]: value, // Store user input
    }))
  }

  return (
    <Grid container spacing={2} p={2}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography variant='h5'>Handoff Call</Typography>
      </Grid>
      {handoffCallData.map(field => {
        const fieldValue = formData[field.key] ?? organization?.[field.key] ?? '' // Use organization data if it exists, otherwise use formData

        return (
          <Grid item xs={12} sm={6} md={4} key={field.key}>
            <TextField
              type={field.type}
              label={field.label}
              fullWidth
              variant='outlined'
              value={fieldValue}
              onChange={e => handleChange(field.key, e.target.value)}
              sx={{ ...fieldStyles(theme.palette.background.default), height: field.height ?? 50 }}
              multiline={field.multiline ?? false}
            />
          </Grid>
        )
      })}
      <Grid item xs={12} sm={6} md={4} key={'launch-date-picker-key'}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label='Launch Date'
            value={organization?.launchDate ? validDateValue(organization?.launchDate) : null}
            onChange={newValue => handleChange('launchDate', normalizeDate(newValue, userTimezone))}
            sx={{ ...fieldStyles(theme.palette.background.default), height: 50 }}
            slotProps={{
              textField: { fullWidth: true },
            }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={'create-doc-button-key'}>
        {/* <Button variant='contained' onClick={() => onCreateDoc(formData)} disabled={organization?.handoffDocId}> */}
        <Button variant='contained' onClick={() => onCreateDoc(formData)}>
          Create Doc
        </Button>
      </Grid>
    </Grid>
  )
}

export default HandoffCall
