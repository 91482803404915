export const patchNotes = {
  "5.8.0": {
    "Features": [
      "handoff call form available inside organization item in the nco, document will be created from template and added inside of organizations google drive folder)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "5.7.0": {
    "Features": [
      "task is now created when onboarding status in nco is changed to awaiting kickoff, team tasks board optimizations: introduced more editable cells, task days and date due are calculating correctly, assigning new users is triggering notifications)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "5.6.0": {
    "Features": [
      "google drive folders added to organizations tab display and NCO tab display for deals, single folder only for each.)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "5.5.1": {
    "Features": [],
    "Bug Fixes": [
      "fixed contracted by not rendering in new boards)"
    ],
    "Style": [],
    "Refactors": []
  },
  "5.5.0": {
    "Features": [
      "extending file management to board drawers and setup all boards except MTB so far)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "5.4.1": {
    "Features": [],
    "Bug Fixes": [
      "fixed search functionality for owners in nco)"
    ],
    "Style": [],
    "Refactors": []
  },
  "5.4.0": {
    "Features": [
      "multiselect filters for type now working as expected in organizations grid)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "5.3.2": {
    "Features": [],
    "Bug Fixes": [
      "dealStatus now updating correctly inside deal details)"
    ],
    "Style": [],
    "Refactors": []
  },
  "5.3.1": {
    "Features": [],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "5.3.0": {
    "Features": [
      "google drive apis created to create a single folder and create a parent folder with multiple subfolders)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "5.2.0": {
    "Features": [
      "google drive hierarchy added to organizations, google picker component will be rendered per folder and uploads/downloads will be available directly in each folder.)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "5.1.0": {
    "Features": [
      "google picker now refreshes access token when needed, new script for bulk creation of deals)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "5.0.0": {
    "Features": [
      "The Picker now maintains folder state after uploads, which changes previous navigation behavior. Folder selection is currently available but may change in the future",
      "refactored Google Picker handling)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "4.7.0": {
    "Features": [
      "google driver picker component feature added.  View, download, upload)"
    ],
    "Bug Fixes": [
      "fix organization owners not updating correctly)"
    ],
    "Style": [],
    "Refactors": []
  },
  "4.6.1": {
    "Features": [],
    "Bug Fixes": [
      "deal won stage now displaying totals for specific deals in pipeline)"
    ],
    "Style": [],
    "Refactors": []
  },
  "4.6.0": {
    "Features": [
      "reassigning recipients for the pandadoc contract now available. If contact does not exist in pandadoc, the contact will be created and updated.)"
    ],
    "Bug Fixes": [
      "contact recipient link updated not to show when the contract has been reverted to draft)"
    ],
    "Style": [],
    "Refactors": []
  },
  "4.5.0": {
    "Features": [
      "when creating a deal, hitting next will navigate to the newly created deal for editing, in the dealspage grid, clicking the edit icon will navigate to the agreement of the selected deal instead of rendering a dialog component)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "4.4.0": {
    "Features": [
      "multi select filters now in organizations and deals)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "4.3.2": {
    "Features": [],
    "Bug Fixes": [
      "bug from team members autocomplete)"
    ],
    "Style": [],
    "Refactors": []
  },
  "4.3.1": {
    "Features": [],
    "Bug Fixes": [],
    "Style": [
      "new layout for dr deals details completed, screen size defined for macbook pro)"
    ],
    "Refactors": []
  },
  "4.3.0": {
    "Features": [
      "new layout for deals details and new fields for editing - owner, dealStatus, invoiceOption, agreementOption, pipeline stage, probability)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "4.2.6": {
    "Features": [],
    "Bug Fixes": [
      "gross profit currently updating when viewing deals in details and review, need to update globally)"
    ],
    "Style": [],
    "Refactors": []
  },
  "4.2.5": {
    "Features": [],
    "Bug Fixes": [],
    "Style": [
      "edit buttons added for smaller screen sizes when viewing the product list inside contracts)"
    ],
    "Refactors": []
  },
  "4.2.4": {
    "Features": [],
    "Bug Fixes": [],
    "Style": [
      "xl, lg, md, sm & xs screen sizes optimized)"
    ],
    "Refactors": []
  },
  "4.2.3": {
    "Features": [],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "4.2.2": {
    "Features": [],
    "Bug Fixes": [],
    "Style": [
      "xl and lg screen sizes optimized)"
    ],
    "Refactors": []
  },
  "4.2.1": {
    "Features": [],
    "Bug Fixes": [
      "when contract is saved, if either recipient has changed, the contract is now being updated correctly, only for 1 recipient each)"
    ],
    "Style": [],
    "Refactors": []
  },
  "4.2.0": {
    "Features": [
      "contracts now no longer setting a signing order on creation and update so links will not require one or the other to finish signing the document before signing themselves.)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "4.1.0": {
    "Features": [
      "generate links, revert to draft, view links, save & update functions added to deals contracts. Deals can now update entire contract data, send the contract, change authorized and contact recipients.)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "4.0.3": {
    "Features": [],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "4.0.2": {
    "Features": [],
    "Bug Fixes": [],
    "Style": [
      "styling fixes for CSA type contract)"
    ],
    "Refactors": []
  },
  "4.0.1": {
    "Features": [],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "4.0.0": {
    "Features": [
      "new contract functionally including updates to pandadoc, changing document from any status to draft, and link generation)"
    ],
    "Bug Fixes": [],
    "Style": [
      "this is a completely new flow for creating, saving and generating contracts"
    ],
    "Refactors": []
  },
  "3.4.7": {
    "Features": [],
    "Bug Fixes": [],
    "Style": [
      "completely new structure to the deals contracts page)"
    ],
    "Refactors": []
  },
  "3.4.6": {
    "Features": [],
    "Bug Fixes": [
      "PandaDoc webhook ip addresses added to allowed list.)"
    ],
    "Style": [],
    "Refactors": []
  },
  "3.4.5": {
    "Features": [],
    "Bug Fixes": [],
    "Style": [
      "fixed scrolling issues inside NCO drawer for strategy tab and review deal)"
    ],
    "Refactors": []
  },
  "3.4.4": {
    "Features": [],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": [
      "replaced single get requests with batch requests for all get apis)"
    ]
  },
  "3.4.3": {
    "Features": [],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": [
      "major changes to server side rendering and queries being invalidated)"
    ]
  },
  "3.4.2": {
    "Features": [],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": [
      "more optimizations for react query)"
    ]
  },
  "3.4.1": {
    "Features": [],
    "Bug Fixes": [],
    "Style": [
      "grid and dependencies cards reacting to screen size)"
    ],
    "Refactors": []
  },
  "3.4.0": {
    "Features": [
      "adding update functionality to pandadoc contracts via grobotic)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "3.3.4": {
    "Features": [],
    "Bug Fixes": [
      "fixed routing issue with nco drawer tabs)"
    ],
    "Style": [],
    "Refactors": []
  },
  "3.3.3": {
    "Features": [],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": [
      "optimizations for react query)"
    ]
  },
  "3.3.2": {
    "Features": [],
    "Bug Fixes": [],
    "Style": [
      "temporary fix for smaller screens in the contract screen)"
    ],
    "Refactors": []
  },
  "3.3.1": {
    "Features": [],
    "Bug Fixes": [],
    "Style": [
      "updating padding and alignment for strategy inside nco)"
    ],
    "Refactors": []
  },
  "3.3.0": {
    "Features": [
      "strategy now included in the Nco details drawer, drawer updated with links to each component including campaigns)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "3.2.2": {
    "Features": [],
    "Bug Fixes": [
      "organization team will update correctly now, and will notify the team member assigned to the role upon updating)"
    ],
    "Style": [],
    "Refactors": []
  },
  "3.2.1": {
    "Features": [],
    "Bug Fixes": [
      "central time and eastern time will now synchronize with dates correctly, in the create deal screen keyboard input is now available for start and end date)"
    ],
    "Style": [],
    "Refactors": []
  },
  "3.2.0": {
    "Features": [
      "light mode/dark mode will now remain set. descriptions added to accounting drawer, click on description icon and click the copy icon to copy the products description)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "3.1.3": {
    "Features": [],
    "Bug Fixes": [],
    "Style": [
      "accounting drawer header styling revised, more data added)"
    ],
    "Refactors": []
  },
  "3.1.2": {
    "Features": [],
    "Bug Fixes": [
      "contact first name, middle name, and last name will now be the place for updating the contacts name and will reflect correctly. fixed recalculations in accounting grid)"
    ],
    "Style": [],
    "Refactors": []
  },
  "3.1.1": {
    "Features": [],
    "Bug Fixes": [
      "dial menu rendering now even when unread notifications don't exist)"
    ],
    "Style": [],
    "Refactors": []
  },
  "3.1.0": {
    "Features": [
      "views now available in the accounting board for invoice, billing, and churn)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "3.0.0": {
    "Features": [
      "Notifications and Toggle for light and dark now are contained in the user profile icon.",
      "New speed dial for notifications, toggle light and dark mode, and settings)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "2.30.0": {
    "Features": [
      "new speed dial component added to application for changing light and dark mode, viewing notifications and settings)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "2.29.2": {
    "Features": [],
    "Bug Fixes": [
      "fixed primary and billing contacts not rendering, billing contact is now automatically created when the deal is won using the information that was filled out by the client)"
    ],
    "Style": [],
    "Refactors": []
  },
  "2.29.1": {
    "Features": [],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": [
      "new products added Expedited Launch, Expedited Creative Launch, Expedited Website Launch)"
    ]
  },
  "2.29.0": {
    "Features": [
      "**tags:** new custom input created for tagging users using the @ symbol)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "2.28.0": {
    "Features": [
      "**NCO:** role assignments, tasks and dependencies now rendering and editable for each deal in the nco)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "2.27.0": {
    "Features": [
      "**nco:** more editing updates for NCO grid and optimized sorting, filtering and resetting)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "2.26.0": {
    "Features": [
      "**NCO:** nco board development begins. Inline editing of Nco Status, Est. Launch Date and Dependencies Due Date currently implemented)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "2.25.0": {
    "Features": [
      "start of notifications development)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "2.24.0": {
    "Features": [
      "**accounting:** organization and deal information added to accounting drawer)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "2.23.0": {
    "Features": [
      "**accounting:** single updates and mass updates for the invoice status is now available, clicking on the deal name in the grid will open the invoice details for that deal)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "2.22.0": {
    "Features": [
      "**boards:** new accounting board added with minimal features, will be updating filters and specific cell editing, clicking the deal will navigate to review, clicking the pandadoc icon will display the current contract attached to the deal and allow for downloading.)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "2.21.0": {
    "Features": [
      "validation for strategy added, still needs more fields and small revisions)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "2.20.1": {
    "Features": [],
    "Bug Fixes": [
      "when navigating to campaigns using the campaign icon, new campaigns will now be created and successfully rendered without error)"
    ],
    "Style": [],
    "Refactors": []
  },
  "2.20.0": {
    "Features": [
      "functionality for adding and removing campaigns, as well as adding adsets to social campaigns)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "2.19.0": {
    "Features": [
      "strategy campaigns are now creating upon accessing them within the product list, they can be edited)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "2.18.0": {
    "Features": [
      "campaigns now rendering using the campaign icon on each service selected for the strategy)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "2.17.0": {
    "Features": [
      "campaigns are now accessibly through the campaign icon rendered on each product in the strategy)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "2.16.0": {
    "Features": [],
    "Bug Fixes": [],
    "Style": [
      "updated strategy product prices to change color and value when the payer is assigned as client)"
    ],
    "Refactors": []
  },
  "2.15.0": {
    "Features": [
      "strategy is efficiently being created for each deal when the strategy tab is accessed. Updates stay synced with organization details and deal details, as well as the addition or removal of products. When deal is deleted the strategy is deleted as well.)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "2.14.5": {
    "Features": [],
    "Bug Fixes": [
      "strategy line items now adding and removing properly based on the deals contract)"
    ],
    "Style": [],
    "Refactors": []
  },
  "2.14.4": {
    "Features": [],
    "Bug Fixes": [],
    "Style": [
      "added color change for billed price when the payer has been set to Client inside the contract)"
    ],
    "Refactors": []
  },
  "2.14.3": {
    "Features": [],
    "Bug Fixes": [
      "optimized contract totals, added time column for timeline and simplified rendering details)"
    ],
    "Style": [],
    "Refactors": []
  },
  "2.14.2": {
    "Features": [],
    "Bug Fixes": [],
    "Style": [
      "updated views for approvers in the review deal tab in deals details.)"
    ],
    "Refactors": []
  },
  "2.14.1": {
    "Features": [],
    "Bug Fixes": [
      "fixed render of monthly total for msa rmsa when dates are the same and or dates are different)"
    ],
    "Style": [],
    "Refactors": []
  },
  "2.14.0": {
    "Features": [
      "deal type change will now trigger a new approval process each time, all contracts will trigger a request for approval)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "2.13.0": {
    "Features": [
      "admin approvals now working with individual approval elements)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "2.12.0": {
    "Features": [
      "approvals now triggered for all deals)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "2.11.0": {
    "Features": [
      "approval process in place, still need to revise admin privileges and custom approvers)"
    ],
    "Bug Fixes": [],
    "Style": [
      "updated approver background for light theme in review deal component)"
    ],
    "Refactors": []
  },
  "2.10.1": {
    "Features": [],
    "Bug Fixes": [
      "fixed missing data from edit dialog for deals in deals grid)"
    ],
    "Style": [],
    "Refactors": []
  },
  "2.10.0": {
    "Features": [
      "new timeline for approvals is now inside the deal details page, this will currently display different actions taken during the approval process and will also display the initial creation of a deal once a deal is created after this feature)"
    ],
    "Bug Fixes": [],
    "Style": [
      "added deal status to deals grid)"
    ],
    "Refactors": []
  },
  "2.9.0": {
    "Features": [
      "**approvals:** approvals board now renders approvers who are directly assigned to the deal as well as their current status of approval)",
      "**CSA Deals:** number of payments will default to 1 when the contract total is less than or equal to 2500. When the total is greater than 2500 the default will be set to 2 and the option to change the number of payments will become available)",
      "rejecting and resolving deals in approvals working correctly and updating the page after interaction approvals page now contains the assigned approvers with their status as a badge displayed)",
      "**release:** changelog updated and version release 2.6.1)",
      "**release:** changelog updates and version release)",
      "**release:** changelog updates and version release 2.6.0)",
      "strategy products populate based on selection, unique fields are generated for each product whether it is two of the same or all different)"
    ],
    "Bug Fixes": [
      "manual changelog adjustments)",
      "**strategy:** fixed edits and rendering of selected products inside strategy)"
    ],
    "Style": [
      "layout change in review deal page for new approval process)"
    ],
    "Refactors": []
  },
  "2.8.0": {
    "Features": [
      "**CSA Deals:** number of payments will default to 1 when the contract total is less than or equal to 2500. When the total is greater than 2500 the default will be set to 2 and the option to change the number of payments will become available)",
      "**release:** changelog updates and version release)",
      "strategy products populate based on selection, unique fields are generated for each product whether it is two of the same or all different)"
    ],
    "Bug Fixes": [
      "manual changelog adjustments)",
      "**strategy:** fixed edits and rendering of selected products inside strategy)"
    ],
    "Style": [
      "layout change in review deal page for new approval process)",
      "layout change in review deal page for new approval process)"
    ],
    "Refactors": []
  },
  "2.7.0": {
    "Features": [
      "strategy products populate based on selection, unique fields are generated for each product whether it is two of the same or all different)"
    ],
    "Bug Fixes": [
      "**strategy:** fixed edits and rendering of selected products inside strategy)"
    ],
    "Style": [
      "layout change in review deal page for new approval process)"
    ],
    "Refactors": []
  },
  "2.6.4": {
    "Features": [],
    "Bug Fixes": [],
    "Style": [
      "**release:** changelog updates and version release)"
    ],
    "Refactors": []
  },
  "2.6.3": {
    "Features": [],
    "Bug Fixes": [],
    "Style": [
      "**release:** changelog updates and version release)"
    ],
    "Refactors": []
  },
  "2.6.2": {
    "Features": [],
    "Bug Fixes": [],
    "Style": [
      "**release:** changelog updated and version release 2.6.1)"
    ],
    "Refactors": []
  },
  "2.6.1": {
    "Features": [],
    "Bug Fixes": [
      "**release:** changelog updates and version release 2.6.0)",
      "**strategy:** fixed edits and rendering of selected products inside strategy)"
    ],
    "Style": [],
    "Refactors": []
  },
  "2.6.0": {
    "Features": [
      "**CSA Deals:** number of payments will default to 1 when the contract total is less than or equal to 2500. When the total is greater than 2500 the default will be set to 2 and the option to change the number of payments will become available)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "2.5.0": {
    "Features": [
      "**approvals update:** the authorized approvers are now rendered in the row with the deal that needs approval, use the different sorting buttons to view all approvals ie. completed or open, as well as deals you have approved, the my approvals filter will also filter deals that require your approval in the near future. The initial filter is set to show only open approvals)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "2.4.1": {
    "Features": [],
    "Bug Fixes": [],
    "Style": [
      "**Grid Updates:** reset buttons are more interactive and will enable and disable based on whether filters, sorting or column visibility has been changed)"
    ],
    "Refactors": []
  },
  "2.4.0": {
    "Features": [
      "approval board now available for keeping track of when deal approvals are requested and approved)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "2.3.0": {
    "Features": [
      "sorting and column visibility will now be saved on change in organizations, deals and contacts. Use the reset buttons to clear the visibility and sorting back to default)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "2.2.6": {
    "Features": [],
    "Bug Fixes": [
      "when creating a new organization from the create deal screen, the organization will now save correctly and be added as the selected organization for the new deal. All deals that have been created should now filter correctly using the search bar or other filters)"
    ],
    "Style": [],
    "Refactors": []
  },
  "2.2.5": {
    "Features": [],
    "Bug Fixes": [
      "**update:** fixed scrolling issue in invoices for deals and statuses should remain more synchronized with deals and their related contracts)"
    ],
    "Style": [],
    "Refactors": []
  },
  "2.2.4": {
    "Features": [],
    "Bug Fixes": [
      "fixed deal status changing when loading into contract, discovery fields correctly identifying when social profiles are assigned by entering the website into organization's website)"
    ],
    "Style": [],
    "Refactors": []
  },
  "2.2.3": {
    "Features": [],
    "Bug Fixes": [
      "deals kanban available again clicking the icon in the top left of the deals grid view)"
    ],
    "Style": [],
    "Refactors": []
  },
  "2.2.2": {
    "Features": [],
    "Bug Fixes": [],
    "Style": [
      "added tooltip for deals details header on status and changed status color to background for easier viewing)"
    ],
    "Refactors": []
  },
  "2.2.1": {
    "Features": [],
    "Bug Fixes": [
      "**update:** strategy tab will be available in the next version of grobotic)"
    ],
    "Style": [],
    "Refactors": []
  },
  "2.2.0": {
    "Features": [
      "**navigation:** remove top navbar and consolidate to left navigation)",
      "the strategy tab is now available and has a notes section for communication throughout the onboarding process)",
      "**update:** added new vertical mortgage to vertical list, fixed loading bug with notes rich text field)"
    ],
    "Bug Fixes": [
      "**navigation:** The top navbar has been removed and the navigation is now consolidated to the left. The theme toggle was replaced with icons to toggle the theme upon click. The create menu, theme toggle, user image and patch notes are located in the bottom of left of the navigationbar",
      "fixed approval button not appearing when request was made)"
    ],
    "Style": [],
    "Refactors": []
  },
  "1.25.5": {
    "Features": [],
    "Bug Fixes": [],
    "Style": [
      "aligned menu items and fix: fixed deal white screen for string function)"
    ],
    "Refactors": []
  },
  "1.25.4": {
    "Features": [],
    "Bug Fixes": [],
    "Style": [
      "replaces create contract button with request approval when approval is needed)"
    ],
    "Refactors": []
  },
  "1.25.3": {
    "Features": [],
    "Bug Fixes": [
      "fixed white screen when deal does not contain products or dates)"
    ],
    "Style": [],
    "Refactors": []
  },
  "1.25.2": {
    "Features": [],
    "Bug Fixes": [],
    "Style": [
      "create menu, user photo, and toggle theme moved to left navigation bar)",
      "new style for toolbar and appbar in grobotic)"
    ],
    "Refactors": []
  },
  "1.25.1": {
    "Features": [],
    "Bug Fixes": [],
    "Style": [
      "scrollbars now appear for content instead of the whole page in details pages)"
    ],
    "Refactors": []
  },
  "1.25.0": {
    "Features": [
      "strategy tab updated and added to every deal)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "1.24.7": {
    "Features": [],
    "Bug Fixes": [],
    "Style": [
      "added contracted by column to deals)"
    ],
    "Refactors": []
  },
  "1.24.6": {
    "Features": [],
    "Bug Fixes": [],
    "Style": [
      "revised create deal popup for more efficient styling)"
    ],
    "Refactors": []
  },
  "1.24.5": {
    "Features": [],
    "Bug Fixes": [
      "fixed vertical type error for deals)",
      "monthly totals should now be adding up correctly)"
    ],
    "Style": [],
    "Refactors": []
  },
  "1.24.4": {
    "Features": [],
    "Bug Fixes": [
      "fixed calculations for totals inside created deals and during creation)"
    ],
    "Style": [],
    "Refactors": []
  },
  "1.24.3": {
    "Features": [],
    "Bug Fixes": [],
    "Style": [
      "grid search bar filter count added)"
    ],
    "Refactors": []
  },
  "1.24.2": {
    "Features": [],
    "Bug Fixes": [],
    "Style": [
      "small border around filter menu and aligned contents)"
    ],
    "Refactors": []
  },
  "1.24.1": {
    "Features": [],
    "Bug Fixes": [
      "added does not contain to the filters list)"
    ],
    "Style": [],
    "Refactors": []
  },
  "1.24.0": {
    "Features": [
      "filters no longer clear on refresh or navigation to another page, the clear filters button will clear the current filters on the active grid)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "1.23.0": {
    "Features": [
      "strategy tab added with campaign selection for auto filling values in select fields)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "1.22.0": {
    "Features": [
      "Insertion orders now have a quantity column that will autofill based on the range of dates input into the contract. The contract in pandadoc will accurately display the total for the insertion order whether products are separated by month or set with a range of dates)"
    ],
    "Bug Fixes": [
      "key error fixes and interactive components)"
    ],
    "Style": [],
    "Refactors": []
  },
  "1.21.0": {
    "Features": [
      "IO types now display the correct total and set the correct total for contracts and rendering)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "1.20.2": {
    "Features": [],
    "Bug Fixes": [
      "fix payment schedule data in contract details for PandaDoc section)"
    ],
    "Style": [],
    "Refactors": []
  },
  "1.20.1": {
    "Features": [],
    "Bug Fixes": [
      "fixed bug with request approval button and added product: Website - Add On to products list)"
    ],
    "Style": [],
    "Refactors": []
  },
  "1.20.0": {
    "Features": [
      "deal status for month to month 30 day notice and active is now available)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "1.19.1": {
    "Features": [],
    "Bug Fixes": [],
    "Style": [
      "organization images added to deals grid)"
    ],
    "Refactors": []
  },
  "1.19.0": {
    "Features": [
      "deals will dynamically update based on the deal type selected or the signature checkbox state even after the deal has been created.)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "1.18.2": {
    "Features": [],
    "Bug Fixes": [
      "deal and organization filters are now working correctly and can be used together ie. filter by status and also type)"
    ],
    "Style": [],
    "Refactors": []
  },
  "1.18.1": {
    "Features": [],
    "Bug Fixes": [
      "when changing deal type the code will correctly determine the stage and status to set and will also add or remove the approval process)"
    ],
    "Style": [],
    "Refactors": []
  },
  "1.18.0": {
    "Features": [
      "deal type can now be edited from all locations in the app)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "1.17.0": {
    "Features": [
      "if organization does not have a vertical assigned, can update org's vertical from create deal screen)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "1.16.3": {
    "Features": [],
    "Bug Fixes": [],
    "Style": [
      "removed lines in details pages and aligned fields more efficiently)"
    ],
    "Refactors": []
  },
  "1.16.2": {
    "Features": [],
    "Bug Fixes": [
      "product prices are correctly auto filling the price column)"
    ],
    "Style": [],
    "Refactors": []
  },
  "1.16.1": {
    "Features": [],
    "Bug Fixes": [
      "added validation to organizations for vertical)"
    ],
    "Style": [],
    "Refactors": []
  },
  "1.16.0": {
    "Features": [
      "notes section added to organizations)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "1.15.3": {
    "Features": [],
    "Bug Fixes": [
      "_dev data_ fixed timeline for webhooks)"
    ],
    "Style": [],
    "Refactors": []
  },
  "1.15.2": {
    "Features": [],
    "Bug Fixes": [
      "deal owner now properly assigns to deal when creating contract directly through the create deal screen)"
    ],
    "Style": [],
    "Refactors": []
  },
  "1.15.1": {
    "Features": [],
    "Bug Fixes": [
      "fixed prepared by not showing up the correct name, and fixed needing to refresh deals grid after creating new deal)"
    ],
    "Style": [],
    "Refactors": []
  },
  "1.15.0": {
    "Features": [
      "invoicing for CSA now properly calculates the separate payments based on the number of payments selected)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "1.14.0": {
    "Features": [
      "contact photos and emails are now displayed when selecting contacts while creating organizations)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "1.13.0": {
    "Features": [
      "deals and contract now show contracted by organization)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "1.12.0": {
    "Features": [
      "deals kanban now contains links to deal by clicking on deal name)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "1.11.7": {
    "Features": [],
    "Bug Fixes": [],
    "Style": [
      "adjusted review deal page for responsiveness to smaller screens)"
    ],
    "Refactors": []
  },
  "1.11.6": {
    "Features": [],
    "Bug Fixes": [],
    "Style": [
      "added smaller loader for details pages)"
    ],
    "Refactors": []
  },
  "1.11.5": {
    "Features": [],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": [
      "further improved performance with optimized queries)"
    ]
  },
  "1.11.4": {
    "Features": [],
    "Bug Fixes": [
      "approval process should update now if hitting back in create deal to change the deal type)",
      "contracted by is now displayed in the contract for each deal when editing)"
    ],
    "Style": [],
    "Refactors": [
      "improve fetching performance by optimizing data fetching and query handling)"
    ]
  },
  "1.11.3": {
    "Features": [],
    "Bug Fixes": [
      "approval process should update now if hitting back in create deal to change the deal type)"
    ],
    "Style": [],
    "Refactors": []
  },
  "1.11.2": {
    "Features": [],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": [
      "improve fetching performance by optimizing data fetching and query handling)"
    ]
  },
  "1.11.1": {
    "Features": [],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": [
      "**api:** optimize data fetching and query handling)"
    ]
  },
  "1.11.0": {
    "Features": [],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "1.10.0": {
    "Features": [],
    "Bug Fixes": [],
    "Style": [
      "can access rich text field in light and dark mode)",
      "formatted reviewdeal descriptions with rich text)"
    ],
    "Refactors": []
  },
  "1.9.0": {
    "Features": [],
    "Bug Fixes": [],
    "Style": [
      "can access rich text field in light and dark mode)"
    ],
    "Refactors": []
  },
  "1.8.2": {
    "Features": [],
    "Bug Fixes": [],
    "Style": [
      "added description to review deal rendered products)"
    ],
    "Refactors": []
  },
  "1.8.1": {
    "Features": [],
    "Bug Fixes": [],
    "Style": [
      "new broadcast message for deal won notifications)"
    ],
    "Refactors": []
  },
  "1.8.0": {
    "Features": [
      "notifications for deal won)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "1.7.1": {
    "Features": [],
    "Bug Fixes": [
      "organization names can now be edited)"
    ],
    "Style": [],
    "Refactors": []
  },
  "1.7.0": {
    "Features": [
      "new tab in deals for invoicing to display cost per month)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "1.6.2": {
    "Features": [],
    "Bug Fixes": [
      "can create insertion orders without approval and removed approval tooltip when deal type is IO)",
      "csa contracts now correctly using the number of payments to calculate payment schedule used for contract)"
    ],
    "Style": [],
    "Refactors": []
  },
  "1.6.1": {
    "Features": [],
    "Bug Fixes": [
      "link to pandadoc contract now available inside deal via pandadoc icon)"
    ],
    "Style": [],
    "Refactors": []
  },
  "1.6.0": {
    "Features": [
      "updating websites will now not only update the website, but add the current organizations active social profiles and cms used automatically)"
    ],
    "Bug Fixes": [
      "active days removed from contract template)",
      "new format for website urls)",
      "no longer will get white screen when editing organizations that do not contain a vertical)"
    ],
    "Style": [],
    "Refactors": []
  },
  "1.5.0": {
    "Features": [
      "ctrl+f or cmd+f to focus grid toolbar quick filter search component)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "1.4.6": {
    "Features": [],
    "Bug Fixes": [
      "fixed websites not upating when editing organizations information)"
    ],
    "Style": [],
    "Refactors": []
  },
  "1.4.5": {
    "Features": [],
    "Bug Fixes": [
      "payer and accountUsed will now correctly stay as the values selected)"
    ],
    "Style": [],
    "Refactors": []
  },
  "1.4.4": {
    "Features": [],
    "Bug Fixes": [
      "added version control automation to changelog and display)"
    ],
    "Style": [],
    "Refactors": []
  },
  "1.4.3": {
    "Features": [],
    "Bug Fixes": [
      "youtube advertising now enables payer and account fields upon selection)"
    ],
    "Style": [],
    "Refactors": []
  },
  "1.4.2": {
    "Features": [],
    "Bug Fixes": [
      "broadcast message displayed for information)"
    ],
    "Style": [],
    "Refactors": []
  },
  "1.4.1": {
    "Features": [],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  }
};
