import React, { useState, useEffect } from 'react'
import { Select, MenuItem, Typography, useTheme, Chip } from '@mui/material'

const ChangeBoardView = ({ context, onChangeView, selectedView, setSelectedView }) => {
  const theme = useTheme()

  // Initialize the selectedView state from localStorage or default to 'invoice'
  // Update localStorage and invoke onChangeView when the view changes
  const handleChange = event => {
    const newView = event.target.value // Ensure newView is a string
    console.log('View selected:', newView)

    if (typeof newView !== 'string') {
      console.error('Invalid view value:', newView)
      return
    }

    setSelectedView(newView)
    // localStorage.setItem(`${context}SelectedView`, JSON.stringify(newView))
    onChangeView(newView)
  }

  const options = [
    { label: 'NCO', value: 'nco', color: 'rgb(176, 220, 81)' },
    { label: 'Creative', value: 'creative', color: 'rgb(167, 103, 253)' },
    { label: 'SEO', value: 'seo', color: 'rgb(200, 55, 226)' },
    { label: 'Website', value: 'website', color: 'rgb(102, 69, 169)' },
    { label: 'Monthly Tasks', value: 'mtb', color: 'rgb(22, 138, 239)' },
  ]

  return (
    <Select
      value={selectedView ?? 'onboarding'}
      onChange={handleChange}
      sx={{
        minWidth: 200,
        maxWidth: 200,
        height: 40,
        color: theme.palette.text.primary,
        bgcolor: theme.palette.background.paper,
        borderRadius: 2,
        '& .MuiSelect-select': { cursor: 'pointer' }, // Prevent I-beam cursor
      }}
    >
      {options.map(option => (
        <MenuItem key={option.value} value={option.value}>
          <Chip
            size='small'
            style={{
              backgroundColor: option.color,
              width: 14,
              height: 14,
              borderRadius: '50%',
              marginRight: 8,
            }}
          />
          <Typography variant='subtitle1'>{option.label}</Typography>
        </MenuItem>
      ))}
    </Select>
  )
}

export default ChangeBoardView

// import React, { useState, useEffect } from 'react'
// import { Box, ToggleButton, ToggleButtonGroup, Typography, useTheme } from '@mui/material'

// const ChangeBoardView = ({ context, onChangeView, selectedView, setSelectedView }) => {
//   const theme = useTheme()

//   // Load initial view from localStorage or default to 'nco'
//   useEffect(() => {
//     const storedView = localStorage.getItem(`${context}SelectedView`)
//     if (storedView) {
//       setSelectedView(JSON.parse(storedView))
//     }
//   }, [setSelectedView])

//   const handleChange = (_event, newView) => {
//     if (newView !== null) {
//       console.log('View selected:', newView)
//       setSelectedView(newView)
//       localStorage.setItem(`${context}SelectedView`, JSON.stringify(newView))
//       onChangeView(newView)
//     }
//   }

//   const options = [
//     { label: 'NCO', value: 'nco', color: 'rgb(41, 235, 76)' },
//     { label: 'Creative', value: 'creative', color: 'rgb(167, 103, 253)' },
//     { label: 'SEO', value: 'seo', color: 'rgb(200, 55, 226)' },
//     { label: 'Website', value: 'website', color: 'rgb(102, 69, 169)' },
//     { label: 'Monthly Tasks', value: 'mtb', color: 'rgb(22, 138, 239)' },
//   ]

//   return (
//     <ToggleButtonGroup
//       value={selectedView ?? 'nco'}
//       exclusive
//       onChange={handleChange}
//       aria-label='Change Board View'
//       sx={{
//         display: 'flex',
//         borderRadius: '20px',
//         height: 55,
//         bgcolor: theme.palette.background.paper,
//         '& .MuiToggleButton-root': {
//           textTransform: 'none',
//           padding: '6px 16px',
//           fontSize: '0.875rem',
//           fontWeight: 500,
//           color: theme.palette.text.primary,
//           transition: 'all 0.2s ease-in-out',
//           '&.Mui-selected': {
//             bgcolor: theme.palette.primary.main,
//             color: theme.palette.common.white,
//           },
//         },
//       }}
//     >
//       {options.map(option => (
//         <ToggleButton
//           sx={{
//             backgroundColor: option.color,
//             width: 75,
//             height: 55,
//           }}
//           key={option.value}
//           value={option.value}
//         >
//           <Typography variant='subtitle2'>{option.label}</Typography>
//         </ToggleButton>
//       ))}
//     </ToggleButtonGroup>
//   )
// }

// export default ChangeBoardView
