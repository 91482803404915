import React from 'react'
import { Badge, Box, IconButton, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material'
import FilterListIcon from '@mui/icons-material/FilterList' // Custom Filters Icon

const slotProps = (theme, badgeColor) => ({
  sx: {
    borderRadius: 2,
    maxHeight: '100%',
    mt: 2,
    mb: 2,
    '&:hover': { backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.main : 'rgba(0,0,0,0.1)' },
    color: theme.palette.text.primary,
    '& .MuiBadge-badge': {
      backgroundColor: badgeColor ?? 'rgb(205, 254, 194)', // Your custom background color
      color: theme.palette.mode === 'dark' ? 'rgba(0,0,0,1)' : 'rgba(0,0,0,1)', // Change the text color if needed
      fontSize: '14px', // Optional: adjust the font size
      width: '20px', // Adjust the width/height to control the size of the badge
      height: '20px',
    },
  },
})

const OpenFiltersMenu = ({ onOpenFilters, filterModel, badgeColor }) => {
  const theme = useTheme()
  const isExtraLargeScreen = useMediaQuery(theme.breakpoints.up('xl'))
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'))
  const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'))
  const isSmallScreen = useMediaQuery(theme.breakpoints.up('sm'))

  return (
    <Box sx={{ ...slotProps(theme, badgeColor), display: 'flex', alignItems: 'center', gap: 2 }}>
      <Badge
        badgeContent={
          <>
            {filterModel && filterModel.items && Array.isArray(filterModel.items) && filterModel.items.length > 0 ? (
              <Typography
                sx={{
                  fontSize: 12,
                  bgcolor: badgeColor,
                  p: 0.5,
                  height: '20px',
                  width: '20px',
                  borderRadius: '50%',
                  display: 'flex', // Flexbox centering
                  alignItems: 'center', // Vertically center
                  justifyContent: 'center', // Horizontally center
                  color: theme.palette.mode === 'dark' ? 'rgba(0,0,0,1)' : 'rgba(0,0,0,1)',
                  fontWeight: 'bold',
                }}
              >
                {filterModel && filterModel.items && Array.isArray(filterModel.items) ? filterModel.items.length : null}
              </Typography>
            ) : null}
          </>
        }
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Tooltip title='Filters'>
          <IconButton
            sx={{
              height: 30,
              width: 30,
              '&:hover': {
                backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.main : 'rgba(0,0,0,0.1)',
              },
            }}
            onClick={onOpenFilters}
          >
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      </Badge>
    </Box>
  )
}

export default OpenFiltersMenu
