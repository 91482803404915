import React, { useState, useEffect } from 'react'
import { Box, CircularProgress, IconButton, useTheme } from '@mui/material'
import SendIcon from '@mui/icons-material/Send'
import StarterKit from '@tiptap/starter-kit'
import BulletList from '@tiptap/extension-bullet-list'
import ListItem from '@tiptap/extension-list-item'
import OrderedList from '@tiptap/extension-ordered-list'
import CharacterCount from '@tiptap/extension-character-count'
import TextStyle from '@tiptap/extension-text-style'
import Color from '@tiptap/extension-color'
import Highlight from '@tiptap/extension-highlight'
import './style.css'
import {
  MenuButtonBold,
  MenuButtonItalic,
  MenuControlsContainer,
  MenuDivider,
  MenuSelectHeading,
  MenuButtonBulletedList,
  MenuButtonOrderedList,
  RichTextEditor,
} from 'mui-tiptap'

const editorExtensions = [
  StarterKit.configure({
    bulletList: false,
    orderedList: false,
    listItem: false,
  }),
  BulletList,
  ListItem,
  OrderedList,
  TextStyle,
  Color,
  Highlight,
  CharacterCount.configure({
    limit: 5000,
  }),
]

const TaskRichText = ({ content, onContentChange }) => {
  const [characterCount, setCharacterCount] = useState(0)
  const [editorInstance, setEditorInstance] = useState(null)
  const theme = useTheme()
  const hoverBgColor = theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.3)' : 'rgba(0,0,0,0.3)'

  useEffect(() => {
    if (content === '') {
      setCharacterCount(0)
    }
  }, [content])

  const handleClear = () => {
    if (editorInstance) {
      editorInstance.commands.clearContent() // Clear editor content when needed
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        position: 'relative',
        '& .MuiCollapse-root': {
          margin: 0,
        },
        '& .MuiMenuBar-root': {
          margin: 0,
        },
      }}
    >
      <RichTextEditor
        className='rich-text full-width-toolbar'
        extensions={editorExtensions}
        content={content}
        onCreate={({ editor }) => {
          setEditorInstance(editor) // Save the editor instance
        }}
        onUpdate={({ editor }) => {
          setCharacterCount(editor.storage.characterCount.characters())
          onContentChange(editor.getHTML())
        }}
        renderControls={() => (
          <MenuControlsContainer
            onClick={e => e.stopPropagation()}
            style={{ width: '100%', justifyContent: 'space-between', height: '100%' }} // Ensure full width and spacing
          >
            <MenuSelectHeading style={{ flexGrow: 1 }} />
            <MenuDivider />
            <MenuButtonBold />
            <MenuButtonItalic />
            <MenuDivider />
            <MenuButtonBulletedList />
            <MenuButtonOrderedList />
          </MenuControlsContainer>
        )}
        editorProps={{
          attributes: {
            style: 'height: 100%; min-height: 76px; overflow-y: auto; text-align: left;',
          },
        }}
      />
    </Box>
  )
}

export default TaskRichText
