import { Box, Card, Typography, useTheme } from '@mui/material'
import TaskReplyInput from './TaskReplyInput'
import { useFetchConversationThread } from '../../../api/slack/useFetchConversationThreads'
import Loading from '../../Loading/Loading'
import { useSlackUserProfile } from '../../../api/slack/useSlackUserProfile'
import UserImageComponent from '../../Users/UserImageComponent'
import { useUsers } from '../../../api/aws/useUsers'
import SlackLogo from '../../../images/slack_logo.png'

const TaskMessages = ({ task }) => {
  const theme = useTheme()
  const typographyColor = theme.palette.type === 'dark' ? 'white' : 'black'
  const conversationId = task?.project?.slackChannel?.id
  const thread_ts = task?.taskThread?.ts
  const { users, isUsersLoading, isUsersError } = useUsers(true, ['taskUsers', 'users'])
  const {
    data: threadMessages,
    isLoading: threadMessagesLoading,
    isError: threadMessagesError,
  } = useFetchConversationThread(conversationId, thread_ts)
  const {
    data: slackUserProfile,
    isLoading: slackUserProfileLoading,
    isError: slackUserProfileError,
  } = useSlackUserProfile()
  if (threadMessagesLoading || slackUserProfileLoading) {
    return <Loading />
  }
  if (threadMessagesError || slackUserProfileError) {
    return <div>Conversation error...</div>
  }
  const getUserImageUrl = userId => {
    const matchedUser = users.find(user => user.id === userId)
    return matchedUser ? matchedUser.profile.image_512 : null
  }
  // Assuming the first message is the parent thread based on your data structure
  const parentMessage = threadMessages?.[0]
  const replies = threadMessages?.slice(1) // Exclude the first message
  console.log('REPLIES IN TASK MESSAGES: ', replies)

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
      >
        {/* <Button onClick={handleSendNotification}>Click</Button> */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img width={60} height={60} src={SlackLogo} />
        </Box>
      </Box>
      <Box
        sx={{
          padding: '20px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center', // Center the content
          width: '100%',
        }}
      >
        {/* Display Parent Thread */}
        <Box sx={{ marginBottom: '16px', padding: '8px', width: '95%' }}>
          <Typography sx={{ color: 'lightgreen', fontSize: '16px', fontWeight: 'bold' }}>
            {parentMessage?.text}
          </Typography>
          {/* Display Replies */}

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center', // Center the replies
            }}
          >
            <Box
              sx={{
                width: '95%',
                marginTop: '25px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <TaskReplyInput task={task} />
            </Box>
            {replies?.map((reply, index) => (
              <Card
                key={index}
                sx={{
                  marginBottom: '8px',
                  padding: '8px',
                  borderRadius: '25px',
                  width: '95%', // Adjust width as needed
                }}
              >
                {reply.user ? <UserImageComponent imageUrl={getUserImageUrl(reply.user)} /> : null}
                <Typography sx={{ color: 'lightblue', textAlign: 'center' }}>{reply.text}</Typography>
                {/* {reply.files?.map((file, fileIndex) => (
                  <SlackImage key={fileIndex} imageUrl={file.thumb_360} alt={file.name} />
                ))} */}
              </Card>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default TaskMessages
