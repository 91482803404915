import React from 'react'
import useGooglePicker from '../../api/google/useGooglePicker.ts'
import useGooglePickerById from '../../api/google/useGooglePickerById.ts'
import GoogleViewer from './GoogleViewer.tsx'
import GoogleViewerById from './GoogleViewById.tsx'

const GooglePickerAPI = () => {
  return (
    <div>
      <GoogleViewer />
      <GoogleViewerById />
    </div>
  )
}
export default GooglePickerAPI
