import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  Collapse,
  IconButton,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import FolderIcon from '@mui/icons-material/Folder'
import EditIcon from '@mui/icons-material/Edit'
import DownloadIcon from '@mui/icons-material/Download'
import CloseIcon from '@mui/icons-material/Close'
import useGooglePickerById from '../../api/google/useGooglePickerById.ts'
import { useGoogleFilesByFolderId } from '../../api/google/useGoogleFilesByFolderId.ts'
import { useUser } from '../../api/aws/useUser.js'
import { useGoogleDownloadFile } from '../../api/google/useGoogleDownloadFile.ts'
import ShowPickerButton from '../ui/show-picker-button.js'

interface DriveFoldersTreeProps {
  creation_timestamp: string
  folderId: string
  folderName: string
  level?: number
}

const DriveFoldersTree: React.FC<DriveFoldersTreeProps> = ({ creation_timestamp, folderId, folderName, level = 0 }) => {
  const currentSlackUserId = localStorage.getItem('slack_user_id')
  const { user: currentUser, isUserLoading } = useUser(currentSlackUserId)
  const [isOpen, setIsOpen] = useState(false) // Controls folder expansion
  const { isLoaded, showPicker, selectedFile } = useGooglePickerById(
    currentUser?.googleRefreshToken,
    currentSlackUserId || ''
  )
  const { data: subfolders, isLoading } = useGoogleFilesByFolderId(folderId)
  const { mutate: downloadGoogleFile } = useGoogleDownloadFile()
  const [isFileViewerOpen, setIsFileViewerOpen] = useState(false)
  const folderColor = [folderName].includes('Deals')
    ? 'rgb(251, 193, 30)'
    : ['Creative Projects', 'Creative Tasks', 'Website Projects'].includes(folderName)
    ? 'rgb(165, 147, 255)'
    : ['Tasks'].includes(folderName)
    ? 'rgb(247, 63, 236)'
    : ''
  useEffect(() => {
    if (selectedFile) {
      setIsFileViewerOpen(true)
    }
  }, [selectedFile])

  const toggleOpen = () => {
    if (subfolders?.length > 0) {
      setIsOpen(!isOpen)
    }
  }

  const getViewerUrl = () => {
    if (!selectedFile) return ''
    if (selectedFile.mimeType.includes('application/vnd.google-apps')) {
      return `https://docs.google.com/document/d/${selectedFile.id}/preview`
    } else if (selectedFile.embedUrl) {
      return selectedFile.embedUrl
    } else {
      return selectedFile.url
    }
  }

  const openInGoogleDocs = () => {
    if (!selectedFile) return
    if (selectedFile.mimeType.includes('application/vnd.google-apps')) {
      window.open(`https://docs.google.com/document/d/${selectedFile.id}/edit`, '_blank')
    } else {
      window.open(selectedFile.url, '_blank')
    }
  }

  const handleCloseFilePreview = () => {
    setIsFileViewerOpen(false)
    showPicker(folderId) // Ensure the picker reopens
  }

  const handleDownloadGoogleFile = () => {
    if (selectedFile?.id) {
      downloadGoogleFile(selectedFile.id)
    }
  }

  return (
    <Box key={folderId} sx={{ ml: level * 2, display: 'flex', flexDirection: 'column' }}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          {/* <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              onClick={() => showPicker(folderId)}
              // disabled={!isLoaded}
              sx={{
                justifyContent: 'left',
                textTransform: 'none',
                bgcolor: 'transparent',
                maxWidth: 345,
                width: '100%',
                textAlign: 'center',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              <FolderIcon sx={{ mr: 1, color: folderColor }} />
              <Typography
                sx={{ textAlign: 'center', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
              >
                {folderName || 'No Folder'}
              </Typography>
            </Box>
          </Box> */}

          <ShowPickerButton folderName={folderName} folderId={folderId} showPicker={showPicker} isLoaded={isLoaded} />

          {subfolders?.map(sf => {
            console.log('sf?.name.includes(creation_timestamp)???', sf?.name.includes(creation_timestamp))
            return (
              <Box key={sf?.id}>
                {sf?.mimeType === 'application/vnd.google-apps.folder' && (
                  <Box key={sf.id}>
                    {sf?.name.includes(creation_timestamp) && (
                      <DriveFoldersTree
                        key={sf.id}
                        creation_timestamp={creation_timestamp}
                        folderId={sf.id}
                        folderName={sf?.name}
                        level={level + 1}
                      />
                    )}
                  </Box>
                )}
              </Box>
            )
          })}
        </>
      )}
      {/* </Collapse> */}

      {/* File Viewer Dialog */}
      {isFileViewerOpen && (
        <Dialog open={isFileViewerOpen} onClose={handleCloseFilePreview} maxWidth='xl' fullWidth>
          <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography
              sx={{
                color: 'rgb(92, 213, 251)',
                fontSize: 24,
              }}
            >
              {selectedFile?.name}
            </Typography>
            <Box>
              <IconButton onClick={openInGoogleDocs}>
                <EditIcon />
              </IconButton>
              <IconButton onClick={handleDownloadGoogleFile}>
                <DownloadIcon />
              </IconButton>
              <IconButton onClick={handleCloseFilePreview}>
                <CloseIcon
                  sx={{
                    ':hover': {
                      color: 'rgba(220, 20, 60, 0.8)',
                    },
                  }}
                />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent sx={{ padding: 0, height: 'calc(100vh - 120px)' }}>
            {selectedFile && (
              <iframe
                src={getViewerUrl()}
                width='100%'
                height='100%'
                style={{ border: 'none', minHeight: '80vh' }}
                title='Google Drive Viewer'
              />
            )}
          </DialogContent>
        </Dialog>
      )}
    </Box>
  )
}

export default DriveFoldersTree

/*
step 1:
website link
target launch
launch status accurate (awaiting kickoff)
assign PM MIA AM DMS SEO
contract is there
push to MCL
go through drawewr statuses next,

step 2:
email client requesting dependencies (list added in later)
add stuck to GA4 GTM

step 3:


Monday:

campaign creation tasks:
MI Campaign column triggers task when changed to Yes in monday
auto push tasks to team tasks for brian to start building
same with SEO
GSC Access marked confirms, SEO Form marked confirmed (when these are marked as confirmed, SEO Status confirmed will push tasks for SEO)

another PM task: handoff internal kickoff, compile notes doc
 */
