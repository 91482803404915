import { useMemo } from 'react'
import { calculateRangeForProducts, calculateTotalMonthsForProducts } from '../../utility/calculateTotalMonths'

const calculateTotalForRow = (row, months) => {
  if (row.payer === 'Client') {
    // For Client payers, return the billed price or gross profit multiplied by the quantity and months
    return parseFloat(row.billedPrice || row.grossProfit || 0) * parseFloat(row.quantity || 1) * months
  }
  // For other payers, return the normal price multiplied by the quantity and months
  return parseFloat(row.price || 0) * parseFloat(row.quantity || 1) * months
}

const calculateMediaSpend = (price, marginPercentage) => {
  if (!price || !marginPercentage) return 0
  const marginValue = parseFloat(price) * (parseFloat(marginPercentage) / 100)
  return (parseFloat(price) - marginValue).toFixed(2)
}

const csaTypes = ['CSA', 'RCSA']
const useTotals = (productRows, initialImplementationFee, type) => {
  const totals = useMemo(() => {
    let contractTotal = 0
    let grossProfit = 0
    let monthlyTotal = 0
    let ioTotal = 0
    let ioMonthlyTotal = 0
    let totalGrossProfit = 0
    // Get products with their respective total months
    const productsWithMonths = productRows ? calculateRangeForProducts(productRows) : []
    const { totalMonths } = calculateTotalMonthsForProducts(productsWithMonths)
    productsWithMonths.forEach(row => {
      const totalForRow = type === 'IO' ? calculateTotalForRow(row, row.months) : calculateTotalForRow(row, 1)
      const contractTotalRows = calculateTotalForRow(row, row.months)
      const mediaSpend = calculateMediaSpend(row.price, row.margin)

      // Accumulate gross profit and monthly total
      grossProfit += parseFloat(row.price) - parseFloat(mediaSpend)
      monthlyTotal += totalForRow // Monthly total is now per product
      contractTotal += contractTotalRows
      totalGrossProfit += parseFloat(row.grossProfit * totalMonths)
      // console.log('grossProfit', grossProfit)
      // console.log('monthlyTotal', monthlyTotal)
      // console.log('contractTotal', contractTotal)
      // console.log('totalGrossProfit', totalGrossProfit)
      // console.log('row.grossProfit', row.grossProfit)
    })

    // For IO-type deals, we accumulate the total from each product based on its individual months
    if (type === 'IO') {
      ioTotal = contractTotal
      ioMonthlyTotal = totalMonths ? ioTotal / totalMonths : 0
    }

    // Return totals based on the deal type
    return {
      grossProfit,
      implementationFee: parseFloat(initialImplementationFee),
      monthlyTotal: type === 'IO' ? ioMonthlyTotal : monthlyTotal,
      total: type === 'IO' ? ioTotal : csaTypes.includes(type) ? monthlyTotal : contractTotal, // Use ioTotal for IO deals, monthlyTotal for others
      totalGrossProfit: totalGrossProfit,
    }
  }, [productRows, initialImplementationFee, type])

  return totals
}

export default useTotals
