import { Progress } from 'antd'

export const statusFilters = [
  { key: 'dealPage-status', label: 'Prospect', value: 'Prospect', color: 'rgb(93, 79, 251)' },
  { key: 'dealPage-status', label: 'Onboarding', value: 'Onboarding', color: 'rgb(92, 179, 253)' },
  { key: 'dealPage-status', label: 'In-Progress', color: 'rgb(110, 156, 222)' },
  { key: 'dealPage-status', label: 'Active', value: 'Active', color: 'rgb(152, 214, 129)' },
  { key: 'dealPage-status', label: 'Active (SEO)', color: 'rgb(152, 98, 161)' },
  { key: 'dealPage-status', label: 'Active (Creative)', color: 'rgb(147, 111, 218)' },
  { key: 'dealPage-status', label: 'Active (Website)', color: 'rgb(102, 69, 169)' },
  { key: 'dealPage-status', label: 'Active (Past Due)', color: 'rgb(255, 131, 88)' },
  { key: 'dealPage-status', label: 'Active (LTP)', color: 'rgb(51, 158, 205)' },
  { key: 'dealPage-status', label: '30 day notice', value: '30 day notice', color: 'rgb(235, 77, 77)' },
  { key: 'dealPage-status', label: 'Cancelled', value: 'Cancelled', color: 'rgb(0,0,0)' },
  { key: 'dealPage-status', label: 'Inactive / Paused', color: 'rgb(255, 145, 145)' },
]
export const contractStatusFilters = [
  { key: 'dealPage-contractStatus', label: 'Active', value: 'Active', color: 'rgb(75, 185, 114)' },
  {
    key: 'dealPage-contractStatus',
    label: 'Approval Requested',
    value: 'Approval Requested',
    color: 'rgba(255, 148, 112, 1)',
  },
  { key: 'dealPage-contractStatus', label: 'Completed', value: 'Completed', color: 'rgb(80, 200, 120)' },
  {
    key: 'dealPage-contractStatus',
    label: 'Contract Approved',
    value: 'Contract Approved',
    color: 'rgb(255, 144, 69)',
  },
  { key: 'dealPage-contractStatus', label: 'Created', value: 'Created', color: 'rgb(112, 224, 213)' },
  {
    key: 'dealPage-contractStatus',
    label: 'Completed Internally',
    value: 'Completed Internally',
    color: 'rgba(22, 160, 133, 1)',
  },
  {
    key: 'dealPage-contractStatus',
    label: 'Month to Month',
    value: 'Month to Month',
    color: 'rgb(23, 127, 217)',
  },
  {
    key: 'dealPage-contractStatus',
    label: 'Needs Approval',
    value: 'Needs Approval',
    color: 'rgb(251, 193, 30)',
  },
  {
    key: 'dealPage-contractStatus',
    label: 'Sent For Approval',
    value: 'Sent For Approval',
    color: 'rgb(163, 96, 205)',
  },
  { key: 'dealPage-contractStatus', label: 'Saved', value: 'Saved', color: 'rgb(34, 121, 145)' },
  { key: 'dealPage-contractStatus', label: 'Sent', value: 'Sent', color: 'rgb(76, 68, 198)' },
  { key: 'dealPage-contractStatus', label: 'Set Status', value: 'Set Status', color: 'rgb(178, 190, 181)' },
  {
    key: 'dealPage-contractStatus',
    label: 'Strategy Approved',
    value: 'Strategy Approved',
    color: 'rgb(6, 255, 167)',
  },
  { key: 'dealPage-contractStatus', label: 'Viewed', value: 'Viewed', color: 'rgb(213, 102, 201)' },
]
export const dealStatusFilters = [
  {
    key: 'dealPage-dealStatus',
    label: 'Month To Month',
    value: 'Month To Month',
    color: 'rgb(22, 138, 239)',
  },
  {
    key: 'dealPage-dealStatus',
    label: 'Active',
    value: 'Active',
    color: 'rgb(80, 200, 120)',
  },
  {
    key: 'dealPage-dealStatus',
    label: 'Active (system)',
    value: 'Active (system)',
    color: 'rgb(40, 249, 144)',
  },
  {
    key: 'dealPage-dealStatus',
    label: 'In Progress',
    value: 'In Progress',
    color: 'rgb(253, 188, 100)',
  },
  {
    key: 'dealPage-dealStatus',
    label: '30 Day Notice',
    value: '30 Day Notice',
    color: 'rgb(255, 123, 208)',
  },
  { label: 'Cancelled', color: 'rgb(0,0,0)' },
  {
    key: 'dealPage-dealStatus',
    label: 'Expired',
    value: 'Expired',
    color: 'rgba(220, 20, 60, 0.8)',
  },
  {
    key: 'dealPage-dealStatus',
    label: 'Expired (system)',
    value: 'Expired (system)',
    color: 'rgb(241, 9, 207)',
  },
]

export const dealStageFilters = [
  { label: 'Discovery', value: 'Discovery', color: 'rgb(25, 117, 255)' },
  {
    label: 'Pending Approval',
    value: 'Pending Approval',
    color: 'rgba(241, 130, 141, 1)',
  },
  { label: 'Proposal', value: 'Proposal', color: 'rgba(178, 94, 178, 1)' },
  {
    label: 'Contract Created',
    value: 'Contract Created',
    color: 'rgb(11, 127, 171, 1)',
  },
  {
    label: 'Contract Sent',
    value: 'Contract Sent',
    color: 'rgb(217, 169, 102, 1)',
  },
  { label: 'Deal Won', value: 'Deal Won', color: 'rgba(80, 200, 120, 1)' },
  { label: 'Cold', value: 'Cold', color: 'rgb(168, 202, 255)' },
  { label: 'Lost', value: 'Purgatory', color: '#ffdc85' },
]
export const dealStageOptions = [
  { label: 'Discovery', value: 'Discovery', color: 'rgb(25, 117, 255)' },
  {
    label: 'Pending Approval',
    value: 'Pending Approval',
    color: 'rgba(241, 130, 141, 1)',
  },
  { label: 'Proposal', value: 'Proposal', color: 'rgba(178, 94, 178, 1)' },
  {
    label: 'Contract Created',
    value: 'Contract Created',
    color: 'rgb(11, 127, 171, 1)',
  },
  {
    label: 'Contract Sent',
    value: 'Contract Sent',
    color: 'rgb(217, 169, 102, 1)',
  },
  { label: 'Deal Won', value: 'Deal Won', color: 'rgba(80, 200, 120, 1)' },
  { label: 'Deal Lost', value: 'Deal Lost', color: '#ffb5ac' },
  { label: 'Cold', value: 'Cold', color: 'rgb(168, 202, 255)' },
  { label: 'Lost', value: 'Lost', color: '#ffdc85' },
]
export const ncoStatusFilters = [
  { label: 'Staging', value: 'Staging', color: 'rgb(238, 111, 141)' },
  { label: 'Onboarding', value: 'Onboarding', color: 'rgb(110, 193, 232)' },
  { label: 'Awaiting Kickoff', value: 'Awaiting Kickoff', color: 'rgb(147, 111, 218)' },
  { label: 'Awaiting Dependencies', value: 'Awaiting Dependencies', color: 'rgb(59, 152, 245)' },
  { label: 'Dependencies Received', value: 'Dependencies Received', color: 'rgb(245, 124, 79)' },
  { label: 'Handoff Call', value: 'Handoff Call', color: 'rgba(106, 65, 192, 1)' },
  { label: 'Launched', value: 'Launched', color: 'rgb(52, 211, 145)' },
]
export const creativeStatusFilters = [
  { label: 'Staging', value: 'Staging', color: 'rgb(238, 111, 141)' },
  { label: 'In Progress', value: 'In Progress', color: 'rgb(253, 188, 100)' },
  { label: 'Needs AM Review', value: 'Needs AM Review', color: 'rgb(255, 131, 88)' },
  { label: 'Needs Revision', value: 'Needs Revision', color: 'rgb(213, 197, 103)' },
  { label: 'Sent for Approval', value: 'Sent for Approval', color: 'rgb(147, 111, 218)' },
  { label: 'Ready to Launch', value: 'Ready to Launch', color: 'rgb(121, 175, 253)' },
  { label: 'Done', value: 'Done', color: 'rgb(53, 153, 112)' },
  { label: 'Blocked', value: 'Blocked', color: 'rgb(133, 214, 255)' },
  { label: 'On Hold', value: 'On Hold', color: 'rgb(238, 40, 99)' },
  { label: 'Needs Copy', value: 'Needs Copy', color: 'rgb(113, 214, 209)' },
  { label: 'Needs CD Review', value: 'Needs CD Review', color: 'rgb(51, 211, 145)' },
  { label: 'CD Approved', value: 'CD Approved', color: 'rgb(255, 68, 161)' },
  { label: 'N/A', value: 'N/A', color: 'rgb(121, 126, 147)' },
  { label: 'Need Creative Attention', value: 'Need Creative Attention', color: 'rgb(51, 158, 205)' },
  { label: 'New Project Review', value: 'New Project Review', color: 'rgb(78, 115, 167)' },
  { label: 'Needs PM Attention', value: 'Needs PM Attention', color: 'rgb(255, 213, 51)' },
  { label: 'With Freelancer', value: 'With Freelancer', color: 'rgb(205, 146, 130)' },
]
export const ncoStatusOptions = [
  { value: 'On Hold', label: 'On Hold' },
  { value: 'Onboarding', label: 'Onboarding' },
  { value: 'Awaiting Dependencies', label: 'Awaiting Dependencies' },
  { value: 'Dependencies Received', label: 'Dependencies Received' },
  { value: 'Handoff Call', label: 'Handoff Call' },
  { value: 'Launched', label: 'Launched' },
]
export const seoStatusOptions = [
  { label: 'Staging', value: 'Staging', color: 'rgb(238, 111, 141)' },
  { value: 'On Hold', label: 'On Hold', color: 'rgb(238, 40, 99)' },
  { label: 'Onboarding', value: 'Onboarding', color: 'rgb(110, 193, 232)' },
  { value: 'Stuck', label: 'Stuck', color: 'rgb(232, 105, 125)' },
  { value: 'No Onboarding Sheet', label: 'No Onboarding Sheet', color: 'rgb(255, 213, 51)' },
  { value: 'Paused', label: 'Paused', color: 'rgb(255, 145, 145)' },
  { value: 'Onboarding Completed', label: 'Onboarding Completed', color: 'rgb(52, 211, 145)' },
]
