import React, { useState, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import GroboticDataGrid from '../DataGrid/GroboticDataGrid.js'
import { useFilteredDeals } from '../../api/aws/useFilteredDeals.js'
import { seoTasks } from '../DataGrid/taskTemplates.js'
import { seoStatusOptions } from '../DataGrid/filterArrays.js'

const initialSortModel = [
  { field: 'dealStatus', sort: 'desc' },
  { field: 'organizations', sort: 'asc' },
]

const initialVisibilityState = {
  implementationFee: false,
  total: false,
  status: false,
  id: false,
  strategy: false,
  vertical: false,
  stage: false,
  contractId: false,
  type: false,
  startDate: false,
  endDate: false,
  isGoogleDisplay: false,
  isGoogleSearch: false,
  isLinkedIn: false,
  isMetaAdvertising: false,
  isMicrosoftAdvertising: false,
  isSimplifi: false,
  isSnapchat: false,
  isSpotify: false,
  isTikTok: false,
  isYoutube: false,
}

const SeoPage = () => {
  const { dealId } = useParams()
  const filters = {
    status: 'Completed',
    status: 'Completed Internally',
    type: 'MSA',
    productCategories: ['SEO'],
    excludedDealStatus: ['Expired', 'Expired (system)', 'Cancelled'],
  }
  const { filteredDeals, isFilteredDealsLoading, isFilteredDealsError } = useFilteredDeals(
    true,
    ['seoPage', 'deals'],
    filters
  )

  // DEPENDENCIES : Google Business Page
  return (
    <GroboticDataGrid
      dealId={dealId}
      filteredDeals={filteredDeals}
      isFilteredDealsLoading={isFilteredDealsLoading}
      route={'seo'}
      tasks={seoTasks}
      context={'seo'}
      filters={filters}
      initialVisibilityState={initialVisibilityState}
      initialSortModel={initialSortModel}
      filterOptions={seoStatusOptions}
    />
  )
}
export default SeoPage
