import React from 'react'
import { Box, Grid, Typography, useTheme } from '@mui/material'
import format from 'date-fns/format'
import { renderColorByString } from '../DataGrid/renderColorByString'
import DisplayChannels from './DisplayChannels.js'

function formatDate(timestamp) {
  if (!timestamp) return
  return format(new Date(timestamp), 'MM/dd/yyyy')
}
const BoardsGridHeader = ({ newDeal, organization }) => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        p: 3,
        backgroundColor: theme.palette.background.paper,
        borderRadius: '8px',
        boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Grid
        container
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          overflowY: 'hidden',
        }}
      >
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              variant='h5'
              sx={{
                fontWeight: 'bold',
                textAlign: 'left',
                color: theme.palette.text.primary,
              }}
            >
              {/* {`${newDeal?.name}-${formatDayjsDateTime(newDeal?.creation_timestamp)}` || 'no deal name'} */}
              {newDeal?.name || 'no deal name'}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
          {/* <Link to={`${rootUrl}/deals/${newDeal?.deal_uuid}`} style={{ textDecoration: 'none', color: 'inherit' }}>
            Deal Link
          </Link> */}
          <Box sx={{ display: 'flex', alignItems: 'center', height: '100%', gap: 2 }}>
            <Typography
              variant='h6'
              sx={{
                fontWeight: 'bold',
                textAlign: 'left',
                color: theme.palette.text.primary,
              }}
            >
              {'Channels'}
            </Typography>
            <DisplayChannels deal={newDeal} />
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={4} xl={4} sx={{ p: 1, pl: 1.5, pr: 1.5 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant='subtitle2' color='textSecondary' sx={{ textAlign: 'left' }}>
              Est Launch Date
            </Typography>
            <Typography sx={{ textAlign: 'left', color: theme.palette.text.primary }}>
              {formatDate(newDeal?.estLaunchDate)}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant='subtitle2' color='textSecondary' sx={{ textAlign: 'left' }}>
              Start Date
            </Typography>
            <Typography sx={{ textAlign: 'left', color: theme.palette.text.primary }}>
              {formatDate(newDeal?.startDate)}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant='subtitle2' color='textSecondary' sx={{ textAlign: 'left' }}>
              End Date
            </Typography>
            <Typography sx={{ textAlign: 'left', color: theme.palette.text.primary }}>
              {formatDate(newDeal?.endDate)}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={4} xl={4} sx={{ p: 1, pl: 1.5, pr: 1.5 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant='subtitle2' color='textSecondary' sx={{ textAlign: 'left' }}>
              Onboarding Status
            </Typography>
            <Typography sx={{ color: renderColorByString(newDeal?.onboardingStatus ?? 'On Hold') }}>
              {newDeal?.onboardingStatus ?? 'On Hold'}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant='subtitle2' color='textSecondary' sx={{ textAlign: 'left' }}>
              Contract Status
            </Typography>
            <Typography sx={{ color: renderColorByString(newDeal?.status) }}>
              {newDeal?.status || 'no nco status'}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant='subtitle2' color='textSecondary' sx={{ textAlign: 'left' }}>
              Deal Status
            </Typography>
            <Typography sx={{ color: renderColorByString(newDeal?.dealStatus) }}>
              {newDeal?.dealStatus || 'no nco status'}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default BoardsGridHeader
