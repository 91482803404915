import React, { useState, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import GroboticDataGrid from '../DataGrid/GroboticDataGrid.js'
import { useFilteredDeals } from '../../api/aws/useFilteredDeals.js'
import { ncoStatusFilters } from '../DataGrid/filterArrays.js'
import { websiteTasks } from '../DataGrid/taskTemplates.js'

const initialSortModel = [
  { field: 'dealStatus', sort: 'desc' },
  { field: 'organizations', sort: 'asc' },
]

const initialVisibilityState = {
  implementationFee: false,
  total: false,
  status: false,
  id: false,
  strategy: false,
  vertical: false,
  stage: false,
  contractId: false,
  type: false,
  startDate: false,
  endDate: false,
  isGoogleDisplay: false,
  isGoogleSearch: false,
  isLinkedIn: false,
  isMetaAdvertising: false,
  isMicrosoftAdvertising: false,
  isSimplifi: false,
  isSnapchat: false,
  isSpotify: false,
  isTikTok: false,
  isYoutube: false,
}

const WebsitePage = () => {
  const { dealId } = useParams()
  const filters = {
    status: 'Completed',
    // type: 'CSA',
    productCategories: ['Website'],
    // productSubcategories: [
    //   'Videography',
    //   'Graphic Design', // Filters deals with Search category in products
    // ],
    excludedDealStatus: ['Expired', 'Expired (system)', 'Cancelled'],
  }
  const { filteredDeals, isFilteredDealsLoading, isFilteredDealsError } = useFilteredDeals(
    true,
    ['website', 'deals'],
    filters
  )
  return (
    <GroboticDataGrid
      dealId={dealId}
      filteredDeals={filteredDeals}
      isFilteredDealsLoading={isFilteredDealsLoading}
      route={'website'}
      tasks={websiteTasks}
      context={'website'}
      filters={filters}
      initialVisibilityState={initialVisibilityState}
      initialSortModel={initialSortModel}
      statusKey={'status'}
      statusLabel={'Website Status'}
      filterOptions={ncoStatusFilters}
    />
  )
}
export default WebsitePage
