// import React, { useState, useEffect } from 'react'
// import useDrivePicker from 'react-google-drive-picker'
// import { Box, Button, List, ListItem, ListItemText, Divider } from '@mui/material'
// import { useGoogleContext } from '../../context/GoogleProvider/GoogleProvider'
// import { baseUrl } from '../../api/queryMutations/mutationFunctions'
// import { useGoogleDrivesFolders } from '../../api/google/useGoogleDrivesFolders'

// interface GoogleDriveItem {
//   id: string
//   name: string
//   mimeType: string
// }

// interface SharedDrive {
//   id: string
//   name: string
// }

// const GooglePicker: React.FC = () => {
//   const [openPicker] = useDrivePicker()
//   const {
//     googleDrivesAndFolders,
//     isGoogleDrivesAndFoldersLoading,
//     isGoogleDrivesAndFoldersError,
//   } = useGoogleDrivesFolders()
//   const tokenInfo = localStorage.getItem('google_access_token')
//   const [selectedDriveId, setSelectedDriveId] = useState<string | null>(null)
//   const [sharedDrives, setSharedDrives] = useState<SharedDrive[]>([])
//   const [folders, setFolders] = useState<GoogleDriveItem[]>([])
//   const [files, setFiles] = useState<GoogleDriveItem[]>([])

//   useEffect(() => {
//     fetchDrivesAndFolders()
//   }, [])

//   const fetchDrivesAndFolders = async () => {
//     try {
//       const response = await fetch(`${baseUrl}/aws/google-drives-folders`, {
//         headers: { Authorization: `Bearer ${tokenInfo}` },
//       })
//       const data = await response.json()
//       setSharedDrives(data.sharedDrives || [])
//       setFolders(data.sharedDriveFolders || [])
//     } catch (error) {
//       console.error('Error fetching drives and folders:', error)
//     }
//   }

//   const handleOpenPicker = () => {
//     console.log('Opening Google Picker...')
//     gapi.load('client:auth2', () => {
//       gapi.client
//         .init({
//           apiKey: process.env.REACT_APP_GOOGLE_PRIVATE_KEY,
//         })
//         .then(() => {
//           console.log('Google Picker initialized with token:', tokenInfo)
//           const pickerFolderConfig: any = {
//             clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
//             developerKey: process.env.REACT_APP_GOOGLE_DEVELOPER_KEY,
//             viewId: 'FOLDERS',
//             token: tokenInfo,
//             showUploadView: true,
//             showUploadFolders: true,
//             supportDrives: true,
//             multiselect: true,
//             includeItemsFromAllDrives: true,
//             enableDrives: true,
//             // disableDefaultView: true, // 🚀 This ensures only Picker UI is shown
//             viewMimeTypes: 'application/vnd.google-apps.folder,application/vnd.google-apps.document',
//             setSelectFolderEnabled: true,
//             callbackFunction: handlePickerCallback,
//           }

//           openPicker(pickerFolderConfig)
//         })
//     })
//   }

//   const handlePickerCallback = (data: any) => {
//     console.log('Picker callback data:', data)
//     if (data.action === 'picked' && data.docs.length > 0) {
//       data.docs.forEach((doc: any) => {
//         console.log('Selected item:', doc)
//         if (doc.mimeType === 'application/vnd.google-apps.folder') {
//           fetchFilesInFolder(doc.id)
//         } else {
//           setFiles(prevFiles => [...prevFiles, doc]) // <- This might be the issue
//         }
//       })
//     }
//   }

//   const fetchFilesInFolder = async (folderId: string) => {
//     try {
//       const apiUrl = `${baseUrl}/aws/googledocs?folderId=${folderId}`
//       const response = await fetch(apiUrl, {
//         headers: { Authorization: `Bearer ${tokenInfo}` },
//       })
//       console.log('[fetchFilesInFolder] RESPONSE', response)
//       const data = await response.json()
//       console.log('Files in selected folder:', data.files)
//       setFiles(data.files)
//     } catch (error) {
//       console.error('Error fetching files in folder:', error)
//     }
//   }

//   return (
//     <Box>
//       <Button onClick={handleOpenPicker} color='primary' variant='outlined'>
//         Open Google Picker
//       </Button>
//       <Divider />
//       {/* <List>
//         {sharedDrives.map((drive, index) => (
//           <ListItem key={`drive-${drive.id}-${index}`} onClick={() => setSelectedDriveId(drive.id)}>
//             <ListItemText primary={drive.name} secondary='Shared Drive' />
//           </ListItem>
//         ))}
//       </List>
//       <Divider />
//       <List>
//         {folders.map((folder, index) => (
//           <ListItem key={`folder-${folder.id}-${index}`} onClick={() => fetchFilesInFolder(folder.id)}>
//             <ListItemText primary={folder.name} secondary={folder.mimeType} />
//           </ListItem>
//         ))}
//       </List>
//       <Divider />
//       <List>
//         {files.map((file, index) => (
//           <ListItem key={`file-${file.id}-${index}`}>
//             <ListItemText primary={file.name} secondary={file.mimeType} />
//           </ListItem>
//         ))}
//       </List> */}
//     </Box>
//   )
// }

// export default GooglePicker

import React, { useState, useEffect } from 'react'
import useDrivePicker from 'react-google-drive-picker'
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import EditIcon from '@mui/icons-material/Edit'
import { baseUrl } from '../../api/queryMutations/mutationFunctions'

interface GoogleDriveItem {
  id: string
  name: string
  mimeType: string
  files?: GoogleDriveItem[]
}

interface Drive {
  id: string
  name: string
}

interface OpenFoldersState {
  [key: string]: boolean
}

const GroboticPickerComponent: React.FC = () => {
  const [openPicker] = useDrivePicker()
  const tokenInfo = localStorage.getItem('google_access_token')
  const [drive, setDrive] = useState<Drive | null>(null)
  const [folders, setFolders] = useState<GoogleDriveItem[]>([])
  const [openFolders, setOpenFolders] = useState<OpenFoldersState>({})
  const [selectedFileId, setSelectedFileId] = useState<string | null>(null)
  const [selectedFileName, setSelectedFileName] = useState<string | null>(null)
  const [isFileDialogOpen, setIsFileDialogOpen] = useState(false)

  useEffect(() => {
    fetchGroboticDriveData()
  }, [])

  const fetchGroboticDriveData = async () => {
    try {
      const response = await fetch(`${baseUrl}/aws/googleapi/grobotic-drive`, {
        headers: { Authorization: `Bearer ${tokenInfo}` },
      })

      // 🔹 Log raw response
      const textResponse = await response.text()
      // console.log('Raw response:', textResponse)

      // 🔹 Check if response is valid JSON
      const data = JSON.parse(textResponse)
      // console.log('Fetched data:', data)

      setDrive(data.drive)
      setFolders(data.folders)
    } catch (error) {
      console.error('Error fetching grobotic drive data:', error)
    }
  }

  const handleOpenPicker = () => {
    const pickerConfig: any = {
      clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      developerKey: process.env.REACT_APP_GOOGLE_DEVELOPER_KEY,
      viewId: 'DOCS',
      token: tokenInfo,
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: true,
      parentId: '0AEsgEZaVZm_nUk9PVA', // grobotic drive ID
      viewMimeTypes: 'application/vnd.google-apps.folder,application/vnd.google-apps.document',
      setSelectFolderEnabled: true,
      callbackFunction: handlePickerCallback,
    }

    openPicker(pickerConfig)
  }

  const handleFileSelect = (fileId: string) => {
    setSelectedFileId(fileId)
    setIsFileDialogOpen(true)
  }

  const handleCloseDialog = () => {
    setIsFileDialogOpen(false)
    setSelectedFileId(null)
  }

  const handlePickerCallback = (data: any) => {
    if (data.action === 'picked' && data.docs.length > 0) {
      console.log('Picked items:', data.docs)
      setSelectedFileId(data.docs[0].id)
      setSelectedFileName(data.docs[0].name)
      setIsFileDialogOpen(true) // Open dialog when a file is picked
    }
  }

  const openInGoogleDocs = (fileId: string) => {
    const editUrl = `https://docs.google.com/document/d/${fileId}/edit`
    window.open(editUrl, '_blank')
  }

  const toggleFolder = (folderId: string) => {
    setOpenFolders(prev => ({ ...prev, [folderId]: !prev[folderId] }))
  }

  return (
    <Box>
      <Button onClick={handleOpenPicker} color='primary' variant='outlined'>
        Open Grobotic Drive Picker
      </Button>
      <Box display='flex' mt={2}>
        <Dialog
          open={isFileDialogOpen}
          onClose={handleCloseDialog}
          maxWidth='lg'
          fullWidth
          PaperProps={{ style: { height: 'calc(100% - 64px)' } }} // Adjust for title and content padding
        >
          <DialogTitle>
            {selectedFileName}
            <IconButton
              onClick={() => openInGoogleDocs(selectedFileId!)}
              style={{ position: 'absolute', right: '16px', top: '16px' }}
            >
              <EditIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent style={{ padding: 0 }}>
            {selectedFileId && (
              <iframe
                src={`https://docs.google.com/document/d/${selectedFileId}/preview`}
                width='100%'
                height='95%' // Adjust this value based on your DialogTitle and DialogContent padding
                title='Google Doc Viewer'
                // frameBorder='0'0
                style={{ border: 'none' }}
              />
            )}
          </DialogContent>
        </Dialog>

        {drive && (
          <Box flexBasis='30%' mr={2}>
            <h2>{drive.name}</h2>
            <List>
              {folders.map(folder => (
                <React.Fragment key={folder.id}>
                  <ListItem button onClick={() => toggleFolder(folder.id)}>
                    <ListItemText primary={folder.name} />
                    {openFolders[folder.id] ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={openFolders[folder.id]} timeout='auto' unmountOnExit>
                    <List component='div' disablePadding>
                      {folder.files?.map((file: GoogleDriveItem) => (
                        <ListItem key={file.id} sx={{ pl: 4 }} button onClick={() => handleFileSelect(file.id)}>
                          <ListItemText primary={file.name} secondary={file.mimeType} />
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                </React.Fragment>
              ))}
            </List>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default GroboticPickerComponent

// import React, { useState, useEffect } from 'react'
// import type { FC } from 'react'
// import useDrivePicker from 'react-google-drive-picker'
// import { Box, Button, List, ListItem, ListItemText, Divider } from '@mui/material'
// import { baseUrl } from '../../api/queryMutations/mutationFunctions'
// import { useGoogleContext } from '../../context/GoogleProvider/GoogleProvider'

// interface GoogleDriveItem {
//   id: string
//   name: string
//   mimeType: string
// }

// const GooglePicker: FC = () => {
//   const [openPicker] = useDrivePicker()
//   const { googleDrive } = useGoogleContext()
//   const tokenInfo = localStorage.getItem('google_access_token')
//   const [sharedDrives, setSharedDrives] = useState<GoogleDriveItem[]>([])
//   const [folders, setFolders] = useState<GoogleDriveItem[]>([])
//   const [files, setFiles] = useState<GoogleDriveItem[]>([])

//   console.log('Google Drive Context Data:', googleDrive)

//   useEffect(() => {
//     if (!tokenInfo) {
//       console.error('No Google access token found')
//     } else if (googleDrive) {
//       console.log('Setting shared drives, folders, and files from Google Drive context')
//       setSharedDrives(googleDrive.sharedDrives || [])
//       setFolders(googleDrive.folders || [])
//       setFiles(googleDrive.files || [])
//     }
//   }, [tokenInfo, googleDrive])

//   const handleOpenPicker = () => {
//     console.log('Opening Google Picker...')
//     gapi.load('client:auth2', () => {
//       gapi.client
//         .init({
//           apiKey: process.env.REACT_APP_GOOGLE_PRIVATE_KEY,
//         })
//         .then(() => {
//           console.log('Google Picker initialized with token:', tokenInfo)

//           const pickerDriveConfig: any = {
//             clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
//             developerKey: process.env.REACT_APP_GOOGLE_DEVELOPER_KEY,
//             viewId: 'DRIVES',
//             token: tokenInfo ? tokenInfo : null,
//             showUploadView: true,
//             showUploadFolders: true,
//             supportDrives: true,
//             multiselect: true,
//             includeItemsFromAllDrives: true,
//             callbackFunction: handleDrivePickerCallback,
//           }

//           console.log('Picker configuration for shared drives:', pickerDriveConfig)
//           openPicker(pickerDriveConfig)
//         })
//     })
//   }

//   const handleDrivePickerCallback = (data: any) => {
//     console.log('Drive picker callback data:', data)
//     const elements = Array.from(document.getElementsByClassName('picker-dialog') as HTMLCollectionOf<HTMLElement>)
//     for (let i = 0; i < elements.length; i++) {
//       elements[i].style.zIndex = '2000'
//     }
//     if (data.action === 'picked' && data.docs.length > 0) {
//       const driveId = data.docs[0].id // Assuming single drive selection
//       console.log('Selected drive ID:', driveId)
//       openFolderPicker(driveId)
//     } else if (data.action === 'loaded') {
//       console.log('Google Picker loaded')
//     }
//   }

//   const openFolderPicker = (driveId: string) => {
//     console.log('Opening folder picker for drive ID:', driveId)
//     const pickerFolderConfig: any = {
//       clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
//       developerKey: process.env.REACT_APP_GOOGLE_DEVELOPER_KEY,
//       viewId: 'FOLDERS',
//       token: tokenInfo ? tokenInfo : null,
//       showUploadView: true,
//       showUploadFolders: true,
//       supportDrives: true,
//       multiselect: true,
//       includeItemsFromAllDrives: true,
//       driveId: driveId, // Specify the drive ID
//       query: `'${driveId}' in parents`, // Restrict to selected drive
//       callbackFunction: handleFolderPickerCallback,
//     }

//     console.log('Folder picker configuration:', pickerFolderConfig)
//     openPicker(pickerFolderConfig)
//   }

//   const handleFolderPickerCallback = (data: any) => {
//     console.log('Folder picker callback data:', data)
//     const elements = Array.from(document.getElementsByClassName('picker-dialog') as HTMLCollectionOf<HTMLElement>)
//     for (let i = 0; i < elements.length; i++) {
//       elements[i].style.zIndex = '2000'
//     }
//     if (data.action === 'picked' && data.docs.length > 0) {
//       const folderId = data.docs[0].id // Assuming single folder selection
//       console.log('Selected folder ID:', folderId)
//       openFilePicker(folderId)
//     } else if (data.action === 'loaded') {
//       console.log('Google Picker loaded')
//     }
//   }

//   const openFilePicker = (folderId: string) => {
//     console.log('Opening file picker for folder ID:', folderId)
//     const pickerFileConfig: any = {
//       clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
//       developerKey: process.env.REACT_APP_GOOGLE_DEVELOPER_KEY,
//       viewId: 'DOCS',
//       viewMimeTypes: 'application/vnd.google-apps.document',
//       token: tokenInfo ? tokenInfo : null,
//       showUploadView: true,
//       showUploadFolders: true,
//       supportDrives: true,
//       multiselect: true,
//       includeItemsFromAllDrives: true,
//       query: `'${folderId}' in parents`, // Restrict to selected folder
//       callbackFunction: handleFilePickerCallback,
//     }

//     console.log('File picker configuration:', pickerFileConfig)
//     openPicker(pickerFileConfig)
//   }

//   const handleFilePickerCallback = (data: any) => {
//     console.log('File picker callback data:', data)
//     const elements = Array.from(document.getElementsByClassName('picker-dialog') as HTMLCollectionOf<HTMLElement>)
//     for (let i = 0; i < elements.length; i++) {
//       elements[i].style.zIndex = '2000'
//     }
//     if (data.action === 'picked') {
//       if (!tokenInfo) {
//         console.log('No token found')
//       }
//       const fetchOptions = {
//         headers: {
//           Authorization: `Bearer ${tokenInfo}`,
//         },
//       }
//       const driveFileUrl = `https://${baseUrl}/aws/googledocs`
//       data.docs.map(async (item: any) => {
//         console.log('Fetching file content for item:', item.id)
//         const response = await fetch(`${driveFileUrl}/${item.id}?alt=media`, fetchOptions)
//         console.log('Fetched file response:', response)
//         // Handle the response as needed
//       })
//     }
//   }

//   return (
//     <Box>
//       <Button onClick={() => handleOpenPicker()} color='primary' variant='outlined'>
//         Open Google Picker
//       </Button>
//       <Divider />
//       <List>
//         {sharedDrives.map((drive, index) => (
//           <ListItem key={`${drive.id}-${index}`} onClick={() => openFolderPicker(drive.id)}>
//             <ListItemText primary={drive.name} secondary={drive.mimeType} />
//           </ListItem>
//         ))}
//       </List>
//       <Divider />
//       <List>
//         {folders.map((folder, index) => (
//           <ListItem key={`${folder.id}-${index}`}>
//             <ListItemText primary={folder.name} secondary={folder.mimeType} />
//           </ListItem>
//         ))}
//       </List>
//       <Divider />
//       <List>
//         {files.map((file, index) => (
//           <ListItem key={`${file.id}-${index}`}>
//             <ListItemText primary={file.name} secondary={file.mimeType} />
//           </ListItem>
//         ))}
//       </List>
//     </Box>
//   )
// }

// export default GooglePicker
