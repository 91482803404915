import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import Loading from '../components/Loading/Loading'
import { useAuth } from './AuthContext'
import { refreshAccessToken } from './axiosInstance'

export const baseAuthUrl = process.env.REACT_APP_API_URL
console.log(
  `[NODE_ENV]: ${process.env.NODE_ENV}\n[baseAuthUrl]: ${baseAuthUrl}\n[REACT_APP_ENV]: ${process.env.REACT_APP_ENV}`
)
// console.log('baseAuthUrl: ', baseAuthUrl)
// console.log('NODE_ENV: ', process.env.NODE_ENV)
// console.log('REACT_APP_ENV: ', process.env.REACT_APP_ENV)

const AuthCallback = () => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)
  const { redirectToSlackAuth, redirectToPandaAuth } = useAuth()

  useEffect(() => {
    const handleAuthCallback = async () => {
      const urlParams = new URLSearchParams(window.location.search)
      const code = urlParams.get('code')
      const state = urlParams.get('state')

      console.log('Code:', code)
      console.log('State:', state)

      if (!code || !state) {
        console.error('No authorization code or state found in URL')
        navigate('/')
        return
      }

      try {
        if (state === 'google') {
          console.log('Google state detected')
          console.log('Google Code: ', code)
          localStorage.setItem('google_code', code)
          redirectToSlackAuth()
        } else if (state === 'slack') {
          console.log('Slack state detected')
          localStorage.setItem('slack_code', code)

          if (process.env.REACT_APP_ENV === 'local' || process.env.REACT_APP_ENV === 'laptop') {
            console.log('Local environment detected, skipping PandaDoc auth')

            const googleCode = localStorage.getItem('google_code')
            const slackCode = localStorage.getItem('slack_code')

            if (!googleCode || !slackCode) {
              console.error('Authorization code missing for one or more services')
              navigate('/')
              return
            }

            const response = await axios.post(`${baseAuthUrl}/aws/combined-authorization`, {
              googleCode,
              slackCode,
              pandaCode: 'dummy_panda_code', // Use a dummy code as it's not needed in local
            })

            console.log('Response in AuthCallback:', response)

            const {
              googleAccessToken,
              googleRefreshToken,
              googleExpiresIn,
              slackAccessToken,
              slackUserAccessToken,
              slackUserId,
            } = response.data

            console.log('Google Access Token:', googleAccessToken)
            console.log('Slack Access Token:', slackAccessToken)

            // Set the static PandaDoc token for local environment
            const pandaAccessToken = '44c682867cd90875b3ba53db7280c56e1a4d6068'
            const pandaRefreshToken = ''

            localStorage.setItem('google_access_token', googleAccessToken)
            setTimeout(refreshAccessToken, (googleExpiresIn - 60) * 1000)
            // localStorage.setItem('google_refresh_token', googleRefreshToken)
            localStorage.setItem('slack_access_token', slackAccessToken)
            localStorage.setItem('slack_user_access_token', slackUserAccessToken)
            localStorage.setItem('slack_user_id', slackUserId)
            localStorage.setItem('panda_access_token', pandaAccessToken)
            localStorage.setItem('panda_refresh_token', pandaRefreshToken)

            navigate('/dashboard')
          } else {
            redirectToPandaAuth()
          }
        } else if (state === 'panda') {
          console.log('Panda state detected')
          localStorage.setItem('panda_code', code)

          const googleCode = localStorage.getItem('google_code')
          const slackCode = localStorage.getItem('slack_code')
          const pandaCode = localStorage.getItem('panda_code')

          console.log('Google Code:', googleCode)
          console.log('Slack Code:', slackCode)
          console.log('Panda Code:', pandaCode)

          if (!googleCode || !slackCode || !pandaCode) {
            console.error('Authorization code missing for one or more services')
            navigate('/')
            return
          }

          const response = await axios.post(`${baseAuthUrl}/aws/combined-authorization`, {
            googleCode,
            slackCode,
            pandaCode,
          })

          console.log('Response in AuthCallback:', response)

          const {
            googleAccessToken,
            googleRefreshToken,
            googleExpiresIn,
            slackAccessToken,
            slackUserAccessToken,
            slackUserId,
            pandaAccessToken,
            pandaRefreshToken,
          } = response.data

          console.log('Google Access Token:', googleAccessToken)
          console.log('Slack Access Token:', slackAccessToken)
          console.log('Panda Access Token:', pandaAccessToken)

          localStorage.setItem('google_access_token', googleAccessToken)
          setTimeout(refreshAccessToken, (googleExpiresIn - 60) * 1000)

          // localStorage.setItem('google_refresh_token', googleRefreshToken)
          localStorage.setItem('slack_access_token', slackAccessToken)
          localStorage.setItem('slack_user_access_token', slackUserAccessToken)
          localStorage.setItem('slack_user_id', slackUserId)
          localStorage.setItem('panda_access_token', pandaAccessToken)
          localStorage.setItem('panda_refresh_token', pandaRefreshToken)

          navigate('/dashboard')
        }
      } catch (error) {
        console.error('Error exchanging tokens:', error)
        navigate('/')
      } finally {
        setIsLoading(false)
      }
    }

    handleAuthCallback()
  }, [navigate, redirectToSlackAuth])

  if (isLoading) {
    return (
      <div>
        <Loading />
      </div>
    )
  }

  return null
}

export default AuthCallback
