import React, { useState, useEffect, useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro'
import { useTheme } from '@mui/material'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider.js'
import { useDeleteMutation } from '../../api/aws/useDeleteMutation.js'
import { filterPanelProps, gridContainerStyles } from '../NCO/style.js'
import { DealsGridFooter } from '../Deals/DealsGridFooter.js'
import { useUsers } from '../../api/aws/useUsers.js'
import { DealDetailsProvider, useDealDetailsContext } from '../../context/DealsDetailsProvider/DealsDetailsProvider.js'
import renderDataGridColumns from './renderDataGridColumns.js'
import { useUpdateDeal } from '../../api/aws/useUpdateDeal.js'
import dayjs from 'dayjs'
import { useLocation, useNavigate } from 'react-router-dom'
import BoardsDrawerComponent from './BoardsDrawerComponent.js'
import { useOrganizations } from '../../api/aws/useOrganizations.js'
import BoardsGridToolbar from './BoardsGridToolbar.js'
import { useCreateTask } from '../../api/aws/useCreateTask.js'
import { tasksInitialState } from '../../api/initialState/initialState.js'
import { calculateTaskDays, workday } from '../Tasks/taskUtil.js'

const GroboticDataGrid = ({
  dealId,
  filteredDeals,
  isFilteredDealsLoading,
  route,
  tasks,
  context,
  initialVisibilityState,
  initialSortModel,
  statusKey,
  statusLabel,
  filterOptions,
  renderFunction,
}) => {
  const { data: organizations, isLoading: isOrganizationsLoading } = useOrganizations(true, [
    'websitePage',
    'organizations',
  ])
  const { users, isUsersLoading, isUsersError } = useUsers(true, ['websitePage', 'users'])
  // const { isTaskView, handleChangeBoardView } = useViewContext()
  const { resetState, ncoDrawerOpen, handleNcoDrawerOpen, handleNcoDrawerClose } = useDealDetailsContext()
  const [selectedDeal, setSelectedDeal] = useState(null)
  const [selectionModel, setSelectionModel] = useState([])
  const [density, setDensity] = useState(() => {
    const savedDensity = localStorage.getItem(`${context}DensityModel`)
    return savedDensity ?? 'standard'
  })
  const [selectedOrganization, setSelectedOrganization] = useState()
  const [newTask, setNewTask] = useState(tasksInitialState)
  const { mutate: updateDeal } = useUpdateDeal()
  const { mutate: createTask } = useCreateTask()
  const { showSnackbar } = useSnackbarContext()
  const theme = useTheme()
  const filterMenuBorder =
    theme.palette.mode === 'dark' ? '1px solid rgba(255,255,255,0.5)' : '1px solid rgba(0,0,0,0.5)'
  const [isDeleting, setIsDeleting] = useState(false)
  const [filterModel, setFilterModel] = useState(() => {
    const savedFilters = localStorage.getItem(`${context}FilterModel`)
    return savedFilters ? JSON.parse(savedFilters) : { items: [], quickFilterValues: [] }
  })
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(() => {
    const savedVisibility = localStorage.getItem(`${context}VisibilityModel`)
    return savedVisibility ? JSON.parse(savedVisibility) : { ...initialVisibilityState }
  })
  const [sortModel, setSortModel] = useState(() => {
    const savedSortModel = localStorage.getItem(`${context}SortModel`)
    return savedSortModel
      ? JSON.parse(savedSortModel)
      : initialSortModel
      ? initialSortModel
      : [
          { field: 'dealStatus', sort: 'desc' },
          { field: 'organizations', sort: 'asc' },
        ]
  })
  const { mutate: deleteItem } = useDeleteMutation()
  const [massUpdateData, setMassUpdateData] = useState()
  const [anchorEl, setAnchorEl] = useState(null)
  const [deals, setDeals] = useState([])
  // const columns = renderDealsColumns(organizations, users, deals, density)
  const apiRef = useGridApiRef()
  const [visibleRowCount, setVisibleRowCount] = useState(deals ? deals.length : 0)
  const [rowCount, setRowCount] = useState(deals ? deals.length : 0)
  const navigate = useNavigate()
  const location = useLocation()
  const [path, setPath] = useState(location?.pathname ?? location?.pathname)
  useEffect(() => {
    if (filterModel) {
      localStorage.setItem(`${context}FilterModel`, JSON.stringify(filterModel))
    }
  }, [filterModel])
  useEffect(() => {
    if (density) {
      localStorage.setItem(`${context}DensityModel`, density)
    }
  }, [density])
  useEffect(() => {
    if (selectionModel) {
      console.log('selectionModel', selectionModel)
    }
  }, [selectionModel])
  useEffect(() => {
    if (filteredDeals && organizations && !isFilteredDealsLoading && !isOrganizationsLoading && location) {
      setDeals(filteredDeals)
      setPath(location?.pathname)
    } else {
      setDeals([])
    }
  }, [filteredDeals, isFilteredDealsLoading, handleNcoDrawerOpen, location])
  // Ensure the drawer opens when the page loads with a dealId
  useEffect(() => {
    if (!isFilteredDealsLoading && dealId) {
      const selected = filteredDeals?.find(deal => deal.id === dealId)
      if (selected) {
        setSelectedDeal(selected)
        handleNcoDrawerOpen()
      }
    }
  }, [dealId, isFilteredDealsLoading, handleNcoDrawerOpen])

  const handleDeleteClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleDealClick = deal => {
    setSelectedDeal(deal)
    handleNcoDrawerOpen()
    navigate(`/${encodeURIComponent(route)}/${deal.id}`)
  }
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const columns = useMemo(() => {
    return renderDataGridColumns(organizations, users, deals, density, handleDealClick, context)
    // return renderFunction
    //   ? renderFunction(organizations, users, deals, density, handleDealClick, context)
    //   : renderDataGridColumns(organizations, users, deals, density, handleDealClick, context)
  }, [organizations, users, deals, density])
  useEffect(() => {
    if (selectedDeal) {
      const selectedOrgId = Array.isArray(selectedDeal.organizations) ? selectedDeal.organizations[0] : undefined

      setSelectedOrganization(organizations.find(org => org.id === selectedOrgId) || null)
    }
  }, [selectedDeal, organizations])
  // Cleanup function for when deals component unmounts
  useEffect(() => {
    // console.log('cleanup function...')
    return () => {
      setSelectionModel([])
      setSelectedDeal(null)
    }
  }, [])

  const handleConfirmDelete = async () => {
    setIsDeleting(true)
    if (selectedDeal) {
      const params = {
        endpoint: `/aws/delete/deals/${selectedDeal.id}`,
        table: 'deals',
      }
      deleteItem(params, {
        onSuccess: message => {
          setSelectedDeal(null)
          showSnackbar(message, 'success')
          setIsDeleting(false)
        },
        onError: error => {
          showSnackbar(error.message, 'error')
          setIsDeleting(false)
        },
      })
    }
  }

  const handleClosePopover = () => {
    setAnchorEl(null)
    if (selectionModel.length > 0) {
      selectionModel.forEach(id => {
        apiRef.current.selectRow(id, false)
      })
      setSelectionModel([])
      setSelectedDeal(null)
    }
  }

  const handleCloneDeal = () => {
    console.log('This icon will be used to clone the selected deal.')
  }

  const handleRowSelected = deal => {
    setSelectedDeal(deal)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const handleMassUpdateChange = e => {
    const { name, value } = e.target
    setMassUpdateData(prev => ({ ...prev, [name]: value }))
  }

  const handleColumnResizeStop = () => {
    if (apiRef.current) {
      apiRef.current.updateColumns()
    }
  }

  useEffect(() => {
    const api = apiRef.current

    const handleFilterModelChange = () => {
      if (api?.state?.filter?.filteredRowsLookup) {
        const filteredRowsCount = Object.values(api.state.filter.filteredRowsLookup).filter(isVisible => isVisible)
          .length
        setVisibleRowCount(filteredRowsCount)
      }
    }

    // Subscribe to the filterModelChange event
    const unsubscribe = api?.subscribeEvent('filterModelChange', handleFilterModelChange)
    // const unsubscribeView = api?.subscribeEvent('viewChange', handleViewState)
    // Initialize the visible row count when the component mounts
    handleFilterModelChange()

    // Cleanup function to unsubscribe
    return () => {
      if (unsubscribe) unsubscribe()
    }
  }, [apiRef, deals])

  const totalRowCount = deals ? deals.length : 0
  useEffect(() => {
    if (massUpdateData) {
      console.log('Mass Update Data: ', massUpdateData)
    }
  }, [massUpdateData])
  const handleDensityChange = newDensity => {
    setDensity(newDensity)
    localStorage.setItem(`${context}DensityModel`, newDensity)
  }

  ////////////////// handleUpdate ////////////////////////
  const handleUpdate = async (dealId, updatedRow, updatedData) => {
    try {
      // Call the API mutation with the deal ID and updated fields
      updateDeal(
        { dealId, dealData: updatedData },
        {
          onSuccess: () => {
            showSnackbar(
              <Box sx={{ display: 'flex', alignItems: 'center' }} gap={1}>
                <Typography sx={{ color: theme.palette.text.primary, fontWeight: 'bold' }}>
                  {updatedRow?.name || ''}
                </Typography>
                <Typography>Updated successfully</Typography>
              </Box>,
              'success'
            )
          },
          onError: error => {
            showSnackbar(`Failed to update deal ${dealId}: ${error.message}`, 'error')
          },
        }
      )
    } catch (error) {
      console.error(`Failed to update deal ${dealId}:`, error)
    }
  }
  const handleDrawerClose = () => {
    handleNcoDrawerClose()
    setSelectedDeal(null)
    navigate(`/${route}`) // Navigate back to the NCO board
  }
  const handleClearSorting = e => {
    e.preventDefault()
    setSortModel(initialSortModel) // Update state first
    localStorage.setItem(`${context}SortModel`, JSON.stringify(initialSortModel))
    if (apiRef.current) {
      apiRef.current.setSortModel(initialSortModel) // Use API to sync
    }
  }
  const handleClearVisibility = e => {
    e.preventDefault()
    setColumnVisibilityModel(initialVisibilityState)
    localStorage.setItem(`${context}ColumnVisibilityModel`, JSON.stringify(initialVisibilityState))
  }
  const handleChangeView = newView => {
    console.log('[handleChangeView] newView', newView)
    switch (newView) {
      case 'nco':
        navigate('/nco')
        break
      case 'creative':
        navigate('/creative')
        break
      case 'seo':
        navigate('/seo')
        break
      case 'website':
        navigate('/website')
        break
      case 'mtb':
        navigate('/mtb')
        break
      default:
        console.error('Invalid view option:', newView)
    }
  }

  // const handleViewState = () => {
  //   handleChangeBoardView(path, selectionModel?.[0])
  //   if (!isTaskView && selectionModel.length > 0) {
  //     selectionModel.forEach(id => {
  //       apiRef.current.selectRow(id, false)
  //     })
  //     setSelectionModel([])
  //     setSelectedDeal(null)
  //   }
  // }

  return (
    <>
      <Box sx={{ ...gridContainerStyles, height: 'calc(100vh - 20px)' }}>
        {/* {!isTaskView ? ( */}
        <DataGridPro
          initialState={{
            sorting: {
              sortModel: [
                { field: 'lastModified', sort: 'desc' },
                { field: 'name', sort: 'asc' },
                { field: 'organizations', sort: 'asc' },
                { field: 'status', sort: 'asc' },
              ],
            },
            filter: {
              filterModel: filterModel,
            },
            columns: {
              columnVisibilityModel: initialVisibilityState,
            },
          }}
          rows={deals}
          columns={columns}
          processRowUpdate={async (updatedRow, oldRow) => {
            const updatedFields = { ...updatedRow }
            if (
              updatedRow.slaDays !== oldRow.slaDays ||
              updatedRow.dependenciesDueDate !== oldRow.dependenciesDueDate
            ) {
              const dependenciesDueDate = updatedRow.dependenciesDueDate ? dayjs(updatedRow.dependenciesDueDate) : null
              const slaDays = updatedRow.slaDays || 0

              if (dependenciesDueDate) {
                updatedFields.estLaunchDate = dependenciesDueDate.add(slaDays, 'day').toISOString() // Store ISO string in the backend
              } else {
                updatedFields.estLaunchDate = null
              }
              await handleUpdate(updatedRow.id, updatedRow, updatedFields)
            } else if (updatedRow.onboardingStatus !== oldRow.onboardingStatus) {
              console.log('updatedRow.onboardingStatus', updatedRow.onboardingStatus)
              console.log('oldRow.onboardingStatus', oldRow.onboardingStatus)
              if (updatedRow.onboardingStatus === 'Awaiting Kickoff') {
                const startDate = dayjs().utc().toISOString()
                const taskDays = calculateTaskDays('New Client Onboard')
                const newDueDate = startDate && taskDays ? workday(startDate, taskDays) : null
                createTask(
                  {
                    ...newTask?.task,
                    taskName: 'Kickoff Call With Client',
                    taskType: 'New Client Onboard',
                    taskDays: taskDays ?? 0,
                    masterStatus: 'In Progress',
                    deal: updatedRow?.id,
                    startDate: startDate,
                    dueDate: newDueDate,
                  },
                  {
                    onSuccess: () => {
                      console.log('Successfully created new Task from datagrid')
                    },
                  }
                )
              }
              // Call the centralized update function
              await handleUpdate(updatedRow.id, updatedRow, { onboardingStatus: updatedRow.onboardingStatus })
            } else {
              console.log('no changes needed')
              // await handleUpdate(updatedRow.id, updatedRow, updatedFields)
            }
            return updatedFields // Return updated row for UI
          }}
          onProcessRowUpdateError={error => {
            console.error('Error updating row:', error)
          }}
          rowHeight={density === 'compact' ? 40 : density === 'comfortable' ? 60 : 52}
          getRowId={row => row.deal_uuid || row.id || row.deal.id}
          checkboxSelection
          disableRowSelectionOnClick
          selectionModel={selectionModel}
          onRowSelectionModelChange={newSelectionModel => setSelectionModel(newSelectionModel)}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={newModel => {
            setColumnVisibilityModel(newModel)
            localStorage.setItem(`${context}ColumnVisibilityModel`, JSON.stringify(newModel))
          }}
          sortModel={sortModel}
          onSortModelChange={newModel => {
            setSortModel(newModel)
            localStorage.setItem(`${context}SortModel`, JSON.stringify(newModel))
          }}
          onRowCountChange={count => setRowCount(count)}
          filterModel={filterModel}
          onFilterModelChange={model => setFilterModel(model)}
          apiRef={apiRef}
          slots={{
            toolbar: BoardsGridToolbar,
            footer: DealsGridFooter,
          }}
          slotProps={{
            toolbar: {
              filterModel: filterModel,
              selectionModel: selectionModel,
              // isTaskView: isTaskView,
              // onChangeBoardView: handleViewState,
              initialVisibilityState: initialVisibilityState,
              visibilityModel: columnVisibilityModel,
              initialSortModel: initialSortModel,
              sortModel: sortModel,
              setFilterModel: setFilterModel,
              onClearSorting: handleClearSorting,
              onClearVisibility: handleClearVisibility,
              onChangeView: handleChangeView,
              statusKey: statusKey,
              statusLabel: statusLabel,
              filterOptions: filterOptions,
              context: context,
            },
            footer: {
              selectionModel: selectionModel,
              totalRowCount: totalRowCount,
              visibleRowCount: visibleRowCount,
              rowCount: rowCount,
              massUpdateData: massUpdateData,
              deals: deals,
              anchorEl: anchorEl,
              handleDeleteClick: handleDeleteClick,
              handleConfirmDelete: handleConfirmDelete,
              handleClosePopover: handleClosePopover,
              // handleEditClick: handleEditClick,
              handleClone: handleCloneDeal,
              selectedData: selectedDeal,
            },
            panel: {
              sx: {
                border: filterMenuBorder,
                borderRadius: '4px',
              },
            },
            filterPanel: {
              sx: {
                ...filterPanelProps,
              },
            },
          }}
          sx={{
            '& .MuiDataGrid-cell': {
              padding: '0px', // Removes internal cell padding
            },
            '& .MuiDataGrid-columnHeaders': {
              borderBottom: 'none', // Removes unnecessary column header border
            },
            '& .MuiDataGrid-columnHeaderTitleContainer': {
              padding: '0px', // Ensures the column header text has no extra padding
            },
          }}
          onColumnResizeStop={handleColumnResizeStop}
          disableExtendRowFullWidth
          onDensityChange={handleDensityChange}
          loading={isFilteredDealsLoading || isOrganizationsLoading}
        />
        {/* ) : isTaskView ? (
          <GridDetailView selectionModel={selectionModel} isTaskView={isTaskView} onChangeBoardView={handleViewState} />
        ) : null} */}
      </Box>

      <DealDetailsProvider dealData={selectedDeal}>
        {ncoDrawerOpen && (
          <BoardsDrawerComponent open={ncoDrawerOpen} onClose={handleDrawerClose} context={context} tasks={tasks} />
        )}
      </DealDetailsProvider>
    </>
  )
}

export default GroboticDataGrid
