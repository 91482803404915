import React, { useState, useEffect } from 'react'
import TaskDetails from './TaskDetails'
import TaskMessages from './TaskMessages/TaskMessages'
import { CircularProgress, Drawer } from '@mui/material'
import { Box, Tabs, TableCell, useTheme } from '@mui/material'
import { MyStyledComponent, CustomBox } from '../../style/styleElements'
import { styled } from '@mui/material/styles'
import { Tab as MuiTab } from '@mui/material'
import { throttle } from 'lodash'
import { useParams } from 'react-router-dom'
import { useTask } from '../../api/aws/tasks/useTask'

export const DRAWER_CONFIG = [
  {
    tabValue: 0,
    label: 'Task Details',
    path: 'taskdetails',
    component: TaskDetails,
  },
  {
    tabValue: 1,
    label: 'Task Messages',
    path: 'taskmessages',
    component: TaskMessages,
  },
]

const StyledTab = styled(MuiTab)(({ theme }) => ({
  '&.Mui-selected': {
    color: 'rgb(121 212 255)', // Change to the desired color
  },
}))

const TaskDrawer = ({ taskDrawerOpen, closeTaskDrawer, selectedTaskData }) => {
  const { taskId } = useParams()
  const [tabIndex, setTabIndex] = useState(0)
  const [taskDrawerWidth, setTaskDrawerWidth] = useState(null) // Initial width of the drawer
  const { task, isTaskLoading, isTaskError } = useTask(taskId)
  console.log('[TaskDrawer] task: ', task)
  const theme = useTheme()
  const drawerBackgroundColor = theme.palette.type === 'dark' ? 'rgb(0,0,0,0.9)' : 'rgb(236, 239, 248)'
  const drawerTransitionDuration = 300
  const handleChange = (event, newValue) => {
    setTabIndex(newValue)
  }

  const handleMouseDown = e => {
    // Apply styles to prevent text selection
    document.body.style.userSelect = 'none'
    document.body.style.webkitUserSelect = 'none' // Safari
    document.body.style.mozUserSelect = 'none' // Firefox
    document.body.style.msUserSelect = 'none' // Internet Explorer/Edge

    document.addEventListener('mousemove', handleMouseMove)
    document.addEventListener('mouseup', handleMouseUp)
  }

  const logWidth = throttle(width => {
    console.log(`Current drawer width: ${width}px`)
  }, 1000) // Throttle logging to once per second
  const handleMouseMove = e => {
    const newWidth = window.innerWidth - e.clientX
    const minWidth = 200 // Set your global minimum width here
    const maxWidth = window.innerWidth * 0.8 // Set your global maximum width here
    const adjustedWidth = Math.min(Math.max(minWidth, newWidth), maxWidth)

    setTaskDrawerWidth(adjustedWidth)

    // Call the throttled logging function
    logWidth(adjustedWidth)
  }

  useEffect(() => {
    if (!taskDrawerOpen) {
      // Wait for the drawer closing animation to complete before resetting the width
      const timer = setTimeout(() => {
        if (!taskDrawerOpen) {
          // Reset the drawer width only if the drawer remains closed
          setTaskDrawerWidth(window.innerWidth * 0.8)
        }
      }, drawerTransitionDuration)

      return () => clearTimeout(timer)
    }
    // Set the drawerWidth to 80% of the window's width when the component mounts
    const initialWidth = window.innerWidth * 0.8
    setTaskDrawerWidth(initialWidth)

    // Optional: Adjust the drawer width dynamically on window resize
    const handleResize = () => {
      const adjustedWidth = Math.min(Math.max(500, window.innerWidth * 0.8), window.innerWidth * 0.9)
      setTaskDrawerWidth(adjustedWidth)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [taskDrawerOpen]) // Empty dependency array ensures this runs once on mount

  const handleMouseUp = () => {
    // Remove the styles to re-enable text selection
    document.body.style.removeProperty('user-select')
    document.body.style.removeProperty('webkit-user-select')
    document.body.style.removeProperty('moz-user-select')
    document.body.style.removeProperty('ms-user-select')

    document.removeEventListener('mousemove', handleMouseMove)
    document.removeEventListener('mouseup', handleMouseUp)
  }

  return (
    <>
      {isTaskLoading ? (
        <CircularProgress />
      ) : (
        <Drawer
          anchor='right'
          sx={{
            '& .MuiDrawer-paper': {
              width: taskDrawerWidth, // Use the state to set the width
            },
          }}
          open={taskDrawerOpen}
          onClose={closeTaskDrawer}
        >
          <MyStyledComponent sx={{ backgroundColor: drawerBackgroundColor }}>
            <CustomBox>
              <Tabs value={tabIndex} onChange={handleChange}>
                {DRAWER_CONFIG.map((tab, index) => (
                  <StyledTab key={index} label={tab.label} />
                ))}
              </Tabs>
            </CustomBox>
            <Box
              sx={{
                flex: 1,
                overflowY: 'auto',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: '20px', // Adjust this value as needed
                  overflow: 'visible',
                }}
              >
                {tabIndex === 0 && task && <TaskDetails task={[task]} />}
                {tabIndex === 1 && task && <TaskMessages task={task} />}
              </Box>
            </Box>
          </MyStyledComponent>
          <Box
            sx={{
              width: '5px',
              cursor: 'ew-resize',
              backgroundColor: 'lightgray',
              height: '100%',
              position: 'absolute',
              left: 0,
              top: 0,
              zIndex: 1200,
            }}
            onMouseDown={handleMouseDown}
          />
        </Drawer>
      )}
    </>
  )
}

export default TaskDrawer
