import React, { useState, useEffect, useRef } from 'react'
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Stack,
  Box,
  useTheme,
  Grid,
  Tooltip,
  IconButton,
} from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider, DateRangePicker } from '@mui/x-date-pickers-pro'
import { useCreateDeal } from '../../api/aws/useCreateDeal'
import { BoxScroller, CreateProjectTextField } from '../../style/styleElements'
import { dialogStyles, dialogContentStyles } from './style'
import StageDefaultTextInput from './StageDefaultTextInput'
import DealTypeSelect from '../DealTypeSelect/DealTypeSelect'
import ContractDialog from '../ContractDialog/ContractDialog'
import ContactsAutocomplete from '../Contacts/ContactsAutocomplete'
import { getUniqueIdentifier } from './getUniqueIdentifier'
import { useCreateDocument } from '../../api/PandaDoc/useCreateDocument'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import CreateDealHeader from './CreateDealHeader'
import CreateDealOrgAutocomplete from './CreateDealOrgAutocomplete'
import ValidateDiscoveryIcons from './ValidateDiscoveryIcons'
import ValidateDiscovery from './ValidateDiscovery'
import ExistingDeal from './ExistingDealSelect'
import TestDealCheck from './TestDealCheck'
import { useOrganizations } from '../../api/aws/useOrganizations'
import DetailsLoading from '../Loading/DetailsLoading'
import { useGetQuery } from '../../api/aws/useGetQuery'
import { useActiveUser } from '../../api/slack/useActiveUser'
import { useDeals } from '../../api/aws/useDeals'
import { useContactsById } from '../../api/customHooks/useContactsById'
import { useContacts } from '../../api/aws/useContacts'
import EditVerticalAutocomplete from '../EditModeComponents/EditVerticalAutocomplete'
import { useUpdateItems } from '../../api/aws/useUpdateItems'
import { initialDealState } from '../../context/DealsDetailsProvider/initialDealState'
import useTotals from '../../api/customHooks/useTotals'
import { useDealDetailsContext } from '../../context/DealsDetailsProvider/DealsDetailsProvider'
import { createDealTimelineData } from '../Timeline/createTimelineData'
import dayjs from 'dayjs'
import { formatDayjsDate } from '../DataGrid/createColumns/renderDealsColumns'
import { normalizeDate } from '../DateTime/dateUtils'
import SelectAgreementOption from './SelectAgreementOption'
import SelectInvoiceOption from './SelectInvoiceOption'
import { useNavigate } from 'react-router-dom'
import CreateContractComponent from '../Deals/CreateContractComponent'
import { baseUrl } from '../../api/queryMutations/mutationFunctions'

const verticalsRequiringApproval = [
  'Construction',
  'Tourism',
  'Marketing Agency',
  'Manufacturing',
  'Entertainment',
  'Restaurant (Food & Beverage)',
  'City/Government',
  'Financial Institution',
  'Technology',
  'Medical',
  'Lawyer',
  'Education',
  'Senior',
  'Homebuilding',
  'Build to rent',
]

const CreateDeal = ({ open, onClose }) => {
  const navigate = useNavigate()
  const { activeUser, isActiveUserLoading, isActiveUserError } = useActiveUser()
  const {
    newDeal,
    setNewDeal,
    productRows,
    setProductRows,
    addRow,
    removeRow,
  } = useDealDetailsContext()
  const {
    data: organizations,
    isLoading: isOrganizationsLoading,
    isError: isOrganizationsError,
  } = useOrganizations(true, ['createDeal', 'organizations'])
  const [selectedOrganization, setSelectedOrganization] = useState([])
  const [selectedContacts, setSelectedContacts] = useState([])
  const { contacts, isContactsLoading, isContactsError } = useContacts(true, [
    'createDeal',
    'contacts',
  ])
  const { mutate: updateItems } = useUpdateItems()
  const [contactIds, setContactIds] = useState([])
  const {
    data: fetchedContacts,
    isLoading: fetchedContactsLoading,
    isError: fetchedContactsError,
  } = useContactsById(contactIds || [])
  const [openTooltip, setOpenTooltip] = useState(false) // To control tooltip visibility
  const [isCreating, setIsCreating] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isCreateContractOpen, setIsCreateContractOpen] = useState(false)
  const [isContractDialogOpen, setIsContractDialogOpen] = useState(false)
  const { deals, isDealsLoading, isDealsError } = useDeals(true, [
    'createDeal',
    'deals',
  ])
  const { mutate: createDeal } = useCreateDeal()
  const theme = useTheme()
  const { mutate: createDocument } = useCreateDocument()
  const { showSnackbar } = useSnackbarContext()
  const [uniqueIdentifier, setUniqueIdentifier] = useState('')
  const totals = useTotals(
    productRows,
    newDeal?.implementationFee || 0,
    newDeal.type
  )
  const [validationErrors, setValidationErrors] = useState({
    organization: false,
    type: false,
    startDate: false,
    endDate: false,
  })
  const [isValid, setIsValid] = useState(false)
  const [missingFields, setMissingFields] = useState([])
  const [iconColor, setIconColor] = useState('gray')
  const [noSignature, setNoSignature] = useState(false)
  const [isTest, setIsTest] = useState(false)
  const [dealTypeName, setDealTypeName] = useState('')
  const testUsers = ['U062JDP8R5X']
  const [agreementOption, setAgreementOption] = useState('')
  const [invoiceOption, setInvoiceOption] = useState('')

  const fetchContactsForOrganization = (organization) => {
    // Ensure primaryContactId is an array
    const primaryContactId =
      organization && Array.isArray(organization.primaryContact)
        ? organization.primaryContact
        : organization.primaryContact
        ? [organization.primaryContact]
        : []

    const contactIds = [...primaryContactId, ...(organization.contacts || [])]
    setContactIds(contactIds)
  }

  const handleSelectOrganization = (organization) => {
    setSelectedOrganization(organization)

    if (organization) {
      fetchContactsForOrganization(organization)
      setNewDeal((prev) => ({
        ...prev,
        organizations: [organization.id || organization.org_uuid],
      }))

      // Check if the organization has a valid vertical
      if (
        !organization.vertical ||
        organization.vertical.includes('No Vertical Assigned')
      ) {
        console.log('No Valid Vertical Assigned')
        setIsValid(false) // Disable buttons
        setValidationErrors((prev) => ({
          ...prev,
          organization: true, // Trigger error
          organizationHelperText:
            'No valid vertical assigned to the organization', // Set helper text
        }))
      } else {
        setIsValid(true) // Enable buttons
        setValidationErrors((prev) => ({
          ...prev,
          organization: false,
          organizationHelperText: '', // Clear the helper text when valid
        }))
      }
    } else {
      setSelectedContacts([])
      setNewDeal((prev) => ({
        ...prev,
        organizations: [],
        contacts: [],
      }))
      setIsValid(false) // Disable buttons if no organization is selected
    }
  }

  useEffect(() => {
    if (fetchedContacts) {
      setSelectedContacts(fetchedContacts)
      setNewDeal((prev) => ({
        ...prev,
        contacts: fetchedContacts.map((contact) => contact.contacts_uuid),
      }))
    }
  }, [fetchedContacts])
  const resetDealState = () => {
    setNewDeal(initialDealState)
    setSelectedOrganization(null)
    setSelectedContacts([])
    setUniqueIdentifier('')
    setIsError(false)
    setIconColor('gray')
    setNoSignature(false)
    setDealTypeName('')
  }
  useEffect(() => {
    if (selectedOrganization && newDeal.type !== 'IO') {
      if (
        selectedOrganization.vertical &&
        Array.isArray(selectedOrganization.vertical) &&
        selectedOrganization.vertical.some((v) =>
          verticalsRequiringApproval.includes(v)
        )
      ) {
        setNewDeal((prev) => ({
          ...prev,
          isApproved: false,
          needsApproval: true,
          isRequested: false,
        }))
      }
    }
  }, [selectedOrganization, newDeal.type])

  const handleCloseCreateDeal = (event, reason) => {
    if (reason && reason === 'backdropClick') return
    resetDealState()
    setSelectedContacts([])
    setUniqueIdentifier('')
    setIsError(false)
    onClose()
  }

  const handleOpenCreateContract = () => {
    setIsCreateContractOpen(true)
  }

  const handleCloseCreateContract = () => {
    setIsCreateContractOpen(false)
  }

  const handleOpenContractDialog = () => {
    setIsContractDialogOpen(true)
  }
  useEffect(() => {
    if (isContractDialogOpen) {
      console.log('isContractDialogOpen', isContractDialogOpen)
    }
  }, [isContractDialogOpen])
  const handleCloseContractDialog = () => {
    console.log('cancelling....')
    setIsContractDialogOpen(false)
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    setNewDeal((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const validateFields = () => {
    const hasValidVertical =
      selectedOrganization?.vertical &&
      !selectedOrganization.vertical.includes('No Vertical Assigned')

    const errors = {
      organization:
        (newDeal &&
          Array.isArray(newDeal.organizations) &&
          newDeal.organizations.length === 0) ||
        !hasValidVertical,
      type: !newDeal.type,
      startDate: !newDeal.startDate,
      endDate: !newDeal.endDate,
      agreementOption: !agreementOption, // Add this validation
      invoiceOption: !invoiceOption, // Add this validation
    }

    setValidationErrors(errors)

    // Ensure all fields are valid, including the vertical
    return !Object.values(errors).some(Boolean)
  }

  useEffect(() => {
    setIsValid(validateFields())
  }, [newDeal])

  const handleSaveDealWithoutContract = (event) => {
    event.preventDefault()
    if (!validateFields()) return

    setNewDeal((prev) => ({
      ...prev,
      status: 'Draft',
    }))

    setIsCreating(true)
    const updatedDeal = {
      ...newDeal,
      owner: activeUser && activeUser.user ? [activeUser.user.id] : [],
      createdBy: activeUser && activeUser.user ? activeUser.user.id : '',
      stage: newDeal && newDeal.noSignature ? 'Deal Won' : newDeal.stage,
      status:
        newDeal && newDeal.noSignature
          ? 'Completed Internally'
          : newDeal.status,
    }
    const timelineData = createDealTimelineData(
      activeUser,
      'Creation',
      'Approval',
      'New Deal Created'
    )

    console.log('{updatedDeal, timelineData}', {
      updatedDeal,
      timelineData,
    })
    createDeal(
      { updatedDeal, timelineData },
      {
        onSuccess: (data) => {
          console.log('ON SUCCESS DATA: ', data)
          setIsCreating(false)
          resetDealState()
          onClose()
        },
        onError: (error) => {
          setIsCreating(false)
          setIsError(true)
        },
      }
    )
  }
  const handleSaveDealAndNavigate = (event) => {
    event.preventDefault()
    if (!validateFields()) return

    setNewDeal((prev) => ({
      ...prev,
      status: 'Draft',
    }))

    setIsCreating(true)
    const updatedDeal = {
      ...newDeal,
      owner: activeUser && activeUser.user ? [activeUser.user.id] : [],
      createdBy: activeUser && activeUser.user ? activeUser.user.id : '',
      stage: newDeal && newDeal.noSignature ? 'Deal Won' : newDeal.stage,
      status:
        newDeal && newDeal.noSignature
          ? 'Completed Internally'
          : newDeal.status,
    }
    const timelineData = createDealTimelineData(
      activeUser,
      'Creation',
      'Approval',
      'New Deal Created'
    )

    console.log('{updatedDeal, timelineData}', {
      updatedDeal,
      timelineData,
    })
    createDeal(
      { updatedDeal, timelineData },
      {
        onSuccess: (data) => {
          console.log('ON SUCCESS DATA: ', data)
          setIsCreating(false)
          resetDealState()
          navigate(`/deals/${data?.deal?.deal_uuid}`)
          onClose()
        },
        onError: (error) => {
          setIsCreating(false)
          setIsError(true)
        },
      }
    )
  }

  // Handle vertical change in CreateDeal
  const handleVerticalChange = (event) => {
    const newVerticals = event.target.value // Get the new vertical values

    // Update the vertical in state without triggering backend update yet
    setSelectedOrganization((prev) => ({
      ...prev,
      vertical: newVerticals, // Update the selected organization's vertical
    }))

    setNewDeal((prev) => ({
      ...prev,
      organizations: [prev.organizations[0]], // Keep other organization-related fields intact
    }))
  }

  // Handle the blur event to trigger backend update
  const handleVerticalBlur = (event) => {
    const { name, value } = event.target

    console.log('Vertical Blur Event:', { name, value }) // Debugging the blur event

    // Ensure that a value exists before proceeding
    if (value) {
      // Update the state (trigger the vertical update)
      handleChange({ target: { name, value } })
      handleVerticalUpdate(value) // Call the backend update function
    } else {
      // Handle the case when no value is provided (optional)
      console.warn(`No value provided for ${name}. Setting to default.`)
      handleChange({ target: { name, value: '' } })
      handleVerticalUpdate('') // Send empty value to backend
    }
  }

  // Call this function on blur to trigger the backend update
  const handleVerticalUpdate = (updatedVertical) => {
    if (!selectedOrganization || !selectedOrganization.org_uuid) {
      console.error('No valid organization selected for vertical update')
      return
    }

    const updates = [
      {
        primaryKey: 'org_uuid',
        tableName: 'organizations',
        itemId: selectedOrganization.org_uuid, // Use the organization's unique ID
        fieldName: 'vertical',
        newValue: updatedVertical, // Send the correct vertical array
      },
    ]

    // Perform the update
    updateItems(
      { updates },
      {
        onSuccess: (data) => {
          console.log('Vertical updated successfully: ', data)
          showSnackbar('Organization vertical updated successfully', 'success')
          setOpenTooltip(false)
        },
        onError: (error) => {
          console.error('Error updating vertical: ', error)
          showSnackbar('Error updating organization vertical', 'error')
        },
      }
    )
  }

  // Ensure this function is not called directly in render logic
  const onChangeOrganization = async (newValue) => {
    if (!newValue) {
      setNewDeal((prev) => ({
        ...prev,
        organizations: [],
        name: '',
        dba: '',
        contacts: [],
      }))
      setUniqueIdentifier('')
      setSelectedContacts([])
      setMissingFields([])
      setIconColor('gray')
      setSelectedOrganization(null)
      return
    }

    handleSelectOrganization(newValue)

    const primaryContactIds =
      newValue &&
      newValue.primaryContact &&
      Array.isArray(newValue.primaryContact)
        ? newValue.primaryContact
        : []
    const additionalContactIds =
      newValue && newValue.contacts && Array.isArray(newValue.contacts)
        ? newValue.contacts
        : []
    const contactIds = [...additionalContactIds, ...primaryContactIds]

    await fetchContactsForOrganization(contactIds)

    const newIdentifier = getUniqueIdentifier(deals.map((deal) => deal.name))
    setUniqueIdentifier(newIdentifier)

    setNewDeal((prev) => ({
      ...prev,
      organizations: [newValue.id || newValue.org_uuid],
      name: `${newValue.name} - ${dealTypeName}`, // Combine organization name and deal type
      dba: newValue.dba,
      contacts: contactIds,
    }))

    const newSelectedContacts = contacts.filter((contact) =>
      contactIds.includes(contact.contacts_uuid)
    )
    setSelectedContacts(newSelectedContacts)
  }

  const handleAddContact = (newValue) => {
    setNewDeal((prev) => ({
      ...prev,
      contacts: Array.isArray(newValue)
        ? newValue.map((item) => item.contacts_uuid)
        : newValue
        ? [newValue.contacts_uuid]
        : [],
    }))
  }

  useEffect(() => {
    if (!open) {
      resetDealState()
      setSelectedContacts([])
      setUniqueIdentifier('')
      setOpenTooltip(false)
      setIsError(false)
    }
  }, [open])

  // Ref to store the Dialog element
  const dialogRef = useRef(null)

  const handleDealTypeChange = (newValue) => {
    const isCSAType = ['CSA', 'RCSA'].includes(newValue)
    const isTypeIO = newValue === 'IO'
    const isTypeMSA = newValue === 'MSA'

    setDealTypeName(newValue)

    setNewDeal((prev) => {
      let needsApproval = prev.needsApproval

      // If it's an MSA deal, check if the vertical is "Student" or "Multifamily"
      if (isTypeMSA) {
        const requiresApproval =
          selectedOrganization &&
          selectedOrganization.vertical &&
          Array.isArray(selectedOrganization.vertical) &&
          selectedOrganization.vertical.some((v) =>
            verticalsRequiringApproval.includes(v)
          ) &&
          !['Student', 'Multifamily', 'Student Housing'].includes(
            selectedOrganization.vertical[0]
          )

        needsApproval = requiresApproval
      }

      return {
        ...prev,
        name: selectedOrganization
          ? `${selectedOrganization.name} - ${newValue}`
          : '',
        type: newValue,
        needsApproval: isCSAType
          ? true
          : isTypeMSA
          ? needsApproval
          : prev.needsApproval,
      }
    })
  }

  const handleAgreementOption = (event) => {
    const selectedType = event.target.value
    setAgreementOption(selectedType)
    setNewDeal((prev) => ({
      ...prev,
      agreementOption: selectedType, // Add to the deal's properties
    }))
  }
  const handleInvoiceOptionChange = (event) => {
    const selectedInvoiceOption = event.target.value
    setInvoiceOption(selectedInvoiceOption)
    setNewDeal((prev) => ({
      ...prev,
      invoiceOption: selectedInvoiceOption, // Add to the deal's properties
    }))
  }

  const isLoading =
    isOrganizationsLoading || isContactsLoading || isActiveUserLoading
  return (
    <>
      <Dialog
        open={open}
        onClose={handleCloseCreateDeal}
        fullWidth
        maxWidth="md"
        PaperProps={{
          sx: dialogStyles(theme),
        }}
        slotProps={{ backdrop: () => {} }}
        disableEscapeKeyDown
        ref={dialogRef}
      >
        <CreateDealHeader context="Create Deals" />
        <BoxScroller
          sx={{
            '&::-webkit-scrollbar-thumb': {
              backgroundColor:
                theme.palette.mode === 'dark'
                  ? 'rgba(167,51,51,1)'
                  : 'rgba(0,0,0,0.5)',
              borderRadius: '6px',
            },
          }}
        >
          {isLoading && <DetailsLoading />}
          <DialogContent ref={dialogRef} sx={dialogContentStyles}>
            <Stack>
              <Grid container>
                <Grid item xs={10}>
                  <CreateDealOrgAutocomplete
                    organizations={organizations}
                    multiple={false}
                    label={'Select Organization'}
                    value={selectedOrganization}
                    addOrganization={onChangeOrganization}
                    setIsValid={setIsValid}
                    error={validationErrors.organization} // Pass error state
                    helperText={validationErrors.organizationHelperText} // Pass helper text
                    openTooltip={openTooltip}
                    setOpenTooltip={setOpenTooltip}
                  />
                </Grid>

                <ValidateDiscoveryIcons
                  organization={selectedOrganization}
                  missingFields={missingFields}
                  setMissingFields={setMissingFields}
                  isLoading={isLoading}
                />
              </Grid>
              <Grid item xs={6}>
                <ValidateDiscovery
                  organization={selectedOrganization}
                  missingFields={missingFields}
                  setMissingFields={setMissingFields}
                />
              </Grid>
              {/* Conditional Fields */}
              <Grid container spacing={0} alignItems="center">
                {selectedOrganization &&
                  (!Array.isArray(selectedOrganization.vertical) ||
                    selectedOrganization.vertical.length === 0 ||
                    selectedOrganization.vertical.includes(
                      'No Vertical Assigned'
                    )) && (
                    <Grid item xs={12} sm={6} xl={12}>
                      <EditVerticalAutocomplete
                        name="vertical"
                        value={selectedOrganization.vertical || []}
                        onChange={handleVerticalChange}
                        onBlur={handleVerticalBlur}
                        hasDeals={selectedOrganization?.deals?.length > 0}
                      />
                    </Grid>
                  )}
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={12} sm={6}>
                    <SelectAgreementOption
                      agreementOption={agreementOption}
                      onChange={handleAgreementOption}
                      validationErrors={validationErrors}
                    />
                  </Grid>
                  {/* Invoice Option */}
                  <Grid item xs={12} sm={6}>
                    <SelectInvoiceOption
                      invoiceOption={invoiceOption}
                      onChange={handleInvoiceOptionChange}
                      validationErrors={validationErrors}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container alignItems="center">
                <Grid item xs={8}>
                  <StageDefaultTextInput
                    stage={newDeal.stage}
                    handleChange={handleChange}
                    error={validationErrors.type}
                    helperText={
                      validationErrors.type && 'Deal type is required'
                    }
                    noSignature={noSignature}
                  />
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{ display: 'flex', justifyContent: 'center' }}
                >
                  <ExistingDeal
                    noSignature={noSignature}
                    setNoSignature={setNoSignature}
                    setNewDeal={setNewDeal}
                  />
                </Grid>
              </Grid>
              <Grid container alignItems="center">
                <Grid item xs={12} xl={12}>
                  <DealTypeSelect
                    newDeal={newDeal}
                    setNewDeal={setNewDeal}
                    error={validationErrors.type}
                    helperText={
                      validationErrors.type && 'Deal type is required'
                    }
                    onChange={handleDealTypeChange}
                  />
                </Grid>
              </Grid>
              <ContactsAutocomplete
                contacts={contacts}
                selectedContacts={selectedContacts}
                setSelectedContacts={setSelectedContacts}
                setNewDeal={setNewDeal}
                label="Select Contacts"
                onChange={handleAddContact}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <DateRangePicker
                    startText="Expected Start Date"
                    endText="Expected Close Date"
                    value={[
                      newDeal.startDate ? dayjs(newDeal.startDate) : null,
                      newDeal.endDate ? dayjs(newDeal.endDate) : null,
                    ]}
                    onChange={(newValue) => {
                      const [startDate, endDate] = newValue

                      setNewDeal((prev) => ({
                        ...prev,
                        startDate: normalizeDate(startDate, 'America/New_York'),
                        endDate: normalizeDate(endDate, 'America/New_York'),
                      }))
                    }}
                    slotProps={{
                      textField: {
                        error:
                          validationErrors.startDate ||
                          validationErrors.endDate,
                      },
                    }}
                    sx={{
                      width: '100%',
                      '& .MuiMultiInputDateRangeField-separator': {
                        marginLeft: 0,
                        marginRight: 0,
                      },
                    }}
                  />
                </Box>
              </LocalizationProvider>
              {activeUser &&
              activeUser.user &&
              testUsers.includes(activeUser.user.id) ? (
                <Grid container alignItems="center">
                  <Grid item xs={12}>
                    <CreateProjectTextField
                      label="Probability (%)"
                      name="probability"
                      value={newDeal.probability}
                      onChange={handleChange}
                      fullWidth
                      autoComplete="off"
                      type="number"
                      inputProps={{
                        min: 0,
                        max: 100,
                        step: 1, // Controls the increment/decrement step
                      }}
                    />
                  </Grid>
                  {/* <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <TestDealCheck isTest={isTest} setIsTest={setIsTest} setNewDeal={setNewDeal} />
                  </Grid> */}
                </Grid>
              ) : (
                <CreateProjectTextField
                  label="Probability (%)"
                  name="probability"
                  value={newDeal.probability}
                  onChange={handleChange}
                  fullWidth
                  autoComplete="off"
                  type="number"
                  inputProps={{
                    min: 0,
                    max: 100,
                    step: 1, // Controls the increment/decrement step
                  }}
                />
              )}
            </Stack>
          </DialogContent>
        </BoxScroller>
        <DialogActions>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Button
              sx={{
                marginLeft: 4,
                backgroundColor: 'rgb(244, 67, 54, 0.2)',
                '&:hover': {
                  backgroundColor: 'rgb(244, 67, 54, 0.7)',
                },
              }}
              onClick={handleCloseCreateDeal}
              color="primary"
              variant="outlined"
            >
              Cancel
            </Button>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <Button
                onClick={handleSaveDealWithoutContract}
                disabled={isCreating || !isValid}
                color="secondary"
                variant="contained"
              >
                Save Deal
              </Button>
              <Button
                onClick={handleSaveDealAndNavigate}
                disabled={isCreating || !isValid}
                color="primary"
                variant="contained"
              >
                Next
              </Button>
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default CreateDeal
