import { useQueryClient, useMutation } from '@tanstack/react-query'
import { baseUrl } from '../queryFunctions/queryFunctions'
import axios from 'axios'

const slackReply = async payload => {
  const slackToken = localStorage.getItem('slack_access_token')
  if (!slackToken) {
    console.error('No authentication token available')
    return
  }

  try {
    const response = await axios.post(
      `${baseUrl}/slack/reply`,
      payload, // Now directly sending JSON payload
      {
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: slackToken,
        },
      }
    )
    return response.data
  } catch (error) {
    console.error('Error sending reply message:', error)
    throw error // Re-throw the error for useMutation's onError to catch
  }
}

export const useSlackReply = () => {
  const queryClient = useQueryClient()
  const mutation = useMutation({
    // Directly pass the object to the mutation function
    mutationFn: slackReply,
    onSuccess: data => {
      console.log('Reply Sent Successfully: ', data)
      queryClient.invalidateQueries(['threadMessages'])
    },
    onError: error => {
      console.error('[useSlackReply] Error sending reply:', error)
    },
  })

  return mutation
}
