import React, { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import Loading from '../components/Loading/Loading'
import { useUser } from '../api/aws/useUser'
import { useValidateGoogleToken } from '../api/google/useValidateGoogleToken.ts'

const ProtectedRoute = ({ children }) => {
  const { validateToken, isValid, accessToken, refreshed } = useValidateGoogleToken()
  const { user: currentUser, isUserLoading, isUserError } = useUser(localStorage.getItem('slack_user_id'))

  const [checking, setChecking] = useState(true)

  const googleAccessToken = localStorage.getItem('google_access_token')
  const slackAccessToken = localStorage.getItem('slack_access_token')
  const pandaAccessToken =
    process.env.REACT_APP_ENV === 'local' || process.env.REACT_APP_ENV === 'laptop'
      ? '44c682867cd90875b3ba53db7280c56e1a4d6068'
      : localStorage.getItem('panda_access_token')

  const allTokensPresent = googleAccessToken && slackAccessToken && pandaAccessToken

  useEffect(() => {
    if (isUserLoading) return // Don't run token validation if user data isn't loaded yet

    const verifyGoogleToken = async () => {
      if (!googleAccessToken) {
        setChecking(false)
        return
      }

      // Validate Google token only when user data is available
      validateToken({
        accessToken: googleAccessToken,
        refreshToken: currentUser?.googleRefreshToken, // This should be available now
        userId: localStorage.getItem('slack_user_id'),
      })
    }

    verifyGoogleToken()
  }, [validateToken, currentUser, isUserLoading]) // Depend on currentUser and isUserLoading

  useEffect(() => {
    if (refreshed && accessToken) {
      localStorage.setItem('google_access_token', accessToken)
    }
    if (isValid) {
      setChecking(false)
    }
  }, [isValid, refreshed, accessToken])

  // Show loading while user data is still being fetched
  if (isUserLoading) {
    return <Loading />
  }

  if (!allTokensPresent) {
    return <Navigate to='/' />
  }

  return children
}

export default ProtectedRoute

// import React from 'react'
// import { Navigate } from 'react-router-dom'
// import Loading from '../components/Loading/Loading'

// const ProtectedRoute = ({ children }) => {
//   const googleAccessToken = localStorage.getItem('google_access_token')
//   const slackAccessToken = localStorage.getItem('slack_access_token')
//   const pandaAccessToken =
//     process.env.REACT_APP_ENV === 'local' || process.env.REACT_APP_ENV === 'laptop'
//       ? '44c682867cd90875b3ba53db7280c56e1a4d6068'
//       : localStorage.getItem('panda_access_token')

//   const allTokensPresent = googleAccessToken && slackAccessToken && pandaAccessToken

//   // console.log('Google Access Token in ProtectedRoute:', googleAccessToken)
//   // console.log('Slack Access Token in ProtectedRoute:', slackAccessToken)
//   // console.log('Panda Access Token in ProtectedRoute:', pandaAccessToken)
//   // console.log('All Tokens Present:', allTokensPresent)
//   if (allTokensPresent) {
//     console.log('*** Fully Authenticated - All Tokens Present ***')

//     if (!allTokensPresent) {
//       return <Loading /> // Show loading while checking tokens
//     }
//     // console.log(
//     //   `All Tokens Present...\n[Google Access Token]: ${googleAccessToken}\n[Slack Access Token]: ${slackAccessToken}\n[Panda Access Token]: ${pandaAccessToken}`
//     // )
//   }

//   return allTokensPresent ? children : <Navigate to='/' />
// }

// export default ProtectedRoute
