import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { baseUrl } from '../queryMutations/queryMutations'

const getDealFolders = async (parentFolderId: string, creation_timestamp?: string) => {
  if (!parentFolderId) return []
  const response = await axios.get(`${baseUrl}/aws/googleapi/drive/subfolder-items`, {
    params: { parentFolderId, creation_timestamp },
    headers: { Authorization: `Bearer ${localStorage.getItem('google_access_token')}` },
  })

  return response.data.subfolderItems
}

export const useDealFolders = (parentFolderId: string | null, creation_timestamp?: string) => {
  return useQuery({
    queryKey: ['dealFolders', parentFolderId, creation_timestamp],
    queryFn: () => getDealFolders(parentFolderId!, creation_timestamp),
    enabled: !!parentFolderId, // Fetch only if parentFolderId exists
    staleTime: 60000, // Cache for 1 min
  })
}
