import React, { useState } from 'react'
import { Box, Modal, Typography, IconButton, useTheme } from '@mui/material'
import AuthorizedLinkItem from './AuthorizedLinkItem'
import ContactLinkItem from './ContactLinkItem'
import { useUser } from '../../api/aws/useUser'
import DetailsLoading from '../Loading/DetailsLoading'
import { useContact } from '../../api/aws/useContact'
import { handleCopy } from './utils/handleCopy'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  width: '50vw',
  p: 4,
}

const ViewLinksModal = ({ isOpen, onClose, newDeal }) => {
  const [isAuthCopied, setIsAuthCopied] = useState(false)
  const [isContactCopied, setIsContactCopied] = useState(false)
  const { user: authorizedUser, isUserLoading, isUserError } = useUser(
    newDeal?.recipients?.authorizedRecipient?.[0]?.id,
    'authorizedUser'
  )
  const { data: contact, isLoading: isContactLoading, isError: isContactError } = useContact(
    newDeal?.recipients?.contactRecipient?.[0]?.id,
    ['contact', 'contactRecipient', newDeal?.recipients?.contactRecipient?.[0]?.id]
  )
  console.log(contact)
  const theme = useTheme()

  if (isUserLoading || isContactLoading) {
    return <DetailsLoading />
  }
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={{ ...modalStyle, display: 'flex', flexDirection: 'column', gap: 7 }}>
        <Typography id='enter-link-emails-typography' variant='h6' component='h2'>
          {`Generated Links for ${newDeal?.name}`}
        </Typography>
        <AuthorizedLinkItem
          label={'Authorized Link'}
          onCopy={() => handleCopy(newDeal?.authorizedDocumentLink, setIsAuthCopied)}
          isCopied={isAuthCopied}
          linkContent={newDeal?.authorizedDocumentLink}
          imageUrl={authorizedUser?.image_512}
        />
        <ContactLinkItem
          label={'Contact Link'}
          onCopy={() => handleCopy(newDeal?.contactDocumentLink, setIsContactCopied)}
          isCopied={isContactCopied}
          linkContent={newDeal?.contactDocumentLink}
        />
      </Box>
    </Modal>
  )
}

export default ViewLinksModal
