export const statusFilters = [
  { key: 'orgPage-status', label: 'Prospect', value: 'Prospect', color: 'rgb(93, 79, 251)' },
  { key: 'orgPage-status', label: 'Onboarding', value: 'Onboarding', color: 'rgb(92, 179, 253)' },
  { key: 'orgPage-status', label: 'In-Progress', value: 'In Progress', color: 'rgb(110, 156, 222)' },
  { key: 'orgPage-status', label: 'Active', value: 'Active', color: 'rgb(152, 214, 129)' },
  { key: 'orgPage-status', label: 'Active (SEO)', color: 'rgb(152, 98, 161)' },
  { key: 'orgPage-status', label: 'Active (Creative)', color: 'rgb(147, 111, 218)' },
  { key: 'orgPage-status', label: 'Active (Website)', color: 'rgb(102, 69, 169)' },
  { key: 'orgPage-status', label: 'Active (Past Due)', color: 'rgb(255, 131, 88)' },
  { key: 'orgPage-status', label: 'Active (LTP)', color: 'rgb(51, 158, 205)' },
  { key: 'orgPage-status', label: '30 day notice', value: '30 day notice', color: 'rgb(235, 77, 77)' },
  { key: 'orgPage-status', label: 'Cancelled', value: 'Cancelled', color: 'rgb(0,0,0)' },
  { key: 'orgPage-status', label: 'Inactive / Paused', color: 'rgb(255, 145, 145)' },
]
export const organizationTypeOptions = [
  {
    key: 'orgPage-type',
    label: 'Property Management Company',
    value: 'PMC',
    color: 'rgb(93, 79, 251)',
  },
  { key: 'orgPage-type', label: 'Ownership Group', value: 'OG', color: 'rgb(92, 179, 253)' },
  { key: 'orgPage-type', label: 'Housing Property', value: 'HP', color: 'rgb(152, 214, 129)' },
  { key: 'orgPage-type', label: 'Parent Client', value: 'PC', color: 'rgb(93, 79, 251)' },
  { key: 'orgPage-type', label: 'Agency Whitelabel', value: 'AW', color: 'rgb(92, 179, 253)' },
  {
    key: 'orgPage-type',
    label: 'Organization',
    value: 'ORG',
    color: 'rgb(242, 140, 40)',
  },
]
