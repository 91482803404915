import React, { useEffect, useState } from 'react'
import { Box, TextField, Chip, CircularProgress, Typography, useTheme } from '@mui/material'
import { Autocomplete } from '@mui/lab'
import RenderCompanyChip from '../RenderChipComponents/RenderCompanyChip'

const MassUpdateContractedBy = ({ name, options, selectionModel, value = [], displayLabel, onChange, onBlur }) => {
  const theme = useTheme()
  const [selectedValues, setSelectedValues] = useState([])
  const [selectedOrganizations, setSelectedOrganizations] = useState(Array.isArray(value) ? value : [])
  const [filteredOrganizations, setFilteredOrganizations] = useState([])
  const parentClientTypes = ['Parent Client', 'Agency Whitelabel', 'Property Management Company', 'Ownership Group']
  // **Step 2: Ensure selectedValues is updated when the parent component changes the value**
  useEffect(() => {
    if (options) {
      const filteredOrgs = options
        ?.filter(org => org && org.type && parentClientTypes.includes(org?.type)) // Ensure org is not null
        .filter(org => org.name && org.org_uuid)
        .filter(org => !(selectionModel || []).includes(org?.org_uuid)) // Ensure org has necessary properties
      const matchedValues = options.filter(org => value.includes(org?.name))
      setSelectedValues(matchedValues)
      setFilteredOrganizations(filteredOrgs)
    }
  }, [value, options])

  // **Step 3: Handle Change & Prevent Duplicates**
  const handleChange = (_, newValues) => {
    setSelectedValues(newValues)
    onChange({ target: { name, value: newValues.map(org => org?.org_uuid) } }) // Pass names only
  }

  const handleBlur = () => {
    if (onBlur) {
      onBlur({ target: { name, value: selectedValues.map(org => org.name) } })
    }
  }

  const filterOptions = (options, { inputValue }) => {
    // Filter out null/undefined options and those without a name
    let filtered = options.filter(
      option => option && option.name && option.name.toLowerCase().includes(inputValue.toLowerCase())
    )

    // Remove the logic that adds a new option when inputValue does not match
    return filtered
  }

  const handleRemoveOrganization = orgId => {
    const newSelectedOrganizations = selectedOrganizations.filter(org => org.org_uuid !== orgId)
    setSelectedOrganizations(newSelectedOrganizations)
  }
  return (
    <Box sx={{ width: '100%' }}>
      <Autocomplete
        multiple={true}
        // value={selectedOrganizations}
        id='massupdate-contractedby-autocomplete'
        options={filteredOrganizations}
        onChange={handleChange}
        getOptionLabel={option => option.name || ''}
        filterOptions={filterOptions}
        renderOption={(props, option) => {
          const { key, id, ...rest } = props
          return (
            <Box key={`${key}-${id}`} {...rest}>
              <RenderCompanyChip option={option} />
            </Box>
          )
        }}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => {
            const { key, ...rest } = getTagProps({ index })
            if (!option) {
              return null
            }
            return (
              <Box key={option.org_uuid}>
                <Chip
                  key={key}
                  label={<RenderCompanyChip option={option} />}
                  {...rest}
                  onDelete={() => handleRemoveOrganization(option.org_uuid)}
                  size='small'
                  style={{
                    height: 'auto',
                    width: 'auto',
                    borderColor: theme.palette.text.primary,
                    borderWidth: '0.5px',
                    borderStyle: 'solid',
                    backgroundColor: 'transparent',
                    color: theme.palette.text.primary,
                    borderRadius: '4px',
                    marginRight: '8px',
                  }}
                />
              </Box>
            )
          })
        }
        renderInput={params => (
          <TextField
            {...params}
            label={'Select Organizations'}
            sx={{
              '& .MuiInputBase-input': {
                color: selectedOrganizations ? 'lightblue' : 'inherit',
              },
            }}
          />
        )}
        // renderInput={params => <TextField {...params} label={label} />}
        sx={{
          '& .MuiOutlinedInput-root': {
            height: 45,
            display: 'flex',
            alignItems: 'center',
            paddingRight: '30px !important',
          },
          '& .MuiAutocomplete-input': {
            height: 'auto',
            padding: '10.5px 14px',
          },
          '& .MuiInputBase-input': {
            padding: '10.5px 14px',
          },
        }}
        fullWidth
        freeSolo
      />
      {/* <Autocomplete
        multiple
        id={`${name}-autocomplete`}
        options={uniqueOptions}
        getOptionLabel={option => option.name}
        value={selectedValues}
        onChange={handleChange}
        onBlur={handleBlur}
        filterSelectedOptions
        isOptionEqualToValue={(option, value) => option?.org_uuid === value?.org_uuid} // Fix selection mismatch
        renderInput={params => (
          <TextField
            {...params}
            label={displayLabel}
            variant='outlined'
            fullWidth
            InputProps={{
              ...params.InputProps,
              endAdornment: <>{params.InputProps.endAdornment}</>,
            }}
          />
        )}
        renderTags={(selected, getTagProps) =>
          selected.map((option, index) => {
            return <Typography key={option.org_uuid || option?.id}>{option?.name}</Typography>
          })
        }
      /> */}
    </Box>
  )
}

export default MassUpdateContractedBy
