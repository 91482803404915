import dayjs from 'dayjs'
import RenderPlatformIcon from './RenderPlatformIcon'
import RenderServiceIcon from '../RenderServiceIcons/RenderServiceIcon'
import { calculateTotalMonthsForProducts } from '../../utility/calculateTotalMonths'
import { renderColorByString } from '../DataGrid/renderColorByString'

const formatDayjsDate = dateStr => {
  // console.log('[renderDealsColumns][formatDayjsDate] dateStr: ', dateStr)
  if (dateStr) {
    return dayjs(dateStr).format('MM/DD/YYYY')
  } else {
    return null
  }
}

const formatCurrency = number => {
  const currencyNumnber = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number)
  return `${currencyNumnber}`
}
const currencyColor = 'rgb(92, 203, 131)'
export const addressMap = organization => [
  { type: 'string', label: 'Street', value: organization.street || '' },
  { type: 'string', label: 'City', value: organization.city || '' },
  { type: 'string', label: 'State', value: organization.state || '' },
  { type: 'string', label: 'Zip', value: organization.zip || '' },
]

export const generalInfoMap = organization => [
  {
    // Street: { type: 'string', value: organization.street || '' },
    // City: { type: 'string', value: organization.city || '' },
    // State: { type: 'string', value: organization.state || '' },
    // Zip: { type: 'string', value: organization.zip || '' },
    Name: { type: 'string', value: organization?.name },
    Address: {
      type: 'string',
      value: `${organization?.street || ''}, ${organization?.city || ''}, ${organization?.state || ''}, ${
        organization?.zip || ''
      }`,
    },
    Status: { type: 'string', value: organization?.status || '' },
    Website: { type: 'string', value: organization?.website || '' },
    'Legal Entity Name': { type: 'string', value: organization?.dba || '' },
  },
]
export const totalsMap = (deal, totals, allSame) => [
  {
    'Implementation Fee': {
      type: 'number',
      label: 'Implementation Fee',
      value: formatCurrency(deal?.totals?.implementationFee),
      color: currencyColor,
    },
    'Total Gross Profit': {
      type: 'number',
      label: 'Total Gross Profit',
      value: formatCurrency(deal?.totals?.totalGrossProfit || totals?.grossProfit),
      color: currencyColor,
    },
    'Monthly Total': {
      type: allSame ? 'number' : 'text',
      label: 'Monthly Total',
      value: allSame ? formatCurrency(deal.totals.monthlyTotal) : 'Varying dates',
      color: allSame ? currencyColor : 'rgb(140, 144, 150)',
    },
    'Total Contract Amount': {
      type: 'number',
      label: 'Total Contact Amount',
      value: formatCurrency(totals.total),
      color: currencyColor,
    },
  },
]
export const ioTotalsMap = (deal, totals, allSame) => [
  {
    'Implementation Fee': {
      type: 'number',
      label: 'Implementation Fee',
      value: formatCurrency(deal.implementationFee),
      color: currencyColor,
    },
    'Total Gross Profit': {
      type: allSame ? 'number' : 'text',
      label: 'Total Gross Profit',
      value: allSame ? formatCurrency(totals ? totals.totalGrossProfit : deal.totals.monthlyTotal) : 'Varying dates',
      color: allSame ? currencyColor : 'rgb(140, 144, 150)',
    },
    'Monthly Total': {
      type: allSame ? 'number' : 'text',
      label: 'Monthly Total',
      value: allSame ? formatCurrency(totals ? totals.monthlyTotal : deal.totals.monthlyTotal) : 'Varying dates',
      color: allSame ? currencyColor : 'rgb(140, 144, 150)',
    },
    'Total Contract Amount': {
      type: 'number',
      label: 'Total Contact Amount',
      value: totals ? formatCurrency(totals.total) : formatCurrency(deal.totals.total),
      color: currencyColor,
    },
    'Total Months': {
      type: 'total',
      label: 'Total Months',
      value: `${
        calculateTotalMonthsForProducts(deal && (Array.isArray(deal.products) ? deal.products : [])).totalMonths
      } mo(s).`,
    },
  },
]
export const billingInfoMap = (deal, contractedBy, createdBy, billingContact) => [
  {
    'Contracted By': {
      type: 'string',
      label: 'Contracted By',
      value: contractedBy ? contractedBy.name : '',
    },
    'Created By': {
      type: 'string',
      label: 'Created By',
      value: createdBy ? createdBy.name : '',
    },
    'Billing Contact': {
      type: 'string',
      label: 'Billing Contact',
      value: billingContact ? billingContact.email : '',
    },
    'Start Date': {
      type: 'date',
      label: 'Contract Start Date',
      value: formatDayjsDate(deal.startDate),
    },
    'End Date': {
      type: 'date',
      label: 'Contract End Date',
      value: formatDayjsDate(deal.endDate),
    },
  },
]
export const csaTotalsMap = (deal, totals) => [
  {
    'Implementation Fee': {
      type: 'number',
      label: 'Implementation Fee',
      value: formatCurrency(totals.implementationFee),
      color: currencyColor,
    },
    'Total Gross Profit': {
      type: 'number',
      label: 'Total Gross Profit',
      value: formatCurrency(totals.totalGrossProfit || totals.grossProfit),
      color: currencyColor,
    },
    'Monthly Total': {
      type: 'text',
      label: 'Monthly Total',
      value: '—',
      color: currencyColor,
    },
    'Total Contract Amount': {
      type: 'number',
      label: 'Total Contact Amount',
      value: formatCurrency(totals.total),
      color: currencyColor,
    },
    'Total Months': {
      type: 'total',
      label: 'Total Months',
      value: `${
        calculateTotalMonthsForProducts(deal && (Array.isArray(deal.products) ? deal.products : [])).totalMonths
      } mo(s).`,
    },
  },
]
export const infoMap = organization => [
  { type: 'string', label: 'Status', value: organization.status || '' },
  { type: 'string', label: 'Website', value: organization.website || '' },
  { type: 'string', label: 'DBA', value: organization.dba || '' },
]
export const contractMap = (productList, theme) => {
  return productList
    .map((item, index) => {
      return {
        Service: {
          type: 'string',
          value: <RenderServiceIcon key={`${index}-${item.productName}`} service={item.productName} />,
          description: item.description,
          xs: 2,
        },
        Price: {
          type: 'number',
          value: item.payer === 'Client' ? formatCurrency(item.billedPrice) : formatCurrency(item.price),
          color: item.payer === 'Client' ? theme.palette.text.warning : currencyColor,
          xs: 1.5,
        },
        Margin: { type: 'string', value: `${item.margin}%`, xs: 1.5 },
        'Media Spend': {
          type: 'number',
          value: formatCurrency(item.mediaSpend),
          color: currencyColor,
          xs: 1.5,
        },
        'Start Date': {
          type: 'string',
          value: formatDayjsDate(item.startDate),
          xs: 1.5,
        },
        'End Date': { type: 'string', value: formatDayjsDate(item.endDate), xs: 1.5 },
        Payer: { type: 'string', value: item.payer || 'GRO', xs: 1.25 },
        'Account Used': { type: 'string', value: item.accountUsed || 'GRO', width: 50, xs: 1.25 },
      }
    })
    .filter(item => item !== null)
}
export const ioContractMap = (productList, theme) => {
  return productList
    .map((item, index) => {
      return {
        Service: {
          type: 'string',
          value: <RenderServiceIcon key={`${index}-${item.productName}`} service={item.productName} />,
          description: item.description,
          xs: 2,
        },
        Qty: {
          type: 'qty',
          value: item.qty,
          color: 'rgb(106, 131, 108)',
          xs: 0.5,
        },
        Price: {
          type: 'number',
          value: item.payer === 'Client' ? formatCurrency(item.billedPrice) : formatCurrency(item.price),
          color: item.payer === 'Client' ? theme.palette.text.warning : currencyColor,
          xs: 1.5,
        },
        Margin: { type: 'string', value: `${item.margin}%`, xs: 1.5 },
        'Media Spend': {
          type: 'number',
          value: formatCurrency(item.mediaSpend),
          color: currencyColor,
          xs: 1.5,
        },
        'Start Date': {
          type: 'string',
          value: formatDayjsDate(item.startDate),
          xs: 1.5,
        },
        'End Date': { type: 'string', value: formatDayjsDate(item.endDate), xs: 1.5 },
        Payer: { type: 'string', value: item.payer, xs: 1.25 },
        'Account Used': { type: 'string', value: item.accountUsed, width: 50, xs: 1.25 },
      }
    })
    .filter(item => item !== null)
}
export const payInfoMap = productList => {
  return productList
    .map((item, index) => {
      return item && item.payer && item.accountUsed
        ? {
            Service: {
              type: 'string',
              value: <RenderPlatformIcon key={`${index}-${item.productName}`} service={item.productName} />,
            },
            Payer: { type: 'string', value: item.payer },
            'Account Used': { type: 'string', value: item.accountUsed },
          }
        : null
    })
    .filter(item => item !== null)
}
export const infoMapOne = (organization, billingContact) => [
  {
    // Status: {
    //   key: 'row-status',
    //   type: 'string',
    //   value: organization?.status || '',
    //   color: renderStatusColor(organization?.status),
    // },
    // Website: { key: 'row-DBA', type: 'string', value: organization?.website || '—' },
    'Billing Contact': { key: 'row-billingContact', type: 'string', value: billingContact?.name || '—' },
    'Billing Email': { key: 'row-billingEmail', type: 'string', value: billingContact?.email || '—' },
    'Billing Phone': { key: 'row-billingPhone', type: 'string', value: billingContact?.phone || '—' },
    'Legal Entity Name': { key: 'row-dba', type: 'string', value: organization?.dba || '—' },
  },
]
export const infoMapTwo = organization => [
  {
    Website: { key: 'row-DBA', type: 'string', value: organization?.website || '—' },

    street: { key: 'row-street', type: 'string', value: organization?.street || '—' },
    city: { key: 'row-city', type: 'string', value: organization?.city || '—' },
    state: { key: 'row-state', type: 'string', value: organization?.state || '—' },
    zip: { key: 'row-zip', type: 'string', value: organization?.zip || '—' },
  },
]
export const infoMapThree = organization => [
  {
    Name: { key: 'row-name', type: 'string', value: organization?.name },
    Status: {
      key: 'row-status',
      type: 'string',
      value: organization?.status || '',
      color: renderColorByString(organization?.status),
    },
  },
]
