import React, { createContext, useContext, useState, useEffect } from 'react'
import dayjs from 'dayjs'
import { useCreateDeal } from '../../api/aws/useCreateDeal'
import { useUpdateItems } from '../../api/aws/useUpdateItems'
import { useCreateDocument } from '../../api/PandaDoc/useCreateDocument'
import { useUpdateDeal } from '../../api/aws/useUpdateDeal'
import { useSnackbarContext } from '../SnackbarProvider/SnackbarProvider'
import {
  calculateTotalMonthsForSingleProduct,
  calculateMonthsForDeal,
  calculateDayjsTotalMonths,
} from '../../utility/calculateTotalMonths'
import { createApproversNotification } from '../../components/Notifications/createNotifyPayload'
import { useNotifyMessage } from '../../api/slack/useNotifyMessage'
import { handleEditDealState } from '../../components/DealApproval/determineApprovalState'
import { v4 as uuidv4 } from 'uuid' // For generating unique note IDs
import { useNotifyApprovers } from '../../api/slack/useNotifyApprovers'
import { useApproveDeal } from '../../api/approvals/useApproveDeal'
import { useResolveDeal } from '../../api/approvals/useResolveDeal'
import { useActiveUser } from '../../api/slack/useActiveUser'
import { updateContractTimelineData } from '../../components/Timeline/createTimelineData'
import { useStrategy } from '../../api/aws/useStrategy'

const paidProductsMargins = {
  'Meta Advertising': 40,
  'Linkedin Advertising': 40,
  'Spotify Advertising': 40,
  'TikTok Advertising': 40,
  'Youtube Advertising': 70,
  'Paid Search Advertising': 30,
  'Google Display Advertising': 70,
}
const draftStatuses = [
  'Draft',
  'Saved',
  'Created',
  'Requested',
  'Rejected',
  'Resolved',
  'Approved',
  'Approval Requested',
  'Updated',
  'Modified',
  'Needs Approval',
  'Completed Internally',
]
const determineDraft = deal => {
  if (deal?.status !== 'Completed' && deal?.stage !== 'Deal Won') {
    return true
  } else {
    return false
  }
}

// Create a context for the deal
const DealContext = createContext()

export const useDealDetailsContext = () => useContext(DealContext)

export const DealDetailsProvider = ({ children, dealData }) => {
  const { mutate: sendNotification } = useNotifyMessage()
  const [isDealStatusChange, setIsDealStatusChange] = useState(false)
  const { mutate: sendApproverNotification } = useNotifyApprovers()
  const { mutate: approveDeal } = useApproveDeal()
  const { mutate: resolveDeal } = useResolveDeal()
  const { totalMonths, allSame } = dealData ? calculateDayjsTotalMonths(dealData) : { totalsMonths: 1 }
  const [totalGrossProfit, setTotalGrossProfit] = useState(
    dealData && dealData.grossProfit && totalMonths ? dealData.grossProfit * totalMonths : 0
  )
  const [isEditMode, setIsEditMode] = useState(false)
  const [viewMode, setViewMode] = useState('strategy')
  const [searchCampaigns, setSearchCampaigns] = useState([])
  const [socialCampaigns, setSocialCampaigns] = useState([])
  const [socialAds, setSocialAds] = useState([])
  const [displayCampaigns, setDisplayCampaigns] = useState([])
  const [originalDeal, setOriginalDeal] = useState({
    ...dealData,
    dealStatus: 'In Progress',
    typeChange: false,
  })
  const [newDeal, setNewDeal] = useState({
    ...dealData,
    dealStatus: 'In Progress',
  })
  const [dealId, setDealId] = useState(newDeal ? newDeal.deal_uuid : '')
  const { strategy: currentStrategy, isStrategyLoading, isStrategyError } = useStrategy(dealId)
  const [strategy, setStrategy] = useState(null) // State for strategy data
  const { activeUser } = useActiveUser()
  const [timelineData, setTimelineData] = useState(null)
  const [clientMargin, setClientMargin] = useState(0)
  const [hasProducts, setHasProducts] = useState(false)
  const [productRows, setProductRows] = useState([])
  const [productMargins, setProductMargins] = useState({})
  const [originalType, setOriginalType] = useState(newDeal.type || '')
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [ncoDrawerOpen, setNcoDrawerOpen] = useState(false)
  ///////////////////////////////////////////////
  //////// Approval State ///////
  const [approvalState, setApprovalState] = useState({
    hasProducts: newDeal?.products?.length > 0,
    needsApproval: newDeal?.needsApproval ?? false,
    status: newDeal?.status ?? 'Draft',
    stage: newDeal?.stage ?? 'Discovery',
    isApproved: newDeal?.isApproved ?? false,
    canCreateContract: newDeal?.canCreateContract ?? false,
    isCompleted: newDeal?.isCompleted ?? false,
  })

  const [originalApprovalState, setOriginalApprovalState] = useState({
    hasProducts: originalDeal?.products?.length > 0,
    needsApproval: originalDeal?.needsApproval ?? false,
    status: originalDeal?.status ?? 'Draft',
    stage: originalDeal?.stage ?? 'Discovery',
    isRequested: originalDeal.isRequested ?? false,
    isApproved: originalDeal?.isApproved ?? false,
    canCreateContract: originalDeal?.canCreateContract ?? false,
    isCompleted: newDeal?.isCompleted ?? false,
  })
  ////////////////////////////
  const { mutate: createDeal } = useCreateDeal()
  const { mutate: updateItems } = useUpdateItems()
  const { mutate: createDocument } = useCreateDocument()
  const { mutate: updateDeal } = useUpdateDeal()
  const { showSnackbar } = useSnackbarContext()
  const [isCreating, setIsCreating] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [validationErrors, setValidationErrors] = useState({
    targetLaunchDate: false,
    dependenciesDueDate: false,
    authorizedRecipient: false,
    contactRecipient: false,
    recipients: false,
    products: false,
    startDate: false,
    endDate: false,
  })
  // calculate totals
  const calculateMediaSpend = (price, marginPercentage) => {
    const marginValue = parseFloat(price) * (parseFloat(marginPercentage) / 100)
    const newMediaSpend = (parseFloat(price) - marginValue).toFixed(2)
    return newMediaSpend
  }

  const calculateTotalForRow = row => {
    // If the payer is 'Client', use the billed price
    if (row.payer === 'Client') {
      return parseFloat(row.billedPrice || 0)
    }

    // Otherwise, use the standard calculation for price * quantity
    return parseFloat(row.price || 0) * parseFloat(row.qty || 1)
  }

  const computeTotals = (deal, productRows) => {
    const grossProfit = productRows.reduce((acc, row) => acc + parseFloat(row.grossProfit || 0), 0)
    const monthlyTotal = productRows.reduce((acc, row) => acc + parseFloat(row.total || 0), 0)
    const implementationFee = parseFloat(deal?.implementationFee || 0)
    const total = monthlyTotal
    return { grossProfit, monthlyTotal, implementationFee, total, totalGrossProfit }
  }
  // Validation
  const validateFields = deal => {
    // Ensure recipients object exists with default empty arrays
    const recipients = deal?.recipients || { authorizedRecipient: [], contactRecipient: [] }

    const errors = {
      targetLaunchDate: !deal?.targetLaunchDate,
      dependenciesDueDate: !deal?.dependenciesDueDate,

      authorizedRecipient: !(
        Array.isArray(recipients.authorizedRecipient) && recipients.authorizedRecipient.length > 0
      ), // Check if array exists and has length
      contactRecipient: !(Array.isArray(recipients.contactRecipient) && recipients.contactRecipient.length > 0), // Check if array exists and has length
      products:
        (Array.isArray(deal?.products) && deal.products.length === 0) ||
        (Array.isArray(deal?.products) && deal.products.some(product => !product.productName)),
      startDate: !deal?.startDate,
      endDate: !deal?.endDate,
    }

    setValidationErrors(errors)
    return !Object.values(errors).some(Boolean) // Return whether any of the errors are true
  }

  const handleUpdateDeal = async (dealId, updatedDeal, onSuccess, onError) => {
    setIsCreating(true)
    updateDeal(
      {
        dealId,
        dealData: updatedDeal,
      },
      {
        onSuccess: data => {
          setIsCreating(false)
          showSnackbar('Deal updated successfully', 'success')
          onSuccess && onSuccess(data)
        },
        onError: error => {
          setIsCreating(false)
          setIsError(true)
          showSnackbar('Error updating deal', 'error')
          onError && onError(error)
        },
      }
    )
  }
  // Product Row Functions
  const addRow = async scrollRef => {
    setHasProducts(true)

    const startDate = newDeal.startDate ? dayjs(newDeal.startDate) : null
    const endDate = newDeal.endDate ? dayjs(newDeal.endDate) : null
    const totalMonthsForDeal = calculateMonthsForDeal(startDate, endDate)

    const newRow = {
      instanceId: uuidv4(),
      rowId: `${productRows.length + 1}`,
      productName: '',
      description: '',
      addtlDescriptor: '',
      qty: totalMonthsForDeal ? totalMonthsForDeal : '1.00',
      price: '0.00',
      billedPrice: '0.00',
      margin: '0.00',
      mediaSpend: '0.00',
      total: '0.00',
      dateRange: [startDate, endDate],
      startDate: startDate,
      endDate: endDate,
      status: '',
      grossProfit: '0.00',
      selectedProduct: null,
    }

    setProductRows(prevRows => {
      const updatedRows = [...prevRows, newRow]
      // Merge updated strategy state into newDeal, ensuring that strategy data is stored along with products
      setNewDeal(prevDetails => ({
        ...prevDetails,
        products: updatedRows, // Update products with new rows
        hasProducts: true, // Ensure hasProducts is set to true
      }))
      // Scroll to the bottom after setting state
      setTimeout(() => {
        scrollRef.current?.scrollTo({ top: scrollRef.current.scrollHeight, behavior: 'smooth' })
      }, 0)

      return updatedRows
    })
  }

  const removeRow = async instanceId => {
    setProductRows(prevRows => {
      const updatedRows = prevRows.filter(row => row.instanceId !== instanceId)

      // Step 2: Update newDeal with the filtered rows and updated strategy state
      setNewDeal(prevDetails => ({
        ...prevDetails,
        products: updatedRows, // Update products with filtered rows
        hasProducts: updatedRows.length > 0, // Update hasProducts based on remaining rows
      }))

      return updatedRows
    })
  }
  const calculateGrossProfit = (price, mediaSpend) => {
    return (parseFloat(price) - parseFloat(mediaSpend)).toFixed(2)
  }
  ///////////////////////////// handleProductChange ////////////////////////////
  /////////////////////////////////////////////////////////////////////////////

  const handleProductChange = (index, field, value, additionalUpdates = {}) => {
    const updatedRows = productRows.map((row, i) => {
      if (i === index) {
        const updatedRow = {
          ...row,
          [field]: value,
          ...additionalUpdates,
        }
        updatedRow.mediaSpend = calculateMediaSpend(updatedRow.price, updatedRow.margin)
        updatedRow.grossProfit = calculateGrossProfit(updatedRow.price, updatedRow.mediaSpend)
        // If date range changes, recalculate quantity
        if (field === 'dateRange') {
          const startDate = value[0]
          const endDate = value[1]
          const totalMonthsForProduct = calculateTotalMonthsForSingleProduct(startDate, endDate)
          updatedRow.startDate = startDate
          updatedRow.endDate = endDate
          updatedRow.qty = totalMonthsForProduct || 1
        }

        // Update based on payer
        if (field === 'payer' && value === 'Client') {
          updatedRow.margin = clientMargin || 0
          updatedRow.mediaSpend = calculateMediaSpend(updatedRow.price, updatedRow.margin)
          updatedRow.grossProfit = (parseFloat(updatedRow.price) - parseFloat(updatedRow.mediaSpend)).toFixed(2)
          updatedRow.billedPrice = parseFloat(updatedRow.grossProfit).toFixed(2) // Use gross profit as billed price
        }

        if (field === 'payer' && value === 'GRO') {
          updatedRow.margin = paidProductsMargins[row.productName]
          updatedRow.mediaSpend = calculateMediaSpend(updatedRow.price, paidProductsMargins[row.productName])
        }
        // Handle price, margin, and totals update
        if (['price', 'margin', 'grossProfit'].includes(field)) {
          updatedRow.mediaSpend = calculateMediaSpend(updatedRow.price, updatedRow.margin)
          updatedRow.grossProfit = calculateGrossProfit(updatedRow.price, updatedRow.mediaSpend)

          if (updatedRow.payer === 'Client') {
            updatedRow.billedPrice = updatedRow.grossProfit // For Client, use gross profit as the billed price
          }

          updatedRow.total = calculateTotalForRow(updatedRow) // Calculate total based on payer
        }

        return updatedRow
      }
      return row
    })

    setProductRows(updatedRows)

    // Update the deal with the new productRows
    setNewDeal(prevDetails => {
      const updatedDeal = {
        ...prevDetails,
        products: updatedRows,
      }

      const isValid = validateFields(updatedDeal)
      setIsValid(isValid)

      return updatedDeal
    })
    const newTimelineData = updateContractTimelineData(
      newDeal?.deal_uuid,
      activeUser,
      'Product Update',
      'productUpdate',
      'Product(s) Updated',
      `${field} : ${JSON.stringify(value)}`
    )
    setTimelineData(newTimelineData)
  }

  /////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////

  ////////////////////////// handleChange //////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////
  const handleChange = (field, newValue, organization = {}) => {
    console.log('handleChangeTriggered')
    setNewDeal(prevDetails => {
      const updatedDeal = { ...prevDetails }

      // Handle specific fields like implementationFee or recipients
      if (field === 'implementationFee') {
        updatedDeal[field] = parseFloat(newValue) || 0
      } else if (field === 'authorizedRecipient') {
        console.log('authorizedRecipient CHANGE DETECTED: ', 'NEW VALUE: ', newValue)

        const recipients = newValue.map(recipient => ({
          id: recipient.id || recipient.contacts_uuid || recipient.user_uuid,
          contact_id: recipient?.panda_contact_id || recipient?.contact_id || recipient?.id,
          email: recipient['Employee Email'] || recipient.email,
          first_name: recipient['First Name'] || recipient.firstName || recipient.first_name,
          last_name: recipient['Last Name'] || recipient.lastName || recipient.last_name,
          role: field === 'authorizedRecipient' ? 'Authorized Personnel' : 'Client',
          signing_order: field === 'authorizedRecipient' ? 1 : 2,
        }))

        updatedDeal.recipients = {
          ...updatedDeal.recipients,
          [field]: recipients,
        }
      } else if (field === 'contactRecipient') {
        console.log('contactRecipient CHANGE DETECTED: ', 'NEW VALUE: ', newValue)

        const recipients = newValue.map(recipient => ({
          id: recipient.id || recipient.contacts_uuid || recipient.user_uuid,
          contact_id: recipient?.panda_contact_id || recipient?.contact_id || recipient?.id,
          email: recipient['Employee Email'] || recipient.email,
          first_name: recipient['First Name'] || recipient.firstName || recipient.first_name,
          last_name: recipient['Last Name'] || recipient.lastName || recipient.last_name,
          role: 'Client',
          signing_order: field === 'contactRecipient' ? 1 : 2,
        }))

        updatedDeal.recipients = {
          ...updatedDeal.recipients,
          [field]: recipients,
        }
        console.log('updatedDeal', updatedDeal)
      } else if (field === 'numberOfPayments') {
        updatedDeal[field] = parseFloat(newValue) || 0
      } else if (field === 'type') {
        // Check against originalDeal.type, not newDeal.type
        if (newValue !== originalDeal.type) {
          // Type has changed, update the name, status, and approval state
          const updatedApprovalState = handleEditDealState(
            hasProducts,
            updatedDeal,
            originalDeal.type,
            newValue,
            organization,
            originalApprovalState
          )
          if ([updatedDeal?.name].includes('undefined')) {
            updatedDeal.name = `${organization?.name} - ${newValue}`
          } else {
            updatedDeal.name = `${organization?.name} - ${newValue}` || updatedDeal.name
          }
          updatedDeal.dealStatus = updatedDeal.dealStatus || 'Active'
          updatedDeal.stage = updatedApprovalState.stage || 'Discovery'
          updatedDeal.status = updatedApprovalState.status || 'Draft'
          updatedDeal.type = newValue // Update the type in the deal
          updatedDeal.isApproved = false
          updatedDeal.isRequested = false
          updatedDeal.typeChange = true
          // updatedDeal.approvers = []
        } else {
          const hasProducts = newDeal?.products?.length > 0
          // Revert to original type, approval state, and deal details
          updatedDeal.name = organization ? `${organization.name} - ${originalDeal.type}` : updatedDeal.name
          updatedDeal.dealStatus = originalDeal.dealStatus
          updatedDeal.stage = hasProducts ? originalApprovalState.stage : 'Discovery'
          updatedDeal.status = hasProducts ? originalApprovalState.status : 'Draft'
          updatedDeal.type = originalDeal.type // Revert the type back to the original
          updatedDeal.typeChange = false
          updatedDeal.isApproved = originalDeal.isApproved
          updatedDeal.isRequested = originalDeal.isRequested
        }
      } else {
        // For any other field, just update the value
        updatedDeal[field] = newValue
      }

      // Validate and return updated deal
      const isValid = validateFields(updatedDeal)
      setIsValid(isValid)
      return updatedDeal
    })
    const newTimelineData = updateContractTimelineData(
      newDeal?.deal_uuid,
      activeUser,
      'Update',
      'update',
      'Field updated',
      `${field} : ${JSON.stringify(newValue)}`
    )
    setTimelineData(newTimelineData)
  }

  /////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (!dealData) return // Early return if dealData is not defined
    console.log('[DealDetailsProvider] Deal Data in useEffect: ', dealData)
    setNewDeal({
      ...dealData,
      totals:
        dealData && dealData.totals
          ? {
              ...dealData.totals,
              totalGrossProfit: dealData && dealData.totals.totalGrossProfit ? dealData.totals.totalGrossProfit : 0,
            }
          : {},
      isDraft: draftStatuses?.includes(dealData?.status) ? true : false,
    })
    setOriginalDeal(dealData)
    setHasProducts(dealData?.products?.length > 0)

    // Ensure that organizations are part of dealData and not cleared
    if (dealData.organizations) {
      // console.log('organizations detected: ', dealData.organizations)
      setNewDeal(prevDeal => ({ ...prevDeal, organizations: dealData.organizations }))
    }

    if (dealData.products) {
      // console.log('PRODUCTS DETECTED: ', dealData.products)
      const updatedContractProducts = dealData.products.map((row, index) => {
        const startDate = row.startDate ? dayjs(row.startDate) : null
        const endDate = row.endDate ? dayjs(row.endDate) : null
        const dateRange = row?.dateRange ? row?.dateRange : startDate && endDate ? [startDate, endDate] : [null, null]
        const totalMonthsForProduct = calculateTotalMonthsForSingleProduct(startDate, endDate)
        const productPrice =
          row.payer === 'Client' && row.margin === 0
            ? 0
            : row.payer === 'Client' && row.margin > 0
            ? parseFloat(row.grossProfit)
            : parseFloat(row.price)
        return {
          ...row, // Preserve all fields in the product row, including new ones
          instanceId: row.instanceId || uuidv4(), // Keep existing instanceId or generate a new one
          rowId: index.toString(), // Ensure rowId is present
          productName: row.productName || row.selectedProduct?.title, // Handle product name
          qty: totalMonthsForProduct || row.qty || '1.00', // Handle quantity calculation
          dateRange: dateRange,
          startDate, // Update startDate as Dayjs object
          endDate, // Update endDate as Dayjs object
          payer: row.payer || 'GRO', // Default payer
          billedPrice: productPrice,
          grossProfit: calculateGrossProfit(row.price, row.mediaSpend),
        }
      })

      setProductRows(updatedContractProducts)
    }
  }, [dealData])

  ////////////////////////////////// Deal Approvals /////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////
  const requestApproval = async (payload, dealData) => {
    console.log('payload', payload, 'dealData', dealData)
    try {
      sendApproverNotification(payload, {
        onSuccess: () => {
          showSnackbar('Request successfully sent', 'success')
          handleUpdateDeal(newDeal.deal_uuid, dealData)
        },
        onError: error => {},
      })
    } catch (error) {
      console.error('Error requesting approval:', error)
    }
  }

  const handleRequestApproval = async (requestor, activeUser, approvers) => {
    console.log('[PROVIDER]activeUser', activeUser, 'approvers', approvers, 'requestor', requestor)
    // const payload = createRequestMessage(newDeal, activeUser.user.id, approvers)
    const payload = createApproversNotification(
      newDeal,
      activeUser && activeUser.user ? activeUser.user.id : activeUser.id,
      approvers
    )
    const dealData = {
      ...newDeal,
      isRequested: true,
      requestedBy: activeUser && activeUser.user ? activeUser.user.id : activeUser.id,
      approvers: approvers,
      status: 'Approval Requested',
      requestedTime: new Date(),
      requestor: requestor
        ? {
            id: requestor.id,
            name: requestor.name,
            email: requestor.email,
            image: requestor.image_512,
            hasRequested: true,
          }
        : {},
    }
    console.log('payload: ', payload, 'dealData: ', dealData)

    try {
      await requestApproval(payload, dealData)
    } catch (error) {
      console.error(error)
    }
  }

  const resetState = () => {
    // Reset newDeal and also reset productRows to match originalDeal products
    setNewDeal({
      ...originalDeal,
      totals: {
        ...originalDeal.totals,
        totalGrossProfit: totalGrossProfit,
      },
      isApproved: originalDeal?.isApproved,
      isCompleted: originalDeal?.isCompleted,
      isCreated: originalDeal?.isCreated,
      isDraft: originalDeal?.isDraft,
      isInternal: originalDeal?.isInternal,
      isRequested: originalDeal?.isRequested,
    })
    // setNewDeal(prev => ({ ...prev, totals: { ...prev.totals, totalGrossProfit: totalGrossProfit } }))
    // Reset productRows based on the original deal's products
    if (originalDeal.products) {
      const updatedContractProducts = originalDeal.products.map((row, index) => {
        const startDate = row && row.startDate ? dayjs(row.startDate) : null
        const endDate = row && row.endDate ? dayjs(row.endDate) : null
        const totalMonthsForProduct = calculateTotalMonthsForSingleProduct(startDate, endDate)
        return {
          rowId: index.toString(),
          instanceId: row?.instanceId,
          productName: row.productName || row.selectedProduct?.title,
          category: row.category_name || '',
          subcategory: row.subcategory || '',
          description: row.description || '',
          addtlDescriptor: row.addtlDescriptor || '',
          qty: totalMonthsForProduct || row.qty || '1.00',
          price: row.price || '0.00',
          billedPrice: row.billedPrice || '0.00',
          margin: row.payer === 'Client' ? '0.00' : row.margin ? row.margin : '0.00',
          mediaSpend: row.mediaSpend || '0.00',
          total: row.total || '0.00',
          startDate: row.startDate ? dayjs(row.startDate) : null,
          endDate: row.endDate ? dayjs(row.endDate) : null,
          status: row.status || '',
          grossProfit: calculateGrossProfit(row.price, row.mediaSpend) || '0.00',
          // grossProfit: row.grossProfit || '0.00',
          payer: row.payer || 'GRO',
          accountUsed: row.accountUsed || 'GRO',
        }
      })
      setProductRows(updatedContractProducts)
    } else {
      setProductRows([]) // If no products exist in originalDeal
    }
  }
  const onDragEnd = result => {
    // console.log('[onDragEnd] START: productRows: ', productRows)
    const { source, destination } = result
    // console.log('source: ', source, 'destination: ', destination)
    // If there's no destination, exit early
    if (!destination) return

    // If the item was dropped in the same place, exit early
    if (source.index === destination.index) return

    // Make a copy of the current rows and reorder them
    const updatedRows = Array.from(productRows)
    console.log('[onDragEnd] END productRows: ', productRows)
    const [movedItem] = updatedRows.splice(source.index, 1)
    console.log('[onDragEnd] movedItem: ', movedItem)
    updatedRows.splice(destination.index, 0, movedItem)

    // Update the state with reordered rows
    setProductRows(updatedRows)
  }
  ///////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////

  const handleCreateTimelineData = (id, activeUser, action, type, details, message) => {
    const newTimelineData = {
      id: id,
      timeline_uuid: id,
      dataset: 'deals',
      user: {
        id: activeUser?.user?.id || activeUser?.id || '',
        name: activeUser?.real_name,
        image: activeUser?.profile?.image_512 || activeUser?.user?.profile?.image_512,
      },
      action: action,
      type: type,
      details: details,
      message: message,
    }
    console.log('newTimelineData', newTimelineData)
    setTimelineData(newTimelineData)
  }

  const handleCampaignChange = (campaignKey, updatedCampaign) => {
    console.log('campaignKey', campaignKey, 'updatedCampaign', updatedCampaign)
    const campaignType = campaignKey.split('-')[0]
    setStrategy(prevState => {
      if (campaignType === 'searchCampaigns') {
        const updatedPaidSearch = prevState?.paidSearch?.map(campaign =>
          campaign.key === campaignKey ? updatedCampaign : campaign
        )
        return { ...prevState, paidSearch: updatedPaidSearch }
      } else if (campaignType === 'socialCampaigns') {
        const updatedPaidSocial = prevState?.paidSocial?.map(campaign =>
          campaign.key === campaignKey ? updatedCampaign : campaign
        )
        return { ...prevState, paidSocial: updatedPaidSocial }
      } else if (campaignType === 'socialAds') {
        const updatedSocialAds = prevState?.socialAds?.map(campaign =>
          campaign.key === campaignKey ? updatedCampaign : campaign
        )
        return { ...prevState, socialAds: updatedSocialAds }
      }
      return prevState
    })
  }
  const handleStartEdit = () => {
    setIsEditMode(true)
  }
  const handleStopEdit = () => {
    setIsEditMode(false)
  }
  const handleOpenView = mode => {
    setViewMode(mode)
  }
  const handleCloseView = () => {
    setViewMode('strategy')
  }

  const handleDrawerOpen = () => {
    setDrawerOpen(true)
  }
  const handleDrawerClose = () => {
    setDrawerOpen(false)
  }
  const handleNcoDrawerOpen = () => {
    setNcoDrawerOpen(true)
  }
  const handleNcoDrawerClose = () => {
    setNcoDrawerOpen(false)
  }

  return (
    <DealContext.Provider
      value={{
        dealId,
        setDealId,
        newDeal,
        setNewDeal,
        productRows,
        setProductRows,
        originalApprovalState,
        approvalState,
        setApprovalState,
        isCreating,
        isError,
        handleUpdateDeal,
        createDocument,
        updateItems,
        validateFields,
        validationErrors,
        setValidationErrors,
        isValid,
        setIsValid,
        calculateMediaSpend,
        calculateTotalForRow,
        computeTotals,
        addRow,
        removeRow,
        handleProductChange,
        handleChange,
        handleRequestApproval,
        // handleApproveDeal,
        drawerOpen,
        handleDrawerOpen,
        handleDrawerClose,
        ncoDrawerOpen,
        handleNcoDrawerOpen,
        handleNcoDrawerClose,
        originalDeal,
        onDragEnd,
        //strategy//
        timelineData,
        handleCreateTimelineData,
        searchCampaigns,
        setSearchCampaigns,
        socialCampaigns,
        setSocialCampaigns,
        socialAds,
        setSocialAds,
        displayCampaigns,
        setDisplayCampaigns,
        // currentStrategy,
        // isStrategyLoading,
        // isStrategyError,
        currentStrategy,
        strategy,
        setStrategy,
        handleCampaignChange,
        handleStartEdit,
        handleStopEdit,
        isEditMode,
        setIsEditMode,
        viewMode,
        handleOpenView,
        handleCloseView,
        resetState,
        isDealStatusChange,
        setIsDealStatusChange,
        // findContactById,
      }}
    >
      {children}
    </DealContext.Provider>
  )
}
